import { Avatar, Button, Col, DatePicker, Form, Input, Popover, Row, Select, Space, Table } from 'antd';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import lineService from '../../../services/production/line.service';
import { PlusOutlined } from '@ant-design/icons';
import moment from 'moment';
import SearchMaterial from '../bom/search_material';
import materialService from '../../../services/config/material.service';
import bomService from '../../../services/production/bom.service';
import userService from '../../../services/admin/user.service';

const { TextArea } = Input;
const NewWorkOrder = ({ form }) => {
    const [state, setState] = useState({
        loading : false,
        visibleIndex : null,
        lines : [],
        users : [],
        boms : [],

        // selected
        selected_products : [],
        select_loading : false,
        material_modal : null,
    })

    const initializeSelections = async () => {
        // fetch production lines
        const productionLineResponse = await lineService?.getLines();
        // fetch users
        const userResponse = await userService?.getUsers();
        setState(state => ({...state, lines : productionLineResponse?.lines, users : userResponse?.users,}));
    }

    const addRow = async () => {
        const generate_uid = (`${Math.random()}_${moment()?.format("YYYY-MM-DD")?.toString()}`);
        let material_arr = [...state?.selected_products, { uid : generate_uid, material_id : null, material : null, quantity : null, bom_id : null, }];

        setState(state => ({...state, selected_products : material_arr, }));
        //dynamic set form
        form.setFieldsValue({ productList : material_arr, });
    }

    const onRowChange = async (index, key, value) => {
        let selected_products = state?.selected_products ?? [];
        selected_products[index][key] = value;
        selected_products[index].updated = true;

        setState(state => ({...state, selected_products : selected_products, }));
        // //dynamic set form
        form.setFieldsValue({ productList : selected_products, });
    }

    const customFunction = async (index, material_id) => {
        const materialResponse = await materialService?.getMaterialDetail(material_id);
        const material = materialResponse?.material;

        let selected_products = state?.selected_products ?? [];
        setState(state => ({...state, select_loading : index }));
        selected_products[index].material_id = material_id;
        selected_products[index].material = material;
        selected_products[index].updated = true;
        selected_products[index].quantity = 0;
        // selected_products[index].bom_id = null;
        setState(state => ({...state, visibleIndex : null }));

        setTimeout(() => {
            setState(state => ({...state, selected_products : selected_products, select_loading : null, }));
            //dynamic set form
            form.setFieldsValue({ productList : selected_products, });
        }, 200);
    }

    const handleOpenChange = (e, index) =>
    {
        if(e === true)
        {
            setState(state => ({...state, visibleIndex : index}));
        } else {
            setState(state => ({...state, visibleIndex : null}));
        }
    };

    const fetchInitialization = async () => {
        const bomResponse = await bomService?.getBomList({});
        setState(state => ({...state, boms : bomResponse?.boms,}))
    }

    useEffect(() => {
        fetchInitialization();
    }, [])

    useEffect(() => {
        initializeSelections();
    }, [])

    return (
        <>
            <div>
                <div>
                    <div style={{background : '#edf2f7', border:'1.5px solid #cbd5e0', padding : '12px 12px 0 12px', borderRadius:8,}}>
                        <Form.Item name="title" label={(<span style={{color:'var(--secondary-text-color)', fontSize:12,}}>Title</span>)}>
                            <Input placeholder='Enter Title' />
                        </Form.Item>
                    </div>
                </div>
                <div style={{marginTop:12,}}>
                    <Row gutter={[20, 20]}>
                        <Col span={8}>
                            <Form.Item name={'production_line_id'} label={(<span style={{color:'var(--secondary-text-color)', fontSize:12,}}>Production Line</span>)}>
                                <Select
                                allowClear
                                placeholder="Select Production Line"
                                options={state?.lines?.map((line, index) => {
                                    const { production_line_id, name, } = line;
                                    return ({ value : production_line_id, label : name, })
                                })}
                                >

                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item name={'assignee_id'} label={(<span style={{color:'var(--secondary-text-color)', fontSize:12,}}>Assignee</span>)}>
                                <Select
                                placeholder={"Select Assignee"}
                                options={state?.users?.map((line, index) => {
                                    const { user_id, name, } = line;

                                    return (
                                        {   
                                            value : user_id, 
                                            label : (
                                                <>
                                                    <Space>
                                                        <Avatar size='small' style={{ backgroundColor: '#fde3cf', color: '#f56a00', }}>
                                                            {name?.split(' ')?.map(word => word.charAt(0).toUpperCase()).join('')}
                                                        </Avatar>
                                                        <span>{name}</span>
                                                    </Space>
                                                    
                                                </>
                                            ), 
                                        })
                                })}
                                >

                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item name={'issue_date'} label={(<span style={{color:'var(--secondary-text-color)', fontSize:12,}}>Issue Date</span>)}>
                                <DatePicker defaultValue={dayjs()} allowClear={false} style={{width : '100%'}} />
                            </Form.Item>
                        </Col>
                    </Row>
                </div>
                <div>
                    <Form.Item
                    className='width100-form-label'
                    name="productList"
                    labelCol={{style: {width: '100%'}}}
                    style={{width : '100%'}}
                    label={
                        (
                            <div style={{display:'flex', alignItems:'center', width:'100%',}}>
                                <div>
                                    <span style={{color:'var(--secondary-text-color)', fontSize:12,}}>Item</span>
                                </div>

                                <div style={{marginLeft:'auto'}}>
                                    <Button onClick={addRow}>Add Item</Button>
                                </div>
                            </div>
                        )
                    }
                    >
                        {
                            state?.selected_products?.length > 0 ?
                            (
                                <>
                                    <Table
                                    pagination={false}
                                    dataSource={state?.selected_products}
                                    scroll={{ x : 400, }}
                                    className='hub-table bordered'
                                    columns={[{
                                        title : 'Item',
                                        dataIndex : 'material',
                                        key : 'material',
                                        width : '20%',
                                        render : (default_material_id, row, index) => {
                                            const { material, material_id } = row ?? {};
                                            const is_loading = state?.select_loading === index;
                                            return (
                                                <>
                                                    <div>
                                                        <div>
                                                            <Popover
                                                            content={<SearchMaterial default_material_id={default_material_id} form={form} customFunction={(material_id) => customFunction(index, material_id,)} />}
                                                            trigger={['click']}
                                                            open={state?.visibleIndex === index}
                                                            onOpenChange={(e) => handleOpenChange(e, index)}
                                                            placement='bottomLeft'
                                                            overlayInnerStyle={{padding : 0,}}
                                                            >
                                                                <Button
                                                                loading={is_loading}
                                                                style={{width:'100%', textAlign:'start',}}
                                                                onClick={() => setState(state => ({...state, material_modal : true, }))}
                                                                >
                                                                    {material?.name}
                                                                </Button>
                                                            </Popover>
                                                        </div>
                                                    </div>
                                                </>
                                            )
                                        }
                                    },{
                                        title : 'BOM',
                                        dataIndex : 'bom_id',
                                        key : 'quantity',
                                        width : '20%',
                                        render : (bom_id, row, index) => {
                                            // const { material } = row;
                                            // const boms = material?.product_boms.filter(x => x.edit_status.is_complete == true);
                                            const boms = state?.boms.filter(x => x.edit_status.is_complete == true);
                                            return (
                                                <>
                                                    <Select
                                                    placeholder={'Select BOM'}
                                                    value={bom_id}
                                                    onChange={(e) => onRowChange(index, 'bom_id', e)}
                                                    options={boms?.map((bom, index) => {
                                                        const { name, bom_id } = bom ?? {};
                                                        return ({ label : name, value : bom_id, });
                                                    })}
                                                    />
                                                </>
                                            )
                                        }
                                    },{
                                        title : 'Quantity',
                                        dataIndex : 'quantity',
                                        key : 'quantity',
                                        width : '10%',
                                        render : (quantity, row, index) => {
                                            const { material, quantity : material_quantity } = row ?? {};
                                            return (
                                                <>
                                                    <Input
                                                    defaultValue={quantity}
                                                    value={material_quantity}
                                                    type='number'
                                                    onChange={(e) => onRowChange(index, 'quantity', e?.target?.value)}
                                                    suffix={
                                                        <>
                                                            <span style={{fontSize:12, color:'var(--secondary-text-color)'}}>{(material?.uom?.code)}</span>
                                                        </>
                                                    }
                                                    />
                                                </>
                                            )
                                        }
                                    },]}
                                    />
                                </>
                            )
                            :
                            (
                                <>
                                    <div>
                                        <div style={{background:'var(--secondary-button-color)', padding:24, borderRadius: 12, textAlign:'center',}}>
                                            <div>
                                                <div><span style={{fontSize:18, fontWeight:500,}}>No Items Added</span></div>
                                            </div>
                                            <div>
                                                <span style={{color:'var(--secondary-text-color)', fontSize:12, }}>You haven't select any items yet.</span>
                                            </div>

                                            <div style={{marginTop:24,}}>
                                                <Button onClick={addRow} icon={<PlusOutlined />} type='primary'>Add Items</Button>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )
                        }
                        
                    </Form.Item>
                </div>
                <div>
                    <Row gutter={[20, 20]}>
                        <Col span={12}>
                            <Form.Item name={'start_date'} label={(<span style={{color:'var(--secondary-text-color)', fontSize:12,}}>Start Date</span>)}>
                                <DatePicker style={{width : '100%'}} />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item name={'end_date'} label={(<span style={{color:'var(--secondary-text-color)', fontSize:12,}}>End Date</span>)}>
                                <DatePicker style={{width : '100%'}} />
                            </Form.Item>
                        </Col>
                    </Row>
                </div>
                <div>
                    <Form.Item name="remark" label={(<span style={{color:'var(--secondary-text-color)', fontSize:12,}}>Remarks</span>)}>
                        <TextArea rows={4} style={{ resize : 'none', }}></TextArea>
                    </Form.Item>
                </div>
            </div>
        </>
    );
}

export default NewWorkOrder;