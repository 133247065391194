import React from 'react';
import PageHeaderComponent from '../../../helpers/components/page_header.component';
import { Avatar, Col, Divider, Row, Space, Table, Tag } from 'antd';
import moment from 'moment';
import { BlockOutlined, CalendarOutlined, FileTextOutlined, Loading3QuartersOutlined, TeamOutlined } from '@ant-design/icons';

const WorkOrderTemplate = ({ work_order, work_order_products, work_order_materials }) => {
    const { innerWidth } = window;
    const is_xl = innerWidth >= 768;
    const align_items = 'start';
    console.log("worrk roder",work_order)
    return (
        <>
            <div style={{padding : 24,}}>
                <div>
                    <div style={{display : 'flex', alignItems:'center'}}>
                        <div>
                            <PageHeaderComponent title={'Work Order'} textAlign={'start'} />
                        </div>
                        <div style={{marginLeft:'auto', fontSize:12, color : 'var(--secondary-text-color)', textAlign:'right',}}>
                            <div>
                                <span>{`#${work_order?.running_number}`}</span>
                            </div>
                            <div>
                                <span>{moment(work_order?.issue_date)?.format("YYYY-MM-DD")}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{ textAlign:'start', }}>
                    <span>{work_order?.title}</span>
                </div>
                <Divider style={{marginTop:8,}} />
                <div style={{textAlign:'start',}}>
                    <Row gutter={[20, 20]}>
                        <Col span={6} xs={12} md={12} xl={12}>
                            <div style={{display:'flex', justifyContent: align_items,}}>
                                <div>
                                    <div style={{color:'var(--secondary-text-color)'}}>
                                        <Space>
                                            <div>
                                                <TeamOutlined />
                                            </div>
                                            <div>
                                                <span>Assignee</span>
                                            </div>
                                        </Space>
                                    </div>
                                    <div style={{marginTop:6,}}>
                                        <div style={{height:30, display:'flex', alignItems: 'center',}}>
                                            <Space>
                                                <div>
                                                    <Avatar style={{ backgroundColor: '#fde3cf', color: '#f56a00', }}>
                                                        {work_order?.assignee?.name?.split(' ')?.map(word => word.charAt(0).toUpperCase()).join('')}
                                                    </Avatar>
                                                </div>
                                                <div>
                                                    <span>{work_order?.assignee?.name}</span>
                                                </div>
                                            </Space>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col span={6} xs={12} md={12} xl={12}>
                            <div style={{display:'flex', justifyContent: align_items,}}>
                                <div>
                                    <div style={{color:'var(--secondary-text-color)'}}>
                                        <Space>
                                            <div>
                                                <BlockOutlined />
                                            </div>
                                            <div>
                                                <span>Production Line</span>
                                            </div>
                                        </Space>
                                    </div>
                                    <div style={{marginTop:6,}}>
                                        <div style={{height:30, display:'flex', alignItems:'center',}}>
                                            <span>{work_order?.production_line?.name}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col span={6} xs={12} md={12} xl={12}>
                            <div style={{display:'flex', justifyContent: align_items,}}>
                                <div>
                                    <div style={{color:'var(--secondary-text-color)'}}>
                                        <Space>
                                            <div>
                                                <Loading3QuartersOutlined />
                                            </div>
                                            <div>
                                                <span>Status</span>
                                            </div>
                                        </Space>
                                    </div>
                                    <div style={{marginTop:6,}}>
                                        <div style={{height:30, display:'flex', alignItems:'center',}}>
                                            <Tag color={work_order?.work_order_status?.color} style={{border:'none'}}>
                                                <span>{work_order?.work_order_status?.name}</span>
                                            </Tag>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col span={6} xs={12} xl={12}>
                            <div style={{display:'flex', justifyContent: align_items,}}>
                                <div>
                                    <div style={{color:'var(--secondary-text-color)'}}>
                                        <Space>
                                            <div>
                                                <CalendarOutlined />
                                            </div>
                                            <div>
                                                <span>Date</span>
                                            </div>
                                        </Space>
                                    </div>
                                    <div style={{marginTop:6,}}>
                                        <div style={{height:30, display:'flex', alignItems:'center',}}>
                                            <span>{moment(work_order?.start_date)?.format("YYYY-MM-DD")}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col span={6} xs={12} xl={12}>
                            <div style={{display:'flex', justifyContent: align_items,}}>
                                <div>
                                    <div style={{color:'var(--secondary-text-color)'}}>
                                        <Space>
                                            <div>
                                                <FileTextOutlined />
                                            </div>
                                            <div>
                                                <span>Remark</span>
                                            </div>
                                        </Space>
                                    </div>
                                    <div style={{marginTop:6,}}>
                                        <div style={{height:30, display:'flex', alignItems:'center',}}>
                                            <span>{work_order?.remark}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>

                <Divider />
                <div>
                    <div style={{textAlign:'start',}}>
                        <span style={{fontSize:18, fontWeight:500,}}>Item</span>
                    </div>
                    {/* empty table with populated item */}
                    <div style={{marginTop:12,}}>
                        <Table
                        pagination={false}
                        className='hub-table bordered'
                        // dataSource={[...Array(work_order?.products?.length > 5 ? work_order?.products?.length : 5)].map((x, i) => {
                        //     return x;
                        // })}
                        dataSource={work_order?.work_order_products}
                        columns={[{
                            title : 'Index',
                            dataIndex : 'index',
                            key : 'index',
                            width : '10%',
                            render : (text, row, index) => {
                                return (
                                    <>
                                        <span style={{fontSize:12, color:'var(--secondary-text-color)'}}>{(index + 1)}</span>
                                    </>
                                )
                            }
                        },{
                            title : 'Item',
                            dataIndex : 'material',
                            key : 'material',
                            width : '40%',
                            render : (material, row, index) => {
                                const { material_code, name, description } = material;
                                return (
                                    <>
                                        <div>
                                            <span>{name}</span>
                                        </div>
                                        <div>
                                            <span style={{ fontSize:12, color : 'var(--secondary-text-color)' }}>{material_code}</span>
                                        </div>
                                        <div>
                                            <span style={{ fontSize:12, color : 'var(--secondary-text-color)' }}>{description}</span>
                                        </div>
                                    </>
                                )
                            }
                        },{
                            title : 'BOM',
                            dataIndex : 'bom_id',
                            key : 'bom_id',
                            width : '40%',
                            render : (bom_id, row, index) => {
                                const { bom, } = row;
                                return (
                                    <>
                                        <div>
                                            <span>{bom?.name}</span>
                                        </div>
                                    </>
                                )
                            }
                        },{
                            title : 'Quantity',
                            dataIndex : 'quantity',
                            key : 'quantity',
                            width : '10%',
                            render : (quantity, row, index) => {
                                const { bom, material, work_order_product_id } = row;
                                return (
                                    <>
                                        <Space>
                                            <span>{quantity}</span>
                                            <span style={{ fontSize:12, color : 'var(--secondary-text-color)' }}>{material?.uom?.name}</span>
                                        </Space>
                                    </>
                                )
                            }
                        }]}
                        />
                    </div>
                </div>
                {
                    work_order?.remark &&
                    (
                        <>
                            <div style={{marginTop:24,}}>
                                <div style={{textAlign:'start',}}>
                                    <span style={{fontSize:18, fontWeight:500,}}>Remarks</span>
                                </div>
                                <div style={{marginTop:12,}}>
                                    <div>
                                    
                                    </div>
                                </div>
                            </div>
                        </>
                    )
                }
                
            </div>
        </>
    );
}

export default WorkOrderTemplate;