import React, { useEffect, useState } from 'react';
import { Button, Drawer, Form, Space, Spin, Table, Tag, notification } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { refresh_locations_list } from '../../../actions/warehouse.action';
import { DeleteOutlined } from '@ant-design/icons';
import locationMaterialService from '../../../services/warehouse/location_material.service';
import { refresh_location_material_list } from '../../../actions/warehouse.action';

const ReportList = ({ filter, }) => {
    const warehouseRedux = useSelector(state => state?.warehouse);
    const [state, setState] = useState({
        loading : false,
        save_loading : false,
        locationmaterial : [],
        count : 0,
        page : 0,
    });
    
    const getLocationMaterials = async () => {
        setState(state => ({...state, loading : true,}));
        const { search, location, material, warehouse } = filter;
            
        const locationMaterialResponse = await locationMaterialService.getLocationMaterials({
            search,
            location_ids : location,
            material_ids : material,
            warehouse_ids: warehouse,

            //pagination
            page : state?.page,    
        });

        const { count, rows : location_material } = locationMaterialResponse?.location_material;
        
        setTimeout(() => {
            setState(state => ({...state, loading : false, locationmaterial: location_material, count : count,}));
        }, 500);        
    }

    useEffect(() => {
        getLocationMaterials();
    }, [filter, state?.page, warehouseRedux?.location_material_list_refresh])

    // const fetchLocationMaterial = async () => {
    //     setState(state => ({...state, loading : true, }));
    //     const locationMaterialResponse = await locationMaterialService.getLocationMaterials();
    //     const { dataList:locationmaterial }  = locationMaterialResponse;
    //     setState(state => ({...state, loading : false, locationmaterial : locationmaterial, }));       
    // }

    // useEffect(() => {
    //     fetchLocationMaterial();
    // },[]);

    return (
        <>
            <Spin spinning={state?.loading}>
                <Table 
                pagination={{ 
                    size : 10,
                    showSizeChanger: false,
                    total : state?.count,
                    current : (state?.page + 1),
                    onChange : (page) => {
                        setState(state => ({...state, page : page - 1}));
                        // dispatch(set_list_page(page - 1));
                    }
                }} 
                scroll={{ x : 400, }}
                className='hub-table bordered'
                dataSource={state?.locationmaterial}
                columns={[{
                    title : 'Item Name',
                    dataIndex : 'material_name',
                    key : 'material_name',
                    width: '25%',
                    render: (material, row, index) => {
                        const { material_name, material_code } = row ?? {};
                        return (
                            <>
                                <div>
                                    <span>{material_name}</span>
                                </div>
                                <div>
                                    <span style={{ fontSize: 12, color: 'var(--secondary-text-color)' }}>{material_code}</span>
                                </div>
                            </>
                        )
                    }
                },{
                    title : 'Warehouse',
                    dataIndex : 'warehouse_name',
                    key : 'warehouse_name',
                    width: '25%',
                    render : (warehouse_name, row, index) => {
                        return (
                            <>
                                <div>
                                    <Tag color='var(--secondary-button-color)'>
                                        <span style={{fontSize:12, color:'var(--secondary-text-color)'}}>{warehouse_name}</span>
                                    </Tag>
                                </div>
                            </>
                        )
                    }
                },{
                    title : 'Location',
                    dataIndex : 'location_name',
                    key : 'location_name',
                    width: '25%',
                    render : (location_name, row, index) => {
                        return (
                            <>
                                <div>
                                    <Tag color='var(--secondary-button-color)'>
                                        <span style={{fontSize:12, color:'var(--secondary-text-color)'}}>{location_name}</span>
                                    </Tag>
                                </div>
                            </>
                        )
                    }
                },{
                    title : 'Quantity',
                    dataIndex : 'quantity',
                    key : 'quantity',
                    render : (quantity, row, index) => {
                        const { uomname, uomcode } = row ?? {};
                        return (
                            <>
                                <div>
                                    {
                                        quantity > 100 ?
                                        (
                                            <>
                                                {/* <Tag color='var(--secondary-button-color)'>
                                                    <span style={{fontSize:12, color:'#008000'}}>{quantity}</span>
                                                </Tag> */}
                                                    <Tag color={'green'} style={{ border: 'none', borderRadius: 4, }}>{quantity} {uomname}</Tag>
                                            </>
                                        ) 
                                        : 
                                        (
                                            <>
                                                {/* <Tag color='var(--secondary-button-color)'>
                                                    <span style={{fontSize:12, color:'#B22222'}}>{quantity}</span>
                                                </Tag> */}
                                                    <Tag color={'orange'} style={{ border: 'none', borderRadius: 4, }}>{quantity} {uomname}</Tag>
                                            </>
                                        )
                                    }


                                    {/* <Tag color='var(--secondary-button-color)'>
                                        <span style={{fontSize:12, color:'var(--secondary-text-color)'}}>{quantity}</span>
                                    </Tag> */}
                                </div>
                            </>
                        )
                    }
                }]}
                />
            </Spin>
        </>
    );
}

export default ReportList;