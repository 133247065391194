import React, { useState, useEffect } from 'react';
import SVG from 'react-inlinesvg';

const DynamicSvg = ({ image, color, size }) => {
    const dynamic_color = color ?? process.env.REACT_APP_COLOR;
    const dynamic_size = size ?? 40;
    return (
        <>
            <div>
                {/* Your SVG component */}
                <SVG 
                src={image}
                style={{ fill: dynamic_color, }}
                width={dynamic_size}
                height={dynamic_size}
                preProcessor={(code) => {
                    const preserved_fill_color = "#f8f7fa";
                    return (
                        code.replace(
                            new RegExp(`(fill=")(?!${preserved_fill_color}")[^"]*(")`, 'g'),
                            `$1${color}$2`
                        )
                    )
                }}
                />
            </div>
        </>
    );
}

export default DynamicSvg;