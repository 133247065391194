
import MenuComponent from '../../../helpers/components/menu.component';
import { Col, Divider, Row, Space, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import menuService from '../../../services/admin/menu.service';
import DynamicSvg from './../../../helpers/components/dynamic_svg';
import { RightOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import EntranceMotion from './../../../helpers/components/entrance_motion';

const WarehouseLayout = () => {
    const [state, setState] = useState({
        loading : false,
        module : null,
    })
    return (
        <>
            <MenuComponent />
            <Row align={'top'}>    
                    <Col xs={24} md={8} span={8}>
                        <div style={{textAlign:'start'}}>
                            <Space direction='vertical'>
                                <div>
                                    <span style={{fontSize:24, fontWeight:500,lineHeight:1,}}>Report</span>
                                </div>
                                <div>
                                    <span style={{color:'var(--secondary-text-color)'}}>{state?.module?.description}</span>
                                </div>
                            </Space>
                        </div>
                    </Col>
                    <Col xs={24} md={16} span={16}>
                        <div style={{textAlign:'start'}}>
                            {/* <Row gutter={[12, 12]}>
                                {
                                    <Col key={1} xs={12} lg={8} span={8}>
                                    <Link to={'/warehouse/report/'}>
                                        <EntranceMotion delay={(1 * 0.1)}>
                                            <div className='menu-grouper'>
                                                <Space size={12}>
                                                    <div style={{ display:'flex', height:40, width:40, border : '1px solid #e6e8eb', background:'#fff', borderRadius:'.5rem'}}>
                                                        <div style={{margin:'auto'}}>
                                                            <DynamicSvg image={'/menus/unordered_list.svg'} color={'#6f7287'} size={16} />
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <span style={{fontSize:16, color:'#11181c'}}>Location</span>
                                                    </div>

                                                    <div style={{marginLeft:'auto', color:'#c1c8cd'}}>
                                                        <RightOutlined className='transition-all ease-out right-arrow' style={{fontSize:10, }} />
                                                    </div>
                                                    
                                                </Space>
                                            </div>
                                        </EntranceMotion>
                                    </Link>
                                </Col>
                                }
                            </Row> */}
                            <Row gutter={[12, 12]}>
                                <Col key={1} xs={12} lg={8} span={8}>
                                    <Link to={'/warehouse/report/'}>
                                        <EntranceMotion delay={(1 * 0.1)}>
                                            <div className='menu-grouper'>
                                                <Space size={12}>
                                                    <div style={{ display:'flex', height:40, width:40, border : '1px solid #e6e8eb', background:'#fff', borderRadius:'.5rem'}}>
                                                        <div style={{margin:'auto'}}>
                                                            <DynamicSvg image={'/menus/unordered_list.svg'} color={'#6f7287'} size={16} />
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <span style={{fontSize:16, color:'#11181c'}}>Location</span>
                                                    </div>

                                                    <div style={{marginLeft:'auto', color:'#c1c8cd'}}>
                                                        <RightOutlined className='transition-all ease-out right-arrow' style={{fontSize:10, }} />
                                                    </div>
                                                    
                                                </Space>
                                            </div>
                                        </EntranceMotion>
                                    </Link>
                                </Col>
                                <Col key={1} xs={12} lg={8} span={8}>
                                    <Link to={'/warehouse/stock/'}>
                                        <EntranceMotion delay={(1 * 0.1)}>
                                            <div className='menu-grouper'>
                                                <Space size={12}>
                                                    <div style={{ display:'flex', height:40, width:40, border : '1px solid #e6e8eb', background:'#fff', borderRadius:'.5rem'}}>
                                                        <div style={{margin:'auto'}}>
                                                            <DynamicSvg image={'/menus/unordered_list.svg'} color={'#6f7287'} size={16} />
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <span style={{fontSize:16, color:'#11181c'}}>Stock</span>
                                                    </div>

                                                    <div style={{marginLeft:'auto', color:'#c1c8cd'}}>
                                                        <RightOutlined className='transition-all ease-out right-arrow' style={{fontSize:10, }} />
                                                    </div>
                                                    
                                                </Space>
                                            </div>
                                        </EntranceMotion>
                                    </Link>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
        </>
    );
}

export default WarehouseLayout;