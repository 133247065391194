import { Button, Form, Input, Popover, Select } from 'antd';
import React, { useState, useEffect, } from 'react';
import SearchMaterial from '../bom/search_material';
import materialService from '../../../services/config/material.service';
import bomService from '../../../services/production/bom.service';

const NewWorkOrderProductForm = ({ form, refresh }) => {
    const [state, setState] = useState({
        open : false,
        material_modal : false,
        material : null,
        boms : null,

        select_loading : false,
    })

    const customFunction = async (material_id) => {
        setState(state => ({...state, select_loading : true}));
        const materialResponse = await materialService?.getMaterialDetail(material_id);
        const material = materialResponse?.material;
        setState(state => ({...state, select_loading : false, material : material, open : false, }));
        form.setFieldsValue({ material_id : material_id, });
    }

    const handleOpenChange = (newOpen) => {
        setState(state => ({...state, open : newOpen}));
    };

    const fetchInitialization = async () => {
        const bomResponse = await bomService?.getBomList({});
        setState(state => ({...state, boms : bomResponse?.boms,}))
    }

    useEffect(() => {
        fetchInitialization();
    }, [])

    useEffect(() => {
        setState(state => ({...state, material : null, }));
    }, [refresh])

    return (
        <>
            <Form.Item name={'material_id'} label={(<><span style={{fontSize:12, color : 'var(--secondary-text-color)'}}>Item</span></>)}>
                <div>
                    <Popover
                    content={<SearchMaterial form={form} customFunction={(material_id) => customFunction(material_id,)} />}
                    trigger={['click']}
                    open={state?.open}
                    onOpenChange={handleOpenChange}
                    placement='bottomLeft'
                    overlayInnerStyle={{padding : 0,}}
                    >
                        <Button
                        loading={state?.select_loading}
                        style={{width:'100%', textAlign:'start',}}
                        onClick={() => setState(state => ({...state, material_modal : true, }))}
                        >
                            {state?.material?.name}
                        </Button>
                    </Popover>
                </div>
            </Form.Item>
            <Form.Item name={'bom_id'} label={(<><span style={{fontSize:12, color : 'var(--secondary-text-color)'}}>BOM</span></>)}>
                <Select
                options={state?.boms?.filter(x => x.edit_status.is_complete == true).map((bom, index) => {
                    return {
                        label : bom?.name,
                        value : bom?.bom_id,
                    }
                })}
                placeholder={'Select BOM'}
                />
            </Form.Item>
            <Form.Item name={'quantity'} label={(<><span style={{fontSize:12, color : 'var(--secondary-text-color)'}}>Quantity</span></>)}>
                <Input
                // defaultValue={quantity}
                // value={material_quantity}
                type='number'
                // onChange={(e) => onRowChange(index, 'quantity', e?.target?.value)}
                suffix={
                    <>
                        <span style={{fontSize:12, color:'var(--secondary-text-color)'}}>{(state?.material?.uom?.code)}</span>
                    </>
                }
                />
            </Form.Item>
        </>
    );
}

export default NewWorkOrderProductForm;