import React, { useEffect, useState, useRef } from 'react';
import { Button, Spin } from 'antd';
import { DownloadOutlined, PrinterOutlined } from '@ant-design/icons';
import ReactToPrint, { PrintContextConsumer } from 'react-to-print';
import OutboundTemplate from './outbound_template';
import outboundService from '../../../services/warehouse/outbound.service';

const OutboundFile = ({ outbound_id, inoutbound_status_id, }) => {
    const componentRef = useRef();
    const [state, setState] = useState({
        loading : false,
        outbound : null,
        outboundMaterial : null,
    })

    const fetchOutbound = async () => {
        setState(state => ({...state, loading : true, }));
        const outboundResponse = await outboundService?.getOutboundDetail(outbound_id);
        const { dataList:outbound } = outboundResponse;
        setState(state => ({...state, loading : false, outbound : outbound}));
    }

    const fetchOutboundMaterials = async () => {
        setState(state => ({...state, loading : true, }));
        const outboundMaterialsResponse = await outboundService?.getOutboundMaterials({outbound_id:outbound_id});
        const { dataList:outboundMaterial } = outboundMaterialsResponse;
        setState(state => ({...state, loading : false, outboundMaterial : outboundMaterial}));
    }

    useEffect(() => {
        fetchOutbound();
    }, [inoutbound_status_id])

    useEffect(() => {
        fetchOutboundMaterials();
    }, [outbound_id])

    return (
        <>
            <div>
                <div style={{display:'flex',}}>
                    <div style={{marginLeft:'auto'}}>
                        <ReactToPrint 
                        trigger={() => (
                            <Button icon={<PrinterOutlined />}>Print</Button>
                        )}
                        content={() => componentRef.current}
                        pageStyle={`@page {
                            margin: 24px;
                        }`}
                        />
                    </div>
                </div>
                <div style={{marginTop:12,}}>
                    <div style={{ margin : 'auto', display: 'flex', justifyContent:'center', }}>
                        <div className='card-shadow' style={{width: 800, minHeight: 1000, }}>
                            <Spin spinning={state?.loading}>
                                <div ref={componentRef}>
                                    <OutboundTemplate outbound={state?.outbound} outboundMaterial={state?.outboundMaterial} />
                                </div>
                            </Spin>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default OutboundFile;