import React, { useEffect, useState } from 'react';
import MenuTitleComponent from '../../../helpers/components/menu_title.component';
import { Button, Checkbox, DatePicker, Input, Space, Form, notification, List, Radio } from 'antd';
import { CaretDownFilled, DownloadOutlined, SearchOutlined, CloseCircleFilled } from '@ant-design/icons';
import FilterBullet from '../../../helpers/components/filter_bullet';
import { useDispatch } from 'react-redux';
import locationMaterialLogsService from '../../../services/warehouse/location_material_log.service';
import TransactionsList from './transactions_list';
import moment from 'moment';
import GeneralLabel from '../inbound/general_label';
import { CSVLink } from 'react-csv';
import globalHelper from '../../../helpers/functions/global.helper';
import warehouseService from '../../../services/config/warehouse.service';
import inoutboundTypeService from '../../../services/config/inoutbound_type.service';
const { RangePicker } = DatePicker;

const TransactionsLayout = () => {
    const CSVReportLink = React.createRef();
    const { innerWidth } = window;
    const [timer, setTimer] = useState(null);
    const [state, setState] = useState({
        open_drawer : false,

        //selections
        warehouses: [],
        material_codes: [],
        materials: [],
        inoutbound_types: [],
        locations: [],
        date: [],

        loading : false,
        save_loading : false,
        transactions : [],
        toDownload: false,

        filter_changed: false,
    });
    const [filter, setFilter] = useState({
        search : null,
        warehouse : null,
        material_code: null,
        material: null,
        inoutbound_type: null,    
        location : null,
        date : null,
        startDate: null,
        endDate: null,
    });

    const doneTyping = async (input) => {
        clearTimeout(timer);
        const newTimer = setTimeout(() => {
            setFilter(filter => ({ ...filter, search: input, }));
        }, 300);
        setTimer(newTimer);
    }

    const onCheckChange = (key, checkedValues) => {
        setFilter(filter => ({...filter, [key] : checkedValues}));
        setState(state => ({ ...state, filter_changed: true, }));
    }

    const onDateChange = (date, dateString) => {
        setFilter(filter => ({ ...filter, startDate: dateString[0], endDate: dateString[1], }));
        setState(state => ({ ...state, filter_changed: true, }));
    };

    const fetchInitialization = async () => {
        let locationMaterialLogsResponse = await locationMaterialLogsService?.getLocationMaterialLogsByMaterial({});
        const { dataList:locations }  = locationMaterialLogsResponse;
        locationMaterialLogsResponse = await locationMaterialLogsService?.getLocationMaterialLogsByLocation({});
        const { dataList:materials }  = locationMaterialLogsResponse;

        const warehouseResponse = await warehouseService?.getWarehouses({});
        const { warehouses } = warehouseResponse;
        const inoutboundTypeResponse = await inoutboundTypeService?.getInOutBoundTypes({});
        const { inoutbound_types } = inoutboundTypeResponse;
        // const materialCodeResponse = await materialCodeService?.getPartners({});
        // const { rows: materialCodes } = materialCodeResponse?.dataList;
        // const date = await inoutboundStatusService?.getInBoundStatus({});
        // const { rows: inoutbound_statuses } = inoutboundStatusResponse?.dataList;

        setState(state => ({...state, locations : locations, materials : materials,
            warehouses : warehouses, inoutbound_types : inoutbound_types,
        }))
    }

    const handleCsvExport = async () => {
        setState(state => ({...state, loading : true,}));
        // const { search, location, material, } = filter;
            
        const locationMaterialLogsResponse = await locationMaterialLogsService.getLocationMaterialLogs({
            search : filter?.search,
            location_ids : filter?.location,
            material_ids : filter?.material,
            warehouse_ids: filter?.warehouse,
            inoutbound_type_ids: filter?.inoutbound_type,
            is_all : 1,   
        });

        const { count, rows : transactions } = locationMaterialLogsResponse?.location_material_logs;
        let data = [];
        transactions.forEach((item)=>{
            let object = {
                'Location'    : item.location.name,
                'Warehouse'   : item.location.warehouse.name,
                'Item Name'   : item.material.name,
                'Quantity'    : item.quantity,
                'Source Type' : item.inbound_material_id ? 'Inbound' : 'Outbound',
                'Created By'  : item.user?.name,
                'Updated At'  : item.updated_at,
            }
            data.push(object);
        })

        setTimeout(() => {
            setState(state => ({...state, loading : false,}));
            globalHelper.exportToExcel({data, name : "Transaction_Logs"})
        }, 1000);        
    }

    useEffect(() => {
        fetchInitialization();
    }, [])

    // useEffect(() => {
    //     if (state?.excelData.length > 0 && state?.loading == 'true') {
    //         CSVReportLink.current.link.click();
    //         setState(state => ({...state, loading : false,}));
    //     }
    // }, [state?.excelData]);

    return (
        <>
            <div>
                <div style={{textAlign:'start',}}>
                    <MenuTitleComponent />
                </div>

                <div>
                    <div className='filter-panel' style={{marginBottom:12,}}>
                        <div>
                            <Input
                            onChange={(e) => doneTyping(e.target.value)}
                            name="search"
                            prefix={<SearchOutlined style={{color :'var(--secondary-text-color)'}} />}
                            placeholder='Search Transactions'
                            style={{minWidth: '100%',}}
                            allowClear
                            />
                        </div>
                    </div>

                    <div style={{display:'flex', alignItems:'center', marginTop:12, gap:8,}}>
                            <div>
                                <div className='filter-panel' style={{textAlign:'start'}}>
                                    <Space direction={innerWidth > 768 ? 'horizontal' : 'vertical'}>
                                    <FilterBullet
                                        popover={
                                            <>
                                                <div style={{padding:12,}}>
                                                    <Input
                                                    prefix={<SearchOutlined style={{color :'var(--secondary-text-color)'}}/>}
                                                    placeholder='Search Item'
                                                    allowClear
                                                    onChange={async (e) => {
                                                        const locationMaterialLogsResponse = await locationMaterialLogsService?.getLocationMaterialLogsByLocation({ search: e.target.value, size:100 });
                                                        setState(state => ({ ...state, materials: locationMaterialLogsResponse?.dataList }))
                                                    }}
                                                    />
                                                </div>
                                                <Checkbox.Group
                                                value={filter?.material}
                                                onChange={(checkedValues) => onCheckChange('material', checkedValues)}
                                                style={{ display:'block', }}
                                                >
                                                    <div style={{padding:'0 12px',}}>
                                                        <List
                                                        style={{ width:280, }}
                                                        dataSource={state?.materials}
                                                        renderItem={(item, index) => {
                                                            const { material_id, name, } = item ?? {};
                                                            return (
                                                                <List.Item style={{width:'100%'}} key={index}>
                                                                        <div style={{display:'flex', alignItems:'center', width:'100%'}}>
                                                                        <div>
                                                                            <span>{name}</span>
                                                                        </div>
                                                                        <div style={{marginLeft:'auto'}}>
                                                                            <Checkbox
                                                                            value={material_id}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </List.Item>
                                                            )
                                                        }}
                                                        />
                                                    </div>
                                                    
                                                </Checkbox.Group>
                                            </>
                                        }
                                        >
                                            <div>
                                                <Space>
                                                    <div>
                                                        <span>{`Item ${filter?.material?.length > 0 ? `: ${filter?.material?.length} items` : "" }`}</span>
                                                    </div>

                                                    <div>
                                                        <CaretDownFilled style={{color:'#a0aec0'}} />
                                                    </div>
                                                    
                                                </Space>
                                                
                                            </div>
                                        </FilterBullet>
                                        <FilterBullet
                                        popover={
                                            <>
                                                <div style={{padding:12,}}>
                                                    <Input
                                                    prefix={<SearchOutlined style={{color :'var(--secondary-text-color)'}}/>}
                                                    placeholder='Search Locations'
                                                    allowClear
                                                    onChange={async (e) => {
                                                        const locationMaterialLogsResponse = await locationMaterialLogsService?.getLocationMaterialLogsByMaterial({ search: e.target.value, size:100 });
                                                        setState(state => ({ ...state, locations: locationMaterialLogsResponse?.dataList }))
                                                    }}
                                                    />
                                                </div>
                                                <Checkbox.Group
                                                value={filter?.location}
                                                onChange={(checkedValues) => onCheckChange('location', checkedValues)}
                                                style={{ display:'block', }}
                                                >
                                                    <div style={{padding:'0 12px',}}>
                                                        <List
                                                        style={{ width:280, }}
                                                        dataSource={state?.locations}
                                                        renderItem={(item, index) => {
                                                            const { location_id, name, } = item ?? {};
                                                            return (
                                                                <List.Item style={{width:'100%'}} key={index}>
                                                                    <div style={{display:'flex', alignItems:'center', width:'100%'}}>
                                                                        <div>
                                                                            <span>{name}</span>
                                                                        </div>
                                                                        <div style={{marginLeft:'auto'}}>
                                                                            <Checkbox
                                                                            value={location_id}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </List.Item>
                                                            )
                                                        }}
                                                        />
                                                    </div>
                                                    
                                                </Checkbox.Group>
                                            </>
                                        }
                                        >
                                            <div>
                                                <Space>
                                                    <div>
                                                        <span>{`Location ${filter?.location?.length > 0 ? `: ${filter?.location?.length} items` : "" }`}</span>
                                                    </div>

                                                    <div>
                                                        <CaretDownFilled style={{color:'#a0aec0'}} />
                                                    </div>
                                                    
                                                </Space>
                                                
                                            </div>
                                        </FilterBullet>
                                    <FilterBullet
                                        popover={
                                            <>
                                                <div style={{ padding: 12, }}>
                                                    <Input
                                                        prefix={<SearchOutlined style={{ color: 'var(--secondary-text-color)' }} />}
                                                        placeholder='Search Warehouse'
                                                        allowClear
                                                        onChange={async (e) => {
                                                            const warehouseResponse = await warehouseService?.getWarehouses({ search: e.target.value, size:100 });
                                                            setState(state => ({ ...state, warehouses: warehouseResponse?.warehouses }))
                                                        }}
                                                    />
                                                </div>
                                                <Checkbox.Group
                                                    value={filter?.warehouse}
                                                    onChange={(checkedValues) => onCheckChange('warehouse', checkedValues)}
                                                    style={{ display: 'block', }}
                                                >
                                                    <div style={{ padding: '0 12px', }}>
                                                        <List
                                                            style={{ width: 280, }}
                                                            dataSource={state?.warehouses}
                                                            renderItem={(item, index) => {
                                                                const { warehouse_id, name, } = item ?? {};
                                                                return (
                                                                    <List.Item style={{ width: '100%' }} key={index}>
                                                                        <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                                                                            <div>
                                                                                <span>{name}</span>
                                                                            </div>
                                                                            <div style={{ marginLeft: 'auto' }}>
                                                                                <Checkbox
                                                                                    value={warehouse_id}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </List.Item>
                                                                )
                                                            }}
                                                        />
                                                    </div>

                                                </Checkbox.Group>
                                            </>
                                        }
                                    >
                                        <div>
                                            <Space>
                                                <div>
                                                    <span>{`Warehouse ${filter?.warehouse?.length > 0 ? `: ${filter?.warehouse?.length} items` : ""}`}</span>
                                                </div>

                                                <div>
                                                    <CaretDownFilled style={{ color: '#a0aec0' }} />
                                                </div>

                                            </Space>

                                        </div>
                                    </FilterBullet>
                                    {/* <FilterBullet
                                        popover={
                                            <>
                                                <div style={{ padding: 12, }}>
                                                    <Input
                                                        prefix={<SearchOutlined style={{ color: 'var(--secondary-text-color)' }} />}
                                                        placeholder='Search Source Type'
                                                        allowClear
                                                    />
                                                </div>
                                                <Checkbox.Group
                                                    value={filter?.inoutbound_type}
                                                    onChange={(checkedValues) => onCheckChange('inoutbound_type', checkedValues)}
                                                    style={{ display: 'block', }}
                                                >
                                                    <div style={{ padding: '0 12px', }}>
                                                        <List
                                                            style={{ width: 280, }}
                                                            dataSource={state?.inoutbound_types}
                                                            renderItem={(item, index) => {
                                                                const { inoutbound_type_id, name, } = item ?? {};
                                                                return (
                                                                    <List.Item style={{ width: '100%' }} key={index}>
                                                                        <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                                                                            <div>
                                                                                <span>{name}</span>
                                                                            </div>
                                                                            <div style={{ marginLeft: 'auto' }}>
                                                                                <Checkbox
                                                                                    value={inoutbound_type_id}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </List.Item>
                                                                )
                                                            }}
                                                        />
                                                    </div>

                                                </Checkbox.Group>
                                            </>
                                        }
                                    >
                                        <div>
                                            <Space>
                                                <div>
                                                    <span>{`Source Type ${filter?.inoutbound_type?.length > 0 ? `: ${filter?.inoutbound_type?.length} items` : ""}`}</span>
                                                </div>

                                                <div>
                                                    <CaretDownFilled style={{ color: '#a0aec0' }} />
                                                </div>

                                            </Space>

                                        </div>
                                    </FilterBullet> */}
                                    <RangePicker onChange={onDateChange} />
                                    </Space>
                                </div>
                            </div>
                            <div style={{display: 'flex', justifyContent:'flex-end'}}>
                {/* <Button onClick={handleCsvExport} icon={<DownloadOutlined />}>
                    <CSVLink
                    filename={"Transaction_Logs.csv"}
                    data={state?.excelData}
                    className="feed-icon-wrapper"
                    ref={CSVReportLink}>
                        {state?.loading ? 'Loading csv...' : 'Download Excel'}
                    </CSVLink>
                </Button> */}
            </div>
                            <div style={{marginLeft:'auto', paddingBottom:'10px'}}>
                            <Button onClick={handleCsvExport} icon={<DownloadOutlined />}>
                                {state?.loading ? 'Loading csv...' : 'Download Excel'}
                            </Button>
                            </div>
                        </div>

                    <div>
                        <TransactionsList filter={filter} filter_changed={state?.filter_changed} />
                    </div>
                </div>
            </div>
        </>
    );
}

export default TransactionsLayout;

