import React, { useEffect, useState } from 'react';
import menuService from '../../services/admin/menu.service';
import { useLocation } from 'react-router-dom';
import PageHeaderComponent from './page_header.component';
import { Space } from 'antd';

const MenuTitleComponent = () => {
    const location = useLocation();
    const [state, setState] = useState({
        loading : false,
        menu : null,
    })

    const fetchMenus = async () => {
        setState(state => ({...state, loading : true, }));
        const menuResponse = await menuService?.getMenus({
            link : location?.pathname,
        });

        const { menus } = menuResponse;
        const menu = menus?.length > 0 ? menus[0] : null;


        setState(state => ({...state, loading : false, menu : menu,}));
    }

    useEffect(() => {
        fetchMenus();
    }, []);

    return (
        <>
            <div style={{marginBottom:20,}}>
                <div>
                    <PageHeaderComponent title={state?.menu?.name} />
                </div>
                <div>
                    <span style={{color:'var(--secondary-text-color)'}}>{state?.menu?.description}</span>
                </div>
            </div>
        </>
    );
}

export default MenuTitleComponent;