import React from 'react';
import ContainerComponent from '../../helpers/components/container.component';
import ReportLayout from './report/report_layout';

const ReportIndex = () => {
    return (
        <>
            <ContainerComponent span={22} top={20}>
                <ReportLayout />
            </ContainerComponent>
        </>
    );
}

export default ReportIndex;