import React, { useEffect, useState } from 'react';
import lineService from '../../../services/production/line.service';
import { Form, Input, Skeleton } from 'antd';

const { TextArea } = Input;
const LineEditDetail = ({ production_line_id, form }) => {

    const [state, setState] = useState({
        loading : false,
        production_line : null,
    })

    const fetchProductionLine = async () => {
        setState(state => ({...state, loading : true,}));
        const productionLineResponse = await lineService.getLines({
            production_line_id,
        });

        const { lines } = productionLineResponse;
        const production_line = lines?.length > 0 ? lines[0] : null;
        const { name, description, } = production_line;

        setTimeout(() => {
            form.setFieldsValue({ name, description, });
            setState(state => ({...state, loading : false, production_line : production_line,}));
        }, 500);
    }

    useEffect(() => {
        production_line_id && fetchProductionLine();
    }, [production_line_id])
    
    return (
        <>
            {
                state?.loading ?
                (
                    <>
                        <div>
                            <Skeleton active />
                        </div>
                    </>
                )
                :
                (
                    <>
                        <div>
                            <div>
                                <Form.Item name={'name'} label={'Production Line Name'}>
                                    <Input placeholder='Enter Production Line Name' />
                                </Form.Item>
                            </div>
                            <div>
                                <Form.Item name={'description'} label={'Description'}>
                                    <TextArea rows={4} style={{resize:'none'}} placeholder='Enter Description' />
                                </Form.Item>
                            </div>
                        </div>
                    </>
                )
            }
        </>
    );
}

export default LineEditDetail;