import { Button, Card, Form, Input, message } from 'antd';
import React, { useState } from 'react';
import authService from '../../../services/admin/auth.service';
import { useHistory, useNavigate  } from 'react-router-dom';

const ResetPasswordLayout = () => {
    const [messageApi, contextHolder] = message.useMessage();
    const history = useHistory();
    const [form] = Form.useForm();
    const [state, setState] = useState({
        login_loading : false,
    })

    const resetPassword = async () => {
        setState(state => ({...state, loading : true,}));
        const { rpassword, confirm_password } = form.getFieldsValue();
        if(rpassword != confirm_password)
        {
            error()
            setTimeout(() => {
                setState(state => ({...state, loading : false,}));
            }, 1000);
        }
        else
        {
            const changePasswordResponse = await authService?.changePassword({
                password : rpassword,
            });
            success();
            setTimeout(() => {
                setState(state => ({...state, loading : false,}));
                history.push('/');  
            }, 5000);
        }    
    }
    
    const success = () => {
        messageApi.open({
        type: 'success',
        content: 'Password Reset Success',
        duration: 5,
        });
    };

    const error = () => {
        messageApi.open({
          type: 'error',
          content: 'Password does not match with Confirm Password',
          duration: 5,
        });
      };

    return (
        <>
            <div className='content' style={{display:'flex', justifyContent:'center', flexDirection : 'column'}}>
                <div>
                    <div>
                        <div>
                            <span
                            className='ticket-label ticket-title h1'
                            style={{
                                fontWeight:'bold',
                                fontSize: 24
        
                            }}
                            >
                                Enter your New Password
                            </span>
                        </div>
                    </div>

                    <div style={{marginTop:42,}}>
                        <Form
                        form={form}
                        onFinish={resetPassword}
                        >
                            <Form.Item name={'rpassword'}>
                                <Input.Password size='large' placeholder='New Password'/>
                            </Form.Item>

                            <Form.Item name={'confirm_password'}>
                                <Input.Password size='large' placeholder='Confirm Password'/>
                            </Form.Item>

                            <Form.Item>
                                {contextHolder}
                                <Button loading={state?.login_loading} htmlType='submit' type='primary' size='large' style={{width:'100%'}}>
                                    Reset Password
                                </Button>
                            </Form.Item>
                        </Form>
                    </div>                                                                                    
                </div>
            </div>
        </>
    );
}

export default ResetPasswordLayout;