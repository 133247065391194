import { Col, Divider, Form, Input, message, Radio, Row, Select, Skeleton, Space } from 'antd';
import React, { useEffect, useState } from 'react';
import materialService from '../../../services/config/material.service';
import materialCategoryService from '../../../services/config/material_category.service';
import materialTypeService from '../../../services/config/material_type.service';
import uomService from '../../../services/config/uom.service';

const { TextArea } = Input;
const MaterialEditDetail = ({ material_id, form }) => {
    const [messageApi, contextHolder] = message.useMessage();
    const [state, setState] = useState({
        loading : false,
        material : null,

        material_code : '',
        is_material_code_valid : false,
    });

    const [select, setSelect] = useState({
        material_categories : [],
        uoms : [],
    })

    const [radio, setRadio] = useState({
        material_types : [],
    })

    const error = (err) => {
        messageApi.open({
          type: 'error',
          content: err,
        });
      };

    const fetchMaterials = async () => {
        setState(state => ({...state, loading : true, }))
        const materialResponse = await materialService?.getMaterials({
            material_id,
        })

        const { count, rows } = materialResponse?.materials;
        const material = rows?.length > 0 ? rows[0] : null;

        setTimeout(() => {
            const { name, uom_id, material_category_id, material_code, description, material_type_id } = material;
            form.setFieldsValue({ name, uom_id, material_category_id, material_code, description, material_type_id, });

            setState(state => ({...state, loading : false, material : material}));
        }, 500);
    }

    const checkItemCode = async (material_code) => {
        const materialResponse = await materialService?.checkItemCode({
            material_code : material_code,
            material_id : material_id
        });
        const { is_material_code_valid } = materialResponse;
        setState(state => ({...state, is_material_code_valid : is_material_code_valid}))
        return is_material_code_valid;
    }

    const fetchInitialization = async () => {
        //get material categories 
        const materialCategoryResponse = await materialCategoryService?.getMaterialCategoryList({});
        const materialTypeResponse = await materialTypeService?.getMaterialTypeList({});
        const uomResponse = await uomService?.getUomList({});

        const material_categories = materialCategoryResponse?.material_categories?.map(x => ({ value : x?.material_category_id, label : x?.name }));
        const material_types = materialTypeResponse?.material_types?.map(x => ({ value : x?.material_type_id, label : x?.name }));
        const uoms = uomResponse?.grouped_uoms?.map(x => ({ label : x?.label ? "Default UOM" : "Custom UOM", options : x?.options?.map(x_option => ({ value : x_option?.uom_id, label : `${x_option?.code} (${x_option?.name})` }) ) }));

        setSelect(select => ({...select, material_categories : material_categories, uoms : uoms, }));
        setRadio(radio => ({...radio, material_types : material_types, }));
    }

    useEffect(() => {
        material_id && fetchMaterials();
    }, [material_id]);

    useEffect(() => {
        fetchInitialization();
    }, [])


    return (
        <>
            <div>
                {
                    state?.loading ?
                    (
                        <>
                            <div>
                                <Skeleton active />
                            </div>
                        </>
                    )
                    :
                    (
                        <>
                            <div>
                                <div style={{background : '#edf2f7', border:'1.5px solid #cbd5e0', padding : '12px 12px 0 12px', borderRadius:8,}}>
                                    <Form.Item name={'name'} label={'Item'}>
                                        <Input placeholder='Item Name' />
                                    </Form.Item>
                                </div>

                                <div style={{marginTop:20,}}>
                                    <Row gutter={[12, 12]}>
                                        <Col span={10}>
                                            <Form.Item name={'material_category_id'} label={'Category'}>
                                                <Select
                                                showSearch
                                                placeholder="Select Category"
                                                options={select?.material_categories}
                                                />
                                            </Form.Item>
                                        </Col>

                                        <Col span={7}>
                                            <Form.Item name={'uom_id'} label={'UOM'}>
                                                <Select
                                                showSearch
                                                placeholder="Select Uom"
                                                options={select?.uoms}
                                                />
                                            </Form.Item>
                                        </Col>

                                        {/* <Col span={7}>
                                            <Form.Item name={'material_code'} label={'Item Code'}>
                                                <Input placeholder='Item Code' />
                                            </Form.Item>
                                        </Col> */}
                                        
                                        <Col span={7}>
                                            <Form.Item
                                                name={'material_code'}
                                                label={'Item Code'}
                                                requiredMark={'optional'}
                                                rules={[
                                                    { required : true, message : 'Item Code is required!' }, 
                                                    { validator : async (rule, value) => {
                                                        const is_valid = await checkItemCode(value);
                                                        setState(state => ({...state, material_code : value, is_material_code_valid : is_valid}));
                                                        return new Promise((resolve, reject) => {
                                                            if(value && is_valid){
                                                                resolve();
                                                            }else{
                                                                reject("Item Code Invalid");
                                                            }
                                                        })
                                                    }}
                                                ]}
                                                hasFeedback
                                                validateStatus={state?.material_code && (state?.is_material_code_valid ? 'success' : 'error')}>
                                                    <Input placeholder='Item Code' />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </div>

                                <div>
                                    <div>
                                        <Form.Item name={'material_type_id'} label={'Type'}>
                                            <Radio.Group options={radio?.material_types} />
                                                
                                            
                                            {/* <Radio.Group>
                                                <Space direction='vertical'>
                                                    <div>
                                                        <Radio value={true}>Product</Radio>
                                                    </div>
                                                    <div>
                                                        <Radio key={id} onChange={()=>setIdChekedFromRequest(id)} checked={id===idChekedFromRequest}>
                                                            {value}
                                                        </Radio>
                                                    </div>
                                                </Space>
                                            </Radio.Group>    */}
                                        </Form.Item>
                                    </div>
                                </div>


                                <Divider style={{marginBottom:12, marginTop:12,}} />

                                <div style={{marginTop:20,}}>
                                    <Form.Item name={'description'} label={
                                        <>
                                            <Space>
                                                <div><span>Description</span></div>
                                                <div><span style={{color:'var(--secondary-text-color)'}}>{`(Optional)`}</span></div>
                                            </Space>
                                        </>
                                    }>
                                        <TextArea placeholder='Insert Description here' rows={4} style={{resize:'none'}} />
                                    </Form.Item>
                                </div>
                            </div>
                        </>
                    )
                }
                
            </div>
        </>
    );
}

export default MaterialEditDetail;