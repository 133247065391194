import React, { useEffect, useState } from 'react';
import warehouseService from '../../../services/config/warehouse.service';
import { Button, Drawer, Form, Space, Spin, Table, Tag, notification } from 'antd';
import WarehouseEditDetail from './warehouse_edit_detail';
import { useDispatch, useSelector } from 'react-redux';
import { refresh_warehouse_list } from '../../../actions/config.action';
import { DeleteOutlined } from '@ant-design/icons';

const WarehouseList = ({ filter, }) => {
    const dispatch = useDispatch();
    const configRedux = useSelector(state => state?.config);
    const { innerWidth } = window;
    const [form] = Form.useForm();
    const [state, setState] = useState({
        loading : false,
        save_loading : false,
        warehouses : [],
        drawer_warehouse_id : null,
    });
    
    const getWarehouses = async () => {
        setState(state => ({...state, loading : true,}));

        const { search, is_hq } = filter;
        const warehouseResponse = await warehouseService?.getWarehouses({
            search,
            ...(is_hq != null && { is_hq : (is_hq ? 1 : 0), })
        });
        setTimeout(() => {
            setState(state => ({...state, loading : false, warehouses : warehouseResponse?.warehouses}));
        }, 500);
        
    }

    const openDrawer = (warehouse_id) => {
        setState(state => ({...state, drawer_warehouse_id : warehouse_id}))
    }

    const saveWarehouse = async (warehouse_id) => {
        setState(state => ({...state, save_loading : true,}));
        const { name, code, type, contact_no, address, } = form?.getFieldsValue();
        if(warehouse_id){
            //update warehouse
            const update_warehouse = await warehouseService?.updateWarehouse(warehouse_id, { name, code, is_hq : type, contact_no, address, });
        }

        //successful
        setTimeout(() => {
            dispatch(refresh_warehouse_list());
            setState(state => ({...state, drawer_warehouse_id : null, save_loading : false, }));
            form.resetFields();

            //success message
            notification?.success({
                message : "Success",
                description : `Successfully saved warehouse ${name}`
            })
        }, 500);
    }

    useEffect(() => {
        getWarehouses();
    }, [filter, configRedux?.warehouse_list_refresh])

    return (
        <>
            <Spin spinning={state?.loading}>
                <Table 
                scroll={{ x : 400, }}
                className='hub-table bordered'
                dataSource={state?.warehouses}
                columns={[{
                    title : 'Warehouse Code',
                    dataIndex : 'code',
                    key : 'code',
                    width : '15%',
                },{
                    title : 'Name',
                    dataIndex : 'name',
                    key : 'name',
                    width: '25%',
                    render : (text, row, index) => {
                        const name = text;
                        const { is_hq } = row;
                        const type = is_hq ? 'HQ' : 'Warehouse';
                        return (
                            <>
                                <div>
                                    <div>
                                        <span style={{fontWeight:500,}}>{name}</span>
                                    </div>
                                    <div>
                                        <Tag style={{border:'none'}}>
                                            <span style={{color:'var(--main-color)', fontWeight:500,}}>{type}</span>
                                        </Tag>
                                    </div>
                                </div>
                            </>
                        )
                    }
                },{
                    title : 'Address',
                    dataIndex : 'address',
                    key : 'address',
                    render : (text, row, index) => {
                        return (
                            <>
                                <div>
                                    <span style={{fontSize:12, color:'var(--secondary-text-color)'}}>{text}</span>
                                </div>
                            </>
                        )
                    }
                },{
                    title : 'Contact No',
                    dataIndex : 'contact_no',
                    key : 'contact_no',
                    render : (text, row, index) => {
                        return (
                            <>
                                <div>
                                    <span style={{fontSize:12, color:'var(--secondary-text-color)'}}>{text}</span>
                                </div>
                            </>
                        )
                    }
                },{
                    title : 'Action',
                    dataIndex : 'warehouse_id',
                    key : 'warehouse_id',
                    width : '10%',
                    render : (text, row, index) => {
                        const warehouse_id = text;
                        return (
                            <Button.Group>
                                <Button
                                onClick={() => openDrawer(warehouse_id)}
                                >
                                    View
                                </Button>
                                <Button icon={<DeleteOutlined style={{color:'var(--secondary-text-color)'}} />} />
                            </Button.Group>
                            
                        )
                    }
                }]}
                />
            </Spin>


            {/* detail */}
            <Drawer
            title="Warehouse Detail"
            rootClassName='drawer-detail-root'
            className='drawer-detail'
            open={state?.drawer_warehouse_id}
            onClose={() => openDrawer(null)}
            width={innerWidth >= 768 ? 800 : "100%"}
            footer={
                <>
                    <div style={{display:'flex', padding:'12px 0',}}>
                        <div style={{marginLeft:'auto',}}>
                            <Space>
                                <Button loading={state?.save_loading} type='primary' onClick={() => form.submit()}>Save Changes</Button>
                            </Space>
                        </div>
                    </div>
                </>
            }
            >
                <div>
                    <Form
                    onFinish={() => saveWarehouse(state?.drawer_warehouse_id)}
                    form={form}
                    layout='vertical'
                    >
                        <WarehouseEditDetail warehouse_id={state?.drawer_warehouse_id} form={form} />
                    </Form>
                </div>
            </Drawer>
        </>
    );
}

export default WarehouseList;