export const refresh_inbound_list = () => {
    return {
        type : 'REFRESH_INBOUND_LIST',
    }
}

export const refresh_outbound_list = () => {
    return {
        type : 'REFRESH_OUTBOUND_LIST',
    }
}

export const refresh_locations_list = () => {
    return {
        type : 'REFRESH_LOCATIONS_LIST',
    };
}

export const refresh_location_material_list = () => {
    return {
        type : 'REFRESH_LOCATION_MATERIAL_LIST',
    };
}

export const refresh_inoutbound_sources_list = () => {
    return {
        type : 'REFRESH_INOUTBOUND_SOURCE_LIST',
    };
}