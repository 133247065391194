import React from 'react';
import ConfigMaterialCategoryLayout from './material_category/config_material_category_layout';
import ContainerComponent from '../../helpers/components/container.component';

const ConfigMaterialCategoryIndex = () => {
    return (
        <>
            <ContainerComponent span={22} top={20}>
                <ConfigMaterialCategoryLayout />
            </ContainerComponent>
        </>
    );
}

export default ConfigMaterialCategoryIndex;