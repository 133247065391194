import React from 'react';
import ContainerComponent from '../../helpers/components/container.component';
import PartnerLayout from './partner/partner_layout';

const PartnerIndex = () => {
    return (
        <>
            <ContainerComponent span={22} top={20}>
                <PartnerLayout />
            </ContainerComponent>
        </>
    );
}

export default PartnerIndex;