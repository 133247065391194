import { Avatar, Button, Col, DatePicker, Form, Input, Popover, Row, Select, Space, Table } from 'antd';
import React, { useEffect, useState } from 'react';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import moment from 'moment';
import SearchMaterial from '../../production/bom/search_material';
import materialService from '../../../services/config/material.service';
import warehouseService from '../../../services/config/warehouse.service';
import inoutboundSourceService from '../../../services/config/inoutbound_source.service';
import partnerService from '../../../services/config/partner.service';
import locationService from '../../../services/warehouse/location.service';


const { TextArea } = Input;
const NewWorkOrder = ({ form }) => {
    const [state, setState] = useState({
        loading : false,
        visibleIndex : null,
        inboundsource : [],
        partner : [],
        locations : [],
        warehouses : [],
        locations_list : [],

        // selected
        selected_products : [],
        select_loading : false,
        material_modal : null,
    })

    const initializeSelections = async () => {
        // fetch inboundsource
        const inoutboundSourceResponse = await inoutboundSourceService?.getInBoundSources();
        // fetch partner
        const partnerResponse = await partnerService?.getPartnerList();
        // fetch locations
        const locationResponse = await locationService?.getLocationsList();
        // fetch warehouse
        const warehouseResponse = await warehouseService?.getWarehouses();
        setState(state => ({...state, inboundsource : inoutboundSourceResponse?.dataList["rows"], partner : partnerResponse?.dataList, locations_list : locationResponse?.dataList, warehouses : warehouseResponse?.warehouses}));
    }

    const addRow = async () => {
        const generate_uid = (`${Math.random()}_${moment()?.format("YYYY-MM-DD")?.toString()}`);
        let material_arr = [...state?.selected_products, { uid : generate_uid, material_id : null, material : null, quantity : null, location_id : null, }];
        const { warehouse_id } = form.getFieldsValue();
        const location = state?.locations_list.filter((location) => location.warehouse_id === warehouse_id);
        setState(state => ({...state, selected_products : material_arr, locations : location}));
        //dynamic set form
        form.setFieldsValue({ inbound_material_list : material_arr, });
    }

    const deleteRow = async (uid) => {
        let material_arr = [...state?.selected_products];
        material_arr = material_arr.filter(x => x.uid !== uid)
        setState(state => ({ ...state, selected_products: material_arr, }));
        //dynamic set form
        form.setFieldsValue({ inbound_material_list: material_arr, });
    }

    const onWarehouseRowChange = async (key, value) => {
        let selected_products = state?.selected_products ?? [];
        selected_products = selected_products.map(obj => ({
            ...obj,
            location_id: null
        }));

        const locations = state?.locations_list.filter((location) => location.warehouse_id === value);

        //----
        setState(state => ({...state, selected_products : selected_products, locations : locations}));
        form.setFieldsValue({ inbound_material_list : selected_products, location_id: null });
        //--
    }

    const onRowChange = async (index, key, value) => {
        let selected_products = state?.selected_products ?? [];
        selected_products[index][key] = value;
        selected_products[index].updated = true;

        setState(state => ({...state, selected_products : selected_products, }));
        form.setFieldsValue({ inbound_material_list : selected_products, });
    }

    const customFunction = async (index, material_id) => {
        const materialResponse = await materialService?.getMaterialDetail(material_id);
        const material = materialResponse?.material;

        let selected_products = state?.selected_products ?? [];
        setState(state => ({...state, select_loading : index }));
        selected_products[index].material_id = material_id;
        selected_products[index].material = material;
        selected_products[index].updated = true;
        selected_products[index].quantity = 0;
        setState(state => ({...state, visibleIndex : null }));

        setTimeout(() => {
            setState(state => ({...state, selected_products : selected_products, select_loading : null, }));
            //dynamic set form
            form.setFieldsValue({ inbound_material_list : selected_products, });
        }, 200);
    }

    const handleOpenChange = (e, index) =>
    {
        if(e === true)
        {
            setState(state => ({...state, visibleIndex : index}));
        } else {
            setState(state => ({...state, visibleIndex : null}));
        }
    };

    useEffect(() => {
        initializeSelections();
    }, [])

    // useEffect(() => {
    //     let selected_products = [...state?.selected_products] ?? [];
    //     for(let selected_product of selected_products){
    //         selected_product.location_id = null;
    //     }
    //     form.setFieldsValue({ inbound_material_list: selected_products, });
    // }, [form?.getFieldsValue()?.warehouse_id])

    return (
        <>
            <div>
                <div style={{marginTop:12,}}>
                    <Row gutter={[20, 20]}>
                        <Col span={8}>
                            <Form.Item name={'warehouse_id'} label={(<span style={{color:'var(--secondary-text-color)', fontSize:12,}}>Warehouse</span>)}>
                                <Select
                                onChange={(e) => onWarehouseRowChange('warehouse_id', e)}
                                allowClear
                                placeholder={"Select Warehouse"}
                                options={state?.warehouses?.map((warehouse, index) => {
                                    const { warehouse_id, name, } = warehouse;
                                    return ({ value : warehouse_id, label : name, })
                                })}
                                >
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item name={'inoutbound_source_id'} label={(<span style={{color:'var(--secondary-text-color)', fontSize:12,}}>Inbound Source</span>)}>
                                <Select
                                allowClear
                                placeholder="Select Inbound Source"
                                options={state?.inboundsource?.map((inboundsource, index) => {
                                    const { inoutbound_source_id, name, } = inboundsource;
                                    return ({ value : inoutbound_source_id, label : name, })
                                })}
                                >

                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item name={'partner_id'} label={(<span style={{color:'var(--secondary-text-color)', fontSize:12,}}>From</span>)}>
                                <Select
                                placeholder={"Select Partner"}
                                options={state?.partner?.map((partner, index) => {
                                    const { partner_id, name, } = partner;

                                    return (
                                        {   
                                            value : partner_id, 
                                            label : (
                                                <>
                                                    <Space>
                                                        {/* <Avatar size='small' style={{ backgroundColor: '#fde3cf', color: '#f56a00', }}>
                                                            {name?.split(' ')?.map(word => word.charAt(0).toUpperCase()).join('')}
                                                        </Avatar> */}
                                                        <span>{name}</span>
                                                    </Space>
                                                    
                                                </>
                                            ), 
                                        })
                                })}
                                >

                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                </div>
                <div>
                    <Form.Item
                    className='width100-form-label'
                    name="inbound_material_list"
                    labelCol={{style: {width: '100%'}}}
                    style={{width : '100%'}}
                    label={
                        (
                            <div style={{display:'flex', alignItems:'center', width:'100%',}}>
                                <div>
                                    <span style={{color:'var(--secondary-text-color)', fontSize:12,}}>Item</span>
                                </div>

                                <div style={{marginLeft:'auto'}}>
                                    <Button onClick={addRow}>Add Item</Button>
                                </div>
                            </div>
                        )
                    }
                    >
                        {console.log(state?.selected_products)}
                        {
                            state?.selected_products?.length > 0 ?
                            (
                                <>
                                    <Table
                                    pagination={false}
                                    dataSource={state?.selected_products}
                                    scroll={{ x : 400, }}
                                    className='hub-table bordered'
                                    columns={[{
                                        title : 'Item',
                                        dataIndex : 'material',
                                        key : 'material',
                                        width : '20%',
                                        render : (default_material_id, row, index) => {
                                            const { material, material_id } = row ?? {};
                                            const is_loading = state?.select_loading === index;
                                            return (
                                                <>
                                                    <div>
                                                        <div>
                                                            <Popover
                                                            content={<SearchMaterial default_material_id={default_material_id} form={form} customFunction={(material_id) => customFunction(index, material_id,)} />}
                                                            trigger={['click']}
                                                            open={state?.visibleIndex === index}
                                                            onOpenChange={(e) => handleOpenChange(e, index)}
                                                            placement='bottomLeft'
                                                            overlayInnerStyle={{padding : 0,}}
                                                            >
                                                                <Button
                                                                loading={is_loading}
                                                                style={{width:'100%', textAlign:'start',}}
                                                                onClick={() => setState(state => ({...state, material_modal : true, }))}
                                                                >
                                                                    {material?.name}
                                                                </Button>
                                                            </Popover>
                                                        </div>
                                                    </div>
                                                </>
                                            )
                                        }
                                    },
                                    // {
                                    //     title : 'Warehouse',
                                    //     dataIndex : 'warehouse',
                                    //     key : 'warehouse',
                                    //     width : '10%',
                                    //     render : (warehouse, row, index) => {
                                    //         const { warehouse_id, name, } = warehouse ?? {};
                                    //         return (
                                    //             <>
                                    //                 <Select
                                    //             // onChange={(e) => onFieldChange('warehouse_id', e)}
                                    //             onChange={(e) => onWarehouseRowChange(index, 'warehouse_id', e)}
                                    //             defaultValue={warehouse_id}
                                    //             options={state?.warehouses?.map((warehouse, index) => {
                                    //                 const { warehouse_id, name, } = warehouse;
                                    //                 return (
                                    //                     {   
                                    //                         value : warehouse_id, 
                                    //                         label : (
                                    //                             <>
                                    //                                 <Space>
                                    //                                     <span>{name}</span>
                                    //                                 </Space>
                                                                    
                                    //                             </>
                                    //                         ), 
                                    //                     })
                                    //             })}
                                    //             placeholder={"Select Warehouse"} 
                                    //             style={{minWidth:200,}}
                                    //             />
                                    //             </>
                                    //         )
                                    //     }
                                    // },
                                    {
                                        title : 'Location',
                                        dataIndex : 'location_id',
                                        key: 'location_id',
                                        width : '10%',
                                        render : (location_id, row, index) => {
                                            return (
                                                <>
                                                    <Select
                                                    // onChange={(e) => onFieldChange('location_id', e)}
                                                    onChange={(e) => onRowChange(index, 'location_id', e)}
                                                    defaultValue={location_id}
                                                    value={location_id}
                                                    options={state?.locations?.map((location, index) => {
                                                        const { location_id, name, } = location;
                                                        return (
                                                            {   
                                                                value : location_id, 
                                                                label : (
                                                                    <>
                                                                        <Space>
                                                                            <span>{name}</span>
                                                                        </Space>
                                                                        
                                                                    </>
                                                                ), 
                                                            })
                                                    })}
                                                    placeholder={"Select Location"} 
                                                    style={{minWidth:200,}}
                                                    />
                                                </>
                                            )
                                        }
                                    },{
                                        title : 'Quantity',
                                        dataIndex : 'quantity',
                                        key : 'quantity',
                                        width : '10%',
                                        render : (quantity, row, index) => {
                                            const { material, quantity : material_quantity } = row ?? {}; 
                                            return (
                                                <> 
                                                    <div style={{ display: 'flex', padding: '12px 0', }}>
                                                        <div style={{ paddingRight: '12px', }}>
                                                            <Input
                                                                defaultValue={quantity}
                                                                value={material_quantity}
                                                                type='number'
                                                                onChange={(e) => onRowChange(index, 'quantity', e?.target?.value)}
                                                                suffix={
                                                                    <>
                                                                        <span style={{ fontSize: 12, color: 'var(--secondary-text-color)' }}>{(material?.uom?.code)}</span>
                                                                    </>
                                                                }
                                                            />
                                                        </div>                                                
                                                        <div>
                                                            <Button icon={<DeleteOutlined style={{ color: 'var(--secondary-text-color)' }} onClick={async () => deleteRow(row.uid)} />} />
                                                        </div>
                                                    </div>      
                                                </>
                                            )
                                        }
                                    },]}
                                    />
                                </>
                            )
                            :
                            (
                                <>
                                    <div>
                                        <div style={{background:'var(--secondary-button-color)', padding:24, borderRadius: 12, textAlign:'center',}}>
                                            <div>
                                                <div><span style={{fontSize:18, fontWeight:500,}}>No Items Added</span></div>
                                            </div>
                                            <div>
                                                <span style={{color:'var(--secondary-text-color)', fontSize:12, }}>You haven't select any items yet.</span>
                                            </div>

                                            <div style={{marginTop:24,}}>
                                                <Button onClick={addRow} icon={<PlusOutlined />} type='primary'>Add Items</Button>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )
                        }
                        
                    </Form.Item>
                </div>
                <div>
                    <Form.Item name="remarks" label={(<span style={{color:'var(--secondary-text-color)', fontSize:12,}}>Remarks</span>)}>
                        <TextArea rows={4} style={{ resize : 'none', }}></TextArea>
                    </Form.Item>
                </div>
            </div>
        </>
    );
}

export default NewWorkOrder;