import React from 'react';
import PageHeaderComponent from '../../helpers/components/page_header.component';
import ContainerComponent from '../../helpers/components/container.component';

const HomeIndex = () => {
    return (
        <>
            <ContainerComponent span={20} top={20} bottom={20}>
                {/* <PageHeaderComponent title={'Coming Soon'}/> */}
            </ContainerComponent>
        </>
    );
}

export default HomeIndex;