import { ArrowLeftOutlined } from '@ant-design/icons';
import { Space } from 'antd';
import React from 'react';
import { useHistory } from 'react-router-dom';

const PageHeaderComponent = ({title, color, isBack, textAlign}) => {
    const history = useHistory();
    return (
        <>
            <div 
            style={{
                ...(textAlign && { textAlign : textAlign}),
            }}
            >
                <Space>
                    {
                        isBack &&
                        (
                            <>
                                <div onClick={() => history.goBack()}>
                                    <ArrowLeftOutlined style={{color:color, cursor:'pointer'}} />
                                </div>
                            </>
                        )
                    }
                    
                    <div>
                        <span
                        className='header-font' 
                        style={{
                            fontWeight:500, 
                            fontSize:20,
                            ...(color && { color: color })
                        }}>
                            {title}
                        </span>
                    </div>
                </Space>
            
            
                
            </div>
        </>
    );
}

export default PageHeaderComponent;