import { Button, Drawer, Form, Modal, Space, Spin, Table, notification } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import materialTypeService from '../../../services/config/material_type.service';
import MaterialTypeEditDetail from './material_type_edit_detail';
import { refresh_material_type_list } from '../../../actions/config.action';
import { DeleteOutlined } from '@ant-design/icons';

const MaterialTypeList = ({ filter }) => {
    const dispatch = useDispatch();
    const configRedux = useSelector(state => state.config);
    const { innerWidth } = window;

    const [form] = Form.useForm();
    const [state, setState] = useState({
        loading : false,
        material_types : [],
        used_material_type_id: [],
        drawer_material_type_id : null,

        // pagination
        count : 0,
        page : 0,

        refresh : 0,
    })
    
    const fetchMaterialType = async () => {
        setState(state => ({...state, loading : true, }));
        const { search, } = filter;
        const materialTypeResponse = await materialTypeService?.getMaterialTypes({
            search,
            page : state?.page,
        });
        const { rows, count } = materialTypeResponse?.material_types;       
        const material_types = rows;

        const usedMaterialTypeResponse = await materialTypeService?.getUsedMaterialTypeList({});
        const used_list = usedMaterialTypeResponse?.dataList;

        setTimeout(() => {
            setState(state => ({ ...state, loading: false, material_types: material_types, count: count, used_material_type_id: used_list }));
        }, 200);
        
    }

    const openDrawer = (material_type_id) => {
        setState(state => ({...state, drawer_material_type_id : material_type_id}))
    }

    const saveMaterialType = async () => {
        setState(state => ({...state, save_loading : true,}));
        const { name, } = form.getFieldsValue();

        const updateMaterialTypeResponse = await materialTypeService.updateMaterialType(state?.drawer_material_type_id, {
            name,
        });

        //successful
        setTimeout(() => {
            dispatch(refresh_material_type_list());
            setState(state => ({...state, save_loading : false, }));

            if(updateMaterialTypeResponse)
                {
                    setState(state => ({...state, drawer_material_type_id : null, }));
                    form.resetFields();
                    notification?.success({
                    message : "Success",
                    description : `Successfully saved item ${name}`
                })}
        }, 500);
    }

    const deleteConfirm = async (material_type_id) => {
        const material_type = state?.material_types?.find(x => x.material_type_id === material_type_id);
        const { name, } = material_type;
        Modal.confirm({
            title : 'Confirm Delete This Record?',
            content : (
                <>
                    <div>
                        <div>
                            <span>{name}</span>
                        </div>
                    </div>
                </>
            ),
            onOk : () => {
                return new Promise(async (resolve, reject) => {
                    await onDeleteMaterialType(material_type_id).then((result) => {
                        setTimeout(() => {
                            // notification
                            notification?.success({
                                message : 'Successfully Deleted',
                                description : (
                                    <>
                                        <div>
                                            <div>
                                                <span>{name}</span>
                                            </div>
                                        </div>
                                    </>
                                )
                            })
                            setState(state => ({...state, refresh : (state?.refresh + 1)}));
                            resolve();
                        }, 500);
                    });
                    
                }).catch(() => console.log('Oops errors!'));
            }
        })
    }

    const onDeleteMaterialType = async (material_type_id) => {
        const deleteMaterialTypeResponse = await materialTypeService?.deleteMaterialType(material_type_id);
        return deleteMaterialTypeResponse;
    }

    useEffect(() => {
        fetchMaterialType();
    }, [filter, state?.page, configRedux?.material_type_list_refresh, state?.refresh])

    return (
        <>
            <Spin spinning={state?.loading}>
                <Table
                pagination={{ 
                    size : 10,
                    showSizeChanger: false,
                    total : state?.count,
                    current : (state?.page + 1),
                    onChange : (page) => {
                        setState(state => ({...state, page : page - 1}));
                    }
                }}
                scroll={{ x : 400, }}
                className='hub-table bordered'
                dataSource={state?.material_types}
                columns={[{
                    title : 'Item Type Details',
                    dataIndex : 'name',
                    key : 'name',
                    render : (text, row, index) => {
                        const { materials } = row;
                        return (
                            <>
                                <div>
                                    <div>
                                        <span>{text}</span>
                                    </div>
                                    <div>
                                        <span style={{fontSize:12, color : 'var(--secondary-text-color)'}}>{materials?.length} items</span>
                                    </div>
                                </div>
                            </>
                        )
                    }
                },{
                    title : 'Action',
                    dataIndex : 'material_type_id',
                    key : 'material_type_id',
                    width : '10%',
                    render : (text, row, index) => {
                        const material_type_id = text;
                        return (
                            <Button.Group>
                                <Button
                                onClick={() => openDrawer(material_type_id)}
                                >
                                    View
                                </Button>
                                {
                                    state?.used_material_type_id.some(x => x.material_type_id === material_type_id) ?
                                        <Button type="primary" disabled />
                                        :
                                        <Button icon={<DeleteOutlined style={{ color: 'var(--secondary-text-color)' }} onClick={async () => deleteConfirm(material_type_id)} />} />
                                }
                            </Button.Group>
                            
                        )
                    }
                },]}
                />
            </Spin>

            {/* detail */}
            <Drawer
            title="Item Type Detail"
            rootClassName='drawer-detail-root'
            className='drawer-detail'
            open={state?.drawer_material_type_id}
            onClose={() => openDrawer(null)}
            width={innerWidth >= 768 ? 800 : "100%"}
            footer={
                <>
                    <div style={{display:'flex', padding:'12px 0',}}>
                        <div style={{marginLeft:'auto',}}>
                            <Space>
                                <Button loading={state?.save_loading} type='primary' onClick={() => form.submit()}>Save Changes</Button>
                            </Space>
                        </div>
                    </div>
                </>
            }
            >
                <div>
                    <Form
                    onFinish={() => saveMaterialType(state?.drawer_material_type_id)}
                    form={form}
                    layout='vertical'
                    >
                        <MaterialTypeEditDetail material_type_id={state?.drawer_material_type_id} form={form} />
                    </Form>
                </div>
            </Drawer>
        </>
    );
}

export default MaterialTypeList;