import React from 'react';
import ContainerComponent from '../../helpers/components/container.component';
import ResetPasswordLayout from './components/reset_password_layout';

const ResetPasswordIndex = () => {

    return (
        <>
            <ContainerComponent span={10} bottom={20}>
                <ResetPasswordLayout />
            </ContainerComponent>
           
        </>
    );
}

export default ResetPasswordIndex;