import { Avatar, Button, Space, Spin, Table, Tag } from 'antd';
import React, { useEffect, useState } from 'react';
import outboundService from '../../../services/warehouse/outbound.service';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { DeleteOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';

const OutboundList = ({ filter, filter_changed }) => {
    const warehouseRedux = useSelector(state => state.config);
    const [state, setState] = useState({
        loading : false,
        outbounds : [],
        
        count : 0,
        page : 0,
    })

    const fetchOutbounds = async () => {
        setState(state => ({...state, loading : true, }));
        const { search, warehouse, inoutbound_source, inoutbound_status, partner, startDate, endDate } = filter;
        const outboundResponse = await outboundService?.getOutbounds({
            search,
            warehouse_ids: warehouse,
            inoutbound_source_ids: inoutbound_source,
            inoutbound_status_ids: inoutbound_status,
            partner_ids: partner,
            startDate: startDate,
            endDate: endDate,

            //pagination
            page : state?.page,
        });

        const { count, rows : outbounds } = outboundResponse?.outbounds;

        setTimeout(() => {
            setState(state => ({...state, loading : false, outbounds : outbounds, count : count }));
        }, 500);
    }

    const filterchangeDefaultPage = () => {
        if (filter_changed) {
            setState(state => ({ ...state, page: 0, filter_changed: false }));
        }
    };

    useEffect(() => {
        filterchangeDefaultPage();
    }, [filter,])

    useEffect(() => {
        fetchOutbounds();
    }, [filter, state?.page, warehouseRedux?.outbound_list_refresh])

    return (
        <>
            <Spin spinning={state?.loading}>
                <Table
                pagination={{ 
                    size : 10,
                    showSizeChanger: false,
                    total : state?.count,
                    current : (state?.page + 1),
                    onChange : (page) => {
                        setState(state => ({...state, page : page - 1}));
                        // dispatch(set_list_page(page - 1));
                    }
                }}
                scroll={{ x : 400, }}
                className='hub-table bordered'
                dataSource={state?.outbounds}
                columns={[{
                    title : 'Outbound',
                    dataIndex : 'running_number',
                    key : 'running_number',
                    // width : '25%',
                },{
                    title : 'Warehouse',
                    dataIndex : 'warehouse',
                    key : 'warehouse',
                    // width : '25%',
                    render : (warehouse, row, index) => {
                        const { name, } = warehouse ?? {};
                        return (
                            <>
                                <div>
                                    <span>{name}</span>
                                </div>
                            </>
                        )
                    }
                },{
                    title : 'Outbound Source',
                    dataIndex : 'inoutbound_source',
                    key : 'inoutbound_source',
                    // width : '25%',
                    render : (inoutbound_source, row, index) => {
                        const { name, code, color } = inoutbound_source ?? {};
                        return (
                            <>
                                <div>
                                    <Tag color={color} style={{ border : 'none', borderRadius:4,}}>{name}</Tag>
                                </div>
                            </>
                        )
                    }
                },{
                    title : 'Status',
                    dataIndex : 'inoutbound_status',
                    key : 'inoutbound_status',
                    // width : '25%',
                    render : (inoutbound_status, row, index) => {
                        const { name, code, color } = inoutbound_status ?? {};
                        return (
                            <>
                                <div>
                                    <Tag color={color} style={{ border : 'none', borderRadius:4,}}>{name}</Tag>
                                </div>
                            </>
                        )
                    }
                },{
                    title : 'To',
                    dataIndex : 'partner',
                    key : 'partner',
                    render : (partner, row, index) => {
                        const { name, code, color } = partner ?? {};
                        return (
                            <>
                                <div>
                                    <Tag color={color} style={{ border : 'none', borderRadius:4,}}>{name}</Tag>
                                </div>
                            </>
                        )
                    }
                },{
                    title : 'Remarks',
                    dataIndex: 'remarks',
                    key: 'remarks',
                    render: (remarks, row, index) => {
                        const { name, code, color } = remarks ?? {};
                        return (
                            <>
                                <div>
                                    <Tag color={color} style={{ border: 'none', borderRadius: 4, }}>{remarks}</Tag>
                                </div>
                            </>
                        )
                    }
                },{
                    title : 'Created Date',
                    dataIndex : 'created_at',
                    key : 'created_at',
                    // width : '25%',
                    render : (created_at, row, index) => {
                        return (
                            <>
                                <div>
                                    <div>
                                        <span>{moment(created_at)?.format("YYYY-MM-DD hh:mm:ssa")}</span>
                                    </div>
                                </div>
                            </>
                        )
                    }
                },{
                    title : 'Action',
                    dataIndex : 'outbound_id',
                    key : 'outbound_id',
                    width : '10%',
                    render : (outbound_id, row, index) => {
                        return (
                            <Button.Group>
                                <Link to={`/warehouse/outbound/${outbound_id}`}>
                                    <Button
                                    // onClick={() => openDrawer(uom_id)}
                                    >
                                        View
                                    </Button>
                                </Link>
                                {/* <Button icon={<DeleteOutlined style={{color:'var(--secondary-text-color)'}} />} /> */}
                            </Button.Group>
                            
                        )
                    }
                },]}
                />
            </Spin>
        </>
    );
}

export default OutboundList;