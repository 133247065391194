import React from 'react';
import ConfigMaterialTypeLayout from './material_type/config_material_type_layout';
import ContainerComponent from '../../helpers/components/container.component';

const ConfigMaterialTypeIndex = () => {
    return (
        <>
            <ContainerComponent span={22} top={20}>
                <ConfigMaterialTypeLayout />
            </ContainerComponent>
        </>
    );
}

export default ConfigMaterialTypeIndex;