import React, { useEffect, useState } from 'react';
import MenuTitleComponent from '../../../helpers/components/menu_title.component';
import UserRoleList from './user_role_list';
import { Button, Checkbox, Drawer, Form, Input, List, Radio, Space, notification } from 'antd';
import { CaretDownFilled, CloseCircleFilled, SearchOutlined } from '@ant-design/icons';
import UserRoleEditDetail from './user_role_edit_detail';
import userRoleService from '../../../services/admin/user_role.service';
import { useDispatch } from 'react-redux';
import { refresh_user_role_list } from '../../../actions/config.action';
import FilterBullet from '../../../helpers/components/filter_bullet';
import warehouseService from '../../../services/config/warehouse.service';
import accessService from '../../../services/admin/access.service';

const ConfigUserRoleLayout = () => {
    const { innerWidth } = window;
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const [state, setState] = useState({
        open_drawer : false,
        save_loading : false,

        // roles : [],
        // warehouses : [],
    });
    const [filter, setFilter] = useState({
        search : null,
        // role_ids : [],
        // menu_ids : [],
    });
    const [timer, setTimer] = useState(null);

    const doneTyping = async (input) => {
        clearTimeout(timer);
        const newTimer = setTimeout(() => {
            setFilter(filter => ({ ...filter, search: input, }));
        }, 300);
        setTimer(newTimer);
    }

    const onCheckChange = (key, checkedValues) => {
        setFilter(filter => ({...filter, [key] : checkedValues}));
    }

    const saveUserRole = async () => {
        setState(state => ({...state, save_loading : true,}));

        const { name, menu_ids, feature_ids } = form.getFieldsValue();
        const saveUserRoleResponse = await userRoleService.saveRole({
            name, menu_ids, feature_ids,
        });

        //successful
        setTimeout(() => {
            dispatch(refresh_user_role_list());
            setState(state => ({...state, save_loading : false, }));

            if(saveUserRoleResponse)
                {
                    setState(state => ({...state, open_drawer : null, }));
                    form.resetFields();
                    notification?.success({
                    message : "Success",
                    description : `Successfully saved item ${name}`
                })}
        }, 500);
    }

    // const fetchInitialization = async () => {
    //     const warehouseResponse = await warehouseService?.getWarehouses();
    //     const roleResponse = await accessService?.getRoles();

    //     setState(state => ({...state, roles : roleResponse?.roles, warehouses : warehouseResponse?.warehouses}));
    // }

    // useEffect(() => {
    //     fetchInitialization();
    // }, []);

    return (
        <>
            <div>
                <div style={{textAlign:'start',}}>
                    <MenuTitleComponent />
                </div>

                <div className='filter-panel' style={{marginBottom:12,}}>
                    <div>
                        <Input
                        onChange={(e) => doneTyping(e.target.value)}
                        name="search"
                        prefix={<SearchOutlined style={{color :'var(--secondary-text-color)'}} />}
                        placeholder='Search UserRole'
                        style={{minWidth: '100%',}}
                        allowClear
                        />
                    </div>

                    <div style={{display:'flex', alignItems:'center', marginTop:12, gap:8,}}>
                        {/* <div>
                            <div className='filter-panel' style={{textAlign:'start'}}>
                                <Space direction={innerWidth > 768 ? 'horizontal' : 'vertical'}>
                                    <FilterBullet
                                    popover={
                                        <>
                                            <div style={{padding:12,}}>
                                                <Input
                                                prefix={<SearchOutlined style={{color :'var(--secondary-text-color)'}}/>}
                                                placeholder='Search Roles'
                                                allowClear
                                                />
                                            </div>
                                            
                                            <Checkbox.Group
                                            value={filter?.role_ids}
                                            onChange={(checkedValues) => onCheckChange('role_ids', checkedValues)}
                                            >
                                                <List
                                                style={{ width:280, }}
                                                dataSource={state?.roles}
                                                renderItem={(item, index) => {
                                                    const { role_id, name, } = item ?? {};
                                                    return (
                                                        <List.Item style={{width:'100%'}} key={index}>
                                                            <div style={{display:'flex', alignItems:'center', width:'100%'}}>
                                                                <div>
                                                                    <span>{name}</span>
                                                                </div>
                                                                <div style={{marginLeft:'auto'}}>
                                                                    <Checkbox
                                                                    value={role_id}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </List.Item>
                                                    )
                                                }}
                                                />
                                            </Checkbox.Group>
                                        </>
                                    }
                                    >
                                        <div>
                                            <Space>
                                                <div>
                                                    <span>{`Roles ${filter?.role_ids?.length > 0 ? `: ${filter?.role_ids?.length} items` : "" }`}</span>
                                                </div>

                                                <div>
                                                    <CaretDownFilled style={{color:'#a0aec0'}} />
                                                </div>
                                                
                                            </Space>
                                            
                                        </div>
                                    </FilterBullet>

                                    <FilterBullet
                                    popover={
                                        <>
                                            <div style={{padding:12,}}>
                                                <Input
                                                prefix={<SearchOutlined style={{color :'var(--secondary-text-color)'}}/>}
                                                placeholder='Search Warehouses'
                                                allowClear
                                                />
                                            </div>
                                            
                                            <Checkbox.Group
                                            value={filter?.warehouse_ids}
                                            onChange={(checkedValues) => onCheckChange('warehouse_ids', checkedValues)}
                                            >
                                                <List
                                                style={{ width:280, }}
                                                dataSource={state?.warehouses}
                                                renderItem={(item, index) => {
                                                    const { warehouse_id, name, code } = item ?? {};
                                                    return (
                                                        <List.Item style={{width:'100%'}} key={index}>
                                                            <div style={{display:'flex', alignItems:'center', width:'100%'}}>
                                                                <div>
                                                                    <div>
                                                                        <span>{name}</span>
                                                                    </div>
                                                                    <div>
                                                                        <span style={{fontSize:12, color:'var(--secondary-text-color)'}}>{code}</span>
                                                                    </div>
                                                                </div>
                                                                <div style={{marginLeft:'auto'}}>
                                                                    <Checkbox
                                                                    value={warehouse_id}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </List.Item>
                                                    )
                                                }}
                                                />
                                            </Checkbox.Group>
                                        </>
                                    }
                                    >
                                        <div>
                                            <Space>
                                                <div>
                                                    <span>{`Warehouses ${filter?.warehouse_ids?.length > 0 ? `: ${filter?.warehouse_ids?.length} items` : "" }`}</span>
                                                </div>

                                                <div>
                                                    <CaretDownFilled style={{color:'#a0aec0'}} />
                                                </div>
                                                
                                            </Space>
                                            
                                        </div>
                                    </FilterBullet>
                                </Space>
                            </div>
                        </div> */}

                        <div style={{marginLeft:'auto'}}>
                            <Button onClick={() => setState(state => ({...state, open_drawer : true,}))} type='primary'>New UserRole</Button>
                        </div>
                    </div>
                </div>


                <div>
                    <UserRoleList filter={filter} />
                </div>
            </div>

            {/* detail */}
            <Drawer
            title="UserRole Detail"
            rootClassName='drawer-detail-root'
            className='drawer-detail'
            open={state?.open_drawer}
            onClose={() => setState(state => ({...state, open_drawer : false, }))}
            width={innerWidth >= 768 ? 800 : "100%"}
            footer={
                <>
                    <div style={{display:'flex', padding:'12px 0',}}>
                        <div style={{marginLeft:'auto',}}>
                            <Space>
                                <Button loading={state?.save_loading} type='primary' onClick={() => form.submit()}>Save Changes</Button>
                            </Space>
                        </div>
                    </div>
                </>
            }
            >
                <div>
                    <Form
                    onFinish={saveUserRole}
                    form={form}
                    layout='vertical'
                    >
                        <UserRoleEditDetail form={form} />
                    </Form>
                </div>
            </Drawer>
        </>
    );
}

export default ConfigUserRoleLayout;