import React from 'react';
import ContainerComponent from '../../helpers/components/container.component';
import ProductionLayout from './components/production_layout';
import './production.css';

const ProductionIndex = () => {
    return (
        <>
            <ContainerComponent span={22} top={20}>
                <ProductionLayout />
            </ContainerComponent>
        </>
    );
}

export default ProductionIndex;