import React from 'react';
import ContainerComponent from '../../helpers/components/container.component';
import LocationLayout from './location/location_layout';

const LocationIndex = () => {
    return (
        <>
            <ContainerComponent span={22} top={20}>
                <LocationLayout />
            </ContainerComponent>
        </>
    );
}

export default LocationIndex;