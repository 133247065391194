import axios from 'axios';
import serviceHelper from '../../helpers/functions/service.helper';

async function getUserRoles(query){
    const queryString = serviceHelper.queryString(query);
    const userRoleResponse = await axios.get(`admin/user_role/get?${queryString}`, { withCredentials : true, });
    return userRoleResponse?.data;
}

async function getRoleList(query){
    const queryString = serviceHelper.queryString(query);
    const userRoleResponse = await axios.get(`admin/user_role/get/list`, { withCredentials : true, });
    return userRoleResponse?.data;
}

async function saveRole(params){
    const saveUserRoleResponse = await axios.post(`admin/user_role/save`, params, { withCredentials : true, });
    return saveUserRoleResponse?.data;
}

async function updateRole(role_id, params){
    const updateUserRoleResponse = await axios.post(`admin/user_role/${role_id}/update`, params, { withCredentials : true, });
    return updateUserRoleResponse?.data;
}

// async function updateRoleAccess(role_id, params){
//     const updateUserRoleResponse = await axios.post(`admin/user_role/${role_id}/updateroleaccess`, params, { withCredentials : true, });
//     return updateUserRoleResponse?.data;
// }

// async function updateRoleFeatureAccess(role_id, params){
//     const updateUserRoleResponse = await axios.post(`admin/user_role/${role_id}/updaterolefeatureaccess`, params, { withCredentials : true, });
//     return updateUserRoleResponse?.data;
// }

export default {
    getUserRoles,
    getRoleList,
    saveRole,
    updateRole,
    // updateRoleAccess,
    // updateRoleFeatureAccess,
}