import React, { useEffect } from 'react';
import MenuTitleComponent from '../../../helpers/components/menu_title.component';
import ApprovalStep from './approval_step';

const ApprovalLayout = () => {

    useEffect(() => {

    }, [])

    return (
        <>
            <div>
                <div style={{textAlign:'start',}}>
                    <MenuTitleComponent />
                </div>
            </div>
            <div>
                <ApprovalStep />
            </div>
        </>
    );
}

export default ApprovalLayout;