import React from 'react';
import PageHeaderComponent from '../../../helpers/components/page_header.component';
import { Avatar, Col, Divider, Row, Space, Table, Tag } from 'antd';
import moment from 'moment';
import { BlockOutlined, CalendarOutlined, Loading3QuartersOutlined, TeamOutlined } from '@ant-design/icons';

const InboundTemplate = ({ inbound, inboundMaterial }) => {
    const { innerWidth } = window;
    const is_xl = innerWidth >= 768;
    const align_items = 'start';
    return (
        <>
            <div style={{padding : 24,}}>
                <div>
                    <div style={{display : 'flex', alignItems:'center'}}>
                        <div>
                            <PageHeaderComponent title={'Inbound'} textAlign={'start'} />
                        </div>
                        <div style={{marginLeft:'auto', fontSize:12, color : 'var(--secondary-text-color)', textAlign:'right',}}>
                            <div>
                                <span>{`#${inbound?.running_number}`}</span>
                            </div>
                            <div>
                                <span>{moment(inbound?.created_at)?.format("YYYY-MM-DD")}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{ textAlign:'start', }}>
                    <span>{inbound?.title}</span>
                </div>
                <Divider style={{marginTop:8,}} />
                <div style={{textAlign:'start',}}>
                    <Row gutter={[20, 20]}>
                        <Col span={8} xs={12} md={12} xl={12}>
                            <div style={{display:'flex', justifyContent: align_items,}}>
                                <div>
                                    <div style={{color:'var(--secondary-text-color)'}}>
                                        <Space>
                                            <div>
                                                <TeamOutlined />
                                            </div>
                                            <div>
                                                <span>From</span>
                                            </div>
                                        </Space>
                                    </div>
                                    <div style={{marginTop:6,}}>
                                        <div style={{height:30, display:'flex', alignItems: 'center',}}>
                                            <Space>
                                                <div>
                                                    {/* <Avatar style={{ backgroundColor: '#fde3cf', color: '#f56a00', }}>
                                                        {inbound?.partner?.name?.split(' ')?.map(word => word.charAt(0).toUpperCase()).join('')}
                                                    </Avatar> */}
                                                </div>
                                                <div>
                                                    <span>{inbound?.partner?.name}</span>
                                                </div>
                                            </Space>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col span={8} xs={12} md={12} xl={12}>
                            <div style={{display:'flex', justifyContent: align_items,}}>
                                <div>
                                    <div style={{color:'var(--secondary-text-color)'}}>
                                        <Space>
                                            <div>
                                                <BlockOutlined />
                                            </div>
                                            <div>
                                                <span>Inbound Source</span>
                                            </div>
                                        </Space>
                                    </div>
                                    <div style={{marginTop:6,}}>
                                        <div style={{height:30, display:'flex', alignItems:'center',}}>
                                            <span>{inbound?.inoutbound_source?.name}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col span={8} xs={12} md={12} xl={12}>
                            <div style={{display:'flex', justifyContent: align_items,}}>
                                <div>
                                    <div style={{color:'var(--secondary-text-color)'}}>
                                        <Space>
                                            <div>
                                                <Loading3QuartersOutlined />
                                            </div>
                                            <div>
                                                <span>Status</span>
                                            </div>
                                        </Space>
                                    </div>
                                    <div style={{marginTop:6,}}>
                                        <div style={{height:30, display:'flex', alignItems:'center',}}>
                                            <Tag color={inbound?.inoutbound_status?.color} style={{border:'none'}}>
                                                <span>{inbound?.inoutbound_status?.name}</span>
                                            </Tag>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>

                <Divider />
                <div>
                    <div style={{textAlign:'start',}}>
                        <span style={{fontSize:18, fontWeight:500,}}>Item</span>
                    </div>
                    {/* empty table with populated item */}
                    <div style={{marginTop:12,}}>
                        <Table
                        pagination={false}
                        className='hub-table bordered'
                        // dataSource={[...Array(inbound?.products?.length > 5 ? inbound?.products?.length : 5)].map((x, i) => {
                        //     return x;
                        // })}
                        dataSource={inboundMaterial}
                        columns={[{
                            title : 'Index',
                            dataIndex : 'product',
                            key : 'product',
                            width : '10%',
                            render : (text, row, index) => {
                                return (
                                    <>
                                        <span style={{fontSize:12, color:'var(--secondary-text-color)'}}>{(index + 1)}</span>
                                    </>
                                )
                            }
                        },{
                            title : 'Item',
                            dataIndex : 'material',
                            key : 'material',
                            width : '40%',
                            render : (material, row, index) => {
                                const { name } = material ?? {};
                                    const { inbound_material_id } = row;
                                return (
                                    <>
                                        <span>{name}</span>
                                    </>
                                )
                            }
                        },{
                            title : 'Location',
                            dataIndex : 'location',
                            key : 'location',
                            width : '30%',
                            render : (location, row, index) => {
                                const { location_id, name, warehouse_id } = location ?? {};
                                    const { inbound_material_id } = row;
                                return (
                                    <>
                                        <span>{name}</span>
                                    </>
                                )
                            }
                        },{
                            title : 'Quantity',
                            dataIndex : 'quantity',
                            key : 'quantity',
                            width : '20%',
                            render : (quantity, row, index) => {
                                const { material, inbound_material_id } = row;
                                return (
                                    <>
                                        <span>{quantity} </span>
                                        <span style={{ fontSize:12, color : 'var(--secondary-text-color)' }}>{material?.uom?.name}</span>
                                    </>
                                )
                            }
                        }]}
                        />
                    </div>
                </div>

                {
                    inbound?.remarks &&
                    (
                        <>
                            <div style={{marginTop:24,}}>
                                <div style={{textAlign:'start',}}>
                                    <span style={{fontSize:18, fontWeight:500,}}>Remarks</span>
                                </div>
                                <div style={{marginTop:12, textAlign:'start',}}>
                                    <div>
                                        <span>{inbound?.remarks}</span>
                                    </div>
                                </div>
                            </div>
                        </>
                    )
                }
                
            </div>
        </>
    );
}

export default InboundTemplate;