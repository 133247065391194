import { Button, Col, Collapse, Form, Input, List, notification, Radio, Row, Select, Skeleton, Space, Switch } from 'antd';
import { CheckOutlined, CloseOutlined, SettingOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import userRoleService from '../../../services/admin/user_role.service';
import menuService from '../../../services/admin/menu.service';
import styled from 'styled-components';
import FormItem from 'antd/es/form/FormItem';
const { Panel } = Collapse;
const StyledCollapse = styled(Collapse.Panel)`
  &&& {
    border: none;
    border-radius: 0px;
    background-color: #f7f7f7;
    box-shadow: none;
  }
  .ant-collapse-content {
    // background: palegreen;
//     margin: 0px;
//    padding: 0px;
  }
`;

const UserRoleEditDetail = ({ role_id, form }) => {
    const [state, setState] = useState({
        loading : false,
        role : null,
        menus : [],
        // modules : [],
        menu_ids : [],
        feature_ids : [],

        expandIconPosition : 'start',
    })

    const onChange = (key) => {};

    // const onMenuSwitchChange = async (checked, e) => {
    //     setState(state => ({...state, save_loading : true,}));
    //     const menu_id = e.currentTarget.id;
    //     const updateUserRoleResponse = await userRoleService.updateRoleAccess(role_id, {
    //         menu_id,
    //     });

    //     setTimeout(() => {
    //         setState(state => ({...state, save_loading : false, }));

    //         //success message
    //         notification?.success({
    //             message : "Success",
    //             description : `Successfully Updated User Role Access`
    //         })
    //     }, 100);
    // };

    // const onFeatureSwitchChange = async (checked, e) => {
    //     setState(state => ({...state, save_loading : true,}));
    //     const feature_id = e.currentTarget.id;
    //     const updateUserRoleResponse = await userRoleService.updateRoleFeatureAccess(role_id, {
    //         feature_id : feature_id,
    //         enabled : checked,
    //     });

    //     setTimeout(() => {
    //         setState(state => ({...state, save_loading : false, }));

    //         //success message
    //         notification?.success({
    //             message : "Success",
    //             description : `Successfully Updated User Role Access`
    //         })
    //     }, 100);
    // };

    const onMenuSwitchChange = async (checked, e) => {
        setState(state => ({...state, save_loading : true,}));
        let menu_id = parseInt(e.currentTarget.id);
        let menu_ids = state?.menu_ids;

        if(checked)
        {
            menu_ids.includes(menu_id) ? menu_id = 0 : menu_ids.push(menu_id)
        } else 
        {
            if(menu_ids.includes(menu_id))
            {
                const x = menu_ids.filter((menu) => menu != menu_id);
                menu_ids = x;
            }
        }
        
        setTimeout(() => {
            form.setFieldsValue({ menu_ids });
            setState(state => ({...state, menu_ids : menu_ids, save_loading : false, }));
        }, 100);
    };

    const onFeatureSwitchChange = async (checked, e) => {
        setState(state => ({...state, save_loading : true,}));
        const feature_id = parseInt(e.currentTarget.id);
        let feature_ids = state?.feature_ids;

        if(checked)
        {
            feature_ids.includes(feature_id) ? feature_id = 0 : feature_ids.push(feature_id)
        } else 
        {
            if(feature_ids.includes(feature_id))
            {
                const x = feature_ids.filter((feature) => feature != feature_id);
                feature_ids = x;
            }
        }

        setTimeout(() => {
            form.setFieldsValue({  feature_ids });
            setState(state => ({...state, feature_ids : feature_ids, save_loading : false, }));
        }, 100);
    };

    const fetchUserRole = async () => {
        setState(state => ({...state, loading : true, }));
        const userRoleResponse = await userRoleService?.getUserRoles({
            role_id,
        });
        let menu_ids = [];
        let feature_ids = [];
        const { rows : roles } = userRoleResponse?.roles;
        const role = roles?.length > 0 ? roles[0] : null;

        let menuResponse = await menuService?.getMenus();
        let { menus }  = menuResponse;
        menus = menus.filter(x => x.name != "Warehouse");
        menus = menus.map(menu => {
            const menuitem = role.accesses.find(x => x.menu_id === menu.menu_id)
            
            menu.isChecked = menuitem 
            ? true : false

            if(menuitem) 
            {
                menu.isChecked = true
                menu_ids.push(menu.menu_id)
            } else {
                menu.isChecked = false
            }

            if (menu.features.length > 0)
            {
                menu.features.map(feature => {
                    const featureitem = role.features.find(x => x.RoleFeature.role_id === role_id && x.RoleFeature.feature_id === feature.feature_id)
                    {
                        if(featureitem) 
                        {
                            feature.enabled = featureitem.RoleFeature.enabled
                            feature_ids.push(feature.feature_id)
                        } else {
                            feature.enabled = false
                        }
                    }
                })
            }
            return menu
        })
        setTimeout(() => {
            const { name, role_id, accesses } = role;
            form.setFieldsValue({ name, role_id, menu_ids, feature_ids });
            setState(state => ({...state, loading : false, role : role, menus : menus, menu_ids : menu_ids, feature_ids : feature_ids }));
        }, 500);
    }

    const fetchInitialization = async () => {
        let menuResponse = await menuService?.getMenus();
        let { menus }  = menuResponse;
        menus = menus.filter(x => x.name != "Warehouse");
        let menu_ids = []
        let feature_ids = []

        menus = menus.map(menu => {
            menu.isChecked = true;
            menu_ids.push(menu.menu_id);
            if (menu.features.length > 0)
            {
                menu.features.map(feature => {
                    feature.enabled = true
                    feature_ids.push(feature.feature_id)
                })
            }
            return menu
        })
        if (!role_id)
        {form.setFieldsValue({ menu_ids, feature_ids });}
        setState(state => ({...state, menus : menus, menu_ids : menu_ids, feature_ids : feature_ids }));
    }

    useEffect(() => {
        role_id && fetchUserRole();
    }, [role_id,]);

    useEffect(() => {
        fetchInitialization();
    }, [])

    return (
        <>
            <div>
                {
                    state?.loading ?
                    (
                        <>
                            <div>
                                <Skeleton active />
                            </div>
                        </>
                    )
                    :
                    (
                        <>
                            <div>
                                <Row gutter={[12, 12]}>
                                    <Col span={12}>
                                        <Form.Item name={'name'} label={'Name'}>
                                            <Input placeholder={`UserRole's Name`} />                                       
                                        </Form.Item>
                                    </Col>
                                    {/* <Col span={12}>
                                    <Space style={{  paddingTop:30 }}>
                                        <Button loading={state?.save_loading} type='primary' onClick={() => form.submit()}>Save Changes</Button>
                                    </Space>
                                    </Col> */}
                                    
                                </Row>
                            </div>
                                   
                            {/* <div style={{display:'flex', padding:'12px 0',}}>
                            <div>
                                <Row gutter={[12, 12]}>
                                    <Col span={12}>
                                        <Form.Item name={'name'} label={'Name'}>
                                            <Input placeholder={`UserRole's Name`} />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </div>
                                <div style={{marginLeft:'auto',}}>
                                    <Space>
                                        <Button loading={state?.save_loading} type='primary' onClick={() => form.submit()}>Save Changes</Button>
                                    </Space>
                                </div>
                            </div> */}
                            

                            

                            {/* Role */}
                            {/* <div>
                                <div>
                                    <span style={{fontWeight:500, fontSize:16,}}>Role</span>
                                </div>

                                <Form.Item name="is_admin" valuePropName="checked">
                                    <Checkbox checked={state?.checked} onChange={onChange}>
                                        Admin
                                    </Checkbox>
                                </Form.Item>
                                
                            </div> */}

                            {/* Menus */}
                            {/* <div>
                                <div>
                                    <span style={{fontWeight:500, fontSize:16,}}>Assign Menu Access</span>
                                </div>

                                <div style={{marginTop:12,}}>
                                    <Form.Item name={'menu_ids'}>
                                        <Select
                                        placeholder="Select Menu Access"
                                        mode='multiple'
                                        options={state?.menus?.map(x =>  ({ value : x?.menu_id, label : x?.name, }))}
                                        />
                                    </Form.Item>
                                </div>
                            </div> */}
                            
                            <div>
                                <div>
                                    <span style={{fontWeight:500, fontSize:16,}}>Menu</span>
                                </div>

                                <div>
                                    <Collapse
                                    defaultActiveKey={['0']}
                                    onChange={onChange}
                                    expandIconPosition={state?.expandIconPosition}>
                                        {state?.menus.map((menu, index) => (
                                        <StyledCollapse header={menu.name} key={menu.menu_id}>
                                            <div style={{display:'flex', alignItems:'center', width:'100%'}}>
                                                <div>
                                                    <span>Screen Access</span>
                                                </div>
                                                <div style={{marginLeft:'auto', marginTop:20}}>
                                                    <Switch id={menu.menu_id} name={menu.menu_id} loading={state?.loading} onClick={onMenuSwitchChange} defaultChecked={menu.isChecked}/>
                                                </div>
                                            </div>
                                            { //Check features
                                            (menu.features.length > 0)
                                            ? (menu.features.map(feature => 
                                            <div style={{display:'flex', alignItems:'center', width:'100%'}}>
                                                <div>
                                                    {feature.name}  
                                                </div>
                                                <div style={{marginLeft:'auto', marginTop:20}}>
                                                    <Switch id={feature.feature_id} name={feature.feature_id} onChange={onFeatureSwitchChange} defaultChecked={feature.enabled}/>
                                                </div>
                                            </div>))  
                                            : 
                                            (
                                                <div> </div> 
                                            )
                                            }
                                            </StyledCollapse>
                                        ))}
                                 </Collapse>
                                    <br />
                                </div>
                                <FormItem name="menu_ids">
                                    <Input type="hidden" value=""/>
                                </FormItem>
                            {/* <FormItem hidden={true} name="menu_ids"></FormItem> */}
                                <FormItem name="feature_ids"  >
                                    <Input type="hidden" value=""/>
                                </FormItem>
                            </div>
                        </>
                    )
                }
            </div>
        </>
    );
}

export default UserRoleEditDetail;