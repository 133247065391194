import axios from 'axios';
import serviceHelper from '../../helpers/functions/service.helper';

async function getUsers(query){
    const queryString = serviceHelper.queryString(query);
    const userResponse = await axios.get(`admin/user/get?${queryString}`, { withCredentials : true, });
    return userResponse?.data;
}

async function saveUser(params){
    const saveUserResponse = await axios.post(`admin/user/save`, params, { withCredentials : true, });
    return saveUserResponse?.data;
}

async function updateUser(user_id, params){
    const updateUserResponse = await axios.post(`admin/user/${user_id}/update`, params, { withCredentials : true, });
    return updateUserResponse?.data;
}

async function checkEmail(params){
    const userResponse = await axios.post(`admin/user/check_email`, params, { withCredentials : true, });
    return userResponse?.data;
}

async function getUsedUserList(query) {
    const queryString = serviceHelper.queryString(query);
    const userResponse = await axios.get(`admin/user/getuse/list`, { withCredentials: true, });
    return userResponse?.data;
}

async function deleteUser(user_id) {
    const deleteUserResponse = await axios.delete(`admin/user/${user_id}/delete`, { withCredentials: true, });
    return deleteUserResponse?.data;
}

export default {
    getUsers,
    saveUser,
    updateUser,
    checkEmail,
    getUsedUserList,
    deleteUser,
}