import axios from 'axios';
import serviceHelper from '../../helpers/functions/service.helper';

async function getOutbounds(query){
    const queryString = serviceHelper.queryString(query);
    const outboundResponse = await axios.get(`production/outbound/get?${queryString}`, { withCredentials : true, });
    return outboundResponse?.data;
}

async function getOutboundDetail(outbound_id){
    const outboundResponse = await axios.get(`production/outbound/${outbound_id}/detail/get`, { withCredentials : true, });
    return outboundResponse?.data;
}

async function getOutboundMaterials(query){
    const queryString = serviceHelper.queryString(query);
    const outboundMaterialsResponse = await axios.get(`production/outbound/outbound_material/get?${queryString}`, { withCredentials : true, });
    return outboundMaterialsResponse?.data;
}

async function saveOutbound(params){
    const saveOutboundResponse = await axios.post(`production/outbound/save`, params, { withCredentials : true, });
    return saveOutboundResponse?.data;
}

async function updateOutboundDetail(outbound_id, params){
    const updateOutboundDetailResponse = await axios.post(`production/outbound/${outbound_id}/update`, params, { withCredentials : true, });
    return updateOutboundDetailResponse?.data;
}

async function saveOutboundMaterial(params){
    const saveOutboundMaterialResponse = await axios.post(`production/outbound/outbound_material/save`, params, { withCredentials : true, });
    return saveOutboundMaterialResponse?.data;
}

async function updateOutboundMaterial(outbound_material_id, params){
    const updateOutboundMaterialResponse = await axios.post(`production/outbound/outbound_material/${outbound_material_id}/update`, params, { withCredentials : true, });
    return updateOutboundMaterialResponse?.data;
}

async function deleteOutboundMaterial(outbound_material_id){
    const deleteOutboundMaterialResponse = await axios.delete(`production/outbound/outbound_material/${outbound_material_id}/delete`, { withCredentials : true, });
    return deleteOutboundMaterialResponse?.data;
}

async function updateOutboundStatus(params){
    const updateOutboundStatusResponse = await axios.post(`production/outbound/updateStatus`, params, { withCredentials : true, });
    return updateOutboundStatusResponse?.data;
}

async function getOutBoundStatusHistory(query){
    const queryString = serviceHelper.queryString(query);
    const outboundHistoryResponse = await axios.get(`production/outbound/outbound_history/get?${queryString}`, { withCredentials : true, });
    return outboundHistoryResponse?.data;
}

async function checkMaterialAvailableQuantity(query){
    const queryString = serviceHelper.queryString(query);
    const outboundMaterialsResponse = await axios.get(`production/outbound/available_quantity/get?${queryString}`, { withCredentials : true, });
    return outboundMaterialsResponse?.data;
}

export default {
    getOutbounds,
    getOutboundDetail,
    getOutboundMaterials,
    saveOutbound,
    updateOutboundDetail,
    saveOutboundMaterial,
    updateOutboundMaterial,
    deleteOutboundMaterial,
    updateOutboundStatus,
    getOutBoundStatusHistory,
    checkMaterialAvailableQuantity
}