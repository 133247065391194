import React, { useEffect, useRef, useState } from 'react';
import GridLayout from "react-grid-layout";
import '/node_modules/react-grid-layout/css/styles.css';
import '/node_modules/react-resizable/css/styles.css';
import { Badge, Button, Card, Drawer, Form, Popconfirm, Space, Spin, Steps } from 'antd';
import lineService from '../../../services/production/line.service';
import EntranceMotion from '../../../helpers/components/entrance_motion';
import { DeleteOutlined, EditOutlined, LoadingOutlined, ThunderboltFilled } from '@ant-design/icons';
import StepCircle from './step_circle';
import LineEditDetail from './line_edit_detail';

const LineGridDraggable = ({ }) => {
    const { innerWidth } = window;
    const cardRef = useRef();
    const [form] = Form.useForm();
    const [state, setState] = useState({
        save_loading : false,
        update_loading : false,
        delete_loading : false,
        loading : false,
        production_lines : [],
        refresh : 0,

        drawer_production_line_id : false,
    })

    const fetchLines = async () => {
        setState(state => ({...state, loading : true,}));
        const lineResponse = await lineService?.getLines();

        setTimeout(() => {
            setState(state => ({...state, loading : false, production_lines : lineResponse?.lines}));
        }, 200);
        
    }

    const onGridChange = async (oldItem, newItem) => {
        const { i : production_line_id, x, y, w : width, h : height } = newItem;
        const { x : old_x, y : old_y, w : old_width, h : old_height } = oldItem;
        const is_changed = !((x == old_x) && (y == old_y) && (height == old_height) && (width == old_width));
        if(is_changed){
            await saveProductionLine(production_line_id, { x, y, width, height});
        }
    }

    const onSaveButtonClick = async (production_line_id) => { // click on save on drawer
        const { name, description } = form?.getFieldsValue();
        await saveProductionLine(production_line_id, { name, description, }).then(() => {
            setState(state => ({...state, drawer_production_line_id : null, }))
        });
    }

    const saveProductionLine = async (production_line_id, body) => {
        setState(state => ({...state, update_loading : true, }));
        const updateLineResponse = await lineService?.updateLine(production_line_id, body);
        setState(state => ({...state, refresh : (state?.refresh + 1), update_loading : false, drawer_production_line_id : null}));
    }

    const openDrawer = async (production_line_id,) => {
        setState(state => ({...state, drawer_production_line_id : production_line_id, }));
    }

    const addGridItem = async () => {
        setState(state => ({...state, save_loading : true, }))
        // get the largest y
        const pl_arr = state?.production_lines;
        const max_y_obj = pl_arr.reduce((maxYObj, currentObj) => {
            return currentObj.y > maxYObj.y ? currentObj : maxYObj;
        }, pl_arr[0]);
        const obj_y = max_y_obj?.y + max_y_obj?.height;
        const new_obj = {
            y : obj_y,
            x : 0,
            height : 1,
            width : 1,
        }
        const saveLineResponse = await lineService?.saveLine(new_obj).catch(err => {
            setState(state => ({...state, save_loading : false,}));
        });
        if(saveLineResponse){
            setTimeout(() => {
                const { production_line_id } = saveLineResponse?.save_line;
                setState(state => ({...state, save_loading : false, refresh : (state?.refresh + 1), drawer_production_line_id : production_line_id}));
            }, 500);
            
        }
    }

    const deleteProductionLine = async (production_line_id) => {
        setState(state => ({...state, delete_loading : true, }));
        const deleteLineResponse = await lineService?.deleteLine(production_line_id).catch((err) => {
            setState(state => ({...state, delete_loading : false}));
        });
        if(deleteLineResponse){
            setTimeout(() => {
                setState(state => ({...state, delete_loading : false, refresh : (state?.refresh + 1)}));
            }, 500);
            
        }
        
    }
    

    useEffect(() => {
        fetchLines();
    }, [state?.refresh])

    return (
        <>
            <div>
                <div style={{display:'flex'}}>
                    <div style={{marginLeft:'auto'}}>
                        <Button loading={state?.save_loading} onClick={addGridItem} type='primary'>New Production Line</Button>
                    </div>
                </div>
                <div className="rgl-container" style={{marginTop:12,}}>
                    <Spin spinning={state?.loading} indicator={<LoadingOutlined />}>
                        <Card
                        ref={cardRef}
                        bodyStyle={{padding : 0,}}
                        style={{overflowX:'auto', overflowY:'hidden'}}
                        >
                            <GridLayout
                            // isDraggable={view_only}
                            // isResizable={view_only}
                            // isDroppable={view_only}
                            className="layout"
                            // layout={layout}
                            cols={12}
                            rowHeight={100}
                            width={1200}
                            compactType={null}
                            preventCollision={true}
                            onDragStop={(layout, oldItem, newItem) => onGridChange(oldItem, newItem)}
                            onResizeStop={(layout, oldItem, newItem) => onGridChange(oldItem, newItem)}
                            >
                                {
                                    state?.production_lines?.map((line, index) => {
                                        const { production_line_id, name, description, x, y, width, height } = line;
                                        return (
                                            <div key={production_line_id} data-grid={{ x: x, y: y, w: width, h: height,  }}>
                                                <Badge className='badge-width' count={
                                                    <div>
                                                        <Button.Group>
                                                            <Button onClick={() => openDrawer(production_line_id)} size='small' icon={<EditOutlined style={{color:'var(--secondary-text-color)'}} />} />

                                                            <Popconfirm
                                                            title="Delete Production Line"
                                                            description={`Are you sure to delete ${name ?? "this production line"}?`}
                                                            onConfirm={() => deleteProductionLine(production_line_id)}
                                                            >
                                                                <Button loading={state?.delete_loading} size='small' icon={<DeleteOutlined style={{color:'var(--secondary-text-color)'}} />} />
                                                            </Popconfirm>
                                                            
                                                        </Button.Group>
                                                    </div>
                                                }
                                                offset={[-15, 5]}
                                                >
                                                    <EntranceMotion delay={(index * 0.1)} style={{height:'100%', overflow:'hidden', textOverflow:'ellipsis', whiteSpace:'nowrap'}}>
                                                        <div style={{padding:8, textAlign:'start', display: 'flex', flexDirection:'column', height:'100%'}}>
                                                            <div style={{display:'flex',}}>
                                                                <div>
                                                                    <div>
                                                                        <span class="header-font" style={{fontWeight:500,}}>{name}</span>
                                                                    </div>
                                                                    <div>
                                                                        <span style={{color:'var(--secondary-text-color)', fontSize:12,}}>{description}</span>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div style={{marginTop:'auto',}}>
                                                                <Space className='step-circle-group' style={{maxWidth:'100%',}}>
                                                                    <div>
                                                                        <StepCircle />
                                                                    </div>
                                                                    <div>
                                                                        {/* <span style={{color:'var(--secondary-text-color)', fontSize:12,}}>3 steps configured</span> */}
                                                                    </div>
                                                                </Space>
                                                            </div>
                                                        </div>
                                                    </EntranceMotion>
                                                </Badge>
                                            </div>
                                        )
                                    })
                                }
                            </GridLayout>
                        </Card>
                    </Spin>
                </div>
            </div>


            {/* Edit Drawer */}
            <Drawer
            title="Production Line Detail"
            rootClassName='drawer-detail-root'
            className='drawer-detail'
            open={state?.drawer_production_line_id}
            onClose={() => openDrawer(null)}
            width={innerWidth >= 768 ? 800 : "100%"}
            footer={
                <>
                    <div style={{display:'flex', padding:'12px 0',}}>
                        <div style={{marginLeft:'auto',}}>
                            <Space>
                                <Button loading={state?.update_loading} type='primary' onClick={() => form.submit()}>Save Changes</Button>
                            </Space>
                        </div>
                    </div>
                </>
            }
            >
                <div>
                    <Form
                    onFinish={() => onSaveButtonClick(state?.drawer_production_line_id)}
                    form={form}
                    layout='vertical'
                    >
                        <LineEditDetail production_line_id={state?.drawer_production_line_id} form={form} />
                    </Form>
                </div>
            </Drawer>
        </>
    );
}

export default LineGridDraggable;