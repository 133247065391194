import React, { useEffect, useState } from 'react';
import uomService from '../../../services/config/uom.service';
import { Button, Drawer, Form, Modal, Space, Spin, Table, Tag, notification } from 'antd';
import UomEditDetail from './uom_edit_detail';
import { useDispatch, useSelector } from 'react-redux';
import { refresh_uom_list } from '../../../actions/config.action';
import { DeleteOutlined } from '@ant-design/icons';

const UomList = ({ is_default, filter }) => {
    const { innerWidth } = window;
    const dispatch = useDispatch();
    const configRedux = useSelector(state => state.config);
    const [form] = Form.useForm();
    const [state, setState] = useState({
        loading : false,
        save_loading : false,
        uoms : [],
        used_uom_id: [],

        count: 0,
        page: 0,
        refresh : 0,
        //drawer
        drawer_uom_id : null,
    })

    const getUoms = async () => {
        setState(state => ({...state, loading : true, }));
        const { search, } = filter;

        const uomResponse = await uomService.getUoms({
            is_default,
            search,

            page : state?.page,
        });

        const { count, rows: uoms } = uomResponse?.uoms;

        const usedUomResponse = await uomService?.getUsedUomList({});
        const used_list = usedUomResponse?.dataList;

        setTimeout(() => {
            setState(state => ({ ...state, loading: false, count: count, uoms: uoms, used_uom_id: used_list }));
        }, 500);
    }

    const saveUom = async () => {
        setState(state => ({...state, save_loading : true,}));
        const { name, code, } = form.getFieldsValue();

        const updateUomResponse = await uomService?.updateUom(state?.drawer_uom_id, {
            name, code,
        });

        //successful
        setTimeout(() => {
            dispatch(refresh_uom_list());
            setState(state => ({...state, save_loading : false, }));

            if(updateUomResponse)
                {
                    setState(state => ({...state, drawer_uom_id : null, }));
                    form.resetFields();
                    notification?.success({
                    message : "Success",
                    description : `Successfully saved item ${name}`
                })}
        }, 500);
    }

    const deleteConfirm = async (uom_id) => {
        const uom = state?.uoms?.find(x => x.uom_id === uom_id);
        const { name, } = uom;
        Modal.confirm({
            title : 'Confirm Delete This Record?',
            content : (
                <>
                    <div>
                        <div>
                            <span>{name}</span>
                        </div>
                    </div>
                </>
            ),
            onOk : () => {
                return new Promise(async (resolve, reject) => {
                    await onDeleteUom(uom_id).then((result) => {
                        setTimeout(() => {
                            // notification
                            notification?.success({
                                message : 'Successfully Deleted',
                                description : (
                                    <>
                                        <div>
                                            <div>
                                                <span>{name}</span>
                                            </div>
                                        </div>
                                    </>
                                )
                            })
                            setState(state => ({...state, refresh : (state?.refresh + 1)}));
                            resolve();
                        }, 500);
                    });
                    
                }).catch(() => console.log('Oops errors!'));
            }
        })
    }

    const onDeleteUom = async (uom_id) => {
        const deleteUomResponse = await uomService?.deleteUom(uom_id);
        return deleteUomResponse;
    }

    const openDrawer = (uom_id) => {
        setState(state => ({...state, drawer_uom_id : uom_id,}))
    }

    useEffect(() => {
        getUoms();
    }, [filter, state?.page, configRedux?.uom_list_refresh, state?.refresh])

    return (
        <>
            <Spin spinning={state?.loading}>
                <Table
                pagination={{ 
                    size : 10,
                    showSizeChanger: false,
                    total : state?.count,
                    current : (state?.page + 1),
                    onChange : (page) => {console.log(state?.page)
                        setState(state => ({...state, page : page - 1}));console.log(state?.page)
                        // dispatch(set_list_page(page - 1));
                    }
                }}
                scroll={{ x : 400, }}
                className='hub-table bordered'
                dataSource={state?.uoms}
                columns={[{
                    title : 'UOM',
                    dataIndex : 'code',
                    key : 'code',
                    // width : '15%',
                    render : (text, row, index) => {
                        const { code, name } = row;
                        return (
                            <>
                                <div>
                                    <div>
                                        <span style={{fontWeight:500}}>{code}</span>
                                    </div>
                                    <div>
                                        <span style={{color:'var(--secondary-text-color)', fontWeight:500, fontSize:12,}}>{name}</span>
                                    </div>
                                </div>
                            </>
                        )
                    }
                }, 
                is_default ?
                {} :
                {
                    title : 'Action',
                    dataIndex : 'uom_id',
                    key : 'uom_id',
                    width : '10%',
                    render : (text, row, index) => {
                        const uom_id = text;
                        return (
                            <Button.Group>
                                <Button
                                onClick={() => openDrawer(uom_id)}
                                >
                                    View
                                </Button>
                                {
                                    state?.used_uom_id.some(x => x.uom_id === uom_id) ?
                                        <Button type="primary" disabled />
                                        :
                                        <Button icon={<DeleteOutlined style={{ color: 'var(--secondary-text-color)' }} onClick={async () => deleteConfirm(uom_id)} />} />
                                }
                            </Button.Group>
                            
                        )
                    }
                },]}
                />
            </Spin>

            {/* detail */}
            <Drawer
            title="UOM Detail"
            rootClassName='drawer-detail-root'
            className='drawer-detail'
            open={state?.drawer_uom_id}
            onClose={() => setState(state => ({...state, drawer_uom_id : null, }))}
            width={innerWidth >= 768 ? 800 : "100%"}
            footer={
                <>
                    <div style={{display:'flex', padding:'12px 0',}}>
                        <div style={{marginLeft:'auto',}}>
                            <Space>
                                <Button loading={state?.save_loading} type='primary' onClick={() => form.submit()}>Save Changes</Button>
                            </Space>
                        </div>
                    </div>
                </>
            }
            >
                <div>
                    <Form
                    onFinish={saveUom}
                    form={form}
                    layout='vertical'
                    >
                        <UomEditDetail uom_id={state?.drawer_uom_id} form={form} />
                    </Form>
                </div>
            </Drawer>
        </>
    );
}

export default UomList;