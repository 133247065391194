import React, { useState } from 'react';
import WarehouseList from './warehouse_list';
import MenuTitleComponent from '../../../helpers/components/menu_title.component';
import { Button, Drawer, Input, Space, Form, notification, List, Radio } from 'antd';
import { CaretDownFilled, SearchOutlined, CloseCircleFilled } from '@ant-design/icons';
import FilterBullet from '../../../helpers/components/filter_bullet';
import WarehouseEditDetail from './warehouse_edit_detail';
import warehouseService from '../../../services/config/warehouse.service';
import { useDispatch } from 'react-redux';
import { refresh_warehouse_list } from '../../../actions/config.action';

const ConfigWarehouseLayout = () => {
    const dispatch = useDispatch();
    const { innerWidth } = window;
    const [form] = Form.useForm();
    const [timer, setTimer] = useState(null);
    const [filter, setFilter] = useState({
        search : null,
        is_hq : null,
    });
    const [state, setState] = useState({
        open_drawer : false,
        save_loading : false,
    })

    const doneTyping = async (input) => {
        clearTimeout(timer);
        const newTimer = setTimeout(() => {
            setFilter(filter => ({ ...filter, search: input, }));
        }, 300);
        setTimer(newTimer);
    }

    const onCheckChange = (key, checkedValues) => {
        setFilter(filter => ({...filter, [key] : checkedValues}));
    }

    const saveWarehouse = async () => {
        setState(state => ({...state, save_loading : true,}));
        const { name, code, type, contact_no, address, } = form?.getFieldsValue();
        
        const saveWarehouseResponse = await warehouseService?.saveWarehouse({ name, code, is_hq : type, contact_no, address, });

        //successful
        setTimeout(() => {
            dispatch(refresh_warehouse_list());
            setState(state => ({...state, save_loading : false, open_drawer : false,}));
            form.resetFields();

            //success message
            notification?.success({
                message : "Success",
                description : `Successfully saved warehouse ${name}`
            })
        }, 500);
    }

    return (
        <>
            <div>
                <div style={{textAlign:'start',}}>
                    <MenuTitleComponent />
                </div>

                <div>
                    <div className='filter-panel' style={{marginBottom:12,}}>
                        <div>
                            <Input
                            onChange={(e) => doneTyping(e.target.value)}
                            name="search"
                            prefix={<SearchOutlined style={{color :'var(--secondary-text-color)'}} />}
                            placeholder='Search Warehouse'
                            style={{minWidth: '100%',}}
                            allowClear
                            />
                        </div>

                        <div style={{display:'flex', alignItems:'center', marginTop:12, gap:8,}}>
                            <div>
                                <div className='filter-panel' style={{textAlign:'start'}}>
                                    <Space direction={innerWidth > 768 ? 'horizontal' : 'vertical'}>
                                        <FilterBullet
                                        popover={
                                            <>
                                                <div style={{padding:12,}}>
                                                    <Input
                                                    prefix={<SearchOutlined style={{color :'var(--secondary-text-color)'}}/>}
                                                    placeholder='Search Warehouse Type'
                                                    allowClear
                                                    />
                                                </div>
                                                
                                                <Radio.Group
                                                value={filter?.is_hq}
                                                onChange={(e) => onCheckChange('is_hq', e.target.value)}
                                                style={{ display:'block', }}
                                                >
                                                    <div style={{padding:'0 12px',}}>
                                                        <List
                                                        style={{ width:280, }}
                                                        dataSource={[{
                                                            value : true,
                                                            label : "HQ"
                                                        },{
                                                            value : false,
                                                            label : "Warehouse"
                                                        }]}
                                                        renderItem={(item, index) => {
                                                            const { value, label, } = item ?? {};
                                                            return (
                                                                <List.Item style={{width:'100%'}} key={index}>
                                                                    <div style={{display:'flex', alignItems:'center', width:'100%'}}>
                                                                        <div>
                                                                            <span>{label}</span>
                                                                        </div>
                                                                        <div style={{marginLeft:'auto'}}>
                                                                            <Radio
                                                                            value={value}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </List.Item>
                                                            )
                                                        }}
                                                        />
                                                    </div>
                                                    
                                                </Radio.Group>
                                            </>
                                        }
                                        >
                                            <div>
                                                <Space>
                                                    <div>
                                                        <span>{`Type ${filter?.is_hq != null ? `: ${(filter?.is_hq ? "HQ" : "Warehouse")}` : "" }`}</span>
                                                    </div>

                                                    {
                                                        filter?.is_hq != null ?
                                                        (
                                                            <>
                                                                <CloseCircleFilled onClick={() => onCheckChange('is_hq', null)} style={{color:'#a0aec0'}} />
                                                            </>
                                                        )
                                                        :
                                                        (
                                                            <>
                                                                <div>
                                                                    <CaretDownFilled style={{color:'#a0aec0'}} />
                                                                </div>
                                                            </>
                                                        )
                                                    }
                                                    
                                                </Space>
                                                
                                            </div>
                                        </FilterBullet>
                                    </Space>
                                </div>
                            </div>
                            <div style={{marginLeft:'auto'}}>
                                <Button onClick={() => setState(state => ({...state, open_drawer : true,}))} type='primary'>New Warehouse</Button>
                            </div>
                        </div>
                    </div>
                    <div>
                        <WarehouseList filter={filter} />
                    </div>
                </div>
            </div>


            {/* detail */}
            <Drawer
            title="Warehouse Detail"
            rootClassName='drawer-detail-root'
            className='drawer-detail'
            open={state?.open_drawer}
            onClose={() => setState(state => ({...state, open_drawer : false,}))}
            width={innerWidth >= 768 ? 800 : "100%"}
            footer={
                <>
                    <div style={{display:'flex', padding:'12px 0',}}>
                        <div style={{marginLeft:'auto',}}>
                            <Space>
                                <Button loading={state?.save_loading} type='primary' onClick={() => form.submit()}>Save</Button>
                            </Space>
                        </div>
                    </div>
                </>
            }
            >
                <div>
                    <Form
                    onFinish={saveWarehouse}
                    form={form}
                    layout='vertical'
                    >
                        <WarehouseEditDetail form={form} />
                    </Form>
                </div>
            </Drawer>
        </>
    );
}

export default ConfigWarehouseLayout;