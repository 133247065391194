import axios from 'axios';
import serviceHelper from '../../helpers/functions/service.helper';

async function getEditStatuses(query){
    const queryString = serviceHelper.queryString(query);
    const editStatusResponse = await axios.get(`config/other/edit_status/get?${queryString}`, { withCredentials : true, });
    return editStatusResponse?.data;
}

export default {
    getEditStatuses
}