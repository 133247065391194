import React from 'react';
import ContainerComponent from '../../helpers/components/container.component';
import TransactionsLayout from './transactions/transactions_layout';

const TransactionsIndex = () => {
    return (
        <>
            <ContainerComponent span={22} top={20}>
                <TransactionsLayout />
            </ContainerComponent>
        </>
    );
}

export default TransactionsIndex;