import axios from 'axios';
import serviceHelper from '../../helpers/functions/service.helper';

async function getInOutBoundTypes(query){
    const queryString = serviceHelper.queryString(query);
    const inoutboundTypeResponse = await axios.get(`config/inoutbound_type/get?${queryString}`, { withCredentials : true, });
    return inoutboundTypeResponse?.data;
}

export default {
    getInOutBoundTypes,
}