import React from 'react';
import ContainerComponent from '../../helpers/components/container.component';
import ConfigUserLayout from './user/config_user_layout';

const ConfigUserIndex = () => {
    return (
        <>
            <ContainerComponent span={22} top={20}>
                <ConfigUserLayout />
            </ContainerComponent>
        </>
    );
}

export default ConfigUserIndex;