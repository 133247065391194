import { Avatar, Button, Space, Spin, Table, Tag } from 'antd';
import React, { useEffect, useState } from 'react';
import workOrderService from '../../../services/production/work_order.service';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { DeleteOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';

const WorkOrderList = ({ filter, filter_changed }) => {
    const productionRedux = useSelector(state => state.config);
    const [state, setState] = useState({
        loading : false,
        work_orders : [],

        count : 0,
        page : 0,

        refresh: 0,
    })

    const fetchWorkOrders = async () => {
        setState(state => ({...state, loading : true, }));
        const { search, work_order_status, assignee, startDate, endDate } = filter;
        const workOrderResponse = await workOrderService?.getWorkOrders({
            search,
            work_order_status_ids: work_order_status,
            user_ids: assignee,
            startDate: startDate,
            endDate: endDate,
            page : state?.page,
        });

        const { count, rows : work_orders } = workOrderResponse?.work_orders;

        setTimeout(() => {
            setState(state => ({...state, loading : false, work_orders : work_orders, count : count, }));
        }, 500);
    }

    const filterchangeDefaultPage = () => {
        if (filter_changed) {
            setState(state => ({ ...state, page: 0, filter_changed: false }));
        }
    };

    useEffect(() => {
        filterchangeDefaultPage();
    }, [filter,])

    useEffect(() => {
        fetchWorkOrders();
    }, [filter, state?.page, productionRedux?.work_order_list_refresh])

    return (
        <>
            <Spin spinning={state?.loading}>
                <Table
                pagination={{ 
                    size : 10,
                    showSizeChanger: false,
                    total : state?.count,
                    current : (state?.page + 1),
                    onChange : (page) => {
                        setState(state => ({...state, page : page - 1}));
                        // dispatch(set_list_page(page - 1));
                    }
                }} 
                scroll={{ x : 400, }}
                className='hub-table bordered'
                dataSource={state?.work_orders}
                columns={[{
                    title : 'Work Order',
                    dataIndex : 'running_number',
                    key : 'running_number',
                    // width : '25%',
                },{
                    title : 'Name',
                    dataIndex : 'title',
                    key : 'title',
                    // width : '25%',
                    // render : (name, row, index) => {
                    //     return (
                    //         <>
                    //             <div>
                    //                 <Tag color={color} style={{ border : 'none', borderRadius:4,}}>{name}</Tag>
                    //             </div>
                    //         </>
                    //     )
                    // }
                },{
                    title : 'Issue Date',
                    dataIndex: 'issue_date',
                    key: 'issue_date',
                    // width : '25%',
                    render : (created_at, row, index) => {
                        return (
                            <>
                                <div>
                                    <div>
                                        {/* <span>{moment(created_at)?.format("YYYY-MM-DD hh:mm:ssa")}</span> */}
                                        <span>{moment(created_at)?.format("YYYY-MM-DD")}</span>
                                    </div>
                                </div>
                            </>
                        )
                    }
                },{
                    title : 'Status',
                    dataIndex : 'work_order_status',
                    key : 'work_order_status',
                    // width : '25%',
                    render : (work_order_status, row, index) => {
                        const { name, code, color } = work_order_status;
                        return (
                            <>
                                <div>
                                    <Tag color={color} style={{ border : 'none', borderRadius:4,}}>{name}</Tag>
                                </div>
                            </>
                        )
                    }
                },{
                    // title : 'Assignee',
                    // dataIndex : 'assignee',
                    // key : 'assignee',
                    // render : (assignee, row, index) => {
                    //     const { name } = assignee ?? {};
                    //     // Split the name into words
                    //     const words = name?.split(' ');
                    //     // Extract the first letter of each word and convert to uppercase
                    //     const initials = words?.map(word => word.charAt(0).toUpperCase()).join('');
                    //     return (
                    //         <>
                    //             <div>
                    //                 <Avatar style={{ backgroundColor: '#fde3cf', color: '#f56a00', }}>
                    //                     {initials}
                    //                 </Avatar>
                    //             </div>
                    //         </>
                    //     )
                    // }
                    title : 'Assignee',
                    dataIndex : 'assignee',
                    key : 'assignee',
                    render : (assignee, row, index) => {
                        const { name } = assignee ?? {};
                        return (
                            <>
                                <div>
                                    <Tag color='var(--secondary-button-color)'>
                                        <span style={{fontSize:12, color:'var(--secondary-text-color)'}}>{name}</span>
                                    </Tag>
                                </div>
                            </>
                        )
                    }
                },{
                    title : 'Action',
                    dataIndex : 'work_order_id',
                    key : 'work_order_id',
                    width : '10%',
                    render : (work_order_id, row, index) => {
                        return (
                            <Button.Group>
                                <Link to={`/production/work_order/${work_order_id}`}>
                                    <Button
                                    // onClick={() => openDrawer(uom_id)}
                                    >
                                        View
                                    </Button>
                                </Link>
                                {/* <Button icon={<DeleteOutlined style={{color:'var(--secondary-text-color)'}} />} /> */}
                            </Button.Group>
                            
                        )
                    }
                },]}
                />
            </Spin>
        </>
    );
}

export default WorkOrderList;