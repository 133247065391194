import React, { useEffect, useState } from 'react';
import PartnerList from './partner_list';
import MenuTitleComponent from '../../../helpers/components/menu_title.component';
import { Button, Drawer, Input, Space, Form, notification, List, Radio } from 'antd';
import { CaretDownFilled, SearchOutlined, CloseCircleFilled } from '@ant-design/icons';
import FilterBullet from '../../../helpers/components/filter_bullet';
import PartnerEditDetail from './partner_edit_detail';
import partnerService from '../../../services/config/partner.service';
import { useDispatch } from 'react-redux';
import { refresh_partner_list } from '../../../actions/config.action';

const PartnerLayout = () => {
    const dispatch = useDispatch();
    const { innerWidth } = window;
    const [form] = Form.useForm();
    const [timer, setTimer] = useState(null);
    const [filter, setFilter] = useState({
        search : null,
        partner_id : [],
    });
    const [state, setState] = useState({
        open_drawer : false,
        save_loading : false,
    })

    const doneTyping = async (input) => {
        clearTimeout(timer);
        const newTimer = setTimeout(() => {
            setFilter(filter => ({ ...filter, search: input, }));
        }, 300);
        setTimer(newTimer);
    }

    const onCheckChange = (key, checkedValues) => {
        setFilter(filter => ({...filter, [key] : checkedValues}));
    }

    const savePartner = async () => {
        setState(state => ({...state, save_loading : true,}));
        const { name, address, email, phone_no, partner_id, } = form?.getFieldsValue();
        
        const savePartnerResponse = await partnerService?.savePartner({ name, address, email, phone_no, partner_id, });

        //successful
        setTimeout(() => {
            dispatch(refresh_partner_list());
            setState(state => ({...state, save_loading : false, }));

            if(savePartnerResponse)
                {
                    setState(state => ({...state, open_drawer : null, }));
                    form.resetFields();
                    notification?.success({
                    message : "Success",
                    description : `Successfully saved item ${name}`
                })}
        }, 500);
    }

    return (
        <>
            <div>
                <div style={{textAlign:'start',}}>
                    <MenuTitleComponent />
                </div>

                <div>
                    <div className='filter-panel' style={{marginBottom:12,}}>
                        <div>
                            <Input
                            onChange={(e) => doneTyping(e.target.value)}
                            name="search"
                            prefix={<SearchOutlined style={{color :'var(--secondary-text-color)'}} />}
                            placeholder='Search Partner'
                            style={{minWidth: '100%',}}
                            allowClear
                            />
                        </div>

                        <div style={{display:'flex', alignItems:'center', marginTop:12, gap:8,}}>
                            
                            <div style={{marginLeft:'auto'}}>
                                <Button onClick={() => setState(state => ({...state, open_drawer : true,}))} type='primary'>New Partner</Button>
                            </div>
                        </div>
                    </div>
                    <div>
                        <PartnerList filter={filter} />
                    </div>
                </div>
            </div>


            {/* detail */}
            <Drawer
            title="Partner Detail"
            rootClassName='drawer-detail-root'
            className='drawer-detail'
            open={state?.open_drawer}
            onClose={() => setState(state => ({...state, open_drawer : false,}))}
            width={innerWidth >= 768 ? 800 : "100%"}
            footer={
                <>
                    <div style={{display:'flex', padding:'12px 0',}}>
                        <div style={{marginLeft:'auto',}}>
                            <Space>
                                <Button loading={state?.save_loading} type='primary' onClick={() => form.submit()}>Save</Button>
                            </Space>
                        </div>
                    </div>
                </>
            }
            >
                <div>
                    <Form
                    onFinish={savePartner}
                    form={form}
                    layout='vertical'
                    >
                        <PartnerEditDetail form={form} />
                    </Form>
                </div>
            </Drawer>
        </>
    );
}

export default PartnerLayout;