const initialState = {
    bom_list_refresh : 0,
    work_order_list_refresh : 0,
}

const productionReducer = (state = initialState, action) => {
    switch(action.type){
        case 'REFRESH_BOM_LIST' :
            return {...state, bom_list_refresh : (state.bom_list_refresh + 1)}
        case 'REFRESH_WORK_ORDER_LIST' :
            return {...state, work_order_list_refresh : (state.work_order_list_refresh + 1)}
        default :
            return state;
    }
}

export default productionReducer;