import { Button, Modal, notification, Space, Switch, Table, Tag } from 'antd';
import React, { useEffect, useState } from 'react';
import bomService from '../../../services/production/bom.service';
import { DeleteOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

const BomList = ({ filter, }) => {
    const productionRedux = useSelector(state => state.production);
    const [state, setState] = useState({
        loading : false,
        boms : [],
        used_bom_id: [],

        refresh : 0,
        count : 0,
        page : 0,
    })

    const deleteConfirm = async (bom_id) => {
        const bom = state?.boms?.find(x => x.bom_id === bom_id);
        const { name, } = bom;
        Modal.confirm({
            title : 'Confirm Delete This Record?',
            content : (
                <>
                    <div>
                        <div>
                            <span>{name}</span>
                        </div>
                    </div>
                </>
            ),
            onOk : () => {
                return new Promise(async (resolve, reject) => {
                    await onDeleteBom(bom_id).then((result) => {
                        setTimeout(() => {
                            // notification
                            notification?.success({
                                message : 'Successfully Deleted',
                                description : (
                                    <>
                                        <div>
                                            <div>
                                                <span>{name}</span>
                                            </div>
                                        </div>
                                    </>
                                )
                            })
                            setState(state => ({...state, refresh : (state?.refresh + 1)}));
                            resolve();
                        }, 500);
                    });
                    
                }).catch(() => console.log('Oops errors!'));
            }
        })
    }

    const onDeleteBom = async (bom_id) => {
        const deleteBomResponse = await bomService?.deleteBom(bom_id);
        return deleteBomResponse;
    }

    const fetchBom = async () => {
        setState(state => ({...state, loading : true,}));
        const { search, } = filter;
        const bomResponse = await bomService?.getBoms({
            search,
            page : state?.page,
        });
        const { count, rows : boms } = bomResponse?.boms;

        const usedBomResponse = await bomService?.getUsedBomList({});
        const used_list = usedBomResponse?.dataList;

        setState(state => ({ ...state, loading: false, boms: boms, count: count, used_bom_id: used_list }));
    }

    useEffect(() => {
        fetchBom();
    }, [filter, state?.page, productionRedux?.bom_list_refresh, state?.refresh])

    return (
        <>
            <Table
            pagination={{ 
                size : 10,
                showSizeChanger: false,
                total : state?.count,
                current : (state?.page + 1),
                onChange : (page) => {
                    setState(state => ({...state, page : page - 1}));
                    // dispatch(set_list_page(page - 1));
                }
            }} 
            scroll={{ x : 400, }}
            className='hub-table bordered'
            dataSource={state?.boms}
            columns={[{
                title : 'Name',
                dataIndex : 'name',
                key : 'name',
                width : '25%',
            },{
                title : 'Status',
                dataIndex : 'edit_status',
                key : 'edit_status',
                // width : '15%',
                render : (status, row, index) => {
                    const { name, code, color, is_complete } = status;

                    return (
                        <>
                            <div>
                                <Space>
                                    <div>
                                        <Tag style={{minWidth:80, textAlign:'center'}} color={color}>{name}</Tag>
                                    </div>
                                </Space>
                                
                            </div>
                        </>
                    )
                }
            },{
                title : 'Bill Of Materials',
                dataIndex : 'bom_materials',
                key : 'bom_materials',
                
                render : (bom_materials, row, index) => {
                    const count = bom_materials?.length;
                    return (
                        <>
                            <div>
                                <span style={{fontSize:12, color:'var(--secondary-text-color)'}}>{count} items</span>
                            </div>
                        </>
                    )
                }
            },{
                title : 'Action',
                dataIndex : 'bom_id',
                key : 'bom_id',
                width : '10%',
                render : (bom_id, row, index) => {
                    return (
                        <Button.Group>
                            <Link to={`/production/bom/${bom_id}`}>
                                <Button
                                // onClick={() => openDrawer(warehouse_id)}
                                >
                                    View
                                </Button>
                            </Link>
                            {
                                state?.used_bom_id.some(x => x.bom_id === bom_id) ?
                                    <Button type="primary" disabled />
                                    :
                                    <Button icon={<DeleteOutlined style={{ color: 'var(--secondary-text-color)' }} onClick={async () => deleteConfirm(bom_id)} />} />
                            }
                        </Button.Group>
                        
                    )
                }
            },]}
            />
        </>
    );
}

export default BomList;