import { Space } from 'antd';
import React from 'react';

const GeneralLabel = ({ label, value }) => {
    return (
        <>
            <div style={{height:30, alignContent:'center', display:'flex', }}>
                <Space>
                    <div style={{ width : 160, color : 'var(--secondary-text-color)', }}>
                        {label}
                    </div>
                    <div>
                        {value}
                    </div>
                </Space>
            </div>
        </>
    );
}

export default GeneralLabel;