import React, { useEffect, useState } from 'react';
import materialCategoryService from '../../../services/config/material_category.service';
import { Form, Input, Skeleton } from 'antd';

const MaterialCategoryEditDetail = ({ material_category_id, form }) => {
    const [state, setState] = useState({
        loading : false,
        material_category : null,
    })

    const fetchMaterialCategory = async () => {
        setState(state => ({...state, loading : true,}));
        const materialCategoryResponse = await materialCategoryService?.getMaterialCategories({
            material_category_id,
        });
        const { rows, } = materialCategoryResponse?.material_categories;
        const material_category = rows?.length > 0 ? rows[0] : null;
        const { name, } = material_category;

        setTimeout(() => {
            form.setFieldsValue({ name, });
            setState(state => ({...state, loading : false, material_category : material_category,}));
        }, 500);
    }

    useEffect(() => {
        material_category_id && fetchMaterialCategory();
    }, [material_category_id])

    return (
        <>
            {
                state?.loading ?
                (
                    <>
                        <div>
                            <Skeleton active />
                        </div>
                    </>
                )
                :
                (
                    <>
                        <div>
                            <Form.Item name={'name'} label={'Item Category'}>
                                <Input placeholder='Item Category Name' />
                            </Form.Item>
                        </div>
                    </>
                )
            }
        </>
    );
}

export default MaterialCategoryEditDetail;