import { combineReducers } from 'redux';

//reducers
import authReducer from './auth.reducer';
import configReducer from './config.reducer';
import productionReducer from './production.reducer';
import warehouseReducer from './warehouse.reducer';

const allReducers = combineReducers({
    auth : authReducer,
    config : configReducer,
    production : productionReducer,
    warehouse : warehouseReducer,
});

const rootReducer = (state, action) => {
    return allReducers(state, action);
}

export default rootReducer;