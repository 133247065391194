import axios from 'axios';
import serviceHelper from '../../helpers/functions/service.helper';

async function getMaterialCategories(query){
    const queryString = serviceHelper.queryString(query);
    const materialCategoryResponse = await axios.get(`config/material_category/get?${queryString}`, { withCredentials : true, });
    return materialCategoryResponse?.data;
}

async function getMaterialCategoryList(query){
    const queryString = serviceHelper.queryString(query);
    const materialCategoryResponse = await axios.get(`config/material_category/get/list?${queryString}`, { withCredentials : true, });
    return materialCategoryResponse?.data;
}

async function getUsedMaterialCategoryList(query) {
    const queryString = serviceHelper.queryString(query);
    const materialCategoryResponse = await axios.get(`config/material_category/getuse/list`, { withCredentials: true, });
    return materialCategoryResponse?.data;
}

async function saveMaterialCategory(params){
    const saveMaterialCategoryResponse = await axios.post(`config/material_category/save`, params, { withCredentials : true, });
    return saveMaterialCategoryResponse?.data;
}

async function updateMaterialCategory(material_category_id, params){
    const updateMaterialCategoryResponse = await axios.post(`config/material_category/${material_category_id}/update`, params, { withCredentials : true, });
    return updateMaterialCategoryResponse?.data;
}

async function deleteMaterialCategory(material_category_id){
    const deleteMaterialCategoryResponse = await axios.delete(`config/material_category/${material_category_id}/delete`, { withCredentials : true, });
    return deleteMaterialCategoryResponse?.data;
}

export default {
    getMaterialCategories,
    getMaterialCategoryList,
    getUsedMaterialCategoryList,
    saveMaterialCategory,
    updateMaterialCategory,
    deleteMaterialCategory,
}