import React, { useEffect, useState } from 'react';
// import OutboundList from './outbound_list';
import MenuTitleComponent from '../../../helpers/components/menu_title.component';
import { Button, Checkbox, DatePicker, Form, Input, List, Modal, message, Space, Tabs, Tag, notification } from 'antd';
import { CaretDownFilled, SearchOutlined } from '@ant-design/icons';
import FilterBullet from '../../../helpers/components/filter_bullet';
import OutboundList from './outbound_list';
import outboundService from '../../../services/warehouse/outbound.service';
import NewOutbound from './new_outbound';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import inoutboundSourceService from '../../../services/config/inoutbound_source.service';
import inoutboundStatusService from '../../../services/config/inoutbound_status.service';
import partnerService from '../../../services/config/partner.service';
import warehouseService from '../../../services/config/warehouse.service';
const { RangePicker } = DatePicker;

const OutboundLayout = () => {
    const [messageApi, contextHolder] = message.useMessage();
    const history = useHistory();
    const { innerWidth } = window;
    const [timer, setTimer] = useState(null);
    const [form] = Form.useForm();
    const [state, setState] = useState({
        loading : false,
        outbound_statuses : [],
        tab_items : [],

        inoutbound_sources: [],
        inoutbound_statuses: [],
        partners: [],
        warehouses: [],

        // modal
        new_outbound_modal : false,
        save_loading : false,

        filter_changed: false,
    });

    const [filter, setFilter] = useState({
        search: null,
        warehouse: null,
        inoutbound_source: null,
        inoutbound_status: null,
        partner: null,
        startDate: null,
        endDate: null,
    });

    const doneTyping = async (input) => {
        clearTimeout(timer);
        const newTimer = setTimeout(() => {
            setFilter(filter => ({ ...filter, search: input, }));
        }, 300);
        setTimer(newTimer);
    }

    const onCheckChange = (key, checkedValues) => {
        setFilter(filter => ({ ...filter, [key]: checkedValues }));
        setState(state => ({ ...state, filter_changed: true, }));
    }

    const onDateChange = (date, dateString) => {
        setFilter(filter => ({ ...filter, startDate: dateString[0], endDate: dateString[1], }));
        setState(state => ({ ...state, filter_changed: true, }));
    };

    const error = (err) => {
        messageApi.open({
          type: 'error',
          content: err,
        });
    };

    const openOutboundModal = async (open) => {
        setState(state => ({...state, new_outbound_modal : open}));
    }

    const checkQuantity = (arr) => {
        const promises = arr.map(async (material, index) => {
            let outboundMaterialsResponse = await outboundService?.checkMaterialAvailableQuantity({
                material_id : material.material_id,
                location_id : material.location_id,
                uom_id : material.uom_id,
                quantity : material.quantity,
            });
            return {
                id: index,
                resp: outboundMaterialsResponse.err}
        });
        return Promise.all(promises);
    }

    const saveOutbound = async () => {
        setState(state => ({...state, save_loading : true, }))
        const { inoutbound_source_id, partner_id, warehouse_id, remarks, } = form?.getFieldValue();
        let { outbound_material_list } = form?.getFieldValue();

        if (outbound_material_list)
        {
            outbound_material_list = outbound_material_list.filter(x => x.material_id !== null || x.location_id !== null)
            let errList = outbound_material_list.filter(x => x.material_id === null || x.location_id === null)
            if(errList.length > 0)
            {
                setTimeout(async () => {
                    error("Some fields are empty!");
                    setState(state => ({ ...state, save_loading: false, }));
                }, 1500)
                return;
            }
        }

        if (outbound_material_list)
        {
            const resultArray = await Promise.all(outbound_material_list.map(async (i) => outboundService?.checkMaterialAvailableQuantity({
                material_id : i.material_id,
                location_id : i.location_id,
                uom_id : i.uom_id,
                quantity : i.quantity,
            })));

            if(resultArray.length > 0)
                {
                    let errorList = []
                    resultArray.map((err, index) => {
                        if(err.err)
                            {
                                errorList.push(err.err);                   
                            }
                        })
        
                        if(errorList.length > 0)
                        {
                            setTimeout(async () => {
                                error(errorList[0]);
                                setState(state => ({...state, save_loading : false, }));
                            }, 1500)
                            return;
                        }
                }
        }

        // call save outbound api
        const saveOutboundResponse = await outboundService?.saveOutbound({
            inoutbound_source_id, partner_id, warehouse_id, remarks, outbound_material_list
        })
        
        setTimeout(() => {
            setState(state => ({...state, save_loading : false, }));
            
            if(saveOutboundResponse)
                {
                    const { save } = saveOutboundResponse;
                    form.resetFields();
                    notification?.success({
                    message : "New Outbound Created",
                    description : save?.running_number,
                })
                // redirect to saved item page
                history.push(`/warehouse/outbound/${save?.outbound_id}`);
            }
        }, 500);
       
    }

    const fetchInitialization = async () => {
        const warehouseResponse = await warehouseService?.getWarehouses({});
        const { warehouses } = warehouseResponse;
        const inoutboundSourceResponse = await inoutboundSourceService?.getOutBoundSources({});
        const { rows: inoutbound_sources } = inoutboundSourceResponse?.dataList;
        const partnerResponse = await partnerService?.getPartnerList({});
        const inoutboundStatusResponse = await inoutboundStatusService?.getOutBoundStatus({});
        const { rows: inoutbound_statuses } = inoutboundStatusResponse?.dataList;
        // const date = await materialService?.getMaterials({});
        // const { rows: materials } = materialResponse.materials;

        setState(state => ({
            ...state, warehouses: warehouses,
            inoutbound_sources: inoutbound_sources, partners: partnerResponse?.dataList,
            inoutbound_statuses: inoutbound_statuses,
        }))
    }

    useEffect(() => {
        fetchInitialization();
    }, [])

    return (
        <>
            <div style={{textAlign:'start',}}>
                <MenuTitleComponent />
            </div>
            <div>
                <div className='filter-panel' style={{marginBottom:12,}}>
                    <div>
                        <Input
                        onChange={(e) => doneTyping(e.target.value)}
                        name="search"
                        prefix={<SearchOutlined style={{color :'var(--secondary-text-color)'}} />}
                        placeholder='Search Outbound'
                        style={{minWidth: '100%',}}
                        allowClear
                        />
                    </div>

                    <div style={{display:'flex', alignItems:'center', marginTop:12, gap:8,}}>
                        <div>
                            <div className='filter-panel' style={{ textAlign: 'start' }}>
                                <Space direction={innerWidth > 768 ? 'horizontal' : 'vertical'}>
                                    <FilterBullet
                                        popover={
                                            <>
                                                <div style={{ padding: 12, }}>
                                                    <Input
                                                        prefix={<SearchOutlined style={{ color: 'var(--secondary-text-color)' }} />}
                                                        placeholder='Search Warehouse'
                                                        allowClear
                                                        onChange={async (e) => {
                                                            const warehouseResponse = await warehouseService?.getWarehouses({ search: e.target.value, size:100 });
                                                            setState(state => ({ ...state, warehouses: warehouseResponse?.warehouses }))
                                                        }}
                                                    />
                                                </div>
                                                <Checkbox.Group
                                                    value={filter?.warehouse}
                                                    onChange={(checkedValues) => onCheckChange('warehouse', checkedValues)}
                                                    style={{ display: 'block', }}
                                                >
                                                    <div style={{ padding: '0 12px', }}>
                                                        <List
                                                            style={{ width: 280, }}
                                                            dataSource={state?.warehouses}
                                                            renderItem={(item, index) => {
                                                                const { warehouse_id, name, } = item ?? {};
                                                                return (
                                                                    <List.Item style={{ width: '100%' }} key={index}>
                                                                        <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                                                                            <div>
                                                                                <span>{name}</span>
                                                                            </div>
                                                                            <div style={{ marginLeft: 'auto' }}>
                                                                                <Checkbox
                                                                                    value={warehouse_id}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </List.Item>
                                                                )
                                                            }}
                                                        />
                                                    </div>

                                                </Checkbox.Group>
                                            </>
                                        }
                                    >
                                        <div>
                                            <Space>
                                                <div>
                                                    <span>{`Warehouse ${filter?.warehouse?.length > 0 ? `: ${filter?.warehouse?.length} items` : ""}`}</span>
                                                </div>

                                                <div>
                                                    <CaretDownFilled style={{ color: '#a0aec0' }} />
                                                </div>

                                            </Space>

                                        </div>
                                    </FilterBullet>
                                    <FilterBullet
                                        popover={
                                            <>
                                                <div style={{ padding: 12, }}>
                                                    <Input
                                                        prefix={<SearchOutlined style={{ color: 'var(--secondary-text-color)' }} />}
                                                        placeholder='Search Source'
                                                        allowClear
                                                        onChange={async (e) => {
                                                            const inoutboundSourceResponse = await inoutboundSourceService?.getInOutBoundSources({ search: e.target.value, size:100 });
                                                            setState(state => ({ ...state, inoutbound_sources: inoutboundSourceResponse?.dataList["rows"] }))
                                                        }}
                                                    />
                                                </div>
                                                <Checkbox.Group
                                                    value={filter?.inoutbound_source}
                                                    onChange={(checkedValues) => onCheckChange('inoutbound_source', checkedValues)}
                                                    style={{ display: 'block', }}
                                                >
                                                    <div style={{ padding: '0 12px', }}>
                                                        <List
                                                            style={{ width: 280, }}
                                                            dataSource={state?.inoutbound_sources}
                                                            renderItem={(item, index) => {
                                                                const { inoutbound_source_id, name, } = item ?? {};
                                                                return (
                                                                    <List.Item style={{ width: '100%' }} key={index}>
                                                                        <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                                                                            <div>
                                                                                <span>{name}</span>
                                                                            </div>
                                                                            <div style={{ marginLeft: 'auto' }}>
                                                                                <Checkbox
                                                                                    value={inoutbound_source_id}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </List.Item>
                                                                )
                                                            }}
                                                        />
                                                    </div>

                                                </Checkbox.Group>
                                            </>
                                        }
                                    >
                                        <div>
                                            <Space>
                                                <div>
                                                    <span>{`Source ${filter?.inoutbound_source?.length > 0 ? `: ${filter?.inoutbound_source?.length} items` : ""}`}</span>
                                                </div>

                                                <div>
                                                    <CaretDownFilled style={{ color: '#a0aec0' }} />
                                                </div>

                                            </Space>

                                        </div>
                                    </FilterBullet>
                                    <FilterBullet
                                        popover={
                                            <>
                                                <div style={{ padding: 12, }}>
                                                    <Input
                                                        prefix={<SearchOutlined style={{ color: 'var(--secondary-text-color)' }} />}
                                                        placeholder='Search Status'
                                                        allowClear
                                                        onChange={async (e) => {
                                                            const inoutboundStatusResponse = await inoutboundStatusService?.getOutBoundStatus({ search: e.target.value, size:100 });
                                                            setState(state => ({ ...state, inoutbound_statuses: inoutboundStatusResponse?.dataList["rows"] }))
                                                        }}
                                                    />
                                                </div>
                                                <Checkbox.Group
                                                    value={filter?.inoutbound_status}
                                                    onChange={(checkedValues) => onCheckChange('inoutbound_status', checkedValues)}
                                                    style={{ display: 'block', }}
                                                >
                                                    <div style={{ padding: '0 12px', }}>
                                                        <List
                                                            style={{ width: 280, }}
                                                            dataSource={state?.inoutbound_statuses}
                                                            renderItem={(item, index) => {
                                                                const { inoutbound_status_id, name, } = item ?? {};
                                                                return (
                                                                    <List.Item style={{ width: '100%' }} key={index}>
                                                                        <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                                                                            <div>
                                                                                <span>{name}</span>
                                                                            </div>
                                                                            <div style={{ marginLeft: 'auto' }}>
                                                                                <Checkbox
                                                                                    value={inoutbound_status_id}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </List.Item>
                                                                )
                                                            }}
                                                        />
                                                    </div>

                                                </Checkbox.Group>
                                            </>
                                        }
                                    >
                                        <div>
                                            <Space>
                                                <div>
                                                    <span>{`Status ${filter?.inoutbound_status?.length > 0 ? `: ${filter?.inoutbound_status?.length} items` : ""}`}</span>
                                                </div>

                                                <div>
                                                    <CaretDownFilled style={{ color: '#a0aec0' }} />
                                                </div>

                                            </Space>

                                        </div>
                                    </FilterBullet>
                                    <FilterBullet
                                        popover={
                                            <>
                                                <div style={{ padding: 12, }}>
                                                    <Input
                                                        prefix={<SearchOutlined style={{ color: 'var(--secondary-text-color)' }} />}
                                                        placeholder='Search To'
                                                        allowClear
                                                        onChange={async (e) => {
                                                            const partnerResponse = await partnerService?.getPartners({ search: e.target.value, size:100 });
                                                            setState(state => ({ ...state, partners: partnerResponse?.dataList["rows"] }))
                                                        }}
                                                    />
                                                </div>
                                                <Checkbox.Group
                                                    value={filter?.partner}
                                                    onChange={(checkedValues) => onCheckChange('partner', checkedValues)}
                                                    style={{ display: 'block', }}
                                                >
                                                    <div style={{ padding: '0 12px', }}>
                                                        <List
                                                            style={{ width: 280, }}
                                                            dataSource={state?.partners}
                                                            renderItem={(item, index) => {
                                                                const { partner_id, name, } = item ?? {};
                                                                return (
                                                                    <List.Item style={{ width: '100%' }} key={index}>
                                                                        <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                                                                            <div>
                                                                                <span>{name}</span>
                                                                            </div>
                                                                            <div style={{ marginLeft: 'auto' }}>
                                                                                <Checkbox
                                                                                    value={partner_id}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </List.Item>
                                                                )
                                                            }}
                                                        />
                                                    </div>

                                                </Checkbox.Group>
                                            </>
                                        }
                                    >
                                        <div>
                                            <Space>
                                                <div>
                                                    <span>{`To ${filter?.partner?.length > 0 ? `: ${filter?.partner?.length} items` : ""}`}</span>
                                                </div>

                                                <div>
                                                    <CaretDownFilled style={{ color: '#a0aec0' }} />
                                                </div>

                                            </Space>

                                        </div>
                                    </FilterBullet>
                                    {/* <FilterBullet
                                        popover={
                                            <>
                                                <div style={{ padding: 12, }}>
                                                    <Input
                                                        prefix={<SearchOutlined style={{ color: 'var(--secondary-text-color)' }} />}
                                                        placeholder='Search Source'
                                                        allowClear
                                                    />
                                                </div>
                                                <Checkbox.Group
                                                    value={filter?.warehouse}
                                                    onChange={(checkedValues) => onCheckChange('inoutbound_source', checkedValues)}
                                                    style={{ display: 'block', }}
                                                >
                                                    <div style={{ padding: '0 12px', }}>
                                                        <List
                                                            style={{ width: 280, }}
                                                            dataSource={state?.inoutbound_sources}
                                                            renderItem={(item, index) => {
                                                                const { inoutbound_source_id, name, } = item ?? {};
                                                                return (
                                                                    <List.Item style={{ width: '100%' }} key={index}>
                                                                        <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                                                                            <div>
                                                                                <span>{name}</span>
                                                                            </div>
                                                                            <div style={{ marginLeft: 'auto' }}>
                                                                                <Checkbox
                                                                                    value={inoutbound_source_id}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </List.Item>
                                                                )
                                                            }}
                                                        />
                                                    </div>

                                                </Checkbox.Group>
                                            </>
                                        }
                                    >
                                        <div>
                                            <Space>
                                                <div>
                                                    <span>{`Source ${filter?.inoutbound_source?.length > 0 ? `: ${filter?.inoutbound_source?.length} items` : ""}`}</span>
                                                </div>

                                                <div>
                                                    <CaretDownFilled style={{ color: '#a0aec0' }} />
                                                </div>

                                            </Space>

                                        </div>
                                    </FilterBullet> */}
                                    <RangePicker onChange={onDateChange} />
                                </Space>
                            </div>
                        </div>

                        <div style={{marginLeft:'auto'}}>
                            <Button type='primary' onClick={() => openOutboundModal(true)}>New Outbound</Button>
                        </div>
                    </div>
                </div>

                <div>
                    <OutboundList filter={filter} filter_changed={state?.filter_changed} />
                </div>
            </div>

            {contextHolder}
            <Modal
            style={{top : 20,}}
            open={state?.new_outbound_modal}
            onCancel={() => openOutboundModal(false)}
            width={1000}
            title="New Outbound"
            onOk={() => form.submit()}
            confirmLoading={state?.save_loading}
            >
                <Form
                onFinish={saveOutbound}
                form={form}
                layout='vertical'
                >
                    <NewOutbound form={form} />
                </Form>
            </Modal>
        </>
    );
}

export default OutboundLayout;