import React, { useEffect, useState } from 'react';
import workOrderService from '../../../services/production/work_order.service';
import { Badge, Card, Popover, Space } from 'antd';
import WorkOrderStatusCancellation from './work_order_status_cancellation';

const WorkOrderStatusSelect = ({ work_order_status_id, onStatusChange, open_cancel_reason }) => {
    const [state, setState] = useState({
        loading : false,
        popconfirmVisible : false,
        work_order_status_settings : [],
    })

    const [field, setField] = useState({
        cancel_reason: null,
    })

    // const onFieldChange = async (key, value) => {
    //     setField(field => ({...field, [key] : value}));
    // }

    // const hide = () => {
    //     setState(state => ({...state, visible : false,}));
    // };

    // const show = () => {
    //     setState(state => ({...state, visible : true,}));
    // };

    // const content = (
    //     <div>
    //       <p>What is the reason for cancellation?</p>
    //       <Input
    //       onChange={(e) => onFieldChange('cancel_reason', e.target.value)}
    //       defaultValue={state?.work_order?.cancel_reason}
    //       placeholder={"Enter Reason"} 
    //     //   style={{maxWidth:200,}} 
    //       />
    //       <a onClick={hide}>Close</a>
    //     </div>
    // );

    // const handleVisibleChange = visible => {
    //     setState(state => ({...state, visible,}));
    // };

    // const handleOnClick = async (new_status_id) => {
    //     onStatusChange(new_status_id)
    //     if (new_status_id === 4)
    //         {
    //             open_cancel_reason(true)
    //         }
    // }

    const handleOnClick = async (new_status_id) => {
        onStatusChange(new_status_id)
        if (new_status_id === 4) {
            // open_cancel_reason(true);
            console.log("entered onlciked ")
            setState(state => ({ ...state, popconfirmVisible: true, }));
        }
    }

    const handleConfirm = (value) => {
        // console.log("handleconfirm value" ,value)
        // setField(field => ({ ...field, cancel_reason: value }));
        // open_cancel_reason = value;
        // onStatusChange(4);
        onStatusChange(4, value)
        // console.log("passed onstatuschange", value);
        
        // setState(state => ({ ...state, popconfirmVisible : false, }));
    };

    const handleCancel = () => {
        // message.info('Popconfirm canceled');
        setState(state => ({ ...state, popconfirmVisible: false, }));
    };

    const fetchWorkOrderStatusSetting = async () => {
        setState(state => ({...state, loading : true,}));
        const workOrderStatusSettingResponse = await workOrderService?.getWorkOrderStatusSetting({
            initial_status_id : work_order_status_id,
        })

        const { work_order_status_settings } = workOrderStatusSettingResponse;
        setState(state => ({...state, loading : false, work_order_status_settings : work_order_status_settings}));
    }

    useEffect(() => {
        fetchWorkOrderStatusSetting();
    }, [work_order_status_id])

    return (
        <>
            {/* <WorkOrderStatusCancellation visible={state?.popconfirmVisible} onConfirm={handleConfirm} onCancel={handleCancel}/> */}
            {/* <WorkOrderStatusCancellation onConfirm={handleConfirm} onCancel={handleCancel} /> */}
            <div>
                {
                    state?.work_order_status_settings?.length > 0 ?
                    (
                        <>
                            {
                                state?.work_order_status_settings?.map((status, index) => {
                                    const { initial_status, new_status, initial_status_id, new_status_id,  } = status;
                                    return (
                                        <>
                                            {
                                                (
                                                    <>
                                                        {
                                                            new_status_id === 4 ?
                                                                (
                                                                    <>
                                                                        {/* <Popover content={content} visible={state?.visible} onVisibleChange={handleVisibleChange}></Popover> */}
                                                                        <WorkOrderStatusCancellation onConfirm={handleConfirm} onCancel={handleCancel} status_id={new_status_id} status_name={new_status?.name} color={new_status?.color}  />
                                                                    </>
                                                                )
                                                                :
                                                                (
                                                                    <>
                                                                        <div onClick={() => onStatusChange(new_status_id)} style={{ padding: '4px 12px', cursor: 'pointer' }}>
                                                                            <Card
                                                                                className={`selection-card`}
                                                                                style={{ border: 'none' }}
                                                                                bodyStyle={{ padding: 12, }}
                                                                            >
                                                                                <div>
                                                                                    <Space>
                                                                                        <div>
                                                                                            <Badge color={new_status?.color} />
                                                                                        </div>
                                                                                        <div>
                                                                                            <span>{new_status?.name}</span>
                                                                                        </div>
                                                                                    </Space>
                                                                                </div>

                                                                            </Card>
                                                                        </div>
                                                                    </>
                                                                )
                                                        }
                                                    </>
                                                )
                                            }
                                            {/* <div onClick={() => handleOnClick(new_status_id)} style={{ padding: '4px 12px', cursor: 'pointer' }}> */}
                                            {/* <div onClick={() => onStatusChange(new_status_id)} style={{padding : '4px 12px', cursor : 'pointer' }}>
                                                <Card
                                                className={`selection-card`}
                                                style={{border:'none'}}
                                                bodyStyle={{padding:12,}}
                                                >
                                                    <div>
                                                        <Space>
                                                            <div>
                                                                <Badge color={new_status?.color}/>
                                                            </div>
                                                            <div>
                                                                <span>{new_status?.name}</span>
                                                            </div>
                                                        </Space>
                                                    </div>
                                                    
                                                </Card>
                                            </div> */}
                                        </>
                                    )
                                })
                            }
                        </>
                    )
                    :
                    (
                        <>
                            <div style={{padding : 12, textAlign:'center',}}>
                                <span style={{fontSize:12, color:'var(--secondary-text-color)'}}>No Status Configured</span>
                            </div>
                        </>
                    )
                }
            
            </div>
        </>
    );
}

export default WorkOrderStatusSelect;