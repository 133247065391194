import { Button, Card, Checkbox, Col, Form, Input, List, Radio, Row, Select, Skeleton, Space } from 'antd';
import React, { useEffect, useState } from 'react';
import userService from '../../../services/admin/user.service';
import warehouseService from '../../../services/config/warehouse.service';
import accessService from '../../../services/admin/access.service';
import userRoleService from '../../../services/admin/user_role.service';
import { useSelector } from 'react-redux';
// import authReducer from './auth.reducer';

const UserEditDetail = ({ user_id, form }) => {
    const authRedux = useSelector(state => state?.auth);
    const [state, setState] = useState({
        loading : false,
        user : null,
        is_admin : false,
        enabled_features : [],
        email : '',
        is_username_valid : false,

        roles : [],
        warehouses : [],

        passwordVisible : false,
    })
    
    function generatePassword() {
        var length = 8,
            charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
            retVal = "";
        for (var i = 0, n = charset.length; i < length; ++i) {
            retVal += charset.charAt(Math.floor(Math.random() * n));
        }
        form.setFieldsValue({ resetpassword : retVal, });
    }

    // const setPasswordVisible = async (prevState) => {
    //     setState(state => ({...state, passwordVisible : prevState, }));
    // }

    const fetchUser = async () => {
        setState(state => ({...state, loading : true, }));
        const usersResponse = await userService?.getUsers({
            user_id,
        });

        const { users } = usersResponse;
        const user = users?.length > 0 ? users[0] : null;
        const { role_id } = authRedux.auth;
        const userRoleResponse = await userRoleService?.getUserRoles({
            role_id,
        });
        const { rows:role } = userRoleResponse.roles;
        const { features } = role[0] ?? {};
        const enabled_features = [];
        // if(features.length > 0)
        // {
        //     // features.map(feature => {
        //     //     const featureitem = features.find(x => x.RoleFeature.role_id === role_id && x.RoleFeature.feature_id === 1)
        //     //     {
        //     //         if(featureitem && featureitem.RoleFeature.enabled) 
        //     //         {
        //     //             enabled_features.push(featureitem)
        //     //             console.log("enabled_features")
        //     //         }
        //     //     }
        //     // }) 
        // }
        const featureitem = features.find(x => x.RoleFeature.role_id === role_id && x.RoleFeature.feature_id === 1 && x.RoleFeature.enabled)
        // const enabled_feature
        setTimeout(() => {
            const { name, email, role_id, warehouses } = user;
            const { is_admin } = user.role;
            form.setFieldsValue({ name, email, role_id, warehouse_ids : warehouses?.map(x => x?.warehouse_id,) });

            setState(state => ({...state, loading : false, user : user, is_admin : is_admin, enabled_features : featureitem, }));
        }, 500);
    }

    const checkUserEmail = async (email) => {
        const userResponse = await userService?.checkEmail({
            email : email,
            user_id : user_id,
        });
        const { is_username_valid } = userResponse;
        setState(state => ({...state, is_username_valid : is_username_valid}))
        return is_username_valid;
    }

    const fetchInitialization = async () => {
        const warehouseResponse = await warehouseService?.getWarehouses();
        const roleResponse = await accessService?.getRoles();
        // const userResponse = await userService?.getUsers();

        setState(state => ({...state, roles : roleResponse?.roles, warehouses : warehouseResponse?.warehouses, }));
    }

    useEffect(() => {
        user_id && fetchUser();
    }, [user_id,]);

    useEffect(() => {
        fetchInitialization();
    }, [])

    return (
        <>
            <div>
                {
                    state?.loading ?
                    (
                        <>
                            <div>
                                <Skeleton active />
                            </div>
                        </>
                    )
                    :
                    (
                        <>
                            <div>
                                <Row gutter={[12, 12]}>
                                    <Col span={12}>
                                        <Form.Item name={'name'} label={'Name'} rules={[
                                            {
                                                required: true,
                                                message: "Name is required!",
                                            },]}>
                                            <Input placeholder={`User's Name`} />
                                        </Form.Item>
                                    </Col>

                                    <Col span={12}>
                                        {/* <Form.Item name={'email'} label={'Email'} rules={[
                                            {
                                                required: true,
                                                message: "Email is required!",
                                            },{ validator : async (rule, value) => {
                                                const is_valid = await checkEmail(value);
                                                setState(state => ({...state, username : value, is_username_valid : is_valid}));
                                                return new Promise((resolve, reject) => {
                                                    if(value && is_valid){
                                                        resolve();
                                                    }else{
                                                        reject("Email already exist");
                                                    }
                                                })
                                            }},
                                            ]}>
                                            <Input placeholder='Email' />
                                        </Form.Item> */}
                                        <Form.Item
                                            name={'email'}
                                            label={'Email'}
                                            requiredMark={'optional'}
                                            rules={[
                                                { required : true, message : 'Email is required!' }, 
                                                { validator : async (rule, value) => {
                                                    const is_valid = await checkUserEmail(value);
                                                    setState(state => ({...state, email : value, is_email_valid : is_valid}));
                                                    return new Promise((resolve, reject) => {
                                                    if(value && is_valid){
                                                        resolve();
                                                    }else{
                                                        reject("Email Invalid");
                                                    }
                                                })
                                            }}
                                        ]}
                                        hasFeedback
                                        validateStatus={state?.email && (state?.is_email_valid ? 'success' : 'error')}>
                                            <Input placeholder='Email' />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </div>

                            {/* Role */}
                            <div>
                                <div>
                                    <span style={{fontWeight:500, fontSize:16,}}>Role</span>
                                </div>

                                <div style={{marginTop:12,}}>
                                    <Form.Item name={'role_id'} rules={[
                                        {
                                            required: true,
                                            message: "Role is required!",
                                        }]}>
                                        <Radio.Group style={{width:'100%', }}>
                                            <Row gutter={[20, 20]}>    
                                            {
                                                state?.roles?.map((role, index) => {
                                                    const { role_id, name, } = role;
                                                    return (
                                                        <Col key={index} span={8}>
                                                            <Card>
                                                                <Radio value={role_id}>
                                                                    {name}
                                                                </Radio>
                                                            </Card>
                                                        </Col>
                                                    )
                                                })
                                            }
                                            </Row>
                                        </Radio.Group>
                                    </Form.Item>
                                </div>
                            </div>

                            {/* Warehouses */}
                            <div>
                                <div>
                                    <span style={{fontWeight:500, fontSize:16,}}>Assign to</span>
                                </div>

                                <div style={{marginTop:12,}}>
                                    <Form.Item name={'warehouse_ids'}>
                                        <Select
                                        placeholder="Select Warehouses"
                                        mode='multiple'
                                        options={state?.warehouses?.map(x =>  ({ value : x?.warehouse_id, label : x?.name, }))}
                                        />
                                    </Form.Item>
                                </div>
                            </div>


                            {/*Password*/}
                            <div>
                                {
                                    // state?.is_admin == 1? 
                                    // (
                                    //     <>
                                    //         <div>
                                    //             <span style={{ fontWeight: 500, fontSize: 16, }}>Set Password</span>
                                    //         </div>
                                            
                                    //         <div style={{ marginTop: 12, }}>
                                    //             <Space direction="horizontal">
                                    //                 <Input.Password
                                    //                 placeholder="Set Password" />
                                    //                 {/* <Button style={{ width: 80 }} onClick={() => setPasswordVisible((prevState) => !prevState)}>
                                    //                 {state?.passwordVisible ? 'Hide' : 'Show'}
                                    //                 </Button> */}
                                    //             </Space>
                                    //         </div>
                                    //     </>
                                    // ) 
                                    // : 
                                    // (
                                    //     <>
                                    //     </>
                                    // )
                                }
                                            {
                                                state?.enabled_features && 
                                                (
                                                    <>
                                                    <div>
                                                        <span style={{ fontWeight: 500, fontSize: 16, }}>Set Password</span>
                                                    </div>
                                                    <div style={{display:'flex', alignItems:'center', marginTop:12, gap:8,}}>
                                                        <div>
                                                            <Form.Item name={'resetpassword'}>
                                                                <Input.Password size='large' placeholder='Set Password' />
                                                            </Form.Item>
                                                        </div>
                                                        <div style={{marginBottom:24}}>
                                                            <Button style={{ height:'none'}} onClick={() => generatePassword()}>Generate Password</Button>
                                                        </div>
                                                    </div>
                                                    </>
                                                ) 
                                            }
                                {/* <div>
                                    <span style={{ fontWeight: 500, fontSize: 16, }}>Set Password</span>
                                </div>
                                <div style={{display:'flex', alignItems:'center', marginTop:12, gap:8,}}>
                                    <div>
                                        <Form.Item name={'resetpassword'}>
                                            <Input.Password size='large' placeholder='Set Password' value="test" />
                                        </Form.Item>
                                    </div>
                                    <div style={{marginBottom:24}}>
                                        <Button style={{ height:'none'}} onClick={() => generatePassword()}>Generate Password</Button>
                                    </div>
                                </div> */}
                            </div>
                        </>
                    )
                }
            </div>
        </>
    );
}

export default UserEditDetail;