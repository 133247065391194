import React from 'react';
import ContainerComponent from '../../helpers/components/container.component';
import OutboundLayout from './outbound/outbound_layout';

const OutboundIndex = () => {
    return (
        <>
            <ContainerComponent span={22} top={20} bottom={20}>
                <OutboundLayout />
            </ContainerComponent>
        </>
    );
}

export default OutboundIndex;