import React, { useEffect, useState } from 'react';
import authService from './services/admin/auth.service';
import { useDispatch, } from 'react-redux';
import AuthRouter from './routes/auth.router';
import { LoadingOutlined } from '@ant-design/icons';
import { useHistory, useLocation } from 'react-router-dom';
import { set_auth } from './actions/auth.action';
import axios from 'axios';

const Auth = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();
    const [state, setState] = useState({
        loading : true,
    });
    const authAccount = async () => {
        setState(state => ({...state, loading : true}));
        const authResponse = await authService.auth().catch(err => {
            setState(state => ({...state, loading : false}));
        });

        const { user } = authResponse;
        if(user){
            dispatch(set_auth(user));
            //set default axios company id
            axios.defaults.headers.common['company_id'] = user?.company_id;


            //force direct push
            history.push(user ? (location?.pathname != '/login' ? `${location.pathname}${(location.search ?? '')}` : '/') : location.pathname);
        }
        
        setState(state => ({...state, loading : false}));
        
    }

    useEffect(() => {
        authAccount();
    }, [])

    return (
        <>
            {
                state?.loading ?
                (
                    <>
                        <LoadingOutlined />
                    </>
                )
                :
                (
                    <>
                        <AuthRouter />
                    </>
                )
                

            }
            
        </>
    );
}

export default Auth;