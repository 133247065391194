import axios from 'axios';
import serviceHelper from '../../helpers/functions/service.helper';

async function getInBoundSources(query){
    const queryString = serviceHelper.queryString(query);
    const inoutboundSourceResponse = await axios.get(`config/inoutbound_source/inbound/get?${queryString}`, { withCredentials : true, });
    return inoutboundSourceResponse?.data;
}

async function getOutBoundSources(query){
    const queryString = serviceHelper.queryString(query);
    const inoutboundSourceResponse = await axios.get(`config/inoutbound_source/outbound/get?${queryString}`, { withCredentials : true, });
    return inoutboundSourceResponse?.data;
}

async function getInOutBoundSources(query){
    const queryString = serviceHelper.queryString(query);
    const inoutboundSourceResponse = await axios.get(`config/inoutbound_source/get?${queryString}`, { withCredentials : true, });
    return inoutboundSourceResponse?.data;
}

async function getInOutBoundSourcesList(query){
    const queryString = serviceHelper.queryString(query);
    const inoutboundSourceResponse = await axios.get(`config/inoutbound_source/get/list`, { withCredentials : true, });
    return inoutboundSourceResponse?.data;
}

async function getUsedInOutBoundSourcesList(query) {
    const queryString = serviceHelper.queryString(query);
    const inoutboundSourceResponse = await axios.get(`config/inoutbound_source/getuse/list`, { withCredentials: true, });
    return inoutboundSourceResponse?.data;
}

async function saveInOutboundSource(params){
    const saveInOutBoundSourceResponse = await axios.post(`config/inoutbound_source/save`, params, { withCredentials : true, });
    return saveInOutBoundSourceResponse?.data;
}

async function updateInOutboundSource(inoutbound_source_id, params){
    const updateInOutBoundSourceResponse = await axios.post(`config/inoutbound_source/${inoutbound_source_id}/update`, params, { withCredentials : true, });
    return updateInOutBoundSourceResponse?.data;
}

async function deleteInOutBoundSource(inoutbound_source_id){
    const deleteInOutBoundSourceResponse = await axios.delete(`config/inoutbound_source/${inoutbound_source_id}/delete`, { withCredentials : true, });
    return deleteInOutBoundSourceResponse?.data;
}

export default {
    getInBoundSources,
    getOutBoundSources,
    getInOutBoundSources,
    getInOutBoundSourcesList,
    getUsedInOutBoundSourcesList,
    saveInOutboundSource,
    updateInOutboundSource,
    deleteInOutBoundSource,
}