import React from 'react';
import ContainerComponent from '../../helpers/components/container.component';
import BomLayout from './bom/bom_layout';
import './production.css';

const BomIndex = () => {
    return (
        <ContainerComponent span={22} top={20}>
            <BomLayout />
        </ContainerComponent>
    );
}

export default BomIndex;