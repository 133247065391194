import { Button, Col, Form, Input, Modal, Popover, Row, Select, Space } from 'antd';
import React, { useEffect, useState } from 'react';
import materialService from '../../../services/config/material.service';
import SearchMaterial from './search_material';

const BomEditBasic = ({ form, bom }) => {
    const [state, setState] = useState({
        loading : false,
        open : false,
        material : null,

        refresh_state : 0,
        select_loading : false,
    })

    useEffect(() => {
    }, [bom?.bom_id]);

    return (
        <>
            <div>
                

                <div style={{marginTop:12,}}>
                    <div>
                        <div style={{background : '#edf2f7', border:'1.5px solid #cbd5e0', padding : '12px 12px 0 12px', borderRadius:8,}}>
                            <Form.Item name="name" label="BOM">
                                <Input placeholder='BOM name' />
                            </Form.Item>
                        </div>
                    </div>
                    {/* <div style={{marginTop:12,}}>
                        <Row gutter={[12, 12]}>
                            <Col span={16}>
                                <Form.Item name="material_id" label="Item">
                                    <div>
                                        <Popover
                                        content={<SearchMaterial is_end_product={true} default_material_id={state?.selected_material_id} form={form} customFunction={(material_id) => customFunction(material_id)} />}
                                        trigger={['click']}
                                        open={state?.open}
                                        onOpenChange={handleOpenChange}
                                        placement='bottomLeft'
                                        overlayInnerStyle={{padding : 0,}}
                                        >
                                            <Button
                                            loading={state?.select_loading}
                                            style={{width:'100%', textAlign:'start',}}
                                            onClick={() => setState(state => ({...state, material_modal : true, }))}
                                            >
                                                {state?.material?.name}
                                            </Button>
                                        </Popover>
                                    </div>
                                </Form.Item>
                            </Col>

                            <Col span={8}>
                                <Form.Item name="quantity" label="Quantity">
                                    <Input
                                    placeholder='Quantity'
                                    type='number'
                                    defaultValue='1'
                                    suffix={
                                        <>
                                            <span style={{fontSize:12, color:'var(--secondary-text-color)'}}>{(state?.material?.uom?.code)}</span>
                                        </>
                                    }
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                    </div> */}
                </div>
            </div>
        </>
    );
}

export default BomEditBasic;