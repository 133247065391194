import React, { useEffect, useState } from 'react';
import inboundService from '../../../services/warehouse/inbound.service';
import PageHeaderComponent from '../../../helpers/components/page_header.component';
import { Avatar, Badge, Button, Card, Col, DatePicker, Divider, Popover, Row, Select, Input, Space, Spin, Tabs, Tag, notification } from 'antd';
import moment from 'moment';
import GeneralLabel from './general_label';
import { BlockOutlined, CalendarOutlined, CaretDownFilled, CheckOutlined, CloseOutlined, EditOutlined, Loading3QuartersOutlined, MessageOutlined, TeamOutlined } from '@ant-design/icons';
import InboundMaterial from './inbound_material';
import dayjs from 'dayjs';
import InboundHistory from './inbound_history';
import InboundFile from './inbound_file';
import InboundStatusSelect from './inbound_status_select';
import inoutboundSourceService from '../../../services/config/inoutbound_source.service';
import inoutboundStatusService from '../../../services/config/inoutbound_status.service';
import partnerService from '../../../services/config/partner.service';
import warehouseService from '../../../services/config/warehouse.service';

const { TextArea } = Input;
const InboundDetailLayout = ({inbound_id}) => {
    const [state, setState] = useState({
        loading : false,
        inbound : null,
        edit_mode : false,
        
        open_popover : false,
        status_loading : false,
        update_loading : false,
        // refresh entire work order
        refresh : 0,


        // selections
        inboundsource : [],
        inboundstatus : [],
        partner : [],
        warehouses : [],

        //status
        is_complete : false,
    })

    const [field, setField] = useState({
        running_number : null,
        inoutbound_status_id : null,
        inoutbound_source_id : null,
        warehouse_id : null,
        partner_id : null,
        created_at : null,
        remarks : null,
    })

    const fetchInbound = async () => {
        setState(state => ({...state, loading : true, }));
        const inboundResponse = await inboundService.getInboundDetail(inbound_id);
        const { dataList:inbound }  = inboundResponse;

        setState(state => ({...state, loading : false, inbound : inbound, }));
        setField(field=> ({...field, warehouse_id : inbound.warehouse_id, partner_id : inbound.partner_id, inoutbound_source_id : inbound.inoutbound_source_id, remarks : inbound.remarks,}))
    }

    const updateInboundStatus = async (inoutbound_status_id) => {
        setState(state => ({...state, status_loading : true, }));
        const updateInboundStatusResponse = await inboundService?.updateInboundStatus({
            inbound_id : inbound_id,
            inoutbound_status_id : inoutbound_status_id,
        })

        setState(state => ({...state, refresh : (state?.refresh + 1), status_loading : false, open_popover : false,}));
        notification.success({
            message : 'Success Update Inbound Status',
            description : state?.inbound?.running_number,
        })
    }

    const updateInboundDetail = async () => {
        setState(state => ({...state, update_loading : true, }));

        const { inoutbound_source_id, partner_id, warehouse_id, remarks, } = field;
        const updateInboundDetailResponse = await inboundService?.updateInboundDetail(inbound_id, {
            inoutbound_source_id, partner_id, warehouse_id, remarks,
        });

        if(updateInboundDetailResponse){
            setState(state => ({...state, update_loading : false, refresh : (state?.refresh + 1), edit_mode : false,}));

            notification.success({
                message : 'Success Update Inbound',
                description : state?.inbound?.running_number,
            })
        }
    }

    const onFieldChange = async (key, value) => {
        setField(field => ({...field, [key] : value}));
    }

    const initializeSelections = async () => {
        // fetch type
        const inoutboundSourceResponse = await inoutboundSourceService?.getInOutBoundSourcesList();
        // fetch status 
        const inoutboundStatusResponse = await inoutboundStatusService?.getInBoundStatus();
        // fetch partner
        const partnerResponse = await partnerService?.getPartnerList();
        //fetch warehouse
        const warehouseResponse = await warehouseService?.getWarehouses();
        setState(state => ({...state, inboundsource : inoutboundSourceResponse?.dataList, inboundstatus : inoutboundStatusResponse?.dataList["rows"], partner : partnerResponse?.dataList, warehouses : warehouseResponse?.warehouses,}));
    }

    useEffect(() => {
        fetchInbound();
    },[ inbound_id, state?.refresh, ]);

    useEffect(() => {
        initializeSelections();
    }, [])

    return (
        <>
            <Spin spinning={state?.loading}>
                <div>
                    <div style={{textAlign:'start', }}>
                        <div style={{color : 'var(--secondary-text-color)',}}>
                            <Space size={20}>
                                <div>
                                    <span>{`#${state?.inbound?.running_number}`}</span>
                                </div>

                                <div>
                                    <span>{moment(state?.inbound?.created_at)?.format("YYYY-MM-DD hh:mm:ssa")}</span>
                                </div>
                            </Space>
                        </div>

                        <div style={{marginTop:12,}}>
                        <div style={{display:'flex',}}>
                                <div style={{marginLeft:'auto',}}>
                                    <Space>
                                        <div>
                                            <Popover
                                            open={state?.open_popover}
                                            content={
                                                <>
                                                    <div style={{padding:'12px 0'}}>
                                                        <InboundStatusSelect onStatusChange={updateInboundStatus} inoutbound_status_id={state?.inbound?.inoutbound_status_id} is_complete={state?.inbound?.inoutbound_status?.is_complete}/>
                                                    </div>
                                                </>
                                            }
                                            trigger={['click']}
                                            placement='bottomRight'
                                            overlayInnerStyle={{padding : 0, minWidth: 260,}}
                                            >
                                                <Button
                                                onClick={() => setState(state => ({...state, open_popover : (!state?.open_popover)}))}
                                                loading={state?.status_loading}
                                                icon={<CaretDownFilled />}
                                                type='text'
                                                style={{background: (state?.inbound?.inoutbound_status?.color ?? "var(--secondary-button-color)"), fontWeight:500, color : (state?.work_order?.work_order_status?.color ? 'rgba(255, 255, 255, 0.8)' : 'var(--secondary-text-color)') }}
                                                >
                                                    {state?.inbound?.inoutbound_status?.name}
                                                </Button>
                                            </Popover>
                                        </div>
                                        {
                                            (!state?.inbound?.inoutbound_status?.is_complete) &&
                                            (
                                                <>
                                                {state?.edit_mode ?
                                                    (
                                                        <>
                                                            <Space>
                                                                <Button loading={state?.update_loading} onClick={updateInboundDetail} size='small' icon={<CheckOutlined style={{color :'var(--secondary-text-color)'}} />}>

                                                                </Button>
                                                                    <Button onClick={() => setState(state => ({...state, edit_mode : false, }))} size='small' icon={<CloseOutlined style={{color :'var(--secondary-text-color)'}} />}>

                                                                    </Button>
                                                                </Space>
                                                        </>
                                                        )
                                                        :
                                                        (
                                                            <>
                                                                <Button onClick={() => setState(state => ({...state, edit_mode : true, }))} icon={<EditOutlined style={{color :'var(--secondary-text-color)'}} />}>
                                                                    Edit
                                                                </Button>
                                                            </>
                                                        )
                                                    }
                                                </>
                                            )
                                        }
                                        
                                    </Space>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Divider style={{marginTop : 12, marginBottom: 24, }} />
                    
                    <div style={{textAlign:'start',  display : 'flex', flexDirection:'column', gap : 16,}}>
                    <GeneralLabel 
                        label={(
                            <Space>
                                <div>
                                    <BlockOutlined />
                                </div>
                                <div>
                                    <span>Warehouse</span>
                                </div>
                            </Space>
                        )}
                        value={
                            (
                                <>
                                    {/* {
                                        state?.edit_mode ?
                                        (
                                            <>
                                                <Select
                                                onChange={(e) => onFieldChange('warehouse_id', e)}
                                                defaultValue={state?.inbound?.warehouse?.warehouse_id}
                                                options={state?.warehouses?.map((warehouse, index) => {
                                                    const { warehouse_id, name, } = warehouse;
                                                    return ({ value : warehouse_id, label : name, })
                                                })}
                                                placeholder={"Select Warehouse"} 
                                                style={{minWidth:200,}} 
                                                />
                                            </>
                                        )
                                        :
                                        (
                                            <>
                                                <div>
                                                    <span>{(state?.inbound?.warehouse?.name)}</span>
                                                </div>
                                            </>
                                        )
                                    } */}
                                    <div>
                                        <span>{(state?.inbound?.warehouse?.name)}</span>
                                    </div>
                                </>
                            )
                        }
                        />
                        <GeneralLabel 
                        label={(
                            <Space>
                                <div>
                                    <BlockOutlined />
                                </div>
                                <div>
                                    <span>Inbound Source</span>
                                </div>
                            </Space>
                        )}
                        value={
                            (
                                <>
                                    {
                                        state?.edit_mode ?
                                        (
                                            <>
                                                <Select
                                                onChange={(e) => onFieldChange('inoutbound_source_id', e)}
                                                defaultValue={state?.inbound?.inoutbound_source?.inoutbound_source_id}
                                                options={state?.inboundsource?.map((inboundsource, index) => {
                                                    const { inoutbound_source_id, name, } = inboundsource;
                                                    return ({ value : inoutbound_source_id, label : name, })
                                                })}
                                                placeholder={"Select Inbound Source"} 
                                                style={{minWidth:200,}} 
                                                />
                                            </>
                                        )
                                        :
                                        (
                                            <>
                                                <div>
                                                    <span>{(state?.inbound?.inoutbound_source?.name)}</span>
                                                </div>
                                            </>
                                        )
                                    }
                                </>
                            )
                        }
                        />
                        
                        <GeneralLabel 
                        label={(
                            <Space>
                                <div>
                                    <TeamOutlined />
                                </div>
                                <div>
                                    <span>From</span>
                                </div>
                            </Space>
                        )}
                        value={
                            (
                                <>
                                    {
                                        state?.edit_mode ?
                                        (
                                            <>
                                                <Select
                                                onChange={(e) => onFieldChange('partner_id', e)}
                                                defaultValue={state?.inbound?.partner?.partner_id}
                                                options={state?.partner?.map((line, index) => {
                                                    const { partner_id, name, } = line;
                                                    return (
                                                        {   
                                                            value : partner_id, 
                                                            label : (
                                                                <>
                                                                    <Space>
                                                                        {/* <Avatar size='small' style={{ backgroundColor: '#fde3cf', color: '#f56a00', }}>
                                                                            {name?.split(' ')?.map(word => word.charAt(0).toUpperCase()).join('')}
                                                                        </Avatar> */}
                                                                        <span>{name}</span>
                                                                    </Space>
                                                                    
                                                                </>
                                                            ), 
                                                        })
                                                })}
                                                placeholder={"Select Partner"} 
                                                style={{minWidth:200,}}
                                                />
                                            </>
                                        )
                                        :
                                        (
                                            <>
                                                {
                                                    state?.inbound?.partner_id ?
                                                    (
                                                        <>
                                                        <div style={{height:30,}}>
                                                            <Space>
                                                                <div>
                                                                    {/* <Avatar style={{ backgroundColor: '#fde3cf', color: '#f56a00', }}>
                                                                        {state?.inbound?.partner?.name?.split(' ')?.map(word => word.charAt(0).toUpperCase()).join('')}
                                                                    </Avatar> */}
                                                                </div>
                                                                <div>
                                                                    <span>{state?.inbound?.partner?.name}</span>
                                                                </div>
                                                            </Space>
                                                        </div>
                                                        </>
                                                    )
                                                    :
                                                    (
                                                        <>
                                                            <div>
                                                                <span style={{fontSize:12, color:'var(--secondary-text-color)'}}>No partner was assigned</span>
                                                            </div>
                                                        </>
                                                    )
                                                }
                                                
                                            </>
                                        )
                                    }
                                </>
                            )
                        }
                        />

                        <GeneralLabel 
                        label={(
                            <Space>
                                <div>
                                    <MessageOutlined/>
                                </div>
                                <div>
                                    <span>Remark</span>
                                </div>
                            </Space>
                        )}
                        value={
                            (
                                <>
                                    {
                                        state?.edit_mode ?
                                        (
                                            <TextArea rows={1} onChange={(e) => onFieldChange('remarks', e.target.value)}
                                            defaultValue={state?.inbound?.remarks}/>
                                        )
                                        :
                                        (
                                            <>
                                                <div>
                                                    <span>{(state?.inbound?.remarks)}</span>
                                                </div>
                                            </>
                                        )
                                    }
                                    
                                </>
                            )
                        }
                        />

                        
                    </div>

                    <div style={{marginTop:24,}}>
                        <Tabs items={[{
                            key : '1',
                            label : 'Items',
                            children : (<><InboundMaterial inbound_id={inbound_id} inbound_status={state?.inbound?.inoutbound_status} warehouse_id={state?.inbound?.warehouse_id}/></>)
                        },{
                            key : '2',
                            label : 'History',
                            children : (<><InboundHistory inbound_id={inbound_id} inoutbound_status_id={state?.inbound?.inoutbound_status_id} /></>)
                        },{
                            key : '3',
                            label : 'Files',
                            children : (<><InboundFile inbound_id={inbound_id} inoutbound_status_id={state?.inbound?.inoutbound_status_id}  /></>)
                        }]} />
                        {/* content in here */}
                    </div>
                </div>
            </Spin>
        </>
    );
}

export default InboundDetailLayout;