import React, { useEffect, useState } from 'react';
import inoutboundStatusService from '../../../services/config/inoutbound_status.service';
import { Badge, Card, Space } from 'antd';

const InboundStatusSelect = ({ is_complete, inoutbound_status_id, onStatusChange }) => {
    const [state, setState] = useState({
        loading : false,
        inbound_status_settings : null,
    })
    const fetchInboundStatusSetting = async () => {
        setState(state => ({...state, loading : true,}));
        const inboundStatusSettingResponse = await inoutboundStatusService?.getInBoundStatus()
        const { rows:inbound_status_settings } = inboundStatusSettingResponse.dataList;
        const filtered_inbound_status_settings = inbound_status_settings.filter(x => x.inoutbound_status_id != inoutbound_status_id)
        setState(state => ({...state, loading : false, inbound_status_settings : filtered_inbound_status_settings}));
    }

    useEffect(() => { 
        fetchInboundStatusSetting();
    }, [inoutbound_status_id])

    return (
        <>
            <div>
                {
                    state?.inbound_status_settings?.length > 0 && !is_complete?
                    (
                        <>
                            {
                                state?.inbound_status_settings?.map((status, index) => {
                                    const { name, inoutbound_status_id  } = status;
                                    return (
                                        <>
                                            <div onClick={() => onStatusChange(inoutbound_status_id)} style={{padding : '4px 12px', cursor : 'pointer' }}>
                                                <Card
                                                className={`selection-card`}
                                                style={{border:'none'}}
                                                bodyStyle={{padding:12,}}
                                                >
                                                    <div>
                                                        <Space>
                                                            <div>
                                                                <span>{name}</span>
                                                            </div>
                                                        </Space>
                                                    </div>
                                                    
                                                </Card>
                                            </div>
                                        </>
                                    )
                                })
                            }
                        </>
                    )
                    :
                    (
                        <>
                            <div style={{padding : 12, textAlign:'center',}}>
                                <span style={{fontSize:12, color:'var(--secondary-text-color)'}}>No Status Configured</span>
                            </div>
                        </>
                    )
                }
            
            </div>
        </>
    );
}

export default InboundStatusSelect;