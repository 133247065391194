import axios from 'axios';
import serviceHelper from '../../helpers/functions/service.helper';

async function getRoles(query){
    const queryString = serviceHelper.queryString(query);
    const roleResponse = await axios.get(`admin/role/get?${queryString}`, { withCredentials : true, });
    return roleResponse?.data;
}

export default {
    getRoles,
}