import React, { useEffect, useState } from 'react';
import locationService from '../../../services/warehouse/location.service';
import { Button, Drawer, Form, Modal, Space, Spin, Table, Tag, notification } from 'antd';
import LocationEditDetail from './location_edit_detail';
import { useDispatch, useSelector } from 'react-redux';
import { refresh_locations_list } from '../../../actions/warehouse.action';
import { DeleteOutlined } from '@ant-design/icons';

const LocationList = ({ filter, }) => {
    const dispatch = useDispatch();
    const warehouseRedux = useSelector(state => state?.warehouse);
    const { innerWidth } = window;
    const [form] = Form.useForm();
    const [state, setState] = useState({
        loading : false,
        save_loading : false,
        locations : [],
        used_location_id : [],
        drawer_location_id : null,

        count: 0,
        page: 0,
        refresh : 0,
    });
    
    const getLocations = async () => {
        setState(state => ({...state, loading : true,}));
        const { search,  warehouse } = filter;

        const locationResponse = await locationService?.getLocations({
            search, 
            warehouse_ids : warehouse,
            page: state?.page,
        });
        const { count, rows: locations } = locationResponse?.dataList;

        const usedLocationResponse = await locationService?.getUsedLocationList({});
        const used_list = usedLocationResponse?.dataList;

        setTimeout(() => {
            setState(state => ({ ...state, loading: false, count: count, locations: locations, used_location_id: used_list }));
        }, 500);
        
    }

    const openDrawer = (location_id) => {
        setState(state => ({...state, drawer_location_id : location_id}))
    }

    const saveLocation = async (location_id) => {
        setState(state => ({...state, save_loading : true,}));
        const { name, warehouse_id, } = form?.getFieldsValue();
        const update_location = await locationService?.updateLocation(location_id, { name, warehouse_id, });

        //successful
        setTimeout(() => {
            dispatch(refresh_locations_list());
            setState(state => ({...state, save_loading : false, }));

            if(update_location)
                {
                    setState(state => ({...state, drawer_location_id : null, }));
                    form.resetFields();
                    notification?.success({
                    message : "Success",
                    description : `Successfully saved item ${name}`
                })}
        }, 500);
    }

    const deleteConfirm = async (location_id) => {
        const location = state?.locations?.find(x => x.location_id === location_id);
        const { name, } = location;
        Modal.confirm({
            title : 'Confirm Delete This Record?',
            content : (
                <>
                    <div>
                        <div>
                            <span>{name}</span>
                        </div>
                    </div>
                </>
            ),
            onOk : () => {
                return new Promise(async (resolve, reject) => {
                    await onDeleteLocation(location_id).then((result) => {
                        setTimeout(() => {
                            // notification
                            notification?.success({
                                message : 'Successfully Deleted',
                                description : (
                                    <>
                                        <div>
                                            <div>
                                                <span>{name}</span>
                                            </div>
                                        </div>
                                    </>
                                )
                            })
                            setState(state => ({...state, refresh : (state?.refresh + 1)}));
                            resolve();
                        }, 500);
                    });
                    
                }).catch(() => console.log('Oops errors!'));
            }
        })
    }

    const onDeleteLocation = async (location_id) => {
        const deleteLocationResponse = await locationService?.deleteLocation(location_id);
        return deleteLocationResponse;
    }

    useEffect(() => {
        getLocations();
    }, [filter, state?.page, warehouseRedux?.locations_list_refresh, state?.refresh])

    return (
        <>
            <Spin spinning={state?.loading}>
                <Table
                pagination={{ 
                    size : 10,
                    showSizeChanger: false,
                    total : state?.count,
                    current : (state?.page + 1),
                    onChange : (page) => {
                        setState(state => ({...state, page : page - 1}));
                        // dispatch(set_list_page(page - 1));
                    }
                }}
                scroll={{ x : 400, }}
                className='hub-table bordered'
                dataSource={state?.locations}
                columns={[{
                    title : 'Name',
                    dataIndex : 'name',
                    key : 'name',
                    width: '25%',
                },{
                    title : 'Warehouse',
                    dataIndex : 'warehouse',
                    key : 'warehouse',
                    render : (text, row, index) => {
                        const { name } = text;
                        return (
                            <>
                                <div>
                                    <Tag color='var(--secondary-button-color)'>
                                        <span style={{fontSize:12, color:'var(--secondary-text-color)'}}>{name}</span>
                                    </Tag>
                                </div>
                            </>
                        )
                    }
                },{
                    title : 'Action',
                    dataIndex : 'location_id',
                    key : 'location_id',
                    width : '10%',
                    render : (text, row, index) => {
                        const location_id = text;
                        return (
                            <Button.Group>
                                <Button
                                onClick={() => openDrawer(location_id)}
                                >
                                    View
                                </Button>
                                {
                                    state?.used_location_id.some(x => x.location_id === location_id) ?
                                        <Button type="primary" disabled />
                                        :
                                        <Button icon={<DeleteOutlined style={{ color: 'var(--secondary-text-color)' }} onClick={async () => deleteConfirm(location_id)} />} />  
                                }
                            </Button.Group>
                            
                        )
                    }
                }]}
                />
            </Spin>


            {/* detail */}
            <Drawer
            title="Location Detail"
            rootClassName='drawer-detail-root'
            className='drawer-detail'
            open={state?.drawer_location_id}
            onClose={() => openDrawer(null)}
            width={innerWidth >= 768 ? 800 : "100%"}
            footer={
                <>
                    <div style={{display:'flex', padding:'12px 0',}}>
                        <div style={{marginLeft:'auto',}}>
                            <Space>
                                <Button loading={state?.save_loading} type='primary' onClick={() => form.submit()}>Save Changes</Button>
                            </Space>
                        </div>
                    </div>
                </>
            }
            >
                <div>
                    <Form
                    onFinish={() => saveLocation(state?.drawer_location_id)}
                    form={form}
                    layout='vertical'
                    >
                        <LocationEditDetail location_id={state?.drawer_location_id} form={form} />
                    </Form>
                </div>
            </Drawer>
        </>
    );
}

export default LocationList;