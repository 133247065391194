import { Card, Col, Form, Input, Pagination, Row, Space, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import materialService from '../../../services/config/material.service';
import DynamicSvg from '../../../helpers/components/dynamic_svg';

const SearchMaterial = ({ form, customFunction, default_material_id, get_filter, is_end_product,}) => {
    const { material_id : form_material_id } = form?.getFieldsValue() ?? {};
    const [timer, setTimer] = useState(null);
    const [state, setState] = useState({
        loading : false,
        materials : [],
        count : null,

        selected_material : default_material_id,
    })

    const [filter, setFilter] = useState({
        search : null,
        ...(is_end_product != null && ({ is_end_product : is_end_product})),
        // pagination
        page : 1,
        size : 9,
    })

    const fetchMaterials = async () => {
        setState(state => ({...state, loading : true, }));
        const { search, page, size } = filter;
        const materialResponse = await materialService?.getMaterials({
            ...get_filter,
            search,
            page : (page - 1),
            size,
        });

        const { rows : materials, count } = materialResponse?.materials;
        setState(state => ({...state, loading : false, materials : materials, count : count, }));
    }

    const doneTyping = async (input) => {
        clearTimeout(timer);
        const newTimer = setTimeout(() => {
            setFilter(filter => ({ ...filter, search: input, }));
        }, 300);
        setTimer(newTimer);
    }

    const selectMaterial = (material_id) => {     
        setState(state => ({...state, selected_material : material_id, }));
        if(filter?.search){
            setFilter(filter => ({...filter, search : null }));
        }
        
        // form.resetFields(['search',]);
        form.setFieldsValue({ search : null, })
        // run custom function
        customFunction && customFunction(material_id);
    }

    useEffect(() => {
        fetchMaterials();
    }, [filter,])

    return (
        <>
            <div style={{padding:12, width:500,}}>
                <div>
                    <Form.Item name="search">
                        <Input onChange={(e) => doneTyping(e?.target?.value)} placeholder='Search' />
                    </Form.Item>
                </div>
                <div style={{marginTop:12,}}>
                    <Spin spinning={state?.loading}>
                        <Row gutter={[12, 12]}>
                            {
                                state?.materials?.map((material, index) => {
                                    const { material_id, name, material_code } = material;
                                    
                                
                                    const is_selected = state?.selected_material === material_id;
                                    return (
                                        <Col key={index} span={8}>
                                            <Card
                                            className='material-card'
                                            style={{height:'100%', ...(is_selected && ({ borderColor: 'var(--main-color)' }))}}
                                            bodyStyle={{padding : 8, cursor : 'pointer',}}
                                            onClick={() => selectMaterial(material_id)}
                                            // className='card-shadow'
                                            >
                                                <Space>
                                                    <div style={{ display:'flex', height: 30, width: 30, border : '1px solid #e6e8eb', background:'#fff', borderRadius:'.5rem'}}>
                                                        <div style={{margin:'auto'}}>
                                                            <DynamicSvg image={'/menus/leaf.svg'} color={'#6f7287'} size={12} />
                                                        </div>
                                                    </div>

                                                    <div>
                                                        <div>
                                                            <span>{name}</span>
                                                        </div>
                                                        <div>
                                                            <span style={{fontSize:12, color:'var(--secondary-text-color)'}}>{material_code}</span>
                                                        </div>
                                                    </div>
                                                </Space>
                                            </Card>
                                        </Col>
                                    )
                                })
                            }
                        </Row>
                    </Spin>
                </div>
                <div style={{marginTop:12,}}>
                    <Pagination showSizeChanger={false} onChange={(page) => setFilter(filter => ({ ...filter, page: page }))} current={(filter?.page)} total={state?.count} pageSize={9} />
                </div>
            </div>
        </>
    );
}

export default SearchMaterial;