import React, { useState } from 'react';
import MenuTitleComponent from '../../../helpers/components/menu_title.component';
import { Button, Drawer, Form, Input, Space, Tabs, notification } from 'antd';
import { CaretDownFilled, SearchOutlined } from '@ant-design/icons';
import FilterBullet from '../../../helpers/components/filter_bullet';
import UomList from './uom_list';
import UomEditDetail from './uom_edit_detail';
import uomService from '../../../services/config/uom.service';
import { useDispatch } from 'react-redux';
import { refresh_uom_list } from '../../../actions/config.action';

const ConfigUomLayout = () => {
    const { innerWidth } = window;
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const [state, setState] = useState({
        open_drawer : false,
        save_loading : false,

        //drawer
        open_drawer : false,
    });

    const [filter, setFilter] = useState({
        search : null,
    })

    const [timer, setTimer] = useState(null);

    const doneTyping = async (input) => {
        clearTimeout(timer);
        const newTimer = setTimeout(() => {
            setFilter(filter => ({ ...filter, search: input, }));
        }, 300);
        setTimer(newTimer);
    }

    const onCheckChange = (key, checkedValues) => {
        setFilter(filter => ({...filter, [key] : checkedValues}));
    }

    const saveUom = async () => {
        setState(state => ({...state, save_loading : true,}));
        const { name, code } = form.getFieldsValue();
        const saveUomResponse = await uomService.saveUom({
            name, code
        });

        //successful
        setTimeout(() => {
            dispatch(refresh_uom_list());
            setState(state => ({...state, save_loading : false, }));

            if(saveUomResponse)
                {
                    setState(state => ({...state, open_drawer : null, }));
                    form.resetFields();
                    notification?.success({
                    message : "Success",
                    description : `Successfully saved item ${name}`
                })}
        }, 500);
    }

    return (
        <>
            <div>
                <div style={{textAlign:'start',}}>
                    <MenuTitleComponent />
                </div>

                <div>
                    <div className='filter-panel'>
                        <div>
                            <Input
                            onChange={(e) => doneTyping(e.target.value)}
                            name="search"
                            prefix={<SearchOutlined style={{color :'var(--secondary-text-color)'}} />}
                            placeholder='Search UOM'
                            style={{minWidth: '100%',}}
                            allowClear
                            />
                        </div>

                        <div style={{display:'flex', alignItems:'center', marginTop:12, gap:8,}}>
                            <div>
                                <div className='filter-panel' style={{textAlign:'start'}}>
                                    <Space direction={innerWidth > 768 ? 'horizontal' : 'vertical'}>
                                        
                                    </Space>
                                </div>
                            </div>
                            <div style={{ marginLeft: 'auto', marginBottom:12}}>
                                <Button onClick={() => setState(state => ({...state, open_drawer : true,}))} type='primary'>New UOM</Button>
                            </div>
                        </div>
                    </div>
                    <div>
                        {/* Default Tab */}
                        {/* <Tabs
                        defaultActiveKey="1"
                        animated
                        tabPosition='top'
                        items={[{
                            key : "1",
                            label : "Default",
                            children : <><UomList is_default={1} filter={filter} /></>
                        },{
                            key : "0",
                            label : "Custom",
                            children : <><UomList is_default={0} filter={filter} /></>
                        }]}
                        // onChange={changeTab}
                        tabBarExtraContent={<Button onClick={() => setState(state => ({...state, open_drawer : true,}))} type='primary'>New UOM</Button>} 
                        /> */}
                        <UomList is_default={0} filter={filter} />
                    </div>
                </div>
            </div>


            {/* detail */}
            <Drawer
            title="UOM Detail"
            rootClassName='drawer-detail-root'
            className='drawer-detail'
            open={state?.open_drawer}
            onClose={() => setState(state => ({...state, open_drawer : false, }))}
            width={innerWidth >= 768 ? 800 : "100%"}
            footer={
                <>
                    <div style={{display:'flex', padding:'12px 0',}}>
                        <div style={{marginLeft:'auto',}}>
                            <Space>
                                <Button loading={state?.save_loading} type='primary' onClick={() => form.submit()}>Save Changes</Button>
                            </Space>
                        </div>
                    </div>
                </>
            }
            >
                <div>
                    <Form
                    onFinish={saveUom}
                    form={form}
                    layout='vertical'
                    >
                        <UomEditDetail form={form} />
                    </Form>
                </div>
            </Drawer>
        </>
    );
}

export default ConfigUomLayout;