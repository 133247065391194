import axios from 'axios';
import serviceHelper from '../../helpers/functions/service.helper';

async function getInbounds(query){
    const queryString = serviceHelper.queryString(query);
    const inboundResponse = await axios.get(`production/inbound/get?${queryString}`, { withCredentials : true, });
    return inboundResponse?.data;
}

async function getInboundDetail(inbound_id){
    const inboundResponse = await axios.get(`production/inbound/${inbound_id}/detail/get`, { withCredentials : true, });
    return inboundResponse?.data;
}

async function getInboundMaterials(query){
    const queryString = serviceHelper.queryString(query);
    const inboundMaterialsResponse = await axios.get(`production/inbound/inbound_material/get?${queryString}`, { withCredentials : true, });
    return inboundMaterialsResponse?.data;
}

async function saveInbound(params){
    const saveInboundResponse = await axios.post(`production/inbound/save`, params, { withCredentials : true, });
    return saveInboundResponse?.data;
}

async function updateInboundDetail(inbound_id, params){
    const updateInboundDetailResponse = await axios.post(`production/inbound/${inbound_id}/update`, params, { withCredentials : true, });
    return updateInboundDetailResponse?.data;
}

async function saveInboundMaterial(params){
    const saveInboundMaterialResponse = await axios.post(`production/inbound/inbound_material/save`, params, { withCredentials : true, });
    return saveInboundMaterialResponse?.data;
}

async function updateInboundMaterial(inbound_material_id, params){
    const updateInboundMaterialResponse = await axios.post(`production/inbound/inbound_material/${inbound_material_id}/update`, params, { withCredentials : true, });
    return updateInboundMaterialResponse?.data;
}

async function deleteInboundMaterial(inbound_material_id){
    const deleteInboundMaterialResponse = await axios.delete(`production/inbound/inbound_material/${inbound_material_id}/delete`, { withCredentials : true, });
    return deleteInboundMaterialResponse?.data;
}

async function updateInboundStatus(params){
    const updateInboundStatusResponse = await axios.post(`production/inbound/updateStatus`, params, { withCredentials : true, });
    return updateInboundStatusResponse?.data;
}

async function getInBoundStatusHistory(query){
    const queryString = serviceHelper.queryString(query);
    const inboundHistoryResponse = await axios.get(`production/inbound/inbound_history/get?${queryString}`, { withCredentials : true, });
    return inboundHistoryResponse?.data;
}

export default {
    getInbounds,
    getInboundDetail,
    getInboundMaterials,
    saveInbound,
    updateInboundDetail,
    saveInboundMaterial,
    updateInboundMaterial,
    deleteInboundMaterial,
    updateInboundStatus,
    getInBoundStatusHistory,
}