import React, { useEffect, useState } from 'react';
// import InboundList from './inbound_list';
import MenuTitleComponent from '../../../helpers/components/menu_title.component';
import { Button, Checkbox, DatePicker, Form, Input, List, Modal, message, Space, Tabs, Tag, notification } from 'antd';
import { CaretDownFilled, SearchOutlined } from '@ant-design/icons';
import FilterBullet from '../../../helpers/components/filter_bullet';
import InboundList from './inbound_list';
import inboundService from '../../../services/warehouse/inbound.service';
import NewInbound from './new_inbound';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import inoutboundSourceService from '../../../services/config/inoutbound_source.service';
import inoutboundStatusService from '../../../services/config/inoutbound_status.service';
import partnerService from '../../../services/config/partner.service';
import warehouseService from '../../../services/config/warehouse.service';
const { RangePicker } = DatePicker;

const InboundLayout = () => {
    const [messageApi, contextHolder] = message.useMessage();
    const history = useHistory();
    const { innerWidth } = window;
    const [timer, setTimer] = useState(null);
    const [form] = Form.useForm();
    const [state, setState] = useState({
        loading : false,
        inbound_statuses : [],
        tab_items : [],
        inoutbound_sources : [],
        inoutbound_statuses : [],
        partners : [],
        warehouses : [],

        // modal
        new_inbound_modal : false,
        save_loading : false,

        filter_changed: false,
    });

    const [filter, setFilter] = useState({
        search: null,
        warehouse: null,
        inoutbound_source: null,
        inoutbound_status: null,
        partner: null,
        startDate: null,
        endDate: null,
    });

    const doneTyping = async (input) => {
        clearTimeout(timer);
        const newTimer = setTimeout(() => {
            setFilter(filter => ({ ...filter, search: input, }));
        }, 300);
        setTimer(newTimer);
    }

    const onCheckChange = (key, checkedValues) => {
        setFilter(filter => ({ ...filter, [key]: checkedValues }));
        setState(state => ({ ...state, filter_changed: true, }));
    }

    const onDateChange = (date, dateString) => {
        setFilter(filter => ({ ...filter, startDate: dateString[0], endDate: dateString[1], }));
        setState(state => ({ ...state, filter_changed: true, }));
    };

    const error = (err) => {
        messageApi.open({
            type: 'error',
            content: err,
        });
    };

    const openInboundModal = async (open) => {
        setState(state => ({...state, new_inbound_modal : open}));
    }

    const saveInbound = async () => {
        setState(state => ({...state, save_loading : true, }))
        const { inoutbound_source_id, partner_id, warehouse_id, remarks, } = form?.getFieldValue();
        let { inbound_material_list } = form?.getFieldValue();

        if (inbound_material_list) {
            inbound_material_list = inbound_material_list.filter(x => x.material_id !== null || x.location_id !== null)
            let errList = inbound_material_list.filter(x => x.material_id === null || x.location_id === null)

            if (errList.length > 0) {
                setTimeout(async () => {
                    error("Some fields are empty!");
                    setState(state => ({ ...state, save_loading: false, }));
                }, 1500)
                return;
            }
            // setState(state => ({ ...state, save_loading: false, }));
            // return;
        }

        // call save inbound api
        const saveInboundResponse = await inboundService?.saveInbound({
            inoutbound_source_id, partner_id, warehouse_id, remarks, inbound_material_list
        })
        
        setTimeout(() => {
            setState(state => ({...state, save_loading : false, }));
            if(saveInboundResponse)
                {
                    const { save } = saveInboundResponse;
                    form.resetFields();
                    notification?.success({
                    message : "New Inbound Created",
                    description : save?.running_number,
                })
                // redirect to saved item page
                history.push(`/warehouse/inbound/${save?.inbound_id}`);
            }
        }, 500);
    }
    
    const fetchInitialization = async () => {
        const warehouseResponse = await warehouseService?.getWarehouses({});
        const { warehouses } = warehouseResponse;
        const inoutboundSourceResponse = await inoutboundSourceService?.getInBoundSources({});
        const { rows: inoutbound_sources } = inoutboundSourceResponse?.dataList;
        const partnerResponse = await partnerService?.getPartnerList({});
        const inoutboundStatusResponse = await inoutboundStatusService?.getInBoundStatus({});
        const { rows: inoutbound_statuses } = inoutboundStatusResponse?.dataList;
        // const date = await materialService?.getMaterials({});
        // const { rows: materials } = materialResponse.materials;

        setState(state => ({ ...state, warehouses: warehouses, 
            inoutbound_sources: inoutbound_sources, partners: partnerResponse?.dataList,
            inoutbound_statuses: inoutbound_statuses,
        }))
    }

    useEffect(() => {
        fetchInitialization();
    }, [])

    return (
        <>
            <div style={{textAlign:'start',}}>
                <MenuTitleComponent />
            </div>
            <div>
                <div className='filter-panel' style={{marginBottom:12,}}>
                    <div>
                        <Input
                        onChange={(e) => doneTyping(e.target.value)}
                        name="search"
                        prefix={<SearchOutlined style={{color :'var(--secondary-text-color)'}} />}
                        placeholder='Search Inbound'
                        style={{minWidth: '100%',}}
                        allowClear
                        />
                    </div>

                    

                    <div style={{display:'flex', alignItems:'center', marginTop:12, gap:8,}}>
                        <div>
                            <div className='filter-panel' style={{ textAlign: 'start' }}>
                                <Space direction={innerWidth > 768 ? 'horizontal' : 'vertical'}>
                                    <FilterBullet
                                        popover={
                                            <>
                                                <div style={{ padding: 12, }}>
                                                    <Input
                                                        prefix={<SearchOutlined style={{ color: 'var(--secondary-text-color)' }} />}
                                                        placeholder='Search Warehouse'
                                                        allowClear
                                                        name="search_warehouse"
                                                        onChange={async (e) => {
                                                            const warehouseResponse = await warehouseService?.getWarehouses({ search: e.target.value, size:100 });
                                                            setState(state => ({ ...state, warehouses: warehouseResponse?.warehouses }))
                                                        }}
                                                    />
                                                </div>
                                                <Checkbox.Group
                                                    value={filter?.warehouse}
                                                    onChange={(checkedValues) => onCheckChange('warehouse', checkedValues)}
                                                    style={{ display: 'block', }}
                                                >
                                                    <div style={{ padding: '0 12px', }}>
                                                        <List
                                                            style={{ width: 280, }}
                                                            dataSource={state?.warehouses}
                                                            renderItem={(item, index) => {
                                                                const { warehouse_id, name, } = item ?? {};
                                                                return (
                                                                    <List.Item style={{ width: '100%' }} key={index}>
                                                                        <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                                                                            <div>
                                                                                <span>{name}</span>
                                                                            </div>
                                                                            <div style={{ marginLeft: 'auto' }}>
                                                                                <Checkbox
                                                                                    value={warehouse_id}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </List.Item>
                                                                )
                                                            }}
                                                        />
                                                    </div>

                                                </Checkbox.Group>
                                            </>
                                        }
                                    >
                                        <div>
                                            <Space>
                                                <div>
                                                    <span>{`Warehouse ${filter?.warehouse?.length > 0 ? `: ${filter?.warehouse?.length} items` : ""}`}</span>
                                                </div>

                                                <div>
                                                    <CaretDownFilled style={{ color: '#a0aec0' }} />
                                                </div>

                                            </Space>

                                        </div>
                                    </FilterBullet>
                                    <FilterBullet
                                        popover={
                                            <>
                                                <div style={{ padding: 12, }}>
                                                    <Input
                                                        prefix={<SearchOutlined style={{ color: 'var(--secondary-text-color)' }} />}
                                                        placeholder='Search Source'
                                                        allowClear
                                                        onChange={async (e) => {
                                                            const inoutboundSourceResponse = await inoutboundSourceService?.getInOutBoundSources({ search: e.target.value, size:100 });
                                                            setState(state => ({ ...state, inoutbound_sources: inoutboundSourceResponse?.dataList["rows"] }))
                                                        }}
                                                    />
                                                </div>
                                                <Checkbox.Group
                                                    value={filter?.inoutbound_source}
                                                    onChange={(checkedValues) => onCheckChange('inoutbound_source', checkedValues)}
                                                    style={{ display: 'block', }}
                                                >
                                                    <div style={{ padding: '0 12px', }}>
                                                        <List
                                                            style={{ width: 280, }}
                                                            dataSource={state?.inoutbound_sources}
                                                            renderItem={(item, index) => {
                                                                const { inoutbound_source_id, name, } = item ?? {};
                                                                return (
                                                                    <List.Item style={{ width: '100%' }} key={index}>
                                                                        <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                                                                            <div>
                                                                                <span>{name}</span>
                                                                            </div>
                                                                            <div style={{ marginLeft: 'auto' }}>
                                                                                <Checkbox
                                                                                    value={inoutbound_source_id}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </List.Item>
                                                                )
                                                            }}
                                                        />
                                                    </div>

                                                </Checkbox.Group>
                                            </>
                                        }
                                    >
                                        <div>
                                            <Space>
                                                <div>
                                                    <span>{`Source ${filter?.inoutbound_source?.length > 0 ? `: ${filter?.inoutbound_source?.length} items` : ""}`}</span>
                                                </div>

                                                <div>
                                                    <CaretDownFilled style={{ color: '#a0aec0' }} />
                                                </div>

                                            </Space>

                                        </div>
                                    </FilterBullet>
                                    <FilterBullet
                                        popover={
                                            <>
                                                <div style={{ padding: 12, }}>
                                                    <Input
                                                        prefix={<SearchOutlined style={{ color: 'var(--secondary-text-color)' }} />}
                                                        placeholder='Search Status'
                                                        allowClear
                                                        onChange={async (e) => {
                                                            const inoutboundStatusResponse = await inoutboundStatusService?.getInBoundStatus({ search: e.target.value, size:100 });
                                                            setState(state => ({ ...state, inoutbound_statuses: inoutboundStatusResponse?.dataList["rows"] }))
                                                        }}
                                                    />
                                                </div>
                                                <Checkbox.Group
                                                    value={filter?.inoutbound_status}
                                                    onChange={(checkedValues) => onCheckChange('inoutbound_status', checkedValues)}
                                                    style={{ display: 'block', }}
                                                >
                                                    <div style={{ padding: '0 12px', }}>
                                                        <List
                                                            style={{ width: 280, }}
                                                            dataSource={state?.inoutbound_statuses}
                                                            renderItem={(item, index) => {
                                                                const { inoutbound_status_id, name, } = item ?? {};
                                                                return (
                                                                    <List.Item style={{ width: '100%' }} key={index}>
                                                                        <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                                                                            <div>
                                                                                <span>{name}</span>
                                                                            </div>
                                                                            <div style={{ marginLeft: 'auto' }}>
                                                                                <Checkbox
                                                                                    value={inoutbound_status_id}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </List.Item>
                                                                )
                                                            }}
                                                        />
                                                    </div>

                                                </Checkbox.Group>
                                            </>
                                        }
                                    >
                                        <div>
                                            <Space>
                                                <div>
                                                    <span>{`Status ${filter?.inoutbound_status?.length > 0 ? `: ${filter?.inoutbound_status?.length} items` : ""}`}</span>
                                                </div>

                                                <div>
                                                    <CaretDownFilled style={{ color: '#a0aec0' }} />
                                                </div>

                                            </Space>

                                        </div>
                                    </FilterBullet>
                                    <FilterBullet
                                        popover={
                                            <>
                                                <div style={{ padding: 12, }}>
                                                    <Input
                                                        prefix={<SearchOutlined style={{ color: 'var(--secondary-text-color)' }} />}
                                                        placeholder='Search From'
                                                        allowClear
                                                        name="search_partner"
                                                        onChange={async (e) => {
                                                            const partnerResponse = await partnerService?.getPartners({ search: e.target.value, size:100 });
                                                            setState(state => ({...state, partners: partnerResponse?.dataList["rows"]}))
                                                        }}
                                                    />
                                                </div>
                                                <Checkbox.Group
                                                    value={filter?.partner}
                                                    onChange={(checkedValues) => onCheckChange('partner', checkedValues)}
                                                    style={{ display: 'block', }}
                                                >
                                                    <div style={{ padding: '0 12px', }}>
                                                        <List
                                                            style={{ width: 280, }}
                                                            dataSource={state?.partners}
                                                            renderItem={(item, index) => {
                                                                const { partner_id, name, } = item ?? {};
                                                                return (
                                                                    <List.Item style={{ width: '100%' }} key={index}>
                                                                        <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                                                                            <div>
                                                                                <span>{name}</span>
                                                                            </div>
                                                                            <div style={{ marginLeft: 'auto' }}>
                                                                                <Checkbox
                                                                                    value={partner_id}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </List.Item>
                                                                )
                                                            }}
                                                        />
                                                    </div>

                                                </Checkbox.Group>
                                            </>
                                        }
                                    >
                                        <div>
                                            <Space>
                                                <div>
                                                    <span>{`From ${filter?.partner?.length > 0 ? `: ${filter?.partner?.length} items` : ""}`}</span>
                                                </div>

                                                <div>
                                                    <CaretDownFilled style={{ color: '#a0aec0' }} />
                                                </div>

                                            </Space>

                                        </div>
                                    </FilterBullet>
                                    {/* <FilterBullet
                                        popover={
                                            <>
                                                <div style={{ padding: 12, }}>
                                                    <Input
                                                        prefix={<SearchOutlined style={{ color: 'var(--secondary-text-color)' }} />}
                                                        placeholder='Search Source'
                                                        allowClear
                                                    />
                                                </div>
                                                <Checkbox.Group
                                                    value={filter?.warehouse}
                                                    onChange={(checkedValues) => onCheckChange('inoutbound_source', checkedValues)}
                                                    style={{ display: 'block', }}
                                                >
                                                    <div style={{ padding: '0 12px', }}>
                                                        <List
                                                            style={{ width: 280, }}
                                                            dataSource={state?.inoutbound_sources}
                                                            renderItem={(item, index) => {
                                                                const { inoutbound_source_id, name, } = item ?? {};
                                                                return (
                                                                    <List.Item style={{ width: '100%' }} key={index}>
                                                                        <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                                                                            <div>
                                                                                <span>{name}</span>
                                                                            </div>
                                                                            <div style={{ marginLeft: 'auto' }}>
                                                                                <Checkbox
                                                                                    value={inoutbound_source_id}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </List.Item>
                                                                )
                                                            }}
                                                        />
                                                    </div>

                                                </Checkbox.Group>
                                            </>
                                        }
                                    >
                                        <div>
                                            <Space>
                                                <div>
                                                    <span>{`Source ${filter?.inoutbound_source?.length > 0 ? `: ${filter?.inoutbound_source?.length} items` : ""}`}</span>
                                                </div>

                                                <div>
                                                    <CaretDownFilled style={{ color: '#a0aec0' }} />
                                                </div>

                                            </Space>

                                        </div>
                                    </FilterBullet> */}
                                    <RangePicker onChange={onDateChange}/>
                                </Space>
                            </div>
                        </div>

                        <div style={{marginLeft:'auto'}}>
                            <Button type='primary' onClick={() => openInboundModal(true)}>New Inbound</Button>
                        </div>
                    </div>
                </div>

                <div>
                    <InboundList filter={filter} filter_changed={state?.filter_changed} />
                </div>
            </div>

            {contextHolder}
            <Modal
            style={{top : 20,}}
            open={state?.new_inbound_modal}
            onCancel={() => openInboundModal(false)}
            width={1000}
            title="New Inbound"
            onOk={() => form.submit()}
            confirmLoading={state?.save_loading}
            >
                <Form
                onFinish={saveInbound}
                form={form}
                layout='vertical'
                >
                    <NewInbound form={form} />
                </Form>
            </Modal>
        </>
    );
}

export default InboundLayout;