import React, { useEffect, useState } from 'react';
import { Button, Drawer, Form, Space, Spin, Table, Tag, notification } from 'antd';
import { DownloadOutlined, PrinterOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { refresh_locations_list } from '../../../actions/warehouse.action';
import { DeleteOutlined } from '@ant-design/icons';
import locationMaterialLogsService from '../../../services/warehouse/location_material_log.service';
import { refresh_location_material_list } from '../../../actions/warehouse.action';
import moment from 'moment';

import { CSVLink } from 'react-csv';

const TransactionsList = ({ filter, filter_changed }) => {
    const CSVReportLink = React.createRef();
    const warehouseRedux = useSelector(state => state?.warehouse);
    const [state, setState] = useState({
        loading : false,
        save_loading : false,
        transactions : [],
        excelData : [],
        toDownload: false,
        count : 0,
        page : 0,
    });    

    // const getLocationMaterialLogs = async () => {
    //     setState(state => ({...state, loading : true,}));
    //     const { search, location, material, } = filter;
            
    //     const locationMaterialLogsResponse = await locationMaterialLogsService.getLocationMaterialLogs({
    //         search,
    //         location_ids : location,
    //         material_ids : material,

    //         //pagination
    //         page : state?.page,    
    //     });

    //     const { count, rows : transactions } = locationMaterialLogsResponse?.location_material_logs;
        
    //     setTimeout(() => {
    //         setState(state => ({...state, loading : false, transactions: transactions, count : count,}));
    //     }, 500);        
    // }

    // const handleCsvExport = async () => {
    //     setState(state => ({...state, loading : true,}));
    //     const { search, location, material, } = filter;
            
    //     const locationMaterialLogsResponse = await locationMaterialLogsService.getLocationMaterialLogs({
    //         search,
    //         location_ids : location,
    //         material_ids : material,
    //         is_all : 1,   
    //     });

    //     const { count, rows : transactions } = locationMaterialLogsResponse?.location_material_logs;

    //     let data = [];
    //     transactions.forEach((item)=>{
    //         let object = {
    //             'Location'    : item.location.name,
    //             'Item Name'   : item.material.name,
    //             'Quantity'    : item.quantity,
    //             'Source Type' : item.inbound_material_id ? 'Inbound' : 'Outbound',
    //             'Created By'  : item.user?.name,
    //             'Updated At'  : item.updated_at,
    //         }
    //         data.push(object);
    //     })
        

    //     setTimeout(() => {
    //         setState(state => ({...state, loading : false, excelData : data,}));
    //     }, 1000);        
    // }

    const getLocationMaterialLogs = async () => {
        setState(state => ({...state, loading : true,}));
        const { search, location, material, inoutbound_type, warehouse, startDate, endDate } = filter;
            
        const locationMaterialLogsResponse = await locationMaterialLogsService.getLocationMaterialLogs({
            search,
            location_ids : location,
            material_ids : material,
            inoutbound_type_ids : inoutbound_type,
            warehouse_ids : warehouse,
            startDate: startDate,
            endDate: endDate,

            //pagination
            page : state?.page,    
        });

        let { count, rows : transactions } = locationMaterialLogsResponse?.location_material_logs;
        console.log("inoutbound_type",inoutbound_type)

        // if (inoutbound_type)
        // {
        //     transactions = transactions.filter(x => {
        //         if (inoutbound_type.includes(1) && x.inbound_material_id === null) {
        //             return false; // Exclude if inbound_material_id is null and 1 is in inoutbound_type
        //         }
        //         if (inoutbound_type.includes(2) && x.outbound_material_id === null) {
        //             return false; // Exclude if outbound_material_id is null and 2 is in inoutbound_type
        //         }
        //         return true; // Include all other cases
        //     });
        // }

        setTimeout(() => {
            setState(state => ({ ...state, loading: false, transactions: transactions, count : count,}));
        }, 500);        
    }

    const filterchangeDefaultPage = () => {
        if (filter_changed) {
            setState(state => ({ ...state, page: 0, filter_changed: false }));
        }
    };

    useEffect(() => {
        filterchangeDefaultPage();
    }, [filter,])

    useEffect(() => {
        getLocationMaterialLogs();
    }, [filter, state?.page, warehouseRedux?.location_material_list_refresh])

    useEffect(() => {
        if (state?.excelData.length > 0 && state?.loading == 'true') {
            CSVReportLink.current.link.click();
            setState(state => ({...state, loading : false,}));
        }
    }, [state?.excelData]);

    return (
        <>
            {/* <div style={{display: 'flex', justifyContent:'flex-end'}}>
                <Button onClick={handleCsvExport} icon={<DownloadOutlined />}>
                    <CSVLink
                    filename={"Transaction_Logs.csv"}
                    data={state?.excelData}
                    className="feed-icon-wrapper"
                    ref={CSVReportLink}>
                        {state?.loading ? 'Loading csv...' : 'Download Excel'}
                    </CSVLink>
                </Button>
            </div> */}
            <Spin spinning={state?.loading}>
                <Table
                pagination={{ 
                    size : 10,
                    showSizeChanger: false,
                    total : state?.count,
                    current : (state?.page + 1),
                    onChange : (page) => {
                        setState(state => ({...state, page : page - 1}));
                        // dispatch(set_list_page(page - 1));
                    }
                }} 
                scroll={{ x : 400, }}
                className='hub-table bordered'
                dataSource={state?.transactions}
                columns={[{
                    title : 'Location',
                    dataIndex : 'location',
                    key : 'location',                    
                    render : (text, row, index) => {
                        const { name, } = text ?? {};
                        return (
                            <>
                                <div>
                                    <Tag color='var(--secondary-button-color)'>
                                        <span style={{fontSize:12, color:'var(--secondary-text-color)'}}>{name}</span>
                                    </Tag>
                                </div>
                            </>
                        )
                    }
                },{
                    title : 'Warehouse',
                    dataIndex: 'location',
                    key: 'location',                    
                    render : (text, row, index) => {
                        const { warehouse , } = text ?? {};
                        const { name } = warehouse ?? {};                        
                        return (
                            <>
                                <div>
                                    <Tag color='var(--secondary-button-color)'>
                                        <span style={{fontSize:12, color:'var(--secondary-text-color)'}}>{name}</span>
                                    </Tag>
                                </div>
                            </>
                        )
                    }
                },{
                    title : 'Item Name',
                    dataIndex : 'material',
                    key : 'material',                    
                    render : (text, row, index) => {
                        const { name, } = text ?? {};
                        const { uom: code } = text ?? {};                        
                        return (
                            <>
                                <div>
                                    <Tag color='var(--secondary-button-color)'>
                                        <span style={{fontSize:12, color:'var(--secondary-text-color)'}}>{name}</span>
                                    </Tag>
                                </div>
                            </>
                        )
                    }
                },{
                    title : 'Quantity',
                    dataIndex : 'quantity',
                    key : 'quantity',
                    render : (quantity, row, index) => {
                        const { inbound_material_id } = row;
                        const type = inbound_material_id ? 'Inbound' : 'Outbound';  
                        return (
                            <>
                                <div>
                                    {
                                        type == 'Inbound' ?
                                        (
                                            <>
                                                {/* <Tag color='var(--secondary-button-color)'>
                                                    <span style={{fontSize:12, color:'#008000'}}>{quantity}</span>
                                                </Tag> */}
                                                <Tag color={'green'} style={{ border : 'none', borderRadius:4,}}>{quantity}</Tag>
                                            </>
                                        ) 
                                        : 
                                        (
                                            <>
                                                {/* <Tag color='var(--secondary-button-color)'>
                                                    <span style={{fontSize:12, color:'#B22222'}}>{quantity}</span>
                                                </Tag> */}
                                                <Tag color={'orange'} style={{ border : 'none', borderRadius:4,}}>{quantity}</Tag>
                                            </>
                                        )
                                    }


                                    {/* <Tag color='var(--secondary-button-color)'>
                                        <span style={{fontSize:12, color:'var(--secondary-text-color)'}}>{quantity}</span>
                                    </Tag> */}
                                </div>
                            </>
                        )
                    }
                },{
                    title : 'Source Type',
                    dataIndex : 'type',
                    key : 'type',                    
                    render : (text, row, index) => {
                        const { inbound_material_id } = row;
                        const type = inbound_material_id ? 'Inbound' : 'Outbound';                       
                        return (
                            <>
                                {/* <div>
                                    <Tag color='var(--secondary-button-color)'>
                                        <span style={{fontSize:12, color:'var(--secondary-text-color)'}}>{type}</span>
                                    </Tag>
                                </div> */}

                                {
                                        type == 'Inbound' ?
                                        (
                                            <>
                                                {/* <Tag color='var(--secondary-button-color)'>
                                                    <span style={{fontSize:12, color:'#008000'}}>{type}</span>
                                                </Tag> */}
                                                <Tag color={'green'} style={{ border : 'none', borderRadius:4,}}>{type}</Tag>
                                            </>
                                        ) 
                                        : 
                                        (
                                            <>
                                                {/* <Tag color='var(--secondary-button-color)'>
                                                    <span style={{fontSize:12, color:'#B22222'}}>{type}</span>
                                                </Tag> */}
                                                <Tag color={'orange'} style={{ border : 'none', borderRadius:4,}}>{type}</Tag>
                                            </>
                                        )
                                    }
                            </>
                        )
                    }
                },{
                    title : 'Created By',
                    dataIndex : 'user',
                    key : 'user',                    
                    render : (text, row, index) => {      
                        const { name } = text ?? {};               
                        return (
                            <>
                                <div>
                                    <Tag color='var(--secondary-button-color)'>
                                        <span style={{fontSize:12, color:'var(--secondary-text-color)'}}>{name}</span>
                                    </Tag>
                                </div>
                            </>
                        )
                    }
                },{
                    title : 'Updated At',
                    dataIndex : 'updated_at',
                    key : 'updated_at',                    
                    render : (updated_at, row, index) => {                     
                        return (
                            <>
                                <div>
                                    <div>
                                        <span>{moment(updated_at)?.format("YYYY-MM-DD hh:mm:ssa")}</span>
                                    </div>
                                </div>
                            </>
                        )
                    }
                },]}
                />
            </Spin>
        </>
    );
}

export default TransactionsList;