import React, { useEffect, useState } from 'react';
import { Button, Drawer, Form, Space, Spin, Table, Tag, notification } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { refresh_locations_list } from '../../../actions/warehouse.action';
import { DeleteOutlined } from '@ant-design/icons';
import stockService from '../../../services/warehouse/stock.service';
import { refresh_location_material_list } from '../../../actions/warehouse.action';

const StockList = ({ filter, }) => {
    const warehouseRedux = useSelector(state => state?.warehouse);
    const [state, setState] = useState({
        loading : false,
        save_loading : false,
        stocks : [],
        count : 0,
        page : 0,
    });
    
    const getStocks = async () => {
        setState(state => ({...state, loading : true,}));
        const { search, warehouse, material, } = filter;
            
        const stockResponse = await stockService.getStocks({
            search,
            warehouse_ids : warehouse,
            material_ids : material,

            //pagination
            page : state?.page,    
        });

        // const { count, rows : stocks } = stockResponse?.stocks;
        const { count, stocks } = stockResponse;
        console.log(stocks)
        console.log(stockResponse)
        setTimeout(() => {
            setState(state => ({...state, loading : false, stocks: stocks, count : count,}));
        }, 500);        
    }

    useEffect(() => {
        getStocks();
    }, [filter, state?.page, warehouseRedux?.location_material_list_refresh])

    return (
        <>
            <Spin spinning={state?.loading}>
                <Table 
                pagination={{ 
                    size : 10,
                    showSizeChanger: false,
                    total : state?.count,
                    current : (state?.page + 1),
                    onChange : (page) => {
                        setState(state => ({...state, page : page - 1}));
                        // dispatch(set_list_page(page - 1));
                    }
                }} 
                scroll={{ x : 400, }}
                className='hub-table bordered'
                dataSource={state?.stocks}
                columns={[{
                    title : 'Item Name',
                    dataIndex : 'material_name',
                    key : 'material_name',
                    width: '25%',
                    render : (material, row, index) => {
                        const { material_name, material_code } = row ?? {};
                        return (
                            <>
                                <div>
                                    <span>{material_name}</span>
                                </div>
                                <div>
                                    <span style={{ fontSize:12, color : 'var(--secondary-text-color)' }}>{material_code}</span>
                                </div>
                            </>
                        )
                    }
                },{
                    title : 'Warehouse',
                    dataIndex : 'warehouse_name',
                    key : 'warehouse_name',
                    width: '25%',
                    render : (warehouse_name, row, index) => {
                        return (
                            <>
                                <div>
                                    <Tag color='var(--secondary-button-color)'>
                                        <span style={{fontSize:12, color:'var(--secondary-text-color)'}}>{warehouse_name}</span>
                                    </Tag>
                                </div>
                            </>
                        )
                    }
                },{
                    title : 'Quantity',
                    dataIndex : 'total_quantity',
                    key : 'total_quantity',
                    render : (total_quantity, row, index) => {
                        const { uomname, uomcode } = row ?? {};
                        return (
                            <>
                                <div>
                                    {
                                        total_quantity > 100 ?
                                        (
                                            <>
                                                {/* <Tag color='var(--secondary-button-color)'>
                                                    <span style={{fontSize:12, color:'#008000'}}>{quantity}</span>
                                                </Tag> */}
                                                <Tag color={'green'} style={{ border : 'none', borderRadius:4,}}>{total_quantity} {uomname}</Tag>
                                            </>
                                        ) 
                                        : 
                                        (
                                            <>
                                                {/* <Tag color='var(--secondary-button-color)'>
                                                    <span style={{fontSize:12, color:'#B22222'}}>{quantity}</span>
                                                </Tag> */}
                                                <Tag color={'orange'} style={{ border : 'none', borderRadius:4,}}>{total_quantity} {uomname}</Tag>
                                            </>
                                        )
                                    }


                                    {/* <Tag color='var(--secondary-button-color)'>
                                        <span style={{fontSize:12, color:'var(--secondary-text-color)'}}>{quantity}</span>
                                    </Tag> */}
                                </div>
                            </>
                        )
                    }
                }]}
                />
            </Spin>
        </>
    );
}

export default StockList;