import { Col, Form, Input, Row, Skeleton } from 'antd';
import React, { useEffect, useState } from 'react';
import uomService from '../../../services/config/uom.service';

const UomEditDetail = ({ uom_id, form }) => {

    const [state, setState] = useState({
        loading : false,
        uom : null,
    })

    const fetchUoms = async () => {
        setState(state => ({...state, loading : true, }));
        const uomsResponse = await uomService?.getUoms({
            uom_id,
        });

        const { rows: uoms } = uomsResponse?.uoms;
        const uom = uoms?.length > 0 ? uoms[0] : null;

        setTimeout(() => {
            const { name, code, } = uom;
            form.setFieldsValue({ name, code, });

            setState(state => ({...state, loading : false, uom : uom}));
        }, 500);
    }

    useEffect(() => {
        uom_id && fetchUoms();
    }, [uom_id]);

    return (
        <>
            <div>
                {
                    state?.loading ?
                    (
                        <>
                            <div>
                                <Skeleton active />
                            </div>
                        </>
                    )
                    :
                    (
                        <>
                            <Row gutter={[12, 12]}>
                                <Col span={12}>
                                    <Form.Item name={'code'} label={'Code'}>
                                        <Input placeholder='UOM Code' />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item name={'name'} label={'Name'}>
                                        <Input placeholder='UOM Name' />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </>
                    )
                }
                
            </div>
        </>
    );
}

export default UomEditDetail;