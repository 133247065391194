import React, { useEffect, useState } from 'react';
import workOrderService from '../../../services/production/work_order.service';
import PageHeaderComponent from '../../../helpers/components/page_header.component';
import { Avatar, Badge, Button, Card, Col, DatePicker, Divider, Popover, Row, Select, Input, Space, Spin, Tabs, Tag, notification } from 'antd';
import moment from 'moment';
import GeneralLabel from './general_label';
import { BlockOutlined, CalendarOutlined, CaretDownFilled, CheckOutlined, CloseOutlined, EditOutlined, FileTextOutlined, Loading3QuartersOutlined, TeamOutlined } from '@ant-design/icons';
import WorkOrderProduct from './work_order_product';
import dayjs from 'dayjs';
import WorkOrderProductMaterialList from './work_order_product_material_list';
import WorkOrderHistory from './work_order_history';
import WorkOrderFile from './work_order_file';
import WorkOrderStatusSelect from './work_order_status_select';
import WorkOrderStatusCancellation from './work_order_status_cancellation';
import lineService from '../../../services/production/line.service';
import userService from '../../../services/admin/user.service';
import TextArea from 'antd/es/input/TextArea';

const WorkOrderDetailLayout = ({work_order_id}) => {
    const [state, setState] = useState({
        loading : false,
        work_order : null,
        edit_mode : false,
        
        open_popover : false,
        open_cancel_reason : false,
        status_loading : false,
        update_loading : false,
        // refresh entire work order
        refresh : 0,


        // selections
        lines : [],
        users : [],
    })

    const [field, setField] = useState({
        title : null,
        assignee_id : null,
        production_line_id : null,
        issue_date : null,
        start_date : null,
        end_date : null,
        remark : null,
        cancel_reason : null,
    })

    const hide = () => {
        setState(state => ({...state, open_cancel_reason : false,}));
    };

    const show = () => {
        setState(state => ({...state, open_cancel_reason : true,}));
    };


    // const content = (
    //     <div>
    //       <Input
    //       onChange={(e) => onFieldChange('cancel_reason', e.target.value)}
    //       defaultValue={state?.work_order?.cancel_reason}
    //       placeholder={"Enter Reason"} 
    //     //   style={{maxWidth:200,}} 
    //       />
    //       <a onClick={show}>Submit</a>
    //       <a onClick={hide}>Close</a>
    //     </div>
    // );

    // const handleConfirm = (value) => {
    //     // message.info(`You confirmed with input: ${value}`);
    //     console.log(value)
    //     setField(field => ({ ...field, cancel_reason: value }));
    // };

    const fetchWorkOrder = async () => {
        setState(state => ({...state, loading : true, }));
        const workOrderResponse = await workOrderService.getWorkOrderDetail(work_order_id);
        const { work_order } = workOrderResponse;
        setState(state => ({...state, loading : false, work_order : work_order, }));
    }

    // const updateWorkOrderStatus = async (work_order_status_id) => {
    //     setState(state => ({...state, status_loading : true, }));
    //     const updateWorkOrderStatusResponse = await workOrderService?.updateWorkOrderStatus({
    //         work_order_id : work_order_id,
    //         work_order_status_id : work_order_status_id,
    //     })

    //     setState(state => ({...state, refresh : (state?.refresh + 1), status_loading : false, open_popover : false,}));
    //     notification.success({
    //         message : 'Success Update Work Order Status',
    //         description : state?.work_order?.running_number,
    //     })
    // }

    const updateWorkOrderStatus = async (work_order_status_id, cancel_reason) => {
        setState(state => ({...state, status_loading : true, }));
        // const { cancel_reason } = field;

        console.log("updateWorkOrderStatus id",work_order_status_id)
        console.log("updateWorkOrderStatus cancel_reason field", cancel_reason)
        if(work_order_status_id === 4 && !cancel_reason){
            console.log("here open")
            setState(state => ({ ...state, refresh: (state?.refresh + 1), status_loading: false, open_popover: false, }));
            return;
        }

        const updateWorkOrderStatusResponse = await workOrderService?.updateWorkOrderStatus({
            work_order_id : work_order_id,
            work_order_status_id : work_order_status_id,
            cancel_reason: cancel_reason,
        })

        setState(state => ({...state, refresh : (state?.refresh + 1), status_loading : false, open_popover : false,}));
        notification.success({
            message : 'Success Update Work Order Status',
            description : state?.work_order?.running_number,
        })
    }

    const updateWorkOrderDetail = async () => {
        debugger;
        setState(state => ({...state, update_loading : true, }));

        const { title, assignee_id, production_line_id, issue_date, start_date, end_date, remark } = field;
        const updateWorkOrderDetailResponse = await workOrderService?.updateWorkOrderDetail(work_order_id, {
            title, assignee_id, production_line_id, issue_date, start_date, end_date, remark,
        });

        if(updateWorkOrderDetailResponse){
            setState(state => ({...state, update_loading : false, refresh : (state?.refresh + 1), edit_mode : false,}));

            notification.success({
                message : 'Success Update Work Order',
                description : state?.work_order?.running_number,
            })
        }
    }

    const onFieldChange = async (key, value) => {
        setField(field => ({...field, [key] : value}));
    }

    const initializeSelections = async () => {
        // fetch production lines
        const productionLineResponse = await lineService?.getLines();
        // fetch users
        const userResponse = await userService?.getUsers();
        setState(state => ({...state, lines : productionLineResponse?.lines, users : userResponse?.users,}));
    }

    useEffect(() => {
        fetchWorkOrder();
    },[ work_order_id, state?.refresh, ]);

    useEffect(() => {
        initializeSelections();
    }, [])

    return (
        <>
            {/* <WorkOrderStatusCancellation onConfirm={handleConfirm} /> */}
        
            <Spin spinning={state?.loading}>
                <div>
                    <div style={{textAlign:'start', }}>
                        <div style={{color : 'var(--secondary-text-color)',}}>
                            <Space size={20}>
                                <div>
                                    <span>{`#${state?.work_order?.running_number}`}</span>
                                </div>

                                <div>
                                    <span>{moment(state?.work_order?.created_at)?.format("YYYY-MM-DD hh:mm:ssa")}</span>
                                </div>
                            </Space>
                        </div>

                        <div style={{marginTop:12,}}>
                            <div style={{display:'flex',}}>
                                {
                                    (
                                        <>
                                            {
                                                state?.edit_mode ?
                                                (
                                                    <>
                                                        <Input
                                                        onChange={(e) => onFieldChange('title', e.target.value)}
                                                        defaultValue={state?.work_order?.title}
                                                        placeholder={"Title"} 
                                                        style={{maxWidth:200,}} 
                                                        />
                                                    </>
                                                )
                                                :
                                                (
                                                <div>
                                                    <PageHeaderComponent title={state?.work_order?.title} />                                    
                                                </div>
                                                )
                                            }
                                        </>
                                    )
                                    
                                }
                                <div style={{marginLeft:'auto',}}>
                                    <Space>
                                        <div>
                                            <Popover
                                            open={state?.open_popover}
                                            content={
                                                <>
                                                    <div style={{padding:'12px 0'}}>
                                                        <WorkOrderStatusSelect onStatusChange={updateWorkOrderStatus} work_order_status_id={state?.work_order?.work_order_status_id} open_cancel_reason/>
                                                    </div>
                                                </>
                                            }
                                            trigger={['click']}
                                            placement='bottomRight'
                                            overlayInnerStyle={{padding : 0, minWidth: 260,}}
                                            >
                                                <Button
                                                onClick={() => setState(state => ({...state, open_popover : (!state?.open_popover)}))}
                                                loading={state?.status_loading}
                                                icon={<CaretDownFilled />}
                                                type='text'
                                                style={{background: (state?.work_order?.work_order_status?.color ?? "var(--secondary-button-color)"), fontWeight:500, color : (state?.work_order?.work_order_status?.color ? 'rgba(255, 255, 255, 0.8)' : 'var(--secondary-text-color)') }}
                                                >
                                                    {state?.work_order?.work_order_status?.name}
                                                </Button>
                                            </Popover>
                                        </div>
                                        {
                                            (state?.work_order?.work_order_status?.is_editable) &&
                                            (
                                                <>
                                                    <div>
                                                    {
                                                        state?.edit_mode ?
                                                        (
                                                            <>
                                                                <Space>
                                                                    <Button loading={state?.update_loading} onClick={updateWorkOrderDetail} size='small' icon={<CheckOutlined style={{color :'var(--secondary-text-color)'}} />}>

                                                                    </Button>
                                                                    <Button onClick={() => setState(state => ({...state, edit_mode : false, }))} size='small' icon={<CloseOutlined style={{color :'var(--secondary-text-color)'}} />}>

                                                                    </Button>
                                                                </Space>
                                                            </>
                                                        )
                                                        :
                                                        (
                                                            <>
                                                                <Button onClick={() => setState(state => ({...state, edit_mode : true, }))} icon={<EditOutlined style={{color :'var(--secondary-text-color)'}} />}>
                                                                    Edit
                                                                </Button>
                                                            </>
                                                        )
                                                    }
                                                    </div>
                                                </>
                                            )
                                        }
                                        
                                    </Space>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Divider style={{marginTop : 12, marginBottom: 24, }} />
                    
                    <div style={{textAlign:'start',  display : 'flex', flexDirection:'column', gap : 16,}}>
                        <GeneralLabel 
                        label={(
                            <Space>
                                <div>
                                    <TeamOutlined />
                                </div>
                                <div>
                                    <span>Assignee</span>
                                </div>
                            </Space>
                        )}
                        value={
                            (
                                <>
                                    {
                                        state?.edit_mode ?
                                        (
                                            <>
                                                <Select
                                                onChange={(e) => onFieldChange('assignee_id', e)}
                                                defaultValue={state?.work_order?.assignee?.user_id}
                                                options={state?.users?.map((line, index) => {
                                                    const { user_id, name, } = line;
                                                    return (
                                                        {   
                                                            value : user_id, 
                                                            label : (
                                                                <>
                                                                    <Space>
                                                                        <Avatar size='small' style={{ backgroundColor: '#fde3cf', color: '#f56a00', }}>
                                                                            {name?.split(' ')?.map(word => word.charAt(0).toUpperCase()).join('')}
                                                                        </Avatar>
                                                                        <span>{name}</span>
                                                                    </Space>
                                                                    
                                                                </>
                                                            ), 
                                                        })
                                                })}
                                                placeholder={"Select assignee"} 
                                                style={{minWidth:200,}}
                                                />
                                            </>
                                        )
                                        :
                                        (
                                            <>
                                                {
                                                    state?.work_order?.assignee ?
                                                    (
                                                        <>
                                                        <div style={{height:30,}}>
                                                            <Space>
                                                                <div>
                                                                    <Avatar style={{ backgroundColor: '#fde3cf', color: '#f56a00', }}>
                                                                        {state?.work_order?.assignee?.name?.split(' ')?.map(word => word.charAt(0).toUpperCase()).join('')}
                                                                    </Avatar>
                                                                </div>
                                                                <div>
                                                                    <span>{state?.work_order?.assignee?.name}</span>
                                                                </div>
                                                            </Space>
                                                        </div>
                                                        </>
                                                    )
                                                    :
                                                    (
                                                        <>
                                                            <div>
                                                                <span style={{fontSize:12, color:'var(--secondary-text-color)'}}>No user was assigned</span>
                                                            </div>
                                                        </>
                                                    )
                                                }
                                                
                                            </>
                                        )
                                    }
                                </>
                            )
                        }
                        />

                        <GeneralLabel 
                        label={(
                            <Space>
                                <div>
                                    <BlockOutlined />
                                </div>
                                <div>
                                    <span>Production Line</span>
                                </div>
                            </Space>
                        )}
                        value={
                            (
                                <>
                                    {
                                        state?.edit_mode ?
                                        (
                                            <>
                                                <Select
                                                onChange={(e) => onFieldChange('production_line_id', e)}
                                                defaultValue={state?.work_order?.production_line?.production_line_id}
                                                options={state?.lines?.map((line, index) => {
                                                    const { production_line_id, name, } = line;
                                                    return ({ value : production_line_id, label : name, })
                                                })}
                                                placeholder={"Select Production Line"} 
                                                style={{minWidth:200,}} 
                                                />
                                            </>
                                        )
                                        :
                                        (
                                            <>
                                                <div>
                                                    <span>{(state?.work_order?.production_line?.name)}</span>
                                                </div>
                                            </>
                                        )
                                    }
                                </>
                            )
                        }
                        />

                        <GeneralLabel 
                        label={(
                            <Space>
                                <div>
                                    <Loading3QuartersOutlined />
                                </div>
                                <div>
                                    <span>Status</span>
                                </div>
                            </Space>
                        )}
                        value={
                            (
                                <>
                                    <Tag color={state?.work_order?.work_order_status?.color} style={{ border : 'none', borderRadius:4, }}>
                                        {state?.work_order?.work_order_status?.name}
                                    </Tag>
                                </>
                            )
                        }
                        />

                        <GeneralLabel 
                        label={(
                            <Space>
                                <div>
                                    <CalendarOutlined />
                                </div>
                                <div>
                                    <span>Issue Date</span>
                                </div>
                            </Space>
                        )}
                        value={
                            (
                                <>
                                    {
                                        state?.edit_mode ?
                                        (
                                            <>
                                                <DatePicker
                                                onChange={(e, j) => onFieldChange('issue_date', j)}
                                                defaultValue={dayjs(state?.work_order?.issue_date)}
                                                style={{width : '100%', minWidth:200,}}
                                                />
                                            </>
                                        )
                                        :
                                        (
                                            <>
                                                <div>
                                                    <span>{moment(state?.work_order?.issue_date)?.format("YYYY-MM-DD")}</span>
                                                </div>
                                            </>
                                        )
                                    }
                                    
                                </>
                            )
                        }
                        />

                        <GeneralLabel 
                        label={(
                            <Space>
                                <div>
                                    <CalendarOutlined />
                                </div>
                                <div>
                                    <span>Date</span>
                                </div>
                            </Space>
                        )}
                        value={
                            (
                                <>
                                    {
                                        state?.edit_mode ?
                                        (
                                            <>
                                                <Space>
                                                    <DatePicker
                                                    onChange={(e, j) => onFieldChange('start_date', j)}
                                                    placeholder='Start Date'
                                                    defaultValue={state?.work_order?.start_date ? dayjs(state?.work_order?.start_date) : null}
                                                    style={{width : '100%', minWidth:200,}}
                                                    />
                                                    <DatePicker
                                                    onChange={(e, j) => onFieldChange('end_date', j)}
                                                    placeholder='End Date'
                                                    defaultValue={state?.work_order?.end_date ? dayjs(state?.work_order?.end_date) : null}
                                                    style={{width : '100%', minWidth:200,}}
                                                    />
                                                </Space>
                                            </>
                                        )
                                        :
                                        (
                                            <>
                                                <Space size={20}>
                                                    <div>
                                                        <div>
                                                            <span style={{fontSize:12, color:'var(--secondary-text-color)'}}>Start Date</span>
                                                        </div>
                                                        {
                                                            state?.work_order?.start_date ?
                                                            (
                                                                <>
                                                                    <span>{moment(state?.work_order?.start_date)?.format("YYYY-MM-DD")}</span>
                                                                </>
                                                            )
                                                            :
                                                            (
                                                                <>
                                                                    <span style={{fontSize : 12, color : 'var(--secondary-text-color)'}}>Unset</span>
                                                                </>
                                                            )
                                                        }
                                                        
                                                    </div>
                                                    <div>
                                                        <div>
                                                            <span style={{fontSize:12, color:'var(--secondary-text-color)'}}>End Date</span>
                                                        </div>
                                                        {
                                                            state?.work_order?.end_date ?
                                                            (
                                                                <>
                                                                    <span>{moment(state?.work_order?.end_date)?.format("YYYY-MM-DD")}</span>
                                                                </>
                                                            )
                                                            :
                                                            (
                                                                <>
                                                                    <span style={{fontSize : 12, color : 'var(--secondary-text-color)'}}>Unset</span>
                                                                </>
                                                            )
                                                        }
                                                    </div>
                                                </Space>
                                            </>
                                        )
                                    }
                                    
                                </>
                            )
                        }
                        />

                        <GeneralLabel 
                        label={(
                            <Space>
                                <div>
                                    <FileTextOutlined />
                                </div>
                                <div>
                                    <span>Remark</span>
                                </div>
                            </Space>
                        )}
                        value={
                            (
                                <>
                                    {
                                        state?.edit_mode ?
                                        (
                                            <>
                                                <TextArea
                                                        onChange={(e) => onFieldChange('remark', e.target.value)}
                                                        defaultValue={state?.work_order?.remark}
                                                        placeholder={"Remark"} 
                                                        style={{maxWidth:200,}} 
                                                />
                                            </>
                                        )
                                        :
                                        (
                                            <>
                                                {
                                                    <div>
                                                        <span>{state?.work_order?.remark} </span>                                 
                                                    </div>
                                                }
                                                
                                            </>
                                        )
                                    }
                                </>
                            )
                        }
                        />
                    </div>

                    <div style={{marginTop:24,}}>
                        <Tabs items={[{
                            key : '1',
                            label : 'Items',
                            children : (<><WorkOrderProduct work_order_id={work_order_id} work_order_status={state?.work_order?.work_order_status?.is_editable}/></>)
                        },
                        // {
                        //     key : '2',
                        //     label : 'Materials',
                        //     children : (<><WorkOrderProductMaterialList work_order_id={work_order_id} /></>)
                        // },
                        {
                            key : '3',
                            label : 'History',
                            children : (<><WorkOrderHistory work_order_id={work_order_id} work_order_status_id={state?.work_order?.work_order_status_id} /></>)
                        },{
                            key : '4',
                            label : 'Files',
                            children : (<><WorkOrderFile work_order_id={work_order_id} work_order_status_id={state?.work_order?.work_order_status_id}  /></>)
                        }]} />
                        {/* content in here */}
                    </div>
                </div>
            </Spin>
        </>
    );
}

export default WorkOrderDetailLayout;