import { Avatar, Button, Drawer, Form, Image, Modal, Space, Spin, Table, Tag, notification } from 'antd';
import React, { useEffect, useState } from 'react';
import userService from '../../../services/admin/user.service';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import UserEditDetail from './user_edit_detail';
import { refresh_user_list } from '../../../actions/config.action';
import { useDispatch, useSelector } from 'react-redux';
import authService from '../../../services/admin/auth.service';
import { DeleteOutlined } from '@ant-design/icons';

const UserList = ({ filter, }) => {
    const { innerWidth, } = window;
    const dispatch = useDispatch();
    const configRedux = useSelector(state => state.config);
    const [form] = Form.useForm();
    const [state, setState] = useState({
        loading : false,
        users : [],
        used_user_id: [],

        // edit
        drawer_user_id : null,
    })

    const fetchUsers = async () => {
        setState(state => ({...state, loading : true, }));
        const { search, role_ids, warehouse_ids } = filter;
        const usersResponse = await userService?.getUsers({
            search, role_ids, warehouse_ids,
        });

        const usedUserResponse = await userService?.getUsedUserList({});
        const used_list = usedUserResponse?.dataList;

        setTimeout(() => {
            setState(state => ({ ...state, loading: false, users: usersResponse?.users, used_user_id: used_list }));
        }, 500);
    }
    
    const openDrawer = (user_id) => {
        setState(state => ({...state, drawer_user_id : user_id}));
        !user_id && form.resetFields();
    }

    const saveUser = async (user_id) => {
        setState(state => ({...state, save_loading : true,}));
        const { name, email, role_id, warehouse_ids, resetpassword } = form.getFieldsValue();

        const updateUserResponse = await userService.updateUser(user_id, {
            name, email, role_id, warehouse_ids,
        });

        if(resetpassword)
        {
            const changePasswordResponse = await authService?.changePassword({
                password : resetpassword, reset_pw_user_id : user_id,
            });
        }

        //successful
        setTimeout(() => {
            dispatch(refresh_user_list());
            setState(state => ({...state, drawer_user_id : null, save_loading : false, }));
            form.resetFields();

            //success message
            notification?.success({
                message : "Success",
                description : `Successfully saved user ${name}`
            })
        }, 500);
    }

    const deleteConfirm = async (user_id) => {
        const user = state?.users?.find(x => x.user_id === user_id);
        const { name, } = user;
        Modal.confirm({
            title: 'Confirm Delete This Record?',
            content: (
                <>
                    <div>
                        <div>
                            <span>{name}</span>
                        </div>
                    </div>
                </>
            ),
            onOk: () => {
                return new Promise(async (resolve, reject) => {
                    await onDeleteUser(user_id).then((result) => {
                        setTimeout(() => {
                            // notification
                            notification?.success({
                                message: 'Successfully Deleted',
                                description: (
                                    <>
                                        <div>
                                            <div>
                                                <span>{name}</span>
                                            </div>
                                        </div>
                                    </>
                                )
                            })
                            setState(state => ({ ...state, refresh: (state?.refresh + 1) }));
                            resolve();
                        }, 500);
                    });

                }).catch(() => console.log('Oops errors!'));
            }
        })
    }

    const onDeleteUser = async (user_id) => {
        const deleteUserResponse = await userService?.deleteUser(user_id);
        return deleteUserResponse;
    }

    useEffect(() => {
        fetchUsers();
    }, [filter, state?.page, configRedux?.user_list_refresh, state?.refresh]);

    return (
        <>
            <Spin spinning={state?.loading}>
                <Table
                scroll={{ x : 400, }}
                className='hub-table bordered'
                dataSource={state?.users}
                columns={[{
                    title : 'User',
                    dataIndex : 'name',
                    key : 'name',
                    width : '25%',
                    render : (text, row, index) => {
                        return (
                            <>
                                <div>
                                    <Space size={12}>
                                        <div>
                                            <Avatar size={'large'} src={<Image preview={false} src={'/cibanners/avatar.png'}/>} />
                                        </div>
                                        <div>
                                            <span>{text}</span>
                                        </div>
                                    </Space>
                                    
                                </div>
                            </>
                        )
                    }
                },{
                    title : 'Email',
                    dataIndex : 'email',
                    key : 'email',
                    render : (text, row, index) => {
                        const email = text;
                        return (
                            <>
                                <div>
                                    <span style={{fontSize:12, color:'var(--secondary-text-color)'}}>{email}</span>
                                </div>
                            </>
                        )
                    }
                },{
                    title : 'Assigned Warehouses',
                    dataIndex : 'warehouses',
                    key : 'warehouses',
                    render : (text, row, index) => {
                        const warehouses = text;
                        return (
                            <>
                                <div>
                                    <Space>
                                        {
                                            warehouses?.map((warehouse, index) => {
                                                const { name, is_hq } = warehouse;
                                                return (
                                                    <div key={index}>
                                                        <Tag color='var(--secondary-button-color)'>
                                                            <span style={{fontSize:12, color:'var(--secondary-text-color)'}}>{name}</span>
                                                        </Tag>
                                                    </div>
                                                )
                                            })
                                        }
                                    </Space>
                                    
                                </div>
                            </>
                        )
                    }
                },{
                    title : 'Role',
                    dataIndex : 'role',
                    key : 'role',
                    render : (text, row, index) => {
                        const { name } = text;
                        return (
                            <>
                                <div>
                                    <Tag color='var(--secondary-button-color)'>
                                        <span style={{fontSize:12, color:'var(--secondary-text-color)'}}>{name}</span>
                                    </Tag>
                                </div>
                            </>
                        )
                    }
                },{
                    title : 'Action',
                    dataIndex : 'user_id',
                    key : 'user_id',
                    width : '10%',
                    render : (text, row, index) => {
                        const user_id = text;
                        return (
                            <Button.Group>
                                <Button
                                    onClick={() => openDrawer(user_id)}
                                >
                                    View
                                </Button>
                                {
                                    state?.used_user_id.some(x => x.user_id === user_id) ?
                                        <Button type="primary" disabled />
                                        :
                                        <Button icon={<DeleteOutlined style={{ color: 'var(--secondary-text-color)' }} onClick={async () => deleteConfirm(user_id)} />} />
                                }
                            </Button.Group>
                        )
                    }
                },]}
                />
            </Spin>


            {/* Detail */}
            <Drawer
            title="User Detail"
            rootClassName='drawer-detail-root'
            className='drawer-detail'
            open={state?.drawer_user_id}
            onClose={() => openDrawer(null)}
            width={innerWidth >= 768 ? 800 : "100%"}
            footer={
                <>
                    <div style={{display:'flex', padding:'12px 0',}}>
                        <div style={{marginLeft:'auto',}}>
                            <Space>
                                <Button loading={state?.save_loading} type='primary' onClick={() => form.submit()}>Save Changes</Button>
                            </Space>
                        </div>
                    </div>
                </>
            }
            >
                <div>
                    <Form
                    onFinish={() => saveUser(state?.drawer_user_id)}
                    form={form}
                    layout='vertical'
                    >
                        <UserEditDetail user_id={state?.drawer_user_id} form={form} />
                    </Form>
                </div>
            </Drawer>
        </>
    );
}

export default UserList;