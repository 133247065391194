import React, { useEffect, useState, useRef } from 'react';
import { Button, Spin } from 'antd';
import { DownloadOutlined, PrinterOutlined } from '@ant-design/icons';
import ReactToPrint, { PrintContextConsumer } from 'react-to-print';
import InboundTemplate from './inbound_template';
import inboundService from '../../../services/warehouse/inbound.service';

const InboundFile = ({ inbound_id, inoutbound_status_id, }) => {
    const componentRef = useRef();
    const [state, setState] = useState({
        loading : false,
        inbound : null,
        inboundMaterial : null,
    })

    const fetchInbound = async () => {
        setState(state => ({...state, loading : true, }));
        const inboundResponse = await inboundService?.getInboundDetail(inbound_id);
        const { dataList:inbound } = inboundResponse;
        setState(state => ({...state, loading : false, inbound : inbound}));
    }

    const fetchInboundMaterials = async () => {
        setState(state => ({...state, loading : true, }));
        const inboundMaterialsResponse = await inboundService?.getInboundMaterials({inbound_id:inbound_id});
        const { dataList:inboundMaterial } = inboundMaterialsResponse;
        setState(state => ({...state, loading : false, inboundMaterial : inboundMaterial}));
    }

    useEffect(() => {
        fetchInbound();
    }, [inoutbound_status_id])

    useEffect(() => {
        fetchInboundMaterials();
    }, [inbound_id])

    return (
        <>
            <div>
                <div style={{display:'flex',}}>
                    <div style={{marginLeft:'auto'}}>
                        <ReactToPrint 
                        trigger={() => (
                            <Button icon={<PrinterOutlined />}>Print</Button>
                        )}
                        content={() => componentRef.current}
                        pageStyle={`@page {
                            margin: 24px;
                        }`}
                        />
                    </div>
                </div>
                <div style={{marginTop:12,}}>
                    <div style={{ margin : 'auto', display: 'flex', justifyContent:'center', }}>
                        <div className='card-shadow' style={{width: 800, minHeight: 1000, }}>
                            <Spin spinning={state?.loading}>
                                <div ref={componentRef}>
                                    <InboundTemplate inbound={state?.inbound} inboundMaterial={state?.inboundMaterial} />
                                </div>
                            </Spin>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default InboundFile;