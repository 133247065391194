import React from 'react';
import ApprovalLayout from './approval/approval_layout';
import ContainerComponent from '../../helpers/components/container.component';

const ApprovalIndex = () => {
    return (
        <>
            <ContainerComponent span={22} top={20} bottom={20}>
                <ApprovalLayout />
            </ContainerComponent>
        </>
    );
}

export default ApprovalIndex;