const initialState = {
    locations_list_refresh : 0,
    location_material_list_refresh : 0,
    inoutbound_sources_list_refresh : 0,
    inbound_list_refresh : 0,
    outbound_list_refresh : 0,
}

const warehouseReducer = (state = initialState, action) => {
    switch(action.type){
        case 'REFRESH_LOCATIONS_LIST' :
            return {...state, locations_list_refresh : (state.locations_list_refresh + 1)}
        case 'REFRESH_LOCATION_MATERIAL_LIST' :
            return {...state, location_material_list_refresh : (state.location_material_list_refresh + 1)}
        case 'REFRESH_INOUTBOUND_SOURCE_LIST' :
            return {...state, inoutbound_sources_list_refresh : (state.inoutbound_sources_list_refresh + 1)}
        case 'REFRESH_INBOUND_LIST' :
            return {...state, inbound_list_refresh : (state.inbound_list_refresh + 1)}
        case 'REFRESH_OUTBOUND_LIST' :
            return {...state, outbound_list_refresh : (state.outbound_list_refresh + 1)}
        default :
            return state;
    }
}

export default warehouseReducer;