import React from 'react';
import ContainerComponent from '../../helpers/components/container.component';
import { useParams } from 'react-router-dom';
import OutboundDetailLayout from './outbound/outbound_detail_layout';

const OutboundDetail = () => {
    const { outbound_id } = useParams();
    return (
        <>
            <ContainerComponent span={22} top={20} bottom={20}>
                <OutboundDetailLayout outbound_id={outbound_id} />
            </ContainerComponent>
        </>
    );
}

export default OutboundDetail;