import React from 'react';
import ContainerComponent from '../../helpers/components/container.component';
import { useParams } from 'react-router-dom';
import InboundDetailLayout from './inbound/inbound_detail_layout';

const InboundDetail = () => {
    const { inbound_id } = useParams();
    return (
        <>
            <ContainerComponent span={22} top={20} bottom={20}>
                <InboundDetailLayout inbound_id={inbound_id} />
            </ContainerComponent>
        </>
    );
}

export default InboundDetail;