import React, { useEffect, useState } from 'react';
import workOrderService from '../../../services/production/work_order.service';
import { Tag, Timeline } from 'antd';
import moment from 'moment';

const WorkOrderHistory = ({ work_order_id, work_order_status_id, }) => {
    const [state, setState] = useState({
        loading : false,
        work_order_status_histories : [],
    })

    const fetchWorkOrderHistory = async () => {
        setState(state => ({...state, loading : true, }));
        const workOrderHistoryResponse = await workOrderService?.getWorkOrderHistory({
            work_order_id,
        })

        const { work_order_status_history } = workOrderHistoryResponse;
        const mapped_work_order_status_histories = work_order_status_history?.map((history, index) => {
            const { work_order, work_order_status, work_order_status_created_by, created_at } = history;
            return {
                color : work_order_status?.color,
                children : (
                    <>
                        <div>
                            <Tag color={work_order_status?.color} style={{border : 'none', }}>
                                {/* {work_order_status?.name}  {work_order_status?.name === "Cancel" ? "Reason: "+ work_order?.cancel_reason : null} */}
                                {work_order_status?.name}
                            </Tag>
                        </div>
                        <div>
                            {
                                (
                                    <>
                                        {
                                            work_order_status?.name === "Cancel" ?
                                                (
                                                    <>
                                                        
                                                        <span style={{ fontSize: 12, color: 'var(--secondary-text-color)' }}>Reason : {work_order?.cancel_reason}</span>
                                                    </>
                                                )
                                                :
                                                (
                                                    <></>
                                                )
                                        }
                                    </>
                                )
                            }
                        </div>
                        <div>
                            <span style={{fontSize:12, color : 'var(--secondary-text-color)'}}>Updated by {work_order_status_created_by?.name} at {moment(created_at)?.format("YYYY-MM-DD hh:mm:ssa")}</span>
                        </div>
                    </>
                ),
                date : created_at,
            }
        })
        mapped_work_order_status_histories.sort((a, b) => new Date(b?.date) - new Date(a?.date)); // sort date
        setState(state => ({...state, loading : false, work_order_status_histories : mapped_work_order_status_histories }));
    }

    useEffect(() => {
        if(work_order_id){
            fetchWorkOrderHistory();
        }
        
    }, [work_order_id, work_order_status_id])

    return (
        <>
            <div style={{textAlign:'start', padding : 24,}}>
                <Timeline
                items={state?.work_order_status_histories}
            />
            </div>
        </>
    );
}

export default WorkOrderHistory;