import { Layout } from 'antd';
import React, { useEffect, useState } from 'react';
import MasterHeader from './master_header';
import MasterSider from './master_sider';
import {
    MenuFoldOutlined,
    MenuUnfoldOutlined,
    UploadOutlined,
    UserOutlined,
    VideoCameraOutlined,
} from '@ant-design/icons';
import { Button, Menu, theme } from 'antd';

const { Header, Content, Sider } = Layout;
const MasterLayout = ({children}) => {
    const { innerWidth } = window;
    const [state, setState] = useState({
        collapsed: false,
    });
    const setCollapsed = (collapsed) => {
        setState(state => ({ ...state, collapsed: collapsed }));
    }
    const {
        token: { colorBgContainer, borderRadiusLG },
    } = theme.useToken();
    return (
        <>
            {/* <Header style={{ padding: 0, background: colorBgContainer }}>
                <Button
                    type="text"
                    icon={state?.collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                    onClick={() => setCollapsed(!state?.collapsed)}
                    style={{
                        fontSize: '16px',
                        width: 64,
                        height: 64,
                    }}
                />
            </Header>   */}
            <Layout>
                {/* <Sider className='main-sider card-shadow' collapsed={innerWidth <= 768}  width={80} theme='light' style={{ zIndex : 100, }} collapsedWidth={0}> */}
                
                <Sider className='main-sider card-shadow' zeroWidthTriggerStyle={{ position: 'absolute', top: 0}} breakpoint='md' width={80} theme='light' style={{ zIndex: 100, }} collapsedWidth={0}>  
                    <MasterSider /> 
                </Sider>
                <Layout>
                    {/* <Header className="main-header" style={{background : 'transparent',position: 'sticky', top: 0, width: '100%', paddingInline : 24}}>
                        <MasterHeader />
                    </Header> */}
                    <Header className="main-header" style={{ background: 'transparent', position: 'absolute',  top: 0, width: '100%', paddingInline : 24}}>
                        <img src="/Web Logo.png" alt="Header" className="header-image" style={{ float: 'left', width: '100px', height: 'auto', marginTop: '10px', marginLeft: '30px' }}/>
                    </Header>

                    <Content className='main-content'>
                        <div className='content'>
                            {children}
                        </div>
                    </Content>
                </Layout>
            </Layout>
        </>
    );
}

export default MasterLayout;