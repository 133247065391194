import React from 'react';
import MenuComponent from '../../../helpers/components/menu.component';

const ConfigurationLayout = () => {
    return (
        <>
            <MenuComponent />
        </>
    );
}

export default ConfigurationLayout;