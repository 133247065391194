import { message, notification } from 'antd';
import axios from 'axios';
import React from 'react';
import MasterLayout from './components/master_layout';
import './layout.css';

const LayoutIndex = ({children}) => {

    //auto error message
    axios.interceptors.response.use(function (response) {
    // Do something with response data
        return response;
        }, function (error) {
        if(error?.response){
            const err_message = (error?.response?.data?.message);
            if(401 === error.response.status){
            //auth error
            }
            else if(422 === error.response.status){
            notification.error({
                message : err_message,
                duration : 5,
            })
            return Promise.reject(error);
            }
            else if(500 === error.response.status){
                console.log('in index 500')
                notification.error({
                    message : "Invalid Input! Record will not be saved",
                    duration : 8,
                })
                // return Promise.reject(error);
                }
            else{
            // Do something with response error
            // 500
            if(!(error?.request?.responseURL?.includes('fc/recognize')) && !(error?.response?.data?.message === 'Faces not found')){
                notification.error({
                    message : err_message,
                    duration : 5,
                })
            }
            
            return Promise.reject(error);
            }
        }else{
            // Do something with response error
            notification.error({
                message : "Something went wrong, please contact our support",
                duration : 5,
            })
            return Promise.reject(error);
        }
    });
    return (
        <>
            <MasterLayout>
                {children}
            </MasterLayout>
        </>
    );
}

export default LayoutIndex;