import React from 'react';
import ContainerComponent from '../../helpers/components/container.component';
import BomEditLayout from './bom/bom_edit_layout';
import { useParams } from 'react-router-dom';
import './production.css';

const BomEdit = () => {
    const { bom_id } = useParams();
    return (
        <>
            <ContainerComponent span={22} top={20} bottom={20}>
                <BomEditLayout bom_id={bom_id} />
            </ContainerComponent>
        </>
    );
}

export default BomEdit;