import React, { useEffect, useState } from 'react';
import warehouseService from '../../../services/config/warehouse.service';
import { Col, Divider, Form, Input, Radio, Row, Skeleton, Space, Switch } from 'antd';

const { TextArea } = Input;
const WarehouseEditDetail = ({ warehouse_id, form }) => {
    const [state, setState] = useState({
        loading : false,
        warehouse : null,
    })

    const fetchWarehouses = async () => {
        setState(state => ({...state, loading : true,}));
        const warehouseResponse = await warehouseService?.getWarehouses({
            warehouse_id : warehouse_id,
        });

        const warehouses = warehouseResponse?.warehouses;
        const warehouse = warehouses?.length > 0 ? warehouses[0] : null;

        setTimeout(() => {
            //set form
            const { name, code, contact_no, address, is_hq } = warehouse;
            form.setFieldsValue({ name, code, contact_no, address, type : is_hq, });

            setState(state => ({...state, loading : false, warehouse : warehouse}));
        }, 500);
        
    }

    useEffect(() => {
        warehouse_id && fetchWarehouses();
    }, [warehouse_id]);

    return (
        <>
            {
                state?.loading ?
                (
                    <>
                        <div>
                            <Skeleton active />
                        </div>
                    </>
                )
                :
                (
                    <>
                        <div>
                            <div style={{background : '#edf2f7', border:'1.5px solid #cbd5e0', padding : '12px 12px 0 12px', borderRadius:8,}}>
                                <Row gutter={[12, 12]}>
                                    <Col xs={24} md={12}>
                                        <Form.Item name={'code'} label={'Warehouse Code'}>
                                            <Input placeholder='Warehouse Code' />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} md={12}>
                                        <Form.Item name={'name'} label={'Warehouse'}>
                                            <Input placeholder='Warehouse Name' />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </div>
                            <Divider style={{marginBottom:12,}} />

                            <div>
                                <div>
                                    <span style={{fontWeight:500, fontSize:16,}}>Warehouse Detail</span>
                                </div>

                                <div style={{marginTop:20,}}>
                                    <div>
                                        <Form.Item name={'type'} label={'Warehouse Type'}>
                                            <Radio.Group defaultValue={false}>
                                                <Space direction='vertical'>
                                                    <div>
                                                        <Radio value={true}>HQ</Radio>
                                                    </div>
                                                    <div>
                                                        <Radio defaultChecked value={false}>Warehouse</Radio>
                                                    </div>
                                                </Space>
                                                
                                            </Radio.Group>
                                            
                                        </Form.Item>
                                    </div>
                                </div>
                            </div>

                            <Divider />

                            <div>
                                <div>
                                    <span style={{fontWeight:500, fontSize:16,}}>Contact and Address Info</span>
                                </div>

                                <div style={{marginTop:20,}}>
                                    <div>
                                        <Form.Item name={'contact_no'} label={'Contact No'}>
                                            <Input placeholder='0123456789' />
                                        </Form.Item>
                                    </div>

                                    <div>
                                        <Form.Item name={'address'} label={'Address'}>
                                            <TextArea placeholder='Jalan Sultan Azlan Shah' rows={4} style={{resize:'none'}} />
                                        </Form.Item>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </>
                )
            }
        </>
    );
}

export default WarehouseEditDetail;