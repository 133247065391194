import './App.less';
import { BrowserRouter as Router } from 'react-router-dom';
import AuthRouter from './routes/auth.router';
import { ConfigProvider } from 'antd';
import Auth from './Auth';

function App() {
  return (
    <ConfigProvider
    theme={{
      token : {
        colorPrimary : '#0555e1',
      }
    }}
    >
      <div className="App">
        <Router>
          <Auth />
          {/* <AuthRouter /> */}
        </Router>
      </div>
    </ConfigProvider>
  );
}

export default App;
