import { ThunderboltFilled } from '@ant-design/icons';
import React from 'react';

const StepCircle = () => {
    return (
        <>
            <div className='step-circle'>
                <div style={{ background: '#fff', height:30, width:30,  borderRadius:'50%', display:'flex', margin:'auto', justifyContent:'center'}}>
                    <div style={{ background: '#cbd5e0', height:25, width:25,  borderRadius:'50%', display:'flex', margin:'auto', justifyContent:'center'}}>
                        <ThunderboltFilled style={{color:'#fff'}} />
                    </div>
                </div>
            </div>
        </>
    );
}

export default StepCircle;