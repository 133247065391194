import axios from 'axios';
import serviceHelper from '../../helpers/functions/service.helper';

async function getWarehouses(query){
    const queryString = serviceHelper.queryString(query);
    const warehouseResponse = await axios.get(`config/warehouse/get?${queryString}`, { withCredentials : true, });
    return warehouseResponse?.data;
}

async function saveWarehouse(params){
    const saveWarehouseResponse = await axios.post(`config/warehouse/save`, params, { withCredentials : true, });
    return saveWarehouseResponse?.data;
}

async function updateWarehouse(warehouse_id, params){
    const updateWarehouseResponse = await axios.post(`config/warehouse/${warehouse_id}/update`, params, { withCredentials : true, });
    return updateWarehouseResponse?.data;
}

export default {
    getWarehouses,
    saveWarehouse,
    updateWarehouse,
}