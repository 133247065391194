import React from 'react';
import ContainerComponent from '../../helpers/components/container.component';
import ConfigMaterialLayout from './material/config_material_layout';

const ConfigMaterialIndex = () => {
    return (
        <>
            <ContainerComponent span={22} top={20}>
                <ConfigMaterialLayout />
            </ContainerComponent>
        </>
    );
}

export default ConfigMaterialIndex;