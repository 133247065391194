import axios from 'axios';
import serviceHelper from '../../helpers/functions/service.helper';

async function getLocationMaterialLogsByLocation(query){
    const queryString = serviceHelper.queryString(query);
    const locationMaterialLogsResponse = await axios.get(`production/location_material_log/getlogsbylocation/get?${queryString}`, { withCredentials : true, });
    return locationMaterialLogsResponse?.data;
}

async function getLocationMaterialLogsByMaterial(query){
    const queryString = serviceHelper.queryString(query);
    const locationMaterialLogsResponse = await axios.get(`production/location_material_log/getlogsbymaterial/get?${queryString}`, { withCredentials : true, });
    return locationMaterialLogsResponse?.data;
}

async function getLocationMaterialLogs(query){
    const queryString = serviceHelper.queryString(query);
    const locationMaterialLogsResponse = await axios.get(`production/location_material_log/getlocationmateriallogs/get?${queryString}`, { withCredentials : true, });
    return locationMaterialLogsResponse?.data;
}

export default {
    getLocationMaterialLogs,
    getLocationMaterialLogsByLocation,
    getLocationMaterialLogsByMaterial,
}