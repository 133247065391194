import React from 'react';
import ContainerComponent from '../../helpers/components/container.component';
import ConfigurationLayout from './components/configuration_layout';

const ConfigurationIndex = () => {
    return (
        <>
            <ContainerComponent span={22} top={20}>
                <ConfigurationLayout />
            </ContainerComponent>
        </>
    );
}

export default ConfigurationIndex;