import { Button, Drawer, Form, Modal, message, Space, Spin, Table, Tag, notification } from 'antd';
import React, { useEffect, useState } from 'react';
import materialService from '../../../services/config/material.service';
import MaterialEditDetail from './material_edit_detail';
import { useDispatch, useSelector } from 'react-redux';
import { refresh_material_list } from '../../../actions/config.action';
import { DeleteOutlined } from '@ant-design/icons';

const MaterialList = ({ filter, filter_changed }) => {
    const [messageApi, contextHolder] = message.useMessage();
    const { innerWidth } = window;
    const dispatch = useDispatch();
    const configRedux = useSelector(state => state.config);
    const [form] = Form.useForm();
    const [state, setState] = useState({
        loading : false,
        save_loading : false,
        materials : [],
        used_material_id: [],
        drawer_material_id : null,
        count : 0,
        page : 0,

        refresh : 0,
    });

    const getMaterials = async () => {
        setState(state => ({...state, loading : true,}));
        const { search, is_end_product, category, uom } = filter;
            
        const materialResponse = await materialService?.getMaterials({
            search,
            ...(is_end_product != null && ({is_end_product : (is_end_product ? 1 : 0),})),
            material_category_ids : category,
            uom_ids : uom,

            //pagination
            page : state?.page,
            
        });

        const { count, rows : materials } = materialResponse?.materials;
        const material_ids_arr = materials.map(x => x.material_id)
        const usedMaterialResponse = await materialService?.getUsedMaterialList({ material_ids_arr: material_ids_arr });
        const used_list = usedMaterialResponse?.dataList;

        setTimeout(() => {
            setState(state => ({ ...state, loading: false, materials: materials, count: count, used_material_id: used_list }));
        }, 500);
        
    }

    const openDrawer = (material_id) => {
        setState(state => ({...state, drawer_material_id : material_id}))
    }

    const saveMaterials = async (material_id) => {
        setState(state => ({...state, save_loading : true,}));
        const { description, material_category_id, material_code, name, material_type_id, uom_id, } = form.getFieldsValue();

        const updateMaterialResponse = await materialService?.updateMaterial(material_id, {
            description, material_category_id, material_code, name, material_type_id, uom_id,
        });

        //successful
        setTimeout(() => {
            dispatch(refresh_material_list());
            setState(state => ({...state, save_loading : false, }));

            if(updateMaterialResponse)
                {
                    setState(state => ({...state, drawer_material_id : null, }));
                    form.resetFields();
                    notification?.success({
                    message : "Success",
                    description : `Successfully saved item ${name}`
                })}
        }, 500);

    }

    const deleteConfirm = async (material_id) => {
        const material = state?.materials?.find(x => x.material_id === material_id);
        const { name, } = material;
        Modal.confirm({
            title : 'Confirm Delete This Record?',
            content : (
                <>
                    <div>
                        <div>
                            <span>{name}</span>
                        </div>
                    </div>
                </>
            ),
            onOk : () => {
                return new Promise(async (resolve, reject) => {
                    await onDeleteMaterial(material_id).then((result) => {
                        setTimeout(() => {
                            // notification
                            notification?.success({
                                message : 'Successfully Deleted',
                                description : (
                                    <>
                                        <div>
                                            <div>
                                                <span>{name}</span>
                                            </div>
                                        </div>
                                    </>
                                )
                            })
                            setState(state => ({...state, refresh : (state?.refresh + 1)}));
                            resolve();
                        }, 500);
                    });
                    
                }).catch(() => console.log('Oops errors!'));
            }
        })
    }

    const onDeleteMaterial = async (material_id) => {
        const deleteMaterialResponse = await materialService?.deleteMaterials(material_id);
        return deleteMaterialResponse;
    }

    const filterchangeDefaultPage = () => {
        if (filter_changed) {
            setState(state => ({ ...state, page: 0, filter_changed : false}));
        }
    };

    useEffect(() => {
        filterchangeDefaultPage();
    }, [filter,])

    useEffect(() => {
        getMaterials();
    }, [filter, state?.page, configRedux?.material_list_refresh, state?.refresh])

    return (
        <>
            <Spin spinning={state?.loading}>
                <Table
                pagination={{ 
                    size : 10,
                    showSizeChanger: false,
                    total : state?.count,
                    current : (state?.page + 1),
                    onChange : (page) => {
                        setState(state => ({...state, page : page - 1}));
                        // dispatch(set_list_page(page - 1));
                    }
                }}
                scroll={{ x : 400, }}
                className='hub-table bordered'
                dataSource={state?.materials}
                columns={[{
                    title : 'Item Details',
                    dataIndex : 'name',
                    key : 'name',
                    width : '25%',
                    render : (text, row, index) => {
                        const name = text;
                        const { material_code } = row;
                        return (
                            <>
                                <div>
                                    <div>
                                        <span style={{fontWeight:500}}>{name}</span>
                                    </div>
                                    <div>
                                        <span style={{color:'var(--secondary-text-color)', fontWeight:500, fontSize:12,}}>{material_code}</span>
                                    </div>
                                </div>
                            </>
                        )
                    }
                },{
                    title : 'Type',
                    dataIndex : 'material_type',
                    key : 'material_type',
                    render : (text, row, index) => {
                        const { name } = text ?? {};
                        return (
                            <>
                                <div>
                                    <div>
                                        <Tag style={{border:'none'}}>
                                            <span style={{color:'var(--main-color)', fontWeight:500,}}>{name}</span>
                                        </Tag>
                                    </div>
                                </div>
                            </>
                        )
                    }
                },{
                    title : 'Category',
                    dataIndex : 'material_category',
                    key : 'material_category',
                    render : (text, row, index) => {
                        const { name } = text ?? {};
                        return (
                            <>
                                <div>
                                    <span>{name}</span>
                                </div>
                            </>
                        )
                    }
                },{
                    title : 'UOM',
                    dataIndex : 'uom',
                    key : 'uom',
                    render : (text, row, index) => {
                        const { name, code } = text ?? {};
                        return (
                            <>
                                <div>
                                    <span style={{color:'var(--secondary-text-color)'}}>{ text && (`${code} (${name})`)}</span>
                                </div>
                            </>
                        )
                    }
                },{
                    title : 'Action',
                    dataIndex : 'material_id',
                    key : 'material_id',
                    width : '10%',
                    render : (text, row, index) => {
                        const material_id = text;
                        return (
                            <Button.Group>
                                <Button
                                onClick={() => openDrawer(material_id)}
                                >
                                    View
                                </Button>
                                {
                                    state?.used_material_id.some(x => x.material_id === material_id) ?
                                        <Button type="primary" disabled />
                                        :
                                        <Button icon={<DeleteOutlined style={{ color: 'var(--secondary-text-color)' }} onClick={async () => deleteConfirm(material_id)} />} />
                                }
                            </Button.Group>
                            
                        )
                    }
                },]}
                />
            </Spin>

            {/* detail */}
            <Drawer
            title="Item Detail"
            rootClassName='drawer-detail-root'
            className='drawer-detail'
            open={state?.drawer_material_id}
            onClose={() => openDrawer(null)}
            width={innerWidth >= 768 ? 800 : "100%"}
            footer={
                <>
                    <div style={{display:'flex', padding:'12px 0',}}>
                        <div style={{marginLeft:'auto',}}>
                            <Space>
                                <Button loading={state?.save_loading} type='primary' onClick={() => form.submit()}>Save Changes</Button>
                            </Space>
                        </div>
                    </div>
                </>
            }
            >
                <div>
                    <Form
                    onFinish={() => saveMaterials(state?.drawer_material_id)}
                    form={form}
                    layout='vertical'
                    >
                        <MaterialEditDetail material_id={state?.drawer_material_id} form={form} />
                    </Form>
                </div>
            </Drawer>
        </>
    );
}

export default MaterialList;