import axios from 'axios';
import serviceHelper from '../../helpers/functions/service.helper';

async function auth(){
    const authResponse = await axios.get(`admin/user/auth`, { withCredentials : true, });
    return authResponse?.data;
}

async function login(params,){
    const loginResponse = await axios.post(`admin/user/login`, params, { withCredentials : true });
    return loginResponse?.data;
}

async function logout(params,){
    const logoutResponse = await axios.post(`admin/user/logout`, params, { withCredentials : true });
    return logoutResponse?.data;
}

async function changePassword(params,){
    const changePasswordResponse = await axios.post(`admin/user/change_password`, params, { withCredentials : true });
    return changePasswordResponse?.data;
}

export default {
    login,
    logout,
    auth,
    changePassword,
}