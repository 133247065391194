import React, { useEffect, useState } from 'react';
import outboundService from '../../../services/warehouse/outbound.service';
import { Tag, Timeline } from 'antd';
import moment from 'moment';

const OutboundHistory = ({ outbound_id, inoutbound_status_id, }) => {
    const [state, setState] = useState({
        loading : false,
        outbound_status_histories : [],
    })

    const fetchOutboundHistory = async () => {
        setState(state => ({...state, loading : true, }));
        const outboundHistoryResponse = await outboundService?.getOutBoundStatusHistory({
            outbound_id,
        })

        const { history } = outboundHistoryResponse;
        const mapped_outbound_status_histories = history?.map((history, index) => {
            const { outbound, inoutbound_status, outbound_status_created_by, created_at } = history;
            return {
                color : inoutbound_status?.color,
                children : (
                    <>
                        <div>
                            <Tag color={inoutbound_status?.color} style={{border : 'none', }}>
                                {inoutbound_status?.name}
                            </Tag>
                        </div>
                        <div>
                            <span style={{fontSize:12, color : 'var(--secondary-text-color)'}}>Updated by {outbound_status_created_by?.name} at {moment(created_at)?.format("YYYY-MM-DD hh:mm:ssa")}</span>
                        </div>
                    </>
                ),
                date : created_at,
            }
        })
        mapped_outbound_status_histories.sort((a, b) => new Date(b?.date) - new Date(a?.date)); // sort date
        setState(state => ({...state, loading : false, outbound_status_histories : mapped_outbound_status_histories }));
    }

    useEffect(() => {
        if(outbound_id){
            fetchOutboundHistory();
        }
        
    }, [outbound_id, inoutbound_status_id])

    return (
        <>
            <div style={{textAlign:'start', padding : 24,}}>
                <Timeline
                items={state?.outbound_status_histories}
            />
            </div>
        </>
    );
}

export default OutboundHistory;