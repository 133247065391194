// CustomPopconfirm.js

import React, { useState } from 'react';
import { Badge, Card, Space, Popconfirm, Input, Button } from 'antd';

const WorkOrderStatusCancellation = ({ visible, onConfirm, onCancel }) => {
    const [state, setState] = useState({
        // visible: false,
        inputValue: '',
    })

    const [field, setField] = useState({
        inputValue: '',
    })

    const onFieldChange = async (key, value) => {
        // debugger;
        setField(field => ({ ...field, [key]: value }));
        setState(state => ({ ...state, inputValue: value, }));
    }

    const handleConfirm = () => {
        setState(state => ({ ...state, visible: false, }));
        const { inputValue } = field;
        onConfirm(inputValue);
        setField(field => ({ ...field, inputValue: '' }));
    };

    const content = (
        <div>
            <div>
                <span>Reason to Cancel: </span>
            </div>
            <Input
                onChange={(e) => onFieldChange('inputValue', e.target.value)}
                // defaultValue={state?.work_order?.cancel_reason}
                placeholder={"Enter Reason"}
                style={{ marginBottom: 10, marginTop: 10 }}
            />
          
            {/* <Button
                type="primary"
                onClick={handleConfirm}
                disabled={!state?.inputValue}
            >
                Confirm
            </Button>

            <Button
                // type="primary"
                onClick={() => state => ({ ...state, visible: false, })}
                disabled={!state?.inputValue}
                style={{ marginLeft: 10, }} 
            >
                Cancel
            </Button> */}
        </div>
    );

    return (
        <Popconfirm
            title={content}
            // title={
            //     <div></div>
            //     <Input
            //         // value={state?.inputValue}
            //         onChange={(e) => onFieldChange('inputValue', e.target.value)}
            //         // onChange={handleInputChange}
            //         onPressEnter={handleConfirm}
            //         placeholder="Enter something"
            //         style={{ width: 200 }}
            //     />
            // }
            // title="What is the reason for cancellation?"
            icon={null}
            // visible={state?.visible}
            visible={visible}
            // onVisibleChange={(vis) => state => ({ ...state, visible: vis, })}
            onVisibleChange={onCancel}
            // placement="topLeft" // Adjust placement as needed
            placement="top"
            okText="Confirm"
            cancelText="Cancel"
            onCancel={() => state => ({ ...state, visible: false, })}
            onConfirm={handleConfirm}
            // okButtonProps={{ disabled: !state?.inputValue }}
        >
            {/* <Button type="primary">Open Popconfirm</Button> */}
            <div style={{ padding: '4px 12px', cursor: 'pointer' }}>
                <Card
                    className={`selection-card`}
                    style={{ border: 'none' }}
                    bodyStyle={{ padding: 12, }}
                >
                    <div>
                        <Space>
                            <div>
                                {/* <Badge color={new_status?.color} /> */}
                                <Badge color={"red"} />
                            </div>
                            <div>
                                {/* <span>{new_status?.name}</span> */}
                                <span>{"Cancel"}</span>
                            </div>
                        </Space>
                    </div>

                </Card>
            </div>
        </Popconfirm>
    );
};

export default WorkOrderStatusCancellation;
