import React from 'react';
import ContainerComponent from '../../helpers/components/container.component';
import WarehouseLayout from './components/warehouse_layout';

const WarehouseIndex = () => {
    return (
        <>
            <ContainerComponent span={22} top={20}>
                <WarehouseLayout />
            </ContainerComponent>
        </>
    );
}

export default WarehouseIndex;