import React, { useEffect, useState } from 'react';
import MenuTitleComponent from '../../../helpers/components/menu_title.component';
import { SearchOutlined } from '@ant-design/icons';
import { Button, Drawer, Form, Input, Space, notification } from 'antd';
import FilterBullet from '../../../helpers/components/filter_bullet';
import MaterialCategoryList from './material_category_list';
import MaterialCategoryEditDetail from './material_category_edit_detail';
import materialCategoryService from '../../../services/config/material_category.service';
import { refresh_material_category_list } from '../../../actions/config.action';
import { useDispatch } from 'react-redux';

const ConfigMaterialCategoryLayout = () => {
    const { innerWidth } = window;
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const [timer, setTimer] = useState(null);
    const [state, setState]  = useState({
        open_drawer : false,
        save_loading : false,
    });

    const [filter, setFilter] = useState({
        search : null,
    })

    const doneTyping = async (input) => {
        clearTimeout(timer);
        const newTimer = setTimeout(() => {
            setFilter(filter => ({ ...filter, search: input, }));
        }, 300);
        setTimer(newTimer);
    }

    const saveMaterialCategory = async () => {
        setState(state => ({...state, save_loading : true, }));
        const { name, } = form.getFieldsValue();
        const saveMaterialCategoryResponse = await materialCategoryService.saveMaterialCategory({ name, });

        setTimeout(() => {
            dispatch(refresh_material_category_list());
            setState(state => ({...state, save_loading : false, }));

            if(saveMaterialCategory)
                {
                    setState(state => ({...state, open_drawer : null, }));
                    form.resetFields();
                    notification?.success({
                    message : "Success",
                    description : `Successfully saved item ${name}`
                })}
        }, 500);
    }

    return (
        <>
            <div>
                <div style={{textAlign:'start',}}>
                    <MenuTitleComponent />
                </div>

                <div className='filter-panel' style={{marginBottom:12,}}>
                    <div>
                        <Input
                        onChange={(e) => doneTyping(e.target.value)}
                        name="search"
                        prefix={<SearchOutlined style={{color :'var(--secondary-text-color)'}} />}
                        placeholder='Search Item Category'
                        style={{minWidth: '100%',}}
                        allowClear
                        />
                    </div>

                    <div style={{display:'flex', alignItems:'center', marginTop:12, gap:8,}}>
                        <div>
                            <div className='filter-panel' style={{textAlign:'start'}}>
                                <Space direction={innerWidth > 768 ? 'horizontal' : 'vertical'}>
                                </Space>
                            </div>
                        </div>

                        <div style={{marginLeft:'auto'}}>
                            <Button onClick={() => setState(state => ({...state, open_drawer : true,}))} type='primary'>New Item Category</Button>
                        </div>
                    </div>
                </div>


                <div>
                    <MaterialCategoryList filter={filter} />
                </div>
            </div>

            {/* detail */}
            <Drawer
            title="Item Category Detail"
            rootClassName='drawer-detail-root'
            className='drawer-detail'
            open={state?.open_drawer}
            onClose={() => setState(state => ({...state, open_drawer : false, }))}
            width={innerWidth >= 768 ? 800 : "100%"}
            footer={
                <>
                    <div style={{display:'flex', padding:'12px 0',}}>
                        <div style={{marginLeft:'auto',}}>
                            <Space>
                                <Button loading={state?.save_loading} type='primary' onClick={() => form.submit()}>Save Changes</Button>
                            </Space>
                        </div>
                    </div>
                </>
            }
            >
                <div>
                    <Form
                    onFinish={saveMaterialCategory}
                    form={form}
                    layout='vertical'
                    >
                        <MaterialCategoryEditDetail form={form} />
                    </Form>
                </div>
            </Drawer>
        </>
    );
}

export default ConfigMaterialCategoryLayout;