import React from 'react';
import ContainerComponent from '../../helpers/components/container.component';
import ConfigWarehouseLayout from './warehouse/config_warehouse_layout';

const ConfigWarehouseIndex = () => {
    return (
        <>
            <ContainerComponent span={22} top={20}>
                <ConfigWarehouseLayout />
            </ContainerComponent>
        </>
    );
}

export default ConfigWarehouseIndex;