import React, { useEffect, useState } from 'react';
import bomService from '../../../services/production/bom.service';
import { Anchor, Badge, Button, Card, Col, Dropdown, Form, Popover, Row, Skeleton, Space, Spin, notification } from 'antd';
import PageHeaderComponent from '../../../helpers/components/page_header.component';
import BomEditBasic from './bom_edit_basic';
import BomEditMaterial from './bom_edit_material';
import EntranceMotion from '../../../helpers/components/entrance_motion';
import { CaretDownFilled, DownOutlined } from '@ant-design/icons';
import otherService from '../../../services/config/other.service';

const BomEditLayout = ({ bom_id }) => {
    const [form] = Form.useForm();
    const [state, setState] = useState({
        loading : false,
        bom : null,

        save_loading : false,
        update_status_loading : false,
        refresh : 0,
        open_popover : false,

        //selections
        edit_statuses : [],
    });

    const fetchBom = async () => {
        setState(state => ({...state, loading : true, }));
        const bomResponse = await bomService?.getBoms({
            bom_id,
        });
        const { rows : boms } = bomResponse?.boms;
        const bom = boms?.length > 0 ? boms[0] : null;

        //set form
        setTimeout(() => {
            const { name, material_id, quantity } = bom;
            form.setFieldsValue({ name, material_id, quantity, })
            setState(state => ({...state, loading : false, bom : bom, }));
        }, 500);
       
    }

    const saveBom = async () => {
        setState(state => ({...state, save_loading : true,}));
        const { name, material_id, quantity, bom_materials } = (form.getFieldsValue());
        const updateBomResponse = await bomService?.updateBom(bom_id, {
            name, material_id, quantity, bom_materials,
        }).catch(err => {
            setState(state => ({...state, save_loading : false,}));
        });

        setTimeout(() => {
            // setState(state => ({...state, save_loading : false, refresh : (state?.refresh + 1)}));
            // notification?.success({
            //     message : "Success",
            //     description : `Successfully saved BOM ${name}`
            // })
            setState(state => ({...state, save_loading : false, refresh : (state?.refresh + 1)}));

            if(updateBomResponse)
                {
                    notification?.success({
                    message : "Success",
                    description : `Successfully saved item ${name}`
                })}
        }, 200);
    }

    const updateBomStatus = async (edit_status_id) => {
        setState(state => ({...state, update_status_loading : true,}))
        const { bom_id } = state?.bom;
        const updateBomResponse = await bomService?.updateBom(bom_id, { edit_status_id, });

        // setState(state => ({...state, update_status_loading : false, refresh : (state?.refresh + 1), open_popover : false,}));
        // notification?.success({
        //     message : "Success",
        //     description : `Successfully updated BOM status`
        // })
        setState(state => ({...state, update_status_loading : false, refresh : (state?.refresh + 1), }));

            if(updateBomStatus)
                {
                    setState(state => ({...state, open_popover : false, }));
                    form.resetFields();
                    notification?.success({
                    message : "Success",
                    description : `Successfully saved BOM status`
                })}
        
    }

    const fetchInitialization = async () => {
        const editStatusResponse = await otherService?.getEditStatuses();
        setState(state => ({...state, edit_statuses : editStatusResponse?.edit_statuses,}));
    }

    useEffect(() => {
        bom_id && fetchBom();
    }, [bom_id, state?.refresh]);

    useEffect(() => {
        fetchInitialization();
    }, [])

    return (
        <>
            <div>
                <Spin spinning={state?.loading}>
                    <Form
                    onFinish={saveBom}
                    layout='vertical'
                    form={form}
                    >
                        <div>
                            <div style={{display:'flex', alignContent : 'center',}}>
                                <div style={{textAlign:'start',}}>
                                    <PageHeaderComponent isBack title={'Edit'} />
                                </div>
                                <div style={{marginLeft:'auto',}}>
                                    <Space>
                                        <div>
                                            <Form.Item>
                                                <Popover
                                                open={state?.open_popover}
                                                content={
                                                    <>
                                                        <div style={{padding:'12px 0'}}>
                                                        {
                                                            state?.edit_statuses?.map((edit_status, index) => {
                                                                const { edit_status_id, color, name } = edit_status;
                                                                const is_selected = state?.bom?.edit_status_id == edit_status_id;
                                                                return (
                                                                    <>
                                                                        <div onClick={() => updateBomStatus(edit_status_id)} style={{padding : '4px 12px', cursor : 'pointer' }}>
                                                                            <Card
                                                                            className={`selection-card ${is_selected ? "selected" : ""}`}
                                                                            style={{border:'none'}}
                                                                            bodyStyle={{padding:12,}}
                                                                            >
                                                                                <div>
                                                                                    <Space>
                                                                                        <div>
                                                                                            <Badge color={color}/>
                                                                                        </div>
                                                                                        <div>
                                                                                            <span>{name}</span>
                                                                                        </div>
                                                                                    </Space>
                                                                                </div>
                                                                                
                                                                            </Card>
                                                                        </div>
                                                                    </>
                                                                )
                                                            })
                                                        }
                                                        </div>
                                                    </>
                                                }
                                                trigger={['click']}
                                                placement='bottomRight'
                                                overlayInnerStyle={{padding : 0, minWidth: 260,}}
                                                >
                                                    <Button
                                                    loading={state?.update_status_loading}
                                                    style={{minWidth:100}} 
                                                    icon={<CaretDownFilled style={{color:'var(--secondary-text-color)'}} />}
                                                    onClick={() => setState(state => ({...state, open_popover : !state?.open_popover,}))}
                                                    >
                                                        <Space style={{marginLeft:12}}>
                                                            <Badge color={state?.bom?.edit_status?.color} />
                                                            <span>{(state?.bom?.edit_status?.name)}</span>
                                                        </Space>
                                                    </Button>
                                                </Popover>
                                                {/* </Dropdown> */}
                                            </Form.Item>
                                        </div>

                                        <div>
                                            <Form.Item>
                                                <Button loading={state?.save_loading} type='primary' htmlType='submit'>Save BOM</Button>
                                            </Form.Item>
                                        </div>
                                    </Space>
                                </div>
                            </div>
                            
                        </div>
                        
                        <div>
                            <div style={{marginTop:12, background:'#fff'}}>
                                <Row>
                                    <Col span={6}>
                                        <div>
                                            <Anchor
                                            offsetTop={20}
                                            style={{textAlign:'start',}}
                                            items={[
                                            {
                                                key: 'basic_detail',
                                                href: '#basic_detail',
                                                title: 'Basic Details',
                                            },
                                            {
                                                key: 'materials',
                                                href: '#materials',
                                                title: 'Bill of Materials',
                                            },
                                            ]}
                                            />
                                        </div>
                                    </Col>

                                    <Col span={18}>
                                        <div>
                                            
                                                <Space size={20} direction='vertical' style={{width:'100%'}}>
                                                    <div id="basic_detail" >
                                                        <EntranceMotion>
                                                            <Card
                                                            style={{border:'none', textAlign:'start',}}
                                                            className='card-shadow'
                                                            >
                                                                <div>
                                                                    <span
                                                                    className='header-font' 
                                                                    style={{
                                                                        fontWeight:500, 
                                                                        fontSize:16,
                                                                    }}>
                                                                        {`Basic Detail`}
                                                                    </span>
                                                                </div>
                                                                <BomEditBasic bom={state?.bom} form={form} />
                                                            </Card>
                                                        </EntranceMotion>
                                                    </div>
                                                    <div id="materials">
                                                        <EntranceMotion delay={0.1}>
                                                            <Card
                                                            style={{border:'none', textAlign:'start',}}
                                                            className='card-shadow'
                                                            >
                                                                <BomEditMaterial bom={state?.bom} form={form} refresh={state?.refresh} />
                                                            </Card>
                                                        </EntranceMotion>
                                                    </div>
                                                </Space>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </Form>
                </Spin>
            </div>
        </>
    );
}

export default BomEditLayout;