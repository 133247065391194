import React from 'react';
import ContainerComponent from '../../helpers/components/container.component';
import ConfigUserRoleLayout from './user_role/config_user_role_layout';

const ConfigUserRoleIndex = () => {
    return (
        <>
            <ContainerComponent span={22} top={20}>
                <ConfigUserRoleLayout />
            </ContainerComponent>
        </>
    );
}

export default ConfigUserRoleIndex;