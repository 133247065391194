import React from 'react';
import ContainerComponent from '../../helpers/components/container.component';
import InOutboundSourceLayout from './inoutbound_source/inoutbound_source_layout';

const InOutboundSourceIndex = () => {
    return (
        <>
            <ContainerComponent span={22} top={20}>
                <InOutboundSourceLayout />
            </ContainerComponent>
        </>
    );
}

export default InOutboundSourceIndex;