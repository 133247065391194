import axios from 'axios';
import serviceHelper from '../../helpers/functions/service.helper';

async function getLocationsList(query){
    const queryString = serviceHelper.queryString(query);
    const locationResponse = await axios.get(`config/location/get/list`, { withCredentials : true, });
    return locationResponse?.data;
}

async function getLocations(query){
    const queryString = serviceHelper.queryString(query);
    const locationResponse = await axios.get(`config/location/get?${queryString}`, { withCredentials : true, });
    return locationResponse?.data;
}

async function getUsedLocationList(query) {
    const queryString = serviceHelper.queryString(query);
    const locationResponse = await axios.get(`config/location/getuse/list`, { withCredentials: true, });
    return locationResponse?.data;
}

async function saveLocation(params){
    const saveLocationResponse = await axios.post(`config/location/save`, params, { withCredentials : true, });
    return saveLocationResponse?.data;
}

async function updateLocation(location_id, params){
    const updateLocationResponse = await axios.post(`config/location/${location_id}/update`, params, { withCredentials : true, });
    return updateLocationResponse?.data;
}

async function deleteLocation(location_id){
    const deleteLocationResponse = await axios.delete(`config/location/${location_id}/delete`, { withCredentials : true, });
    return deleteLocationResponse?.data;
}

export default {
    getLocationsList,
    getLocations,
    getUsedLocationList,
    saveLocation,
    updateLocation,
    deleteLocation,
}