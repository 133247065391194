import React, { useEffect, useState } from 'react';
import MenuTitleComponent from '../../../helpers/components/menu_title.component';
import { Button, Checkbox, Input, Space, Form, notification, List, Radio } from 'antd';
import { CaretDownFilled, SearchOutlined, CloseCircleFilled } from '@ant-design/icons';
import FilterBullet from '../../../helpers/components/filter_bullet';
import { useDispatch } from 'react-redux';
import locationMaterialService from '../../../services/warehouse/location_material.service';
import warehouseService from '../../../services/config/warehouse.service';
import ReportList from './report_list';
import moment from 'moment';
import GeneralLabel from '../inbound/general_label';
import PageHeaderComponent from '../../../helpers/components/page_header.component';


const ReportLayout = () => {
    const { innerWidth } = window;
    const [timer, setTimer] = useState(null);
    const [state, setState] = useState({
        open_drawer : false,

        //selections
        locations : [],
        materials : [],
        warehouses: [],
    });
    const [filter, setFilter] = useState({
        search : null,
        location : null,
        material : null,
        warehouse: null,
    });

    const doneTyping = async (input) => {
        clearTimeout(timer);
        const newTimer = setTimeout(() => {
            setFilter(filter => ({ ...filter, search: input, }));
        }, 300);
        setTimer(newTimer);
    }

    const onCheckChange = (key, checkedValues) => {
        setFilter(filter => ({...filter, [key] : checkedValues}));
    }

    const fetchInitialization = async () => {
        let locationMaterialResponse = await locationMaterialService?.getLocationMaterialByMaterial({});
        const { dataList:locations }  = locationMaterialResponse;
        locationMaterialResponse = await locationMaterialService?.getLocationMaterialByLocation({});
        const { dataList:materials }  = locationMaterialResponse;
        const warehouseResponse = await warehouseService?.getWarehouses({});
        const { warehouses } = warehouseResponse;

        setState(state => ({ ...state, locations: locations, materials: materials, warehouses: warehouses, }))
    }

    useEffect(() => {
        fetchInitialization();
    }, [])

    return (
        <>
            <div>
                <div style={{textAlign:'start',}}>
                    {/* <MenuTitleComponent /> */}
                    <div style={{marginBottom:20,}}>
                        <div>
                            <PageHeaderComponent title='Location' />
                        </div>
                        <div>
                            <span style={{color:'var(--secondary-text-color)'}}>{state?.menu?.description}</span>
                        </div>
                    </div>
                </div>

                <div>
                    <div className='filter-panel' style={{marginBottom:12,}}>
                        <div>
                            <Input
                            onChange={(e) => doneTyping(e.target.value)}
                            name="search"
                            prefix={<SearchOutlined style={{color :'var(--secondary-text-color)'}} />}
                            placeholder='Search Report'
                            style={{minWidth: '100%',}}
                            allowClear
                            />
                        </div>
                    </div>

                    <div style={{display:'flex', alignItems:'center', marginTop:12, gap:8,}}>
                            <div>
                                <div className='filter-panel' style={{textAlign:'start'}}>
                                    <Space direction={innerWidth > 768 ? 'horizontal' : 'vertical'}>
                                    <FilterBullet
                                        popover={
                                            <>
                                                <div style={{padding:12,}}>
                                                    <Input
                                                    prefix={<SearchOutlined style={{color :'var(--secondary-text-color)'}}/>}
                                                    placeholder='Search Item'
                                                    allowClear
                                                    onChange={async (e) => {
                                                        const locationMaterialResponse = await locationMaterialService?.getLocationMaterialByLocation({ search: e.target.value, size:100 });
                                                        setState(state => ({ ...state, materials: locationMaterialResponse?.dataList }))
                                                    }}
                                                    />
                                                </div>
                                                <Checkbox.Group
                                                value={filter?.material}
                                                onChange={(checkedValues) => onCheckChange('material', checkedValues)}
                                                style={{ display:'block', }}
                                                >
                                                    <div style={{padding:'0 12px',}}>
                                                        <List
                                                        style={{ width:280, }}
                                                        dataSource={state?.materials}
                                                        renderItem={(item, index) => {
                                                            const { material_id, name, } = item ?? {};
                                                            return (
                                                                <List.Item style={{width:'100%'}} key={index}>
                                                                    <div style={{display:'flex', alignItems:'center', width:'100%'}}>
                                                                        <div>
                                                                            <span>{name}</span>
                                                                        </div>
                                                                        <div style={{marginLeft:'auto'}}>
                                                                            <Checkbox
                                                                            value={material_id}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </List.Item>
                                                            )
                                                        }}
                                                        />
                                                    </div>
                                                    
                                                </Checkbox.Group>
                                            </>
                                        }
                                        >
                                            <div>
                                                <Space>
                                                    <div>
                                                        <span>{`Item ${filter?.material?.length > 0 ? `: ${filter?.material?.length} items` : "" }`}</span>
                                                    </div>

                                                    <div>
                                                        <CaretDownFilled style={{color:'#a0aec0'}} />
                                                    </div>
                                                    
                                                </Space>
                                                
                                            </div>
                                        </FilterBullet>
                                        <FilterBullet
                                        popover={
                                            <>
                                                <div style={{padding:12,}}>
                                                    <Input
                                                    prefix={<SearchOutlined style={{color :'var(--secondary-text-color)'}}/>}
                                                    placeholder='Search Locations'
                                                    allowClear
                                                    onChange={async (e) => {
                                                        const locationMaterialResponse = await locationMaterialService?.getLocationMaterialByMaterial({ search: e.target.value, size:100 });
                                                        setState(state => ({ ...state, locations: locationMaterialResponse?.dataList }))
                                                    }}
                                                    />
                                                </div>
                                                <Checkbox.Group
                                                value={filter?.location}
                                                onChange={(checkedValues) => onCheckChange('location', checkedValues)}
                                                style={{ display:'block', }}
                                                >
                                                    <div style={{padding:'0 12px',}}>
                                                        <List
                                                        style={{ width:280, }}
                                                        dataSource={state?.locations}
                                                        renderItem={(item, index) => {
                                                            const { location_id, name, } = item ?? {};
                                                            return (
                                                                <List.Item style={{width:'100%'}} key={index}>
                                                                    <div style={{display:'flex', alignItems:'center', width:'100%'}}>
                                                                        <div>
                                                                            <span>{name}</span>
                                                                        </div>
                                                                        <div style={{marginLeft:'auto'}}>
                                                                            <Checkbox
                                                                            value={location_id}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </List.Item>
                                                            )
                                                        }}
                                                        />
                                                    </div>
                                                    
                                                </Checkbox.Group>
                                            </>
                                        }
                                        >
                                            <div>
                                                <Space>
                                                    <div>
                                                        <span>{`Location ${filter?.location?.length > 0 ? `: ${filter?.location?.length} items` : "" }`}</span>
                                                    </div>

                                                    <div>
                                                        <CaretDownFilled style={{color:'#a0aec0'}} />
                                                    </div>
                                                    
                                                </Space>
                                                
                                            </div>
                                        </FilterBullet>

                                    <FilterBullet
                                        popover={
                                            <>
                                                <div style={{ padding: 12, }}>
                                                    <Input
                                                        prefix={<SearchOutlined style={{ color: 'var(--secondary-text-color)' }} />}
                                                        placeholder='Search Warehouses'
                                                        allowClear
                                                        onChange={async (e) => {
                                                            const warehouseResponse = await warehouseService?.getWarehouses({ search: e.target.value, size:100 });
                                                            setState(state => ({ ...state, warehouses: warehouseResponse?.warehouses }))
                                                        }}
                                                    />
                                                </div>
                                                <Checkbox.Group
                                                    value={filter?.warehouse}
                                                    onChange={(checkedValues) => onCheckChange('warehouse', checkedValues)}
                                                    style={{ display: 'block', }}
                                                >
                                                    <div style={{ padding: '0 12px', }}>
                                                        <List
                                                            style={{ width: 280, }}
                                                            dataSource={state?.warehouses}
                                                            renderItem={(item, index) => {
                                                                const { warehouse_id, name, } = item ?? {};
                                                                return (
                                                                    <List.Item style={{ width: '100%' }} key={index}>
                                                                        <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                                                                            <div>
                                                                                <span>{name}</span>
                                                                            </div>
                                                                            <div style={{ marginLeft: 'auto' }}>
                                                                                <Checkbox
                                                                                    value={warehouse_id}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </List.Item>
                                                                )
                                                            }}
                                                        />
                                                    </div>

                                                </Checkbox.Group>
                                            </>
                                        }
                                    >
                                        <div>
                                            <Space>
                                                <div>
                                                    <span>{`Warehouse ${filter?.warehouse?.length > 0 ? `: ${filter?.warehouse?.length} items` : ""}`}</span>
                                                </div>

                                                <div>
                                                    <CaretDownFilled style={{ color: '#a0aec0' }} />
                                                </div>

                                            </Space>

                                        </div>
                                    </FilterBullet>
                                        
                                    </Space>
                                </div>
                            </div>
                            <div style={{marginLeft:'auto', marginTop:12}}>
                            </div>
                        </div>

                    <div style={{ marginTop:12}}>
                        <ReportList filter={filter} />
                    </div>
                </div>
            </div>
        </>
    );
}

export default ReportLayout;

