import React from 'react';
import LayoutIndex from '../layouts';
import AppRouter from './app.router';
import PublicRoute from './public.router';
import LoginIndex from '../containers/login';
import { Redirect, Switch } from 'react-router-dom';
import { useSelector } from 'react-redux';

const AuthRouter = () => {
    const authRedux = useSelector(state => state?.auth);
    return (
        <>
            <Switch>
                <PublicRoute restricted={false} component={LoginIndex} path="/login"></PublicRoute>
            </Switch>

            {
                authRedux?.auth ?
                (
                    <>
                        <LayoutIndex>
                            <AppRouter />
                        </LayoutIndex>
                    </>
                )
                :
                <Redirect to='/login' />
            }
           
        </>
    );
}

export default AuthRouter;