
import { Route } from 'react-router-dom';

const PublicRoute = ({component: Component, restricted, ...rest}) => {
    return (
        // restricted = false meaning public route
        // restricted = true meaning restricted route
        <Route key={Math.floor(Math.random() * 100000)} {...rest} render={props => (
            <Component {...props} />
        )} />
    );
};

export default PublicRoute;