function queryString(passed_params){
    var url = '';
    if(passed_params){
        // Loop the property names of `el`, creating a new object
        // with the ones whose values aren't `null`.
        // `reduce` is commonly used for doing this:
        const params = Object.keys(passed_params).reduce((newObj, key) => {
            const value = passed_params[key];
            if (value !== null) {
            newObj[key] = value;
            }
            return newObj;
        }, {});
        
        url = Object.keys(params).map(function(k) {
            if(params[k] != null){
                return encodeURIComponent(k) + '=' + encodeURIComponent(params[k]);
            }else{
                return null;
            }
            
        }).join('&');
    }
    return url;
}

function queryStringToJSON(qs) {
    qs = qs.substring(1);

    var pairs = qs.split('&');
    var result = {};
    pairs.forEach(function(p) {
        var pair = p.split('=');
        var key = pair[0];
        var value = decodeURIComponent(pair[1] || '');

        if( result[key] ) {
            if( Object.prototype.toString.call( result[key] ) === '[object Array]' ) {
                result[key].push( value );
            } else {
                result[key] = [ result[key], value ];
            }
        } else {
            result[key] = value;
        }
    });

    return JSON.parse(JSON.stringify(result));
};

export default {
    queryString,
    queryStringToJSON
}