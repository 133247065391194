import { Col, Divider, Row, Space, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import menuService from '../../services/admin/menu.service';
import DynamicSvg from './dynamic_svg';
import { RightOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import EntranceMotion from './entrance_motion';

const MenuComponent = () => {
    const location = useLocation();
    const [state, setState] = useState({
        loading : false,
        module : null,
    })

    const fetchMenus = async () => {
        setState(state => ({...state, loading : true, }));
        const moduleResponse = await menuService?.getModules({
            link : location?.pathname,
        });

        const { modules } = moduleResponse;
        const module = modules?.length > 0 ? modules[0] : null;


        setState(state => ({...state, loading : false, module : module,}));
    }

    useEffect(() => {
        fetchMenus();
    }, []);

    return (
        <>
            <Spin spinning={state?.loading}>
                <Row align={'top'}>
                    <Col xs={24} md={8} span={8}>
                        <div style={{textAlign:'start'}}>
                            <Space direction='vertical'>
                                <div>
                                    <span style={{fontSize:24, fontWeight:500,lineHeight:1,}}>{state?.module?.name}</span>
                                </div>
                                <div>
                                    <span style={{color:'var(--secondary-text-color)'}}>{state?.module?.description}</span>
                                </div>
                            </Space>
                        </div>
                    </Col>
                    <Col xs={24} md={16} span={16}>
                        <div style={{textAlign:'start'}}>
                            <Row gutter={[12, 12]}>
                                {
                                    state?.module?.menus?.map((menu, index) => {
                                        const { name, menu_id, icon, link } = menu;
                                        return (
                                            <Col key={index} xs={12} lg={8} span={8}>
                                                <Link to={link}>
                                                    <EntranceMotion delay={(index * 0.1)}>
                                                        <div className='menu-grouper'>
                                                            <Space size={12}>
                                                                <div style={{ display:'flex', height:40, width:40, border : '1px solid #e6e8eb', background:'#fff', borderRadius:'.5rem'}}>
                                                                    <div style={{margin:'auto'}}>
                                                                        <DynamicSvg image={icon} color={'#6f7287'} size={16} />
                                                                    </div>
                                                                </div>
                                                                <div>
                                                                    <span style={{fontSize:16, color:'#11181c'}}>{name}</span>
                                                                </div>

                                                                <div style={{marginLeft:'auto', color:'#c1c8cd'}}>
                                                                    <RightOutlined className='transition-all ease-out right-arrow' style={{fontSize:10, }} />
                                                                </div>
                                                                
                                                            </Space>
                                                        </div>
                                                    </EntranceMotion>
                                                </Link>
                                            </Col>
                                        )
                                    })
                                }
                            </Row>
                        </div>
                    </Col>
                </Row>
                <Divider />
            </Spin>
        </>
    );
}

export default MenuComponent;