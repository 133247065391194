import axios from 'axios';
import serviceHelper from '../../helpers/functions/service.helper';

async function getWorkOrders(query){
    const queryString = serviceHelper.queryString(query);
    const workOrderResponse = await axios.get(`production/work_order/get?${queryString}`, { withCredentials : true, });
    return workOrderResponse?.data;
}

async function getWorkOrderDetail(work_order_id){
    const workOrderResponse = await axios.get(`production/work_order/${work_order_id}/detail/get`, { withCredentials : true, });
    return workOrderResponse?.data;
}

async function getWorkOrderStatuses(query){
    const queryString = serviceHelper.queryString(query);
    const workOrderStatusResponse = await axios.get(`production/work_order/status/get?${queryString}`, { withCredentials : true, });
    return workOrderStatusResponse?.data;
}

async function saveWorkOrder(params){
    const saveWorkOrderResponse = await axios.post(`production/work_order/save`, params, { withCredentials : true, });
    return saveWorkOrderResponse?.data;
}

async function updateWorkOrderDetail(work_order_id, params){
    const updateWorkOrderDetailResponse = await axios.post(`production/work_order/${work_order_id}/update`, params, { withCredentials : true, });
    return updateWorkOrderDetailResponse?.data;
}

async function getWorkOrderProducts(query){
    const queryString = serviceHelper.queryString(query);
    const workOrderProductResponse = await axios.get(`production/work_order/work_order_product/get?${queryString}`, { withCredentials : true, });
    return workOrderProductResponse?.data;
}

async function saveWorkOrderProduct(params){
    const saveWorkOrderProductResponse = await axios.post(`production/work_order/work_order_product/save`, params, { withCredentials : true, });
    return saveWorkOrderProductResponse?.data;
}

async function updateWorkOrderProduct(work_order_product_id, params){
    const updateWorkOrderProductResponse = await axios.post(`production/work_order/work_order_product/${work_order_product_id}/update`, params, { withCredentials : true, });
    return updateWorkOrderProductResponse?.data;
}

async function deleteWorkOrderProduct(work_order_product_id){
    const deleteWorkOrderProductResponse = await axios.delete(`production/work_order/work_order_product/${work_order_product_id}/delete`, { withCredentials : true, });
    return deleteWorkOrderProductResponse?.data;
}

async function getWorkOrderHistory(query){
    const queryString = serviceHelper.queryString(query);
    const workOrderHistoryResponse = await axios.get(`production/work_order/work_order_history/get?${queryString}`, { withCredentials : true, });
    return workOrderHistoryResponse?.data;
}

async function getWorkOrderStatusSetting(query){
    const queryString = serviceHelper.queryString(query);
    const workOrderStatusSettingResponse = await axios.get(`production/work_order/work_order_status_setting/get?${queryString}`, { withCredentials : true, });
    return workOrderStatusSettingResponse?.data;
}

async function updateWorkOrderStatus(params){
    const updateWorkOrderStatusResponse = await axios.post(`production/work_order/updateStatus`, params, { withCredentials : true, });
    return updateWorkOrderStatusResponse?.data;
}

async function getWorkOrderMaterialList(query){
    const queryString = serviceHelper.queryString(query);
    const workOrderResponse = await axios.get(`production/work_order/work_order_material/get?${queryString}`, { withCredentials : true, });
    return workOrderResponse?.data;
}

async function getWorkOrderDetailTest(work_order_id){
    const workOrderResponse = await axios.get(`production/work_order/${work_order_id}/detailtest/get`, { withCredentials : true, });
    return workOrderResponse?.data;
}

export default {
    getWorkOrders,
    getWorkOrderDetail,
    getWorkOrderStatuses,
    saveWorkOrder,
    updateWorkOrderDetail,
    getWorkOrderProducts,
    saveWorkOrderProduct,
    updateWorkOrderProduct,
    deleteWorkOrderProduct,
    getWorkOrderHistory,
    getWorkOrderStatusSetting,
    updateWorkOrderStatus,
    getWorkOrderMaterialList,
    getWorkOrderDetailTest,
}