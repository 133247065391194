import axios from 'axios';
import serviceHelper from '../../helpers/functions/service.helper';

async function getLocationMaterialByLocation(query){
    const queryString = serviceHelper.queryString(query);
    const locationMaterialResponse = await axios.get(`production/stock/getbylocation/get?${queryString}`, { withCredentials : true, });
    return locationMaterialResponse?.data;
}

async function getLocationMaterialByMaterial(query){
    const queryString = serviceHelper.queryString(query);
    const locationMaterialResponse = await axios.get(`production/stock/getbymaterial/get?${queryString}`, { withCredentials : true, });
    return locationMaterialResponse?.data;
}

async function getStocks(query){
    const queryString = serviceHelper.queryString(query);
    const stockResponse = await axios.get(`production/stock/getstocks/get?${queryString}`, { withCredentials : true, });
    return stockResponse?.data;
}

export default {
    getLocationMaterialByLocation,
    getLocationMaterialByMaterial,
    getStocks,
}