import React, { useEffect, useState } from 'react';
import locationService from '../../../services/warehouse/location.service';
import warehouseService from '../../../services/config/warehouse.service';
import { Col, Divider, Form, Input, Radio, Row, Skeleton, Select, Space, Switch } from 'antd';

const { TextArea } = Input;
const LocationEditDetail = ({ location_id, form }) => {
    const [state, setState] = useState({
        loading : false,
        location : null,

        warehouses : [],
    })

    const fetchLocations = async () => {
        setState(state => ({...state, loading : true,}));
        const locationResponse = await locationService?.getLocations({
            location_id : location_id,
        });

        const locations = locationResponse?.dataList['rows'];
        const location = locations?.length > 0 ? locations[0] : null;

        setTimeout(() => {
            //set form
            const { name, warehouse_id, } = location;
            form.setFieldsValue({ name, warehouse_id, });

            setState(state => ({...state, loading : false, location : location}));
        }, 500);
        
    }

    const fetchInitialization = async () => {
        const warehouseResponse = await warehouseService?.getWarehouses();

        setState(state => ({...state, warehouses : warehouseResponse?.warehouses}));
    }

    useEffect(() => {
        location_id && fetchLocations();
    }, [location_id]);

    useEffect(() => {
        fetchInitialization();
    }, [])

    return (
        <>
            {
                state?.loading ?
                (
                    <>
                        <div>
                            <Skeleton active />
                        </div>
                    </>
                )
                :
                (
                    <>
                        <div>
                            <div style={{background : '#edf2f7', border:'1.5px solid #cbd5e0', padding : '12px 12px 0 12px', borderRadius:8,}}>
                                <Row gutter={[12, 12]}>
                                    <Col xs={24} md={12}>
                                        <Form.Item name={'name'} label={'Location Name'}>
                                            <Input placeholder='Location Name' />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </div>
                            <Divider style={{marginBottom:12,}} />
                            {/* Warehouses */}
                            <div>
                                <div>
                                    <span style={{fontWeight:500, fontSize:16,}}>Assign to</span>
                                </div>

                                <div style={{marginTop:12,}}>
                                    <Form.Item name={'warehouse_id'}>
                                        <Select
                                        placeholder="Select Warehouses"
                                        mode='single'
                                        options={state?.warehouses?.map(x =>  ({ value : x?.warehouse_id, label : x?.name, }))}
                                        />
                                    </Form.Item>
                                </div>
                            </div>

                            <Divider />
                        </div>
                    </>
                )
            }
        </>
    );
}

export default LocationEditDetail;