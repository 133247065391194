import axios from 'axios';
import serviceHelper from '../../helpers/functions/service.helper';

async function getLocationMaterialByLocation(query){
    const queryString = serviceHelper.queryString(query);
    const locationMaterialResponse = await axios.get(`production/location_material/getbylocation/get?${queryString}`, { withCredentials : true, });
    return locationMaterialResponse?.data;
}

async function getLocationMaterialByMaterial(query){
    const queryString = serviceHelper.queryString(query);
    const locationMaterialResponse = await axios.get(`production/location_material/getbymaterial/get?${queryString}`, { withCredentials : true, });
    return locationMaterialResponse?.data;
}

async function getLocationMaterials(query){
    const queryString = serviceHelper.queryString(query);
    const locationMaterialResponse = await axios.get(`production/location_material/getlocationmaterials/get?${queryString}`, { withCredentials : true, });
    return locationMaterialResponse?.data;
}

export default {
    getLocationMaterialByLocation,
    getLocationMaterialByMaterial,
    getLocationMaterials,
}