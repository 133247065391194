import { Steps } from 'antd';
import React, { useState } from 'react';

const ApprovalStep = () => {
    const [state, setState] = useState({
        loading : false,
        approval_levels : [],

        current_step : 0,
    })

    const onChangeStep = (step) => {
        setState(state => ({...state, current_step : step}));
    }

    return (
        <>
            <div>
                <Steps
                type='navigation'
                className='approval-route-step'
                size='small'
                current={state?.current_step}
                onChange={onChangeStep}
                items={[{
                    icon : (
                        <div className='approval-route-step-icon'>
                            <span style={{fontSize:12,}}>1</span>
                        </div>
                    ),
                    status : 'finish',
                    title: 'Level 1',
                },
                {
                    icon : (
                        <div className='approval-route-step-icon'>
                            <span style={{fontSize:12,}}>2</span>
                        </div>
                    ),
                    title: 'Level 2',
                    status : 'finish'
                },
                {
                    icon : (
                        <div className='approval-route-step-icon'>
                            <span style={{fontSize:12,}}>3</span>
                        </div>
                    ),
                    title: 'Level 3',
                    status : 'finish'
                },]}
                />
            </div>
        </>
    );
}

export default ApprovalStep;