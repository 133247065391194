import React, { useEffect, useState } from 'react';
import partnerService from '../../../services/config/partner.service';
import { Button, Drawer, Form, Modal, Space, Spin, Table, Tag, notification } from 'antd';
import PartnerEditDetail from './partner_edit_detail';
import { useDispatch, useSelector } from 'react-redux';
import { refresh_partner_list } from '../../../actions/config.action';
import { DeleteOutlined } from '@ant-design/icons';

const PartnerList = ({ filter, }) => {
    const dispatch = useDispatch();
    const configRedux = useSelector(state => state?.config);
    const { innerWidth } = window;
    const [form] = Form.useForm();
    const [state, setState] = useState({
        loading : false,
        save_loading : false,
        partners : [],
        used_partner_id: [],
        drawer_partner_id : null,

        count: 0,
        page: 0,
        refresh : 0,
    });
    
    const getPartners = async () => {
        setState(state => ({...state, loading : true,}));
        const { search, } = filter;
        // const partnerResponse = await partnerService?.getPartnerList();
        const partnerResponse = await partnerService?.getPartners({
            search,
            page: state?.page,
        });
        const { count, rows: partners } = partnerResponse?.dataList;

        const usedPartnerResponse = await partnerService?.getUsedPartnerList({});
        const used_list = usedPartnerResponse?.dataList;

        setTimeout(() => {
            setState(state => ({ ...state, loading: false, count: count, partners: partners, used_partner_id: used_list }));
        }, 500);
        
    }

    const openDrawer = (partner_id) => {
        setState(state => ({...state, drawer_partner_id : partner_id}))
    }

    const savePartner = async (partner_id) => {
        setState(state => ({...state, save_loading : true,}));
        const { name, address, email, phone_no, } = form?.getFieldsValue();
        const update_partner = await partnerService?.updatePartner(partner_id, { name, address, email, phone_no, });

        //successful
        setTimeout(() => {
            dispatch(refresh_partner_list());
            setState(state => ({...state, save_loading : false, }));

            if(update_partner)
                {
                    setState(state => ({...state, drawer_partner_id : null, }));
                    form.resetFields();
                    notification?.success({
                    message : "Success",
                    description : `Successfully saved item ${name}`
                })}
        }, 500);
    }

    const deleteConfirm = async (partner_id) => {
        const partner = state?.partners?.find(x => x.partner_id === partner_id);
        const { name, } = partner;
        Modal.confirm({
            title : 'Confirm Delete This Record?',
            content : (
                <>
                    <div>
                        <div>
                            <span>{name}</span>
                        </div>
                    </div>
                </>
            ),
            onOk : () => {
                return new Promise(async (resolve, reject) => {
                    await onDeletePartner(partner_id).then((result) => {
                        setTimeout(() => {
                            // notification
                            notification?.success({
                                message : 'Successfully Deleted',
                                description : (
                                    <>
                                        <div>
                                            <div>
                                                <span>{name}</span>
                                            </div>
                                        </div>
                                    </>
                                )
                            })
                            setState(state => ({...state, refresh : (state?.refresh + 1)}));
                            resolve();
                        }, 500);
                    });
                    
                }).catch(() => console.log('Oops errors!'));
            }
        })
    }

    const onDeletePartner = async (partner_id) => {
        const deletePartnerResponse = await partnerService?.deletePartner(partner_id);
        return deletePartnerResponse;
    }

    useEffect(() => {
        getPartners();
    }, [filter, state?.page, configRedux?.partner_list_refresh, state?.refresh])

    return (
        <>
            <Spin spinning={state?.loading}>
                <Table
                pagination={{ 
                    size : 10,
                    showSizeChanger: false,
                    total : state?.count,
                    current : (state?.page + 1),
                    onChange : (page) => {
                        setState(state => ({...state, page : page - 1}));
                        // dispatch(set_list_page(page - 1));
                    }
                }}
                scroll={{ x : 400, }}
                className='hub-table bordered'
                dataSource={state?.partners}
                columns={[{
                    title : 'Name',
                    dataIndex : 'name',
                    key : 'name',
                    width: '25%',
                },{
                    title : 'Address',
                    dataIndex : 'address',
                    key : 'address',
                    width: '25%',
                },{
                    title : 'Email',
                    dataIndex : 'email',
                    key : 'email',
                    width: '25%',
                },{
                    title : 'Phone No.',
                    dataIndex : 'phone_no',
                    key : 'phone_no',
                    width: '25%',
                },{
                    title : 'Action',
                    dataIndex : 'partner_id',
                    key : 'partner_id',
                    width : '10%',
                    render : (text, row, index) => {
                        const partner_id = text;
                        return (
                            <Button.Group>
                                <Button
                                onClick={() => openDrawer(partner_id)}
                                >
                                    View
                                </Button>
                                {
                                    state?.used_partner_id.some(x => x.partner_id === partner_id) ?
                                        <Button type="primary" disabled />
                                        :
                                        <Button icon={<DeleteOutlined style={{ color: 'var(--secondary-text-color)' }} onClick={async () => deleteConfirm(partner_id)} />} />
                                }
                            </Button.Group>
                            
                        )
                    }
                }]}
                />
            </Spin>


            {/* detail */}
            <Drawer
            title="Partner Detail"
            rootClassName='drawer-detail-root'
            className='drawer-detail'
            open={state?.drawer_partner_id}
            onClose={() => openDrawer(null)}
            width={innerWidth >= 768 ? 800 : "100%"}
            footer={
                <>
                    <div style={{display:'flex', padding:'12px 0',}}>
                        <div style={{marginLeft:'auto',}}>
                            <Space>
                                <Button loading={state?.save_loading} type='primary' onClick={() => form.submit()}>Save Changes</Button>
                            </Space>
                        </div>
                    </div>
                </>
            }
            >
                <div>
                    <Form
                    onFinish={() => savePartner(state?.drawer_partner_id)}
                    form={form}
                    layout='vertical'
                    >
                        <PartnerEditDetail partner_id={state?.drawer_partner_id} form={form} />
                    </Form>
                </div>
            </Drawer>
        </>
    );
}

export default PartnerList;