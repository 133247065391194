import { Avatar, Button, Drawer, Form, Image, Space, Spin, Table, Tag, notification } from 'antd';
import React, { useEffect, useState } from 'react';
import userRoleService from '../../../services/admin/user_role.service';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import UserRoleEditDetail from './user_role_edit_detail';
import { refresh_user_role_list } from '../../../actions/config.action';
import { useDispatch, useSelector } from 'react-redux';

const UserRoleList = ({ filter, }) => {
    const { innerWidth, } = window;
    const dispatch = useDispatch();
    const configRedux = useSelector(state => state.config);
    const [form] = Form.useForm();
    const [state, setState] = useState({
        loading : false,
        user_roles : [],

        // edit
        drawer_user_role_id : null,
    })

    const fetchUserRoles = async () => {
        setState(state => ({...state, loading : true, }));
        const { search, } = filter;
        const userRoleResponse = await userRoleService?.getUserRoles({
            search,
        });
        const { roles } = userRoleResponse;

        // const userRoleResponse = await userRoleService.getRoleList();

        setTimeout(() => {
            setState(state => ({...state, loading : false, user_roles : roles.rows, }));
        }, 500);
    }
    
    const openDrawer = (role_id) => {
        setState(state => ({...state, drawer_user_role_id : role_id}))
    }

    const updateUserRole = async (role_id) => {
        setState(state => ({...state, save_loading : true,}));
        const { name, menu_ids, feature_ids, } = form.getFieldsValue();

        const updateUserRoleResponse = await userRoleService.updateRole(role_id, {
            name, menu_ids, feature_ids
        });

        //successful
        setTimeout(() => {
            dispatch(refresh_user_role_list());
            setState(state => ({...state, save_loading : false, }));

            if(updateUserRoleResponse)
                {
                    setState(state => ({...state, drawer_user_role_id : null, }));
                    form.resetFields();
                    notification?.success({
                    message : "Success",
                    description : `Successfully saved item ${name}`
                })}
        }, 500);
    }

    useEffect(() => {
        fetchUserRoles();
    }, [filter, configRedux?.user_role_list_refresh]);

    return (
        <>
            <Spin spinning={state?.loading}>
                <Table
                scroll={{ x : 400, }}
                className='hub-table bordered'
                dataSource={state?.user_roles}
                columns={[{
                    title : 'Role Name',
                    dataIndex : 'name',
                    key : 'name',
                    width : '95%',
                    render : (text, row, index) => {
                        return (
                            <>
                                <div>
                                    <Space size={12}>
                                        {/* <div>
                                            <Avatar size={'large'} src={<Image preview={false} src={'/cibanners/avatar.png'}/>} />
                                        </div> */}
                                        <div>
                                            <span>{text}</span>
                                        </div>
                                    </Space>
                                    
                                </div>
                            </>
                        )
                    }
                },{
                    title : 'Action',
                    dataIndex : 'role_id',
                    key : 'role_id',
                    width : '0%',
                    render : (text, row, index) => {
                        const role_id = text;
                        return (
                            <Button
                            onClick={() => openDrawer(role_id)}
                            >
                                View
                            </Button>
                        )
                    }
                },]}
                />
            </Spin>


            {/* Detail */}
            <Drawer
            title="UserRole Detail"
            rootClassName='drawer-detail-root'
            className='drawer-detail'
            open={state?.drawer_user_role_id}
            onClose={() => openDrawer(null)}
            width={innerWidth >= 768 ? 800 : "100%"}
            footer={
                <>
                    <div style={{display:'flex', padding:'12px 0',}}>
                        <div style={{marginLeft:'auto',}}>
                            <Space>
                                <Button loading={state?.save_loading} type='primary' onClick={() => form.submit()}>Save Changes</Button>
                            </Space>
                        </div>
                    </div>
                </>
            }
            >
                <div>
                    <Form
                    onFinish={() => updateUserRole(state?.drawer_user_role_id)}
                    form={form}
                    layout='vertical'
                    >
                        <UserRoleEditDetail role_id={state?.drawer_user_role_id} form={form} />
                    </Form>
                </div>
            </Drawer>
        </>
    );
}

export default UserRoleList;