import React, { useEffect, useState, useRef } from 'react';
import { Button, Spin } from 'antd';
import { DownloadOutlined, PrinterOutlined } from '@ant-design/icons';
import ReactToPrint, { PrintContextConsumer } from 'react-to-print';
import WorkOrderTemplate from './work_order_template';
import workOrderService from '../../../services/production/work_order.service';

const WorkOrderFile = ({ work_order_id, work_order_status_id, }) => {
    const componentRef = useRef();
    const [state, setState] = useState({
        loading : false,
        work_order : null,
        work_order_products : null,
        work_order_materials : null,
    })

    const fetchWorkOrder = async () => {
        setState(state => ({...state, loading : true, }));
        const workOrderResponse = await workOrderService?.getWorkOrderDetail(work_order_id);
        const { work_order } = workOrderResponse;
        console.log(work_order)
        setState(state => ({...state, loading : false, work_order : work_order}));
    }

    const fetchWorkOrderProducts = async () => {
        setState(state => ({...state, loading : true,}));
        const workOrderProductResponse = await workOrderService?.getWorkOrderProducts({
            work_order_id,
        });

        const { work_order_products } = workOrderProductResponse;
        setState(state => ({...state, loading : false, work_order_products : work_order_products, }));
    }

    const fetchWorkOrderMaterials = async () => {
        setState(state => ({...state, loading : true,}));
        const workOrderProductResponse = await workOrderService?.getWorkOrderMaterialList({
            work_order_id,
        });

        const { list } = workOrderProductResponse;
        setState(state => ({...state, loading : false, work_order_materials : list, }));
    }

    useEffect(() => {
        fetchWorkOrder();
    }, [work_order_status_id])

    useEffect(() => {
        fetchWorkOrderMaterials();
        fetchWorkOrderProducts();
    }, [work_order_id])

    return (
        <>
            <div>
                <div style={{display:'flex',}}>
                    <div style={{marginLeft:'auto'}}>
                        <ReactToPrint 
                        trigger={() => (
                            <Button icon={<PrinterOutlined />}>Print</Button>
                        )}
                        content={() => componentRef.current}
                        documentTitle={state?.work_order?.running_number}
                        pageStyle={`@page {
                            margin: 24px;
                        }`}
                        />
                    </div>
                </div>
                <div style={{marginTop:12,}}>
                    <div style={{ margin : 'auto', display: 'flex', justifyContent:'center', }}>
                        <div className='card-shadow' style={{width: 800, minHeight: 1000, }}>
                            <Spin spinning={state?.loading}>
                                <div ref={componentRef}>
                                    <WorkOrderTemplate work_order={state?.work_order} work_order_products={state?.work_order_products} work_order_materials={state?.work_order_materials} />
                                </div>
                            </Spin>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default WorkOrderFile;