import React, { useEffect, useState } from 'react';
import inoutboundSourceService from '../../../services/config/inoutbound_source.service';
import { Button, Drawer, Form, Modal, Space, Spin, Table, Tag, notification } from 'antd';
import InOutboundSourceEditDetail from './inoutbound_source_edit_detail';
import { useDispatch, useSelector } from 'react-redux';
import { refresh_inoutbound_sources_list } from '../../../actions/warehouse.action';
import { DeleteOutlined } from '@ant-design/icons';

const InOutboundSourceList = ({ filter, }) => {
    const dispatch = useDispatch();
    const warehouseRedux = useSelector(state => state?.warehouse);
    const { innerWidth } = window;
    const [form] = Form.useForm();
    const [state, setState] = useState({
        loading : false,
        save_loading : false,
        inoutbound_sources : [],
        used_inoutbound_source_id: [],
        drawer_inoutbound_source_id : null,

        count: 0,
        page: 0,
        refresh : 0,
    });
    
    const getInOutBoundSources = async () => {
        setState(state => ({...state, loading : true,}));
        const { search, } = filter;

        const inoutboundSourceResponse = await inoutboundSourceService?.getInOutBoundSources({
            search,
            page: state?.page,
        });

        const { count, rows: inoutboundsources } = inoutboundSourceResponse?.dataList;

        const usedInoutboundSourceResponse = await inoutboundSourceService?.getUsedInOutBoundSourcesList({});
        const used_list = usedInoutboundSourceResponse?.dataList;
        console.log("usedlist",used_list)

        setTimeout(() => {
            setState(state => ({ ...state, loading: false, count: count, inoutbound_sources: inoutboundsources, used_inoutbound_source_id: used_list }));
        }, 500);
        
    }

    const openDrawer = (inoutbound_source_id) => {
        setState(state => ({...state, drawer_inoutbound_source_id : inoutbound_source_id}))
    }

    const saveInOutboundSource = async (inoutbound_source_id) => {
        setState(state => ({...state, save_loading : true,}));
        const { name, code, inoutbound_type_ids, } = form?.getFieldsValue();
        const update_inoutbound_source = await inoutboundSourceService?.updateInOutboundSource(inoutbound_source_id, { name, code, inoutbound_type_ids,});

        //successful
        setTimeout(() => {
            dispatch(refresh_inoutbound_sources_list());
            setState(state => ({...state, save_loading : false, }));

            if(update_inoutbound_source)
                {
                    setState(state => ({...state, drawer_inoutbound_source_id : null, }));
                    form.resetFields();
                    notification?.success({
                    message : "Success",
                    description : `Successfully saved item ${name}`
                })}
        }, 500);
    }

    const deleteConfirm = async (inoutbound_source_id) => {
        const inoutbound_source = state?.inoutbound_sources?.find(x => x.inoutbound_source_id === inoutbound_source_id);
        const { name, } = inoutbound_source;
        Modal.confirm({
            title : 'Confirm Delete This Record?',
            content : (
                <>
                    <div>
                        <div>
                            <span>{name}</span>
                        </div>
                    </div>
                </>
            ),
            onOk : () => {
                return new Promise(async (resolve, reject) => {
                    await onDeleteInOutBoundSource(inoutbound_source_id).then((result) => {
                        setTimeout(() => {
                            // notification
                            notification?.success({
                                message : 'Successfully Deleted',
                                description : (
                                    <>
                                        <div>
                                            <div>
                                                <span>{name}</span>
                                            </div>
                                        </div>
                                    </>
                                )
                            })
                            setState(state => ({...state, refresh : (state?.refresh + 1)}));
                            resolve();
                        }, 500);
                    });
                    
                }).catch(() => console.log('Oops errors!'));
            }
        })
    }

    const onDeleteInOutBoundSource = async (inoutbound_source_id) => {
        const deleteInOutBoundSourceResponse = await inoutboundSourceService?.deleteInOutBoundSource(inoutbound_source_id);
        return deleteInOutBoundSourceResponse;
    }

    useEffect(() => {
        getInOutBoundSources();
    }, [filter, state?.page, warehouseRedux?.inoutbound_sources_list_refresh, state?.refresh])

    return (
        <>
            <Spin spinning={state?.loading}>
                <Table
                pagination={{ 
                    size : 10,
                    showSizeChanger: false,
                    total : state?.count,
                    current : (state?.page + 1),
                    onChange : (page) => {
                        setState(state => ({...state, page : page - 1}));
                        // dispatch(set_list_page(page - 1));
                    }
                }}
                scroll={{ x : 400, }}
                className='hub-table bordered'
                dataSource={state?.inoutbound_sources}
                columns={[{
                    title : 'Name',
                    dataIndex : 'name',
                    key : 'name',
                    width: '25%',
                },{
                    title : 'Code',
                    dataIndex : 'code',
                    key : 'code',
                    render : (code, row, index) => {
                        // const { code } = text;
                        return (
                            <>
                                <div>
                                    <Tag color='var(--secondary-button-color)'>
                                        <span style={{fontSize:12, color:'var(--secondary-text-color)'}}>{code}</span>
                                    </Tag>
                                </div>
                            </>
                        )
                    }
                },{
                    title : 'Type',
                    dataIndex: 'inoutbound_types',
                    key: 'inoutbound_types',
                    render : (text, row, index) => {
                        const inoutbound_types = text;
                        console.log("whyhwy", inoutbound_types)
                        return (
                            <>
                                <div>
                                    <Space>
                                        {
                                            inoutbound_types?.map((inoutbound_type, index) => {
                                                const { name, } = inoutbound_type;
                                                return (
                                                    <div key={index}>
                                                        <Tag color='var(--secondary-button-color)'>
                                                            <span style={{ fontSize: 12, color: 'var(--secondary-text-color)' }}>{name}</span>
                                                        </Tag>
                                                    </div>
                                                )
                                            })
                                        }
                                    </Space>

                                </div>
                            </>
                        )
                    }
                },{
                    title : 'Action',
                    dataIndex : 'inoutbound_source_id',
                    key : 'inoutbound_source_id',
                    width : '10%',
                    render : (text, row, index) => {
                        const inoutbound_source_id = text;
                        return (
                            <Button.Group>
                                <Button
                                onClick={() => openDrawer(inoutbound_source_id)}
                                >
                                    View
                                </Button>
                                {
                                    state?.used_inoutbound_source_id.some(x => x.inoutbound_source_id === inoutbound_source_id) ?
                                        <Button type="primary" disabled />
                                        :
                                        <Button icon={<DeleteOutlined style={{ color: 'var(--secondary-text-color)' }} onClick={async () => deleteConfirm(inoutbound_source_id)} />} />
                                }
                            </Button.Group>
                            
                        )
                    }
                }]}
                />
            </Spin>


            {/* detail */}
            <Drawer
            title="InOutboundSource Detail"
            rootClassName='drawer-detail-root'
            className='drawer-detail'
            open={state?.drawer_inoutbound_source_id}
            onClose={() => openDrawer(null)}
            width={innerWidth >= 768 ? 800 : "100%"}
            footer={
                <>
                    <div style={{display:'flex', padding:'12px 0',}}>
                        <div style={{marginLeft:'auto',}}>
                            <Space>
                                <Button loading={state?.save_loading} type='primary' onClick={() => form.submit()}>Save Changes</Button>
                            </Space>
                        </div>
                    </div>
                </>
            }
            >
                <div>
                    <Form
                    onFinish={() => saveInOutboundSource(state?.drawer_inoutbound_source_id)}
                    form={form}
                    layout='vertical'
                    >
                        <InOutboundSourceEditDetail inoutbound_source_id={state?.drawer_inoutbound_source_id} form={form} />
                    </Form>
                </div>
            </Drawer>
        </>
    );
}

export default InOutboundSourceList;