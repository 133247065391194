import axios from 'axios';
import serviceHelper from '../../helpers/functions/service.helper';

async function getInBoundStatus(query){
    const queryString = serviceHelper.queryString(query);
    const inoutboundStatusResponse = await axios.get(`config/inoutbound_status/inbound/get?${queryString}`, { withCredentials : true, });
    return inoutboundStatusResponse?.data;
}

async function getOutBoundStatus(query){
    const queryString = serviceHelper.queryString(query);
    const inoutboundStatusResponse = await axios.get(`config/inoutbound_status/outbound/get?${queryString}`, { withCredentials : true, });
    return inoutboundStatusResponse?.data;
}

export default {
    getInBoundStatus,
    getOutBoundStatus,
}