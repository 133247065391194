import React from 'react';
import MenuComponent from '../../../helpers/components/menu.component';
import WorkOrderScheduler from '../work_order/work_order_scheduler';

const ProductionLayout = () => {
    return (
        <>
            <MenuComponent />

            <div>
                {/* Work Order Scheduler */}
                {/* <WorkOrderScheduler /> */}
            </div>
        </>
    );
}

export default ProductionLayout;