import React from 'react';
import { Switch } from 'react-router-dom';
import PublicRoute from './public.router';
import PrivateRoute from './private.router';

// Page
import HomeIndex from '../containers/home';
import WarehouseIndex from '../containers/warehouse';
import InboundIndex from '../containers/warehouse/inbound_index';
import InboundDetail from '../containers/warehouse/inbound_detail';
import OutboundIndex from '../containers/warehouse/outbound_index';
import OutboundDetail from '../containers/warehouse/outbound_detail';
import LocationIndex from '../containers/warehouse/location_index';
import ReportIndex from '../containers/warehouse/report_index';
import StockIndex from '../containers/warehouse/stock_index';
//import ReportIndex from '../containers/warehouse/report_index';
import TransactionsIndex from '../containers/warehouse/transactions_index';
import InOutboundSourceIndex from '../containers/warehouse/inoutbound_source_index';
import ProductionIndex from '../containers/production';
import ConfigurationIndex from '../containers/configuration';
import ConfigWarehouseIndex from '../containers/configuration/warehouse_index';
import ConfigMaterialIndex from '../containers/configuration/material_index';
import ConfigUomIndex from '../containers/configuration/uom_index';
import ConfigMaterialCategoryIndex from '../containers/configuration/material_category_index';
import ConfigMaterialTypeIndex from '../containers/configuration/material_type_index';
import ConfigUserIndex from '../containers/configuration/user_index';
import ConfigUserRoleIndex from '../containers/configuration/user_role_index';
import ConfigPartnerIndex from '../containers/configuration/partner_index';
import BomIndex from '../containers/production/bom_index';
import BomEdit from '../containers/production/bom_edit';
import LineIndex from '../containers/production/line_index';
import WorkOrderIndex from '../containers/production/work_order_index';
import WorkOrderDetail from '../containers/production/work_order_detail';
import ApprovalIndex from '../containers/production/approval_index';

import ResetPasswordIndexIndex from '../containers/reset_password/index';


const AppRouter = () => {
    return (
        <>
            <Switch>
                {/* Home */}
                <PrivateRoute component={HomeIndex} path="/" exact restricted={false} />
                <PrivateRoute component={ResetPasswordIndexIndex} path="/reset_password" exact restricted={false} />
                
                {/* Warehouse */}
                <PrivateRoute component={WarehouseIndex} path="/warehouse" exact restricted={false} />
                <PrivateRoute component={InboundIndex} path="/warehouse/inbound" exact restricted={false} />
                <PrivateRoute component={InboundDetail} path="/warehouse/inbound/:inbound_id" exact restricted={false} />
                <PrivateRoute component={OutboundIndex} path="/warehouse/outbound" exact restricted={false} />
                <PrivateRoute component={OutboundDetail} path="/warehouse/outbound/:outbound_id" exact restricted={false} />
                <PrivateRoute component={LocationIndex} path="/warehouse/location" exact restricted={false} />
                <PrivateRoute component={ReportIndex} path="/warehouse/report" exact restricted={false} />
                <PrivateRoute component={StockIndex} path="/warehouse/stock" exact restricted={false} />
                <PrivateRoute component={TransactionsIndex} path="/warehouse/transactions" exact restricted={false} />
                <PrivateRoute component={InOutboundSourceIndex} path="/warehouse/inoutbound_source" exact restricted={false} />

                {/* Production */}
                <PrivateRoute component={ProductionIndex} path="/production" exact restricted={false} />
                {/* Bom */}
                <PrivateRoute component={BomIndex} path="/production/bom" exact restricted={false} />
                <PrivateRoute component={BomEdit} path="/production/bom/:bom_id" exact restricted={false} />
                {/* Production Line */}
                <PrivateRoute component={LineIndex} path="/production/line" exact restricted={false} />
                {/* Work Order */}
                <PrivateRoute component={WorkOrderIndex} path="/production/work_order" exact restricted={false} />
                <PrivateRoute component={WorkOrderDetail} path="/production/work_order/:work_order_id" exact restricted={false} />
                {/* Approval */}
                <PrivateRoute component={ApprovalIndex} path="/production/approval" exact restricted={false} />


                {/* Configuration */}
                <PrivateRoute component={ConfigurationIndex} path="/config" exact restricted={false} />
                <PrivateRoute component={ConfigWarehouseIndex} path="/config/warehouse" exact restricted={false} />
                <PrivateRoute component={ConfigMaterialIndex} path="/config/item" exact restricted={false} />
                <PrivateRoute component={ConfigUomIndex} path="/config/uom" exact restricted={false} />
                <PrivateRoute component={ConfigMaterialCategoryIndex} path="/config/material_category" exact restricted={false} />
                <PrivateRoute component={ConfigMaterialTypeIndex} path="/config/material_type" exact restricted={false} />
                <PrivateRoute component={ConfigUserIndex} path="/config/user" exact restricted={false} />
                <PrivateRoute component={ConfigUserRoleIndex} path="/config/user_role" exact restricted={false} />
                <PrivateRoute component={ConfigPartnerIndex} path="/config/partner" exact restricted={false} />

            </Switch>
        </>
    );
}

export default AppRouter;