import axios from 'axios';
import serviceHelper from '../../helpers/functions/service.helper';

async function getMaterials(query){
    const queryString = serviceHelper.queryString(query);
    const materialResponse = await axios.get(`config/material/get?${queryString}`, { withCredentials : true, });
    return materialResponse?.data;
}

async function getMaterialDetail(material_id){
    const materialResponse = await axios.get(`config/material/${material_id}/detail`, { withCredentials : true, });
    return materialResponse?.data;
}

async function getUsedMaterialList(query) {
    const queryString = serviceHelper.queryString(query);
    const materialResponse = await axios.get(`config/material/getuse/list?${queryString}`, { withCredentials: true, });
    return materialResponse?.data;
}

async function saveMaterial(params){
    const saveMaterialResponse = await axios.post(`config/material/save`, params, { withCredentials : true, });
    return saveMaterialResponse?.data;
}

async function updateMaterial(material_id, params){
    const updateMaterialResponse = await axios.post(`config/material/${material_id}/update`, params, { withCredentials : true, });
    return updateMaterialResponse?.data;
}

async function deleteMaterials(material_id){
    const deleteMaterialResponse = await axios.delete(`config/material/${material_id}/delete`, { withCredentials : true, });
    return deleteMaterialResponse?.data;
}

async function checkItemCode(params){
    const materialResponse = await axios.post(`config/material/check_item_code`, params, { withCredentials : true, });
    return materialResponse?.data;
}


export default {
    getMaterials,
    getUsedMaterialList,
    saveMaterial,
    updateMaterial,
    deleteMaterials,
    getMaterialDetail,
    checkItemCode,
}