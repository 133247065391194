import React from 'react';
import ContainerComponent from '../../helpers/components/container.component';
import WorkOrderLayout from './work_order/work_order_layout';

const WorkOrderIndex = () => {
    return (
        <>
            <ContainerComponent span={22} top={20} bottom={20}>
                <WorkOrderLayout />
            </ContainerComponent>
        </>
    );
}

export default WorkOrderIndex;