import React, { useEffect, useState } from 'react';
import LocationList from './location_list';
import MenuTitleComponent from '../../../helpers/components/menu_title.component';
import { Button, Checkbox, Drawer, Input, Space, Form, notification, List, Radio } from 'antd';
import { CaretDownFilled, SearchOutlined, CloseCircleFilled } from '@ant-design/icons';
import FilterBullet from '../../../helpers/components/filter_bullet';
import LocationEditDetail from './location_edit_detail';
import locationService from '../../../services/warehouse/location.service';
import { useDispatch } from 'react-redux';
import { refresh_locations_list } from '../../../actions/warehouse.action';
import warehouseService from '../../../services/config/warehouse.service';

const LocationLayout = () => {
    const dispatch = useDispatch();
    const { innerWidth } = window;
    const [form] = Form.useForm();
    const [timer, setTimer] = useState(null);
    const [filter, setFilter] = useState({
        search : null,
        warehouse : null,
    });
    const [state, setState] = useState({
        open_drawer : false,
        save_loading : false,

        warehouses : [],
    })

    const doneTyping = async (input) => {
        clearTimeout(timer);
        const newTimer = setTimeout(() => {
            setFilter(filter => ({ ...filter, search: input, }));
        }, 300);
        setTimer(newTimer);
    }

    const onCheckChange = (key, checkedValues) => {
        setFilter(filter => ({...filter, [key] : checkedValues}));
    }

    const saveLocation = async () => {
        setState(state => ({...state, save_loading : true,}));
        const { name, warehouse_id, } = form?.getFieldsValue();
        
        const saveLocationResponse = await locationService?.saveLocation({ name, warehouse_id, });

        //successful
        setTimeout(() => {
            dispatch(refresh_locations_list());
            setState(state => ({...state, save_loading : false, }));

            if(saveLocationResponse)
                {
                    setState(state => ({...state, open_drawer : null, }));
                    form.resetFields();
                    notification?.success({
                    message : "Success",
                    description : `Successfully saved item ${name}`
                })}
        }, 500);
    }

    const fetchInitialization = async () => {
        const warehouseResponse = await warehouseService?.getWarehouses();

        setState(state => ({...state, warehouses : warehouseResponse?.warehouses}));
    }

    useEffect(() => {
        fetchInitialization();
    }, []);

    return (
        <>
            <div>
                <div style={{textAlign:'start',}}>
                    <MenuTitleComponent />
                </div>

                <div>
                    <div className='filter-panel' style={{marginBottom:12,}}>
                        <div>
                            <Input
                            onChange={(e) => doneTyping(e.target.value)}
                            name="search"
                            prefix={<SearchOutlined style={{color :'var(--secondary-text-color)'}} />}
                            placeholder='Search Location'
                            style={{minWidth: '100%',}}
                            allowClear
                            />
                        </div>

                        <div style={{display:'flex', alignItems:'center', marginTop:12, gap:8,}}>
                            <div>
                                <div className='filter-panel' style={{textAlign:'start'}}>
                                    <Space direction={innerWidth > 768 ? 'horizontal' : 'vertical'}>
                                        
                                        <FilterBullet
                                        popover={
                                            <>
                                                <div style={{ maxHeight:400, overflowY:'auto'}}>
                                                    <div style={{padding:12,}}>
                                                        <Input
                                                        prefix={<SearchOutlined style={{color :'var(--secondary-text-color)'}}/>}
                                                        placeholder='Search Warehouse'
                                                        allowClear
                                                        onChange={async (e) => {
                                                            const warehouseResponse = await warehouseService?.getWarehouses({ search: e.target.value, size:100 });
                                                            setState(state => ({ ...state, warehouses: warehouseResponse?.warehouses }))
                                                        }}
                                                        />
                                                    </div>
                                                    {/* <div> */}
                                                    <Checkbox.Group
                                                    value={filter?.warehouse}
                                                    onChange={(checkedValues) => onCheckChange('warehouse', checkedValues)}
                                                    style={{ display:'block', }}
                                                    >
                                                        <div style={{padding:'0 12px',}}>
                                                            <List
                                                            dataSource={state?.warehouses}
                                                            renderItem={(item, index) => {
                                                                const { warehouse_id, name, } = item ?? {};
                                                                return (
                                                                    <List.Item style={{width:'100%'}} key={index}>
                                                                        <div style={{display:'flex', alignItems:'center', width:'100%'}}>
                                                                            <div>
                                                                                <span>{name}</span>
                                                                            </div>
                                                                            <div style={{marginLeft:'auto'}}>
                                                                                <Checkbox
                                                                                value={warehouse_id}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </List.Item>
                                                                )
                                                            }}
                                                            />

                                                        </div>
                                                        
                                                    </Checkbox.Group>
                                                    {/* </div> */}
                                                </div>               
                                            </>
                                        }
                                        >
                                            <div>
                                                <Space>
                                                    <div>
                                                        <span>{`Warehouse ${filter?.warehouse?.length > 0 ? `: ${filter?.warehouse?.length} items` : "" }`}</span>
                                                    </div>

                                                    <div>
                                                        <CaretDownFilled style={{color:'#a0aec0'}} />
                                                    </div>
                                                    
                                                </Space>
                                                
                                            </div>
                                        </FilterBullet>
                                    </Space>
                                </div>
                            </div>
                            <div style={{marginLeft:'auto'}}>
                                <Button onClick={() => setState(state => ({...state, open_drawer : true,}))} type='primary'>New Location</Button>
                            </div>
                        </div>
                    </div>
                    <div>
                        <LocationList filter={filter} />
                    </div>
                </div>
            </div>


            {/* detail */}
            <Drawer
            title="Location Detail"
            rootClassName='drawer-detail-root'
            className='drawer-detail'
            open={state?.open_drawer}
            onClose={() => setState(state => ({...state, open_drawer : false,}))}
            width={innerWidth >= 768 ? 800 : "100%"}
            footer={
                <>
                    <div style={{display:'flex', padding:'12px 0',}}>
                        <div style={{marginLeft:'auto',}}>
                            <Space>
                                <Button loading={state?.save_loading} type='primary' onClick={() => form.submit()}>Save</Button>
                            </Space>
                        </div>
                    </div>
                </>
            }
            >
                <div>
                    <Form
                    onFinish={saveLocation}
                    form={form}
                    layout='vertical'
                    >
                        <LocationEditDetail form={form} />
                    </Form>
                </div>
            </Drawer>
        </>
    );
}

export default LocationLayout;