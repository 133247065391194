import React, { useEffect, useState } from 'react';
import workOrderService from '../../../services/production/work_order.service';
import { Button, Dropdown, Form, Input, Modal, Popover, Select, Space, Spin, Table, Tabs, notification } from 'antd';
import SearchMaterial from '../bom/search_material';
import { CheckOutlined, CloseOutlined, DeleteOutlined, EditOutlined, EllipsisOutlined, LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import NewWorkOrderProductForm from './new_work_order_product_form';
import materialService from '../../../services/config/material.service';
import bomService from '../../../services/production/bom.service';
import { color } from 'framer-motion';

const WorkOrderProduct = ({ work_order_id, work_order_status }) => {
    const [form] = Form.useForm();
    const [new_form] = Form.useForm();
    const [state, setState] = useState({
        loading : false,
        open : false,
        work_order_products : [],
        // materials : [],

        edit_mode : null,
        save_loading : null,
        edit_mode_loading : null,
        material_modal : false,
        new_modal : false,

        // edit
        edit_boms : [],

        refresh : 0,
    })

    const fetchWorkOrderProducts = async () => {
        setState(state => ({...state, loading : true,}));
        const workOrderProductResponse = await workOrderService?.getWorkOrderProducts({
            work_order_id,
        });

        const { work_order_products } = workOrderProductResponse;

        setState(state => ({...state, loading : false, work_order_products : work_order_products, }));
        // set into form
        form.setFieldsValue({ work_order_products : work_order_products,});
    }

    const customFunction = async (index, material_id,) => {
        const materialResponse = await materialService?.getMaterials({ material_id });
        const { rows : materials, count } = materialResponse?.materials;
        const material2 = materials?.length > 0 ? materials[0] : null;

        let work_order_product_arr = state?.work_order_products ?? [];
        setState(state => ({...state, select_loading : index }));
        work_order_product_arr[index].material_id = material_id;
        work_order_product_arr[index].material = material2;
        work_order_product_arr[index].updated = true;

        const materialDetailResponse = await materialService?.getMaterialDetail(material_id);
        const { material } = materialDetailResponse;
        work_order_product_arr[index].bom_id = null;
        setState(state => ({...state, open : false }));

        setTimeout(() => {
            setState(state => ({...state, work_order_products : work_order_product_arr, edit_mode_loading : null, }));
            
            //dynamic set form
            form.setFieldsValue({ work_order_products : work_order_product_arr, });
        }, 200);

        // setState(state => ({...state, select_loading : true}));
        // const materialResponse = await materialService?.getMaterialDetail(material_id);
        // const material = materialResponse?.material;
        // setState(state => ({...state, select_loading : false, material : material, }));
        // form.setFieldsValue({ material_id : material_id, bom_id : null, });

        
    }

    const onSaveWorkOrderProduct = async () => {
        setState(state => ({...state, save_loading : true, }));
        const { material_id, bom_id, quantity } = new_form?.getFieldsValue();
        
        const saveWorkOrderProductResponse = await workOrderService?.saveWorkOrderProduct({
            work_order_id : work_order_id,
            bom_id,
            material_id,
            quantity,
        });

        setTimeout(async () => {
            setState(state => ({...state, refresh : (state?.refresh + 1), save_loading : false, new_modal : false,}));
            if(saveWorkOrderProductResponse){
                const materialResponse = await materialService?.getMaterialDetail(material_id);
                const { material } = materialResponse;
                notification?.success({
                    message : 'Successfully added item to work order',
                    description : (
                        <>
                            <div>
                                <div>
                                    <span>{material?.name}</span>
                                </div>
                                <div>
                                    <span style={{color : 'var(--secondary-text-color)', fontSize:12,}}>{material?.material_code}</span>
                                </div>
                            </div>
                        </>
                    )
                })
            }
            
            new_form?.resetFields();
        }, 500);
    }

    const onUpdateWorkOrderProduct = async (work_order_product_id) => {
        setState(state => ({...state, save_loading : work_order_product_id, }));
        const { work_order_products, } = form?.getFieldsValue();
        const work_order_product = work_order_products?.find(x => x.work_order_product_id === work_order_product_id);
        const { quantity, bom_id, material, } = work_order_product ?? {};
        const { material_id } = material ?? {};

        const updateWorkOrderProductResponse = await workOrderService?.updateWorkOrderProduct(work_order_product_id, {
            material_id,
            quantity,
            bom_id,
        })

        setTimeout(() => {
            setState(state => ({...state, save_loading : null, edit_mode : null, refresh : (state?.refresh + 1) }));
            notification?.success({
                message : 'Successfully updated item',
                description : (
                    <>
                        <div>
                            <div>
                                <span>{material?.name}</span>
                            </div>
                            <div>
                                <span style={{color : 'var(--secondary-text-color)', fontSize:12,}}>{material?.material_code}</span>
                            </div>
                        </div>
                    </>
                )
            })
        }, 500);
    }

    const deleteConfirm = async (work_order_product_id) => {
        const work_order_product = state?.work_order_products?.find(x => x.work_order_product_id === work_order_product_id);
        const { material, } = work_order_product;
        Modal.confirm({
            title : 'Delete product from work order',
            content : (
                <>
                    <div>
                        <div>
                            <span>{material?.name}</span>
                        </div>
                        <div>
                            <span style={{fontSize:12, color : 'var(--secondary-text-color)'}}>{material?.material_code}</span>
                        </div>
                    </div>
                </>
            ),
            onOk : () => {
                return new Promise(async (resolve, reject) => {
                    await onDeleteWorkOrderProduct(work_order_product_id).then((result) => {
                        setTimeout(() => {
                            // notification
                            notification?.success({
                                message : 'Successfully deleted item from work order',
                                description : (
                                    <>
                                        <div>
                                            <div>
                                                <span>{material?.name}</span>
                                            </div>
                                            <div>
                                                <span style={{fontSize:12, color : 'var(--secondary-text-color)'}}>{material?.material_code}</span>
                                            </div>
                                        </div>
                                    </>
                                )
                            })
                            setState(state => ({...state, refresh : (state?.refresh + 1)}));
                            resolve();
                        }, 500);
                    });
                    
                }).catch(() => console.log('Oops errors!'));
            }
        })
    }

    const onDeleteWorkOrderProduct = async (work_order_product_id) => {
        const deleteWorkOrderProductResponse = await workOrderService?.deleteWorkOrderProduct(work_order_product_id);
        return deleteWorkOrderProductResponse;
    }

    const onRowChange = async (index, key, value) => {
        let work_order_products = state?.work_order_products ?? [];
        work_order_products[index][key] = value;

        setState(state => ({...state, work_order_products : work_order_products, }));
        // //dynamic set form
        form.setFieldsValue({ work_order_products : work_order_products, });
    }

    const toggleEditMode = async (work_order_product_id) => {
        setState(state => ({...state, edit_mode_loading : work_order_product_id}))
        const work_order_product = state?.work_order_products?.find(x => x.work_order_product_id === work_order_product_id);
        const materialDetailResponse = await materialService?.getMaterialDetail(work_order_product?.material?.material_id);
        const { material } = materialDetailResponse;

        setState(state => ({...state, edit_mode : work_order_product_id, edit_mode_loading : null, }));
    }

    const handleOpenChange = (newOpen) => {
        setState(state => ({...state, open : newOpen}));
    };

    const fetchInitialization = async () => {
        const bomResponse = await bomService?.getBomList({});
        setState(state => ({...state, edit_boms : bomResponse?.boms,}))
    }

    useEffect(() => {
        fetchInitialization();
    }, [])

    useEffect(() => {
        fetchWorkOrderProducts();
    }, [state?.refresh])

    

    return (
        <>
            <Spin spinning={state?.loading} indicator={<LoadingOutlined />}>
                <div style={{display : 'flex'}}>                   
                    {
                        (
                            <>
                                {
                                    work_order_status ?
                                    (
                                        <>
                                            <div style={{marginLeft:'auto'}}>
                                            <Button onClick={() => setState(state => ({...state, new_modal : true,}))} icon={<PlusOutlined style={{color:'var(--secondary-text-color)'}} />}>
                                                Add Item
                                            </Button>
                                            </div>
                                        </>
                                    )
                                    :
                                    (
                                        <>
                                        </>
                                    )
                                }
                            </>
                        )             
                    }
                </div>
                <div style={{marginTop:12,}}>
                    <Form
                    form={form}
                    layout='vertical'
                    >
                        <Form.Item name='work_order_products'>
                            <Table
                            pagination={false}
                            // scroll={{ x : 400, }}
                            className='hub-table bordered'
                            dataSource={state?.work_order_products}
                            columns={[{
                                title : 'Item',
                                dataIndex : 'material',
                                key : 'material',
                                width : '30%',
                                render : (material, row, index) => {
                                    const { material_id, name, material_code } = material ?? {};
                                    const { work_order_product_id } = row;
                                    return (
                                        <>
                                            <div>
                                                {
                                                    state?.edit_mode === work_order_product_id ?
                                                    (
                                                        <>
                                                            <Popover
                                                            content={<SearchMaterial form={form} default_material_id={material_id} customFunction={(material_id) => customFunction(index, material_id,)} />}
                                                            trigger={['click']}
                                                            open={state?.open}
                                                            onOpenChange={handleOpenChange}
                                                            placement='bottomLeft'
                                                            overlayInnerStyle={{padding : 0,}}
                                                            >
                                                                <Button
                                                                // loading={is_loading}
                                                                style={{width:'100%', textAlign:'start',}}
                                                                onClick={() => setState(state => ({...state, material_modal : true, }))}
                                                                >
                                                                    {name}
                                                                </Button>
                                                            </Popover>
                                                        </>
                                                    )
                                                    :
                                                    (
                                                        <>
                                                            <div>
                                                                <span>{name}</span>
                                                            </div>
                                                            <div>
                                                                <span style={{ fontSize:12, color : 'var(--secondary-text-color)' }}>{material_code}</span>
                                                            </div>
                                                        </>
                                                    )
                                                }
                                                
                                            </div>
                                        </>
                                    )
                                }
                            },{
                                title : 'BOM',
                                dataIndex : 'bom_id',
                                key : 'bom_id',
                                width : '30%',
                                render : (bom_id, row, index) => {
                                    const { material, bom, work_order_product_id } = row;
                                    const is_edit = state?.edit_mode === work_order_product_id;
                                    return (
                                        <>
                                            {
                                                is_edit ?
                                                (
                                                    <>
                                                        <Select
                                                        style={{width : '100%',}}
                                                        placeholder={'Select BOM'}
                                                        value={bom_id}
                                                        onChange={(e) => onRowChange(index, 'bom_id', e)}
                                                        options={state?.edit_boms?.filter(x => x.edit_status.is_complete == true).map((bom, index) => {
                                                            const { name, bom_id } = bom ?? {};
                                                            return ({ label : name, value : bom_id, });
                                                        })}
                                                        />
                                                    </>
                                                )
                                                :
                                                (
                                                    <>
                                                        <div>
                                                            <span>{bom?.name}</span>
                                                        </div>
                                                        {/* <div>
                                                            {bom?.bom_materials.map((x) => (
                                                                <span style={{ fontSize:12, color : 'var(--secondary-text-color)' }}>- {x.material?.name} <br/> </span>
                                                            ))}
                                                        </div> */}
                                                                                                           
                                                    </>
                                                )
                                            }
                                            
                                        </>
                                    )
                                }
                            },{
                                title : 'Quantity',
                                dataIndex : 'quantity',
                                key : 'quantity',
                                width : '30%',
                                render : (quantity, row, index) => {
                                    const { bom, material, work_order_product_id } = row;
                                    return (
                                        <>
                                            <div>
                                                {
                                                    state?.edit_mode === work_order_product_id ?
                                                    (
                                                        <>
                                                            <Input
                                                            defaultValue={quantity}
                                                            // value={material_quantity}
                                                            type='number'
                                                            onChange={(e) => onRowChange(index, 'quantity', e?.target?.value)}
                                                            suffix={
                                                                <>
                                                                    <span style={{fontSize:12, color:'var(--secondary-text-color)'}}>{(material?.uom?.code)}</span>
                                                                </>
                                                            }
                                                            />
                                                        </>
                                                    )
                                                    :
                                                    (
                                                        <>
                                                            <Space>
                                                                <span>{quantity}</span>
                                                                <span style={{ fontSize:12, color : 'var(--secondary-text-color)' }}>{material?.uom?.name}</span>
                                                            </Space>
                                                            {/* <div>
                                                            {bom?.bom_materials.map((x) => (
                                                                <span style={{ fontSize:12, color : 'var(--secondary-text-color)' }}>- {x.quantity} {x.material?.uom?.name}<br/> </span>
                                                            ))}
                                                            </div> */}
                                                        </>
                                                    )
                                                }
                                                
                                            </div>
                                        </>
                                    )
                                }
                            },{
                                title : 'Action',
                                dataIndex : 'work_order_product_id',
                                key : 'work_order_product_id',
                                width : '10%',
                                render : (work_order_product_id, row, index) => {
                                    return (
                                        <>
                                            {
                                                work_order_status ? 
                                                (
                                                    state?.edit_mode === work_order_product_id ?
                                                (
                                                    <>
                                                        <Space>
                                                            <Button
                                                            loading={state?.save_loading === work_order_product_id}
                                                            size='small' 
                                                            icon={<CheckOutlined 
                                                            style={{color :'var(--secondary-text-color)'}} />}
                                                            onClick={() => onUpdateWorkOrderProduct(work_order_product_id)}
                                                            />
                                                            <Button
                                                            onClick={() => setState(state => ({...state, edit_mode : null, }))}
                                                            size='small'
                                                            icon={<CloseOutlined style={{color :'var(--secondary-text-color)'}} />}
                                                            />
                                                        </Space>
                                                    </>
                                                )
                                                :
                                                (
                                                    <>
                                                        <Dropdown
                                                        menu={{ 
                                                            items : [{
                                                                key: '1',
                                                                icon : <EditOutlined style={{color : 'var(--secondary-text-color)'}} />,
                                                                label: (
                                                                    <>
                                                                        <span>Edit</span>
                                                                    </>
                                                                ),
                                                                onClick : async () => {
                                                                    await toggleEditMode(work_order_product_id);
                                                                },
                                                            },
                                                            {
                                                                key: '2',
                                                                label: (
                                                                    <>
                                                                        <div>
                                                                            <span>Delete</span>
                                                                        </div>
                                                                    </>
                                                                ),
                                                                icon: <DeleteOutlined style={{color : 'var(--secondary-text-color)'}} />,
                                                                onClick : async () => {
                                                                    await deleteConfirm(work_order_product_id);
                                                                }
                                                            },]
                                                        }}
                                                        >
                                                            <Button loading={(state?.edit_mode_loading === work_order_product_id)} size='small' icon={<EllipsisOutlined style={{color : 'var(--secondary-text-color)'}} />}/>
                                                        </Dropdown>
                                                    </>
                                                )
                                                ) 
                                                : 
                                                (
                                                    <>
                                                    </>
                                                )
                                            }
                                        </>
                                    )
                                }
                            }]}
                            />
                        </Form.Item>
                    </Form>
                </div>



            </Spin>
            
            <Modal
            open={state?.new_modal}
            title="Add Item To Work Order"
            onCancel={() => setState(state => ({...state, new_modal : false,}))}
            onOk={() => new_form?.submit()}
            confirmLoading={state?.save_loading}
            >
                <Form
                form={new_form}
                layout='vertical'
                onFinish={onSaveWorkOrderProduct}
                >
                    <NewWorkOrderProductForm refresh={state?.refresh} form={new_form} />
                </Form>
            </Modal>
        </>
    );
}

export default WorkOrderProduct;

