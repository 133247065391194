import React from 'react';
import ContainerComponent from '../../helpers/components/container.component';
import StockLayout from './stock/stock_layout';

const StockIndex = () => {
    return (
        <>
            <ContainerComponent span={22} top={20}>
                <StockLayout />
            </ContainerComponent>
        </>
    );
}

export default StockIndex;