import axios from 'axios';
import serviceHelper from '../../helpers/functions/service.helper';

async function getPartnerList(query){
    const queryString = serviceHelper.queryString(query);
    const partnerResponse = await axios.get(`config/partner/get/list`, { withCredentials : true, });
    return partnerResponse?.data;
}

async function getPartners(query){
    const queryString = serviceHelper.queryString(query);
    const partnerResponse = await axios.get(`config/partner/get?${queryString}`, { withCredentials : true, });
    return partnerResponse?.data;
}

async function getUsedPartnerList(query) {
    const queryString = serviceHelper.queryString(query);
    const partnerResponse = await axios.get(`config/partner/getuse/list`, { withCredentials: true, });
    return partnerResponse?.data;
}

async function savePartner(params){
    const savepartnerResponse = await axios.post(`config/partner/save`, params, { withCredentials : true, });
    return savepartnerResponse?.data;
}

async function updatePartner(partner_id, params){
    const updatepartnerResponse = await axios.post(`config/partner/${partner_id}/update`, params, { withCredentials : true, });
    return updatepartnerResponse?.data;
}

async function deletePartner(partner_id){
    const deletePartnerResponse = await axios.delete(`config/partner/${partner_id}/delete`, { withCredentials : true, });
    return deletePartnerResponse?.data;
}

async function getPartnerInoutboundSources(query){
    const queryString = serviceHelper.queryString(query);
    const partnerResponse = await axios.get(`config/partner_inoutbound_source/get?${queryString}`, { withCredentials : true, });
    return partnerResponse?.data;
}

export default {
    getPartners,
    getPartnerInoutboundSources,
    getPartnerList,
    getUsedPartnerList,
    savePartner,
    updatePartner,
    deletePartner,
}