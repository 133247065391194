import React, { useState, useEffect } from 'react';
import MenuTitleComponent from '../../../helpers/components/menu_title.component';
import { Button, Checkbox, Drawer, Form, Input, List, message, Radio, Space, notification } from 'antd';
import { CaretDownFilled, CloseCircleFilled, SearchOutlined } from '@ant-design/icons';
import FilterBullet from '../../../helpers/components/filter_bullet';
import MaterialList from './material_list';
import MaterialEditDetail from './material_edit_detail';
import materialService from '../../../services/config/material.service';
import { useDispatch } from 'react-redux';
import { refresh_material_list } from '../../../actions/config.action';
import materialCategoryService from '../../../services/config/material_category.service';
import uomService from '../../../services/config/uom.service';

const ConfigMaterialLayout = () => {
    const [messageApi, contextHolder] = message.useMessage();
    const { innerWidth } = window;
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const [state, setState] = useState({
        open_drawer : false,
        save_loading : false,

        // selections
        material_categories : [],
        uoms : [],

        filter_changed : false,
    })
    const [filter, setFilter] = useState({
        search : null,
        category : null,
        uom : null,
        is_end_product : null,
    });
    const [timer, setTimer] = useState(null);

    const doneTyping = async (input) => {
        clearTimeout(timer);
        const newTimer = setTimeout(() => {
            setFilter(filter => ({ ...filter, search: input, }));
        }, 300);
        setTimer(newTimer);
    }

    const onCheckChange = (key, checkedValues) => {
        setFilter(filter => ({...filter, [key] : checkedValues}));
        setState(state => ({ ...state, filter_changed: true, }));
    }

    const error = (err) => {
        messageApi.open({
          type: 'error',
          content: err,
        });
      };

    const saveMaterials = async () => {
        setState(state => ({...state, save_loading : true,}));
        const { description, material_category_id, material_code, name, material_type_id, uom_id, } = form.getFieldsValue();
        const saveMaterialResponse = await materialService.saveMaterial({
            description, material_category_id, material_code, name, material_type_id, uom_id,
        });

        setTimeout(() => {
            dispatch(refresh_material_list());
            setState(state => ({...state, save_loading : false, }));

            if(saveMaterialResponse)
                {
                    setState(state => ({...state, open_drawer : null, }));
                    form.resetFields();
                    notification?.success({
                    message : "Success",
                    description : `Successfully saved item ${name}`
                })}
        }, 500);
    }

    const fetchInitialization = async () => {
        const materialCategoryResponse = await materialCategoryService?.getMaterialCategoryList({});
        const uomResponse = await uomService?.getUomList({})
        
        
        setState(state => ({...state, uoms : uomResponse?.uoms, material_categories : materialCategoryResponse?.material_categories,}))
    }

    useEffect(() => {
        fetchInitialization();
    }, [])
    
    return (
        <>
            <div>
                <div style={{textAlign:'start',}}>
                    <MenuTitleComponent />
                </div>

                <div>
                    <div className='filter-panel' style={{marginBottom:12,}}>
                        <div>
                            <Input
                            onChange={(e) => doneTyping(e.target.value)}
                            name="search"
                            prefix={<SearchOutlined style={{color :'var(--secondary-text-color)'}} />}
                            placeholder='Search Item'
                            style={{minWidth: '100%',}}
                            allowClear
                            />
                        </div>

                        <div style={{display:'flex', alignItems:'center', marginTop:12, gap:8,}}>
                            <div>
                                <div className='filter-panel' style={{textAlign:'start'}}>
                                    <Space direction={innerWidth > 768 ? 'horizontal' : 'vertical'}>
                                        {/* <FilterBullet
                                        popover={
                                            <>
                                                <div style={{padding:12,}}>
                                                    <Input
                                                    prefix={<SearchOutlined style={{color :'var(--secondary-text-color)'}}/>}
                                                    placeholder='Search Item Type'
                                                    allowClear
                                                    />
                                                </div>
                                                
                                                <Radio.Group
                                                value={filter?.is_end_product}
                                                onChange={(e) => onCheckChange('is_end_product', e.target.value)}
                                                style={{ display:'block', }}
                                                >
                                                    <div style={{padding:'0 12px',}}>
                                                        <List
                                                            dataSource={[{
                                                                value: true,
                                                                label: "Product"
                                                            }, {
                                                                value: false,
                                                                label: "Raw Material"
                                                            }]}
                                                            renderItem={(item, index) => {
                                                                const { value, label, } = item ?? {};
                                                                return (
                                                                    <List.Item style={{ width: '100%' }} key={index}>
                                                                        <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                                                                            <div>
                                                                                <span>{label}</span>
                                                                            </div>
                                                                            <div style={{ marginLeft: 'auto' }}>
                                                                                <Radio
                                                                                    value={value}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </List.Item>
                                                                )
                                                            }}
                                                        />
                                                    </div>
                                                    
                                                </Radio.Group>
                                            </>
                                        }
                                        >
                                            <div>
                                                <Space>
                                                    <div>
                                                        <span>{`Type ${filter?.is_end_product != null ? `: ${(filter?.is_end_product ? "Product" : "Raw Material")}` : "" }`}</span>
                                                    </div>

                                                    {
                                                        filter?.is_end_product != null ?
                                                        (
                                                            <>
                                                                <CloseCircleFilled onClick={() => onCheckChange('is_end_product', null)} style={{color:'#a0aec0'}} />
                                                            </>
                                                        )
                                                        :
                                                        (
                                                            <>
                                                                <div>
                                                                    <CaretDownFilled style={{color:'#a0aec0'}} />
                                                                </div>
                                                            </>
                                                        )
                                                    }
                                                    
                                                </Space>
                                                
                                            </div>
                                        </FilterBullet> */}
                                        <FilterBullet
                                        popover={
                                            <>
                                                <div style={{ maxHeight:400, overflowY:'auto'}}>
                                                    <div style={{padding:12,}}>
                                                        <Input
                                                        prefix={<SearchOutlined style={{color :'var(--secondary-text-color)'}}/>}
                                                        placeholder='Search Categories'
                                                        allowClear
                                                        onChange={async (e) => {
                                                            const materialCategoryResponse = await materialCategoryService?.getMaterialCategories({ search: e.target.value, size:100 });
                                                            setState(state => ({ ...state, material_categories: materialCategoryResponse?.material_categories["rows"] }))
                                                        }}
                                                        />
                                                    </div>
                                                    {/* <div> */}
                                                    <Checkbox.Group
                                                    value={filter?.category}
                                                    onChange={(checkedValues) => onCheckChange('category', checkedValues)}
                                                    style={{ display:'block', }}
                                                    >
                                                        <div style={{padding:'0 12px',}}>
                                                            <List
                                                            dataSource={state?.material_categories}
                                                            renderItem={(item, index) => {
                                                                const { material_category_id, name, } = item ?? {};
                                                                return (
                                                                    <List.Item style={{width:'100%'}} key={index}>
                                                                        <div style={{display:'flex', alignItems:'center', width:'100%'}}>
                                                                            <div>
                                                                                <span>{name}</span>
                                                                            </div>
                                                                            <div style={{marginLeft:'auto'}}>
                                                                                <Checkbox
                                                                                value={material_category_id}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </List.Item>
                                                                )
                                                            }}
                                                            />

                                                        </div>
                                                        
                                                    </Checkbox.Group>
                                                    {/* </div> */}
                                                </div>               
                                            </>
                                        }
                                        >
                                            <div>
                                                <Space>
                                                    <div>
                                                        <span>{`Category ${filter?.category?.length > 0 ? `: ${filter?.category?.length} items` : "" }`}</span>
                                                    </div>

                                                    <div>
                                                        <CaretDownFilled style={{color:'#a0aec0'}} />
                                                    </div>
                                                    
                                                </Space>
                                                
                                            </div>
                                        </FilterBullet>
                                        <FilterBullet
                                        popover={
                                            <>
                                                <div style={{maxHeight:400, overflowY:'auto'}}>
                                                    <div style={{padding:12,}}>
                                                        <Input
                                                        prefix={<SearchOutlined style={{color :'var(--secondary-text-color)'}}/>}
                                                        placeholder='Search UOM'
                                                        allowClear
                                                        onChange={async (e) => {
                                                            const uomResponse = await uomService?.getUoms({ search: e.target.value, size:100});
                                                            setState(state => ({ ...state, uoms: uomResponse?.uoms["rows"] }))
                                                        }}
                                                        />
                                                    </div>

                                                    <div>
                                                            <Checkbox.Group
                                                                value={filter?.uom}
                                                                onChange={(checkedValues) => onCheckChange('uom', checkedValues)}
                                                                style={{ display: 'block', }}
                                                            >
                                                                <div style={{ padding: '0 12px', }}>
                                                                    <List
                                                                        dataSource={state?.uoms}
                                                                        renderItem={(item, index) => {
                                                                            const { code, name, uom_id, } = item ?? {};
                                                                            return (
                                                                                <List.Item style={{ width: '100%' }} key={index}>
                                                                                    <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                                                                                        <div>
                                                                                            <span>{`${code} (${name})`}</span>
                                                                                        </div>
                                                                                        <div style={{ marginLeft: 'auto' }}>
                                                                                            <Checkbox
                                                                                                value={uom_id}
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                </List.Item>
                                                                            )
                                                                        }}
                                                                    />

                                                                </div>

                                                            </Checkbox.Group>
                                                        {/* <Checkbox.Group
                                                        value={filter?.uom}
                                                        onChange={(checkedValues) => onCheckChange('uom', checkedValues)}
                                                        style={{ display:'block', }}
                                                        >
                                                            {
                                                                state?.uoms?.map((default_uom, index) => {
                                                                    const { label, options } = default_uom;
                                                                    const name = label ? "Default Category" : "Custom Category";
                                                                    return (
                                                                        <>
                                                                            {
                                                                                options?.length > 0 &&
                                                                                (
                                                                                    <>
                                                                                        <div style={{padding:'0 12px',}}>
                                                                                            <div>
                                                                                                <span style={{fontSize:12, color:'var(--secondary-text-color)'}}>{name}</span>
                                                                                            </div>
                                                                                            <div>
                                                                                                <List
                                                                                                dataSource={options}
                                                                                                renderItem={(uom, index) =>{
                                                                                                    const { code, name, uom_id } = uom;
                                                                                                    return (
                                                                                                        <List.Item style={{width:'100%'}} key={index}>
                                                                                                            <div style={{display:'flex', alignItems:'center', width:'100%'}}>
                                                                                                                <div>
                                                                                                                    <span>{`${code} (${name})`}</span>
                                                                                                                </div>
                                                                                                                <div style={{marginLeft:'auto'}}>
                                                                                                                    <Checkbox
                                                                                                                    value={uom_id}
                                                                                                                    />
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </List.Item>
                                                                                                    )
                                                                                                }}
                                                                                                />
                                                                                            </div>
                                                                                        </div>
                                                                                    </>
                                                                                )
                                                                            }
                                                                            
                                                                        </>
                                                                    )
                                                                })
                                                            }
                                                        </Checkbox.Group> */}
                                                    </div>
                                                </div>
                                            </>
                                        }
                                        >
                                            <div>
                                                <Space>
                                                    <div>
                                                        <span>{`UOM ${filter?.uom?.length > 0 ? `: ${(filter?.uom?.length)} items` : "" }`}</span>
                                                    </div>

                                                    <div>
                                                        <CaretDownFilled style={{color:'#a0aec0'}} />
                                                    </div>
                                                    
                                                </Space>
                                                
                                            </div>
                                        </FilterBullet>
                                    </Space>
                                </div>
                            </div>
                            <div style={{marginLeft:'auto'}}>
                                <Button onClick={() => setState(state => ({...state, open_drawer : true,}))} type='primary'>New Item</Button>
                            </div>
                        </div>
                    </div>
                    <div>
                        <MaterialList filter={filter} filter_changed={state?.filter_changed} />
                    </div>
                </div>
            </div>

            {/* detail */}
            <Drawer
            title="Item Detail"
            rootClassName='drawer-detail-root'
            className='drawer-detail'
            open={state?.open_drawer}
            onClose={() => setState(state => ({...state, open_drawer : false, }))}
            width={innerWidth >= 768 ? 800 : "100%"}
            footer={
                <>
                    <div style={{display:'flex', padding:'12px 0',}}>
                        <div style={{marginLeft:'auto',}}>
                            <Space>
                                <Button loading={state?.save_loading} type='primary' onClick={() => form.submit()}>Save Changes</Button>
                            </Space>
                        </div>
                    </div>
                </>
            }
            >
                <div>
                {contextHolder}
                    <Form
                    onFinish={saveMaterials}
                    form={form}
                    layout='vertical'
                    >
                        <MaterialEditDetail form={form} />
                    </Form>
                </div>
            </Drawer>
        </>
    );
}

export default ConfigMaterialLayout;