import React, { useState } from 'react';
import MenuTitleComponent from '../../../helpers/components/menu_title.component';
import { Button, Input, Space } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import LineGridDraggable from './line_grid_draggable';

const LineLayout = () => {
    const { innerWidth } = window;
    const [timer, setTimer] = useState(null);
    const [state, setState] = useState({
        open_drawer : false,
    });
    const [filter, setFilter] = useState({});

    const doneTyping = async (input) => {
        clearTimeout(timer);
        const newTimer = setTimeout(() => {
            setFilter(filter => ({ ...filter, search: input, }));
        }, 300);
        setTimer(newTimer);
    }

    return (
        <>
            <div>
                <div style={{textAlign:'start',}}>
                    <MenuTitleComponent />
                </div>

                <div className='filter-panel' style={{marginBottom:12,}}>
                    <div>
                        <Input
                        onChange={(e) => doneTyping(e.target.value)}
                        name="search"
                        prefix={<SearchOutlined style={{color :'var(--secondary-text-color)'}} />}
                        placeholder='Search Production Line'
                        style={{minWidth: '100%',}}
                        allowClear
                        />
                    </div>

                    {/* <div style={{display:'flex', alignItems:'center', marginTop:12, gap:8,}}>
                        <div>
                            <div className='filter-panel' style={{textAlign:'start'}}>
                                <Space direction={innerWidth > 768 ? 'horizontal' : 'vertical'}>
                                </Space>
                            </div>
                        </div>

                        <div style={{marginLeft:'auto'}}>
                            <Button onClick={() => setState(state => ({...state, open_drawer : true,}))} type='primary'>New Production Line</Button>
                        </div>
                    </div> */}
                </div>

                <div>
                    <LineGridDraggable filter={filter} />
                </div>
            </div>
        </>
    );
}

export default LineLayout;