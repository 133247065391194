import axios from 'axios';
import serviceHelper from '../../helpers/functions/service.helper';

async function getMaterialTypes(query){
    const queryString = serviceHelper.queryString(query);
    const materialTypeResponse = await axios.get(`config/material_type/get?${queryString}`, { withCredentials : true, });
    return materialTypeResponse?.data;
}

async function getMaterialTypeList(query){
    const queryString = serviceHelper.queryString(query);
    const materialTypeResponse = await axios.get(`config/material_type/get/list?${queryString}`, { withCredentials : true, });
    return materialTypeResponse?.data;
}

async function getUsedMaterialTypeList(query) {
    const queryString = serviceHelper.queryString(query);
    const materialTypeResponse = await axios.get(`config/material_type/getuse/list`, { withCredentials: true, });
    return materialTypeResponse?.data;
}

async function saveMaterialType(params){
    const saveMaterialTypeResponse = await axios.post(`config/material_type/save`, params, { withCredentials : true, });
    return saveMaterialTypeResponse?.data;
}

async function updateMaterialType(material_type_id, params){
    const updateMaterialTypeResponse = await axios.post(`config/material_type/${material_type_id}/update`, params, { withCredentials : true, });
    return updateMaterialTypeResponse?.data;
}

async function deleteMaterialType(material_type_id){
    const deleteMaterialTypeResponse = await axios.delete(`config/material_type/${material_type_id}/delete`, { withCredentials : true, });
    return deleteMaterialTypeResponse?.data;
}

export default {
    getMaterialTypes,
    getMaterialTypeList,
    getUsedMaterialTypeList,
    saveMaterialType,
    updateMaterialType,
    deleteMaterialType,
}