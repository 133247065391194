import React, { useEffect, useState } from 'react';
import InOutboundSourceList from './inoutbound_source_list';
import MenuTitleComponent from '../../../helpers/components/menu_title.component';
import { Button, Drawer, Input, Space, Form, notification, List, Radio } from 'antd';
import { CaretDownFilled, SearchOutlined, CloseCircleFilled } from '@ant-design/icons';
import FilterBullet from '../../../helpers/components/filter_bullet';
import InOutboundSourceEditDetail from './inoutbound_source_edit_detail';
import inoutboundSourceService from '../../../services/config/inoutbound_source.service';
import { useDispatch } from 'react-redux';
import { refresh_inoutbound_sources_list } from '../../../actions/warehouse.action';
import warehouseService from '../../../services/config/warehouse.service';

const InOutboundSourceLayout = () => {
    const dispatch = useDispatch();
    const { innerWidth } = window;
    const [form] = Form.useForm();
    const [timer, setTimer] = useState(null);
    const [filter, setFilter] = useState({
        search : null,
    });
    const [state, setState] = useState({
        open_drawer : false,
        save_loading : false,
    })

    const doneTyping = async (input) => {
        clearTimeout(timer);
        const newTimer = setTimeout(() => {
            setFilter(filter => ({ ...filter, search: input, }));
        }, 300);
        setTimer(newTimer);
    }

    const onCheckChange = (key, checkedValues) => {
        setFilter(filter => ({...filter, [key] : checkedValues}));
    }

    const saveInOutboundSource = async () => {
        setState(state => ({...state, save_loading : true,}));
        const { name, code, inoutbound_type_ids, } = form?.getFieldsValue();
        
        const saveInOutboundSourceResponse = await inoutboundSourceService?.saveInOutboundSource({ name, code, inoutbound_type_ids,});

        //successful
        setTimeout(() => {
            dispatch(refresh_inoutbound_sources_list());
            setState(state => ({...state, save_loading : false, open_drawer : false,}));
            form.resetFields();

            //success message
            notification?.success({
                message : "Success",
                description : `Successfully saved inoutbound_source ${name}`
            })
        }, 500);
    }

    const fetchInitialization = async () => {
        // const warehouseResponse = await warehouseService?.getWarehouses();

        // setState(state => ({...state, warehouses : warehouseResponse?.warehouses}));
    }

    useEffect(() => {
        fetchInitialization();
    }, []);

    return (
        <>
            <div>
                <div style={{textAlign:'start',}}>
                    <MenuTitleComponent />
                </div>

                <div>
                    <div className='filter-panel' style={{marginBottom:12,}}>
                        <div>
                            <Input
                            onChange={(e) => doneTyping(e.target.value)}
                            name="search"
                            prefix={<SearchOutlined style={{color :'var(--secondary-text-color)'}} />}
                            placeholder='Search Source'
                            style={{minWidth: '100%',}}
                            allowClear
                            />
                        </div>

                        <div style={{display:'flex', alignItems:'center', marginTop:12, gap:8,}}>
                            
                            <div style={{marginLeft:'auto'}}>
                                <Button onClick={() => setState(state => ({...state, open_drawer : true,}))} type='primary'>New Source</Button>
                            </div>
                        </div>
                    </div>
                    <div>
                        <InOutboundSourceList filter={filter} />
                    </div>
                </div>
            </div>


            {/* detail */}
            <Drawer
            title="Source Detail"
            rootClassName='drawer-detail-root'
            className='drawer-detail'
            open={state?.open_drawer}
            onClose={() => setState(state => ({...state, open_drawer : false,}))}
            width={innerWidth >= 768 ? 800 : "100%"}
            footer={
                <>
                    <div style={{display:'flex', padding:'12px 0',}}>
                        <div style={{marginLeft:'auto',}}>
                            <Space>
                                <Button loading={state?.save_loading} type='primary' onClick={() => form.submit()}>Save</Button>
                            </Space>
                        </div>
                    </div>
                </>
            }
            >
                <div>
                    <Form
                    onFinish={saveInOutboundSource}
                    form={form}
                    layout='vertical'
                    >
                        <InOutboundSourceEditDetail form={form} />
                    </Form>
                </div>
            </Drawer>
        </>
    );
}

export default InOutboundSourceLayout;