import axios from 'axios';
import serviceHelper from '../../helpers/functions/service.helper';

async function getBoms(query){
    const queryString = serviceHelper.queryString(query);
    const bomResponse = await axios.get(`production/bom/get?${queryString}`, { withCredentials : true, });
    return bomResponse?.data;
}

async function getBomList(query){
    const queryString = serviceHelper.queryString(query);
    const bomResponse = await axios.get(`production/bom/get/list?${queryString}`, { withCredentials : true, });
    return bomResponse?.data;
}

async function getUsedBomList(query) {
    const queryString = serviceHelper.queryString(query);
    const bomResponse = await axios.get(`production/bom/getuse/list`, { withCredentials: true, });
    return bomResponse?.data;
}

async function getBomMaterials(bom_id, query){
    const queryString = serviceHelper.queryString(query);
    const bomMaterialResponse = await axios.get(`production/bom/${bom_id}/bom_material/get?${queryString}`, { withCredentials : true, });
    
    return bomMaterialResponse?.data;
}

async function saveBom(params){
    const saveBomResponse = await axios.post(`production/bom/save`, params, { withCredentials : true, });
    return saveBomResponse?.data;
}

async function updateBom(bom_material_id, params){
    const updateBomResponse = await axios.post(`production/bom/${bom_material_id}/update`, params, { withCredentials : true, });
    return updateBomResponse?.data;
}

async function deleteBom(bom_id){
    const deleteBomResponse = await axios.delete(`production/bom/${bom_id}/delete`, { withCredentials : true, });
    return deleteBomResponse?.data;
}

export default {
    getBoms,
    getBomList,
    getUsedBomList,
    getBomMaterials,
    saveBom,
    updateBom,
    deleteBom,
}