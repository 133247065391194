import React, { useEffect, useState } from 'react';
import inoutboundSourceService from '../../../services/config/inoutbound_source.service';
import inoutboundTypeService from '../../../services/config/inoutbound_type.service';
import { Col, Divider, Form, Input, Radio, Row, Skeleton, Select, Space, Switch } from 'antd';

const { TextArea } = Input;
const InOutboundSourceEditDetail = ({ inoutbound_source_id, form }) => {
    const [state, setState] = useState({
        loading : false,
        inoutbound_source : null,

        inoutbound_types : [],
    })

    const fetchInOutboundSources = async () => {
        setState(state => ({...state, loading : true,}));
        const inoutboundSourceResponse = await inoutboundSourceService?.getInOutBoundSources({
            inoutbound_source_id : inoutbound_source_id,
        });

        const inoutbound_sources = inoutboundSourceResponse?.dataList['rows'];
        const inoutbound_source = inoutbound_sources?.length > 0 ? inoutbound_sources[0] : null;

        setTimeout(() => {
            //set form
            const { name, code, inoutbound_types, } = inoutbound_source;
            form.setFieldsValue({ name, code, inoutbound_type_ids: inoutbound_types?.map(x => x?.inoutbound_type_id,) });

            setState(state => ({...state, loading : false, inoutbound_source : inoutbound_source}));
        }, 500);
        
    }

    const fetchInitialization = async () => {
        setState(state => ({...state, loading : true,}));

        const inoutboundSourceResponse = await inoutboundTypeService?.getInOutBoundTypes();
        const { inoutbound_types } = inoutboundSourceResponse;

        setTimeout(() => {
            setState(state => ({...state, loading : false, inoutbound_types : inoutbound_types}));
        }, 500);
    }

    useEffect(() => {
        inoutbound_source_id && fetchInOutboundSources();
    }, [inoutbound_source_id]);

    useEffect(() => {
        fetchInitialization();
    }, [])

    return (
        <>
            {
                state?.loading ?
                (
                    <>
                        <div>
                            <Skeleton active />
                        </div>
                    </>
                )
                :
                (
                    <>
                        <div>
                            <div style={{background : '#edf2f7', border:'1.5px solid #cbd5e0', padding : '12px 12px 0 12px', borderRadius:8,}}>
                                <Row gutter={[12, 12]}>
                                    <Col xs={24} md={12}>
                                        <Form.Item name={'name'} label={'Source Name'}>
                                            <Input placeholder='Source Name' />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} md={12}>
                                        <Form.Item name={'code'} label={'Code'}>
                                            <Input placeholder='Code' />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </div>
                            <Divider style={{marginBottom:12,}} />
                            {/* Warehouses */}
                            <div>
                                <div>
                                    <span style={{fontWeight:500, fontSize:16,}}>Assign to</span>
                                </div>

                                <div style={{marginTop:12,}}>
                                    <Form.Item name={'inoutbound_type_ids'}>
                                        <Select
                                        placeholder="Select Type"
                                        mode='multiple'
                                        options={state?.inoutbound_types?.map(x =>  ({ value : x?.inoutbound_type_id, label : x?.name, }))}
                                        />
                                    </Form.Item>
                                </div>
                            </div>

                            <Divider />
                        </div>
                    </>
                )
            }
        </>
    );
}

export default InOutboundSourceEditDetail;