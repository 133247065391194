export const refresh_warehouse_list = () => {
    return {
        type : 'REFRESH_WAREHOUSE_LIST',
    };
}

export const refresh_material_list = () => {
    return {
        type : 'REFRESH_MATERIAL_LIST',
    };
}

export const refresh_uom_list = () => {
    return {
        type : 'REFRESH_UOM_LIST',
    };
}

export const refresh_material_category_list = () => {
    return {
        type : 'REFRESH_MATERIAL_CATEGORY_LIST',
    };
}

export const refresh_material_type_list = () => {
    return {
        type : 'REFRESH_MATERIAL_TYPE_LIST',
    };
}

export const refresh_user_list = () => {
    return {
        type : 'REFRESH_USER_LIST',
    }
}

export const refresh_user_role_list = () => {
    return {
        type : 'REFRESH_USER_ROLE_LIST',
    }
}

export const refresh_partner_list = () => {
    return {
        type : 'REFRESH_PARTNER_LIST',
    }
}