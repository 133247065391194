const initialState = {
    warehouse_list_refresh : 0,
    material_list_refresh : 0,
    uom_list_refresh : 0,
    material_category_list_refresh : 0,
    material_type_list_refresh : 0,
    user_list_refresh : 0,
    user_role_list_refresh : 0,
    partner_list_refresh : 0,
}

const configReducer = (state = initialState, action) => {
    switch(action.type){
        case 'REFRESH_WAREHOUSE_LIST' :
            return {...state, warehouse_list_refresh : (state.warehouse_list_refresh + 1)}
        case 'REFRESH_MATERIAL_LIST' :
            return {...state, material_list_refresh : (state.material_list_refresh + 1)}
        case 'REFRESH_UOM_LIST' :
            return {...state, uom_list_refresh : (state?.uom_list_refresh + 1)}
        case 'REFRESH_MATERIAL_CATEGORY_LIST' :
            return {...state, material_category_list_refresh : (state?.material_category_list_refresh + 1)}
        case 'REFRESH_MATERIAL_TYPE_LIST' :
            return {...state, material_type_list_refresh : (state?.material_type_list_refresh + 1)}
        case 'REFRESH_USER_LIST' : 
            return {...state, user_list_refresh : (state?.user_list_refresh + 1)}
        case 'REFRESH_USER_ROLE_LIST' : 
            return {...state, user_role_list_refresh : (state?.user_role_list_refresh + 1)}    
        case 'REFRESH_PARTNER_LIST' : 
            return {...state, partner_list_refresh : (state?.partner_list_refresh + 1)}
        default :
            return state;
    }
}

export default configReducer;