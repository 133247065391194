import React, { useEffect, useState } from 'react';
import MenuTitleComponent from '../../../helpers/components/menu_title.component';
import { Button, Checkbox, Input, Space, Form, notification, List, Radio } from 'antd';
import { CaretDownFilled, SearchOutlined, CloseCircleFilled } from '@ant-design/icons';
import FilterBullet from '../../../helpers/components/filter_bullet';
import { useDispatch } from 'react-redux';
import locationMaterialService from '../../../services/warehouse/location_material.service';
import stockService from '../../../services/warehouse/stock.service';
import StockList from './stock_list';
import moment from 'moment';
import GeneralLabel from '../inbound/general_label';
import PageHeaderComponent from '../../../helpers/components/page_header.component';

import materialService from '../../../services/config/material.service';
import warehouseService from '../../../services/config/warehouse.service';

const StockLayout = () => {
    const { innerWidth } = window;
    const [timer, setTimer] = useState(null);
    const [state, setState] = useState({
        open_drawer : false,

        //selections
        warehouses : [],
        materials : [],
    });
    const [filter, setFilter] = useState({
        search : null,
        warehouse : null,
        material : null,
    });

    const doneTyping = async (input) => {
        clearTimeout(timer);
        const newTimer = setTimeout(() => {
            setFilter(filter => ({ ...filter, search: input, }));
        }, 300);
        setTimer(newTimer);
    }

    const onCheckChange = (key, checkedValues) => {
        setFilter(filter => ({...filter, [key] : checkedValues}));
    }

    const fetchInitialization = async () => {
        // let locationMaterialResponse = await locationMaterialService?.getLocationMaterialByMaterial({});
        // const { dataList:locations }  = locationMaterialResponse;
        // locationMaterialResponse = await locationMaterialService?.getLocationMaterialByLocation({});
        // const { dataList:materials }  = locationMaterialResponse;
        const materialResponse = await materialService?.getMaterials({});
        const { rows:materials } = materialResponse.materials;
        const warehouseResponse = await warehouseService?.getWarehouses({});
        const { warehouses } = warehouseResponse;
 
        let stockResponse = await locationMaterialService?.getLocationMaterialByMaterial({});
        const { dataList:stocks }  = stockResponse;

        setState(state => ({ ...state, warehouses: warehouses, materials : materials,}))
    }

    useEffect(() => {
        fetchInitialization();
    }, [])

    return (
        <>
            <div>
                <div style={{textAlign:'start',}}>
                    {/* <MenuTitleComponent /> */}
                    <div style={{marginBottom:20,}}>
                        <div>
                            <PageHeaderComponent title='Stock' />
                        </div>
                        <div>
                            <span style={{color:'var(--secondary-text-color)'}}>{state?.menu?.description}</span>
                        </div>
                    </div>
                </div>

                <div>
                    <div className='filter-panel' style={{marginBottom:12,}}>
                        <div>
                            <Input
                            onChange={(e) => doneTyping(e.target.value)}
                            name="search"
                            prefix={<SearchOutlined style={{color :'var(--secondary-text-color)'}} />}
                            placeholder='Search Stock'
                            style={{minWidth: '100%',}}
                            allowClear
                            />
                        </div>
                    </div>

                    <div style={{display:'flex', alignItems:'center', marginTop:12, gap:8,}}>
                            <div>
                                <div className='filter-panel' style={{textAlign:'start'}}>
                                    <Space direction={innerWidth > 768 ? 'horizontal' : 'vertical'}>
                                    <FilterBullet
                                        popover={
                                            <>
                                                <div style={{padding:12,}}>
                                                    <Input
                                                    prefix={<SearchOutlined style={{color :'var(--secondary-text-color)'}}/>}
                                                    placeholder='Search Item'
                                                    allowClear
                                                    onChange={async (e) => {
                                                        const materialResponse = await materialService?.getMaterials({ search: e.target.value, size:100 });
                                                        setState(state => ({ ...state, materials: materialResponse?.materials["rows"] }))
                                                    }}
                                                    />
                                                </div>
                                                <Checkbox.Group
                                                value={filter?.material}
                                                onChange={(checkedValues) => onCheckChange('material', checkedValues)}
                                                style={{ display:'block', }}
                                                >
                                                    <div style={{padding:'0 12px',}}>
                                                        <List
                                                        style={{ width:280, }}
                                                        dataSource={state?.materials}
                                                        renderItem={(item, index) => {
                                                            const { material_id, name, } = item ?? {};
                                                            return (
                                                                <List.Item style={{width:'100%'}} key={index}>
                                                                    <div style={{display:'flex', alignItems:'center', width:'100%'}}>
                                                                        <div>
                                                                            <span>{name}</span>
                                                                        </div>
                                                                        <div style={{marginLeft:'auto'}}>
                                                                            <Checkbox
                                                                            value={material_id}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </List.Item>
                                                            )
                                                        }}
                                                        />
                                                    </div>
                                                    
                                                </Checkbox.Group>
                                            </>
                                        }
                                        >
                                            <div>
                                                <Space>
                                                    <div>
                                                        <span>{`Item ${filter?.material?.length > 0 ? `: ${filter?.material?.length} items` : "" }`}</span>
                                                    </div>

                                                    <div>
                                                        <CaretDownFilled style={{color:'#a0aec0'}} />
                                                    </div>
                                                    
                                                </Space>
                                                
                                            </div>
                                        </FilterBullet>
                                        <FilterBullet
                                        popover={
                                            <>
                                                <div style={{padding:12,}}>
                                                    <Input
                                                    prefix={<SearchOutlined style={{color :'var(--secondary-text-color)'}}/>}
                                                    placeholder='Search Warehouses'
                                                    allowClear
                                                    onChange={async (e) => {
                                                        const warehouseResponse = await warehouseService?.getWarehouses({ search: e.target.value, size:100 });
                                                        setState(state => ({ ...state, warehouses: warehouseResponse?.warehouses }))
                                                    }}
                                                    />
                                                </div>
                                                <Checkbox.Group
                                                value={filter?.warehouse}
                                                onChange={(checkedValues) => onCheckChange('warehouse', checkedValues)}
                                                style={{ display:'block', }}
                                                >
                                                    <div style={{padding:'0 12px',}}>
                                                        <List
                                                        style={{ width:280, }}
                                                        dataSource={state?.warehouses}
                                                        renderItem={(item, index) => {
                                                            const { warehouse_id, name, } = item ?? {};
                                                            return (
                                                                <List.Item style={{width:'100%'}} key={index}>
                                                                    <div style={{display:'flex', alignItems:'center', width:'100%'}}>
                                                                        <div>
                                                                            <span>{name}</span>
                                                                        </div>
                                                                        <div style={{marginLeft:'auto'}}>
                                                                            <Checkbox
                                                                            value={warehouse_id}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </List.Item>
                                                            )
                                                        }}
                                                        />
                                                    </div>
                                                    
                                                </Checkbox.Group>
                                            </>
                                        }
                                        >
                                            <div>
                                                <Space>
                                                    <div>
                                                        <span>{`Warehouse ${filter?.warehouse?.length > 0 ? `: ${filter?.warehouse?.length} items` : "" }`}</span>
                                                    </div>

                                                    <div>
                                                        <CaretDownFilled style={{color:'#a0aec0'}} />
                                                    </div>
                                                    
                                                </Space>
                                                
                                            </div>
                                        </FilterBullet>
                                        
                                    </Space>
                                </div>
                            </div>
                        </div>

                    <div style={{ marginTop:12}}>
                        <StockList filter={filter} />
                    </div>
                </div>
            </div>
        </>
    );
}

export default StockLayout;

