import React, { useState } from 'react';
import MenuTitleComponent from '../../../helpers/components/menu_title.component';
import { Button, Form, Input, Modal, Space, notification } from 'antd';
import BomList from './bom_list';
import { SearchOutlined } from '@ant-design/icons';
import BomEditBasic from './bom_edit_basic';
import bomService from '../../../services/production/bom.service';
import { useDispatch } from 'react-redux';
import { refresh_bom_list } from '../../../actions/production.action';
import { useHistory } from 'react-router-dom';

const BomLayout = () => {
    const { innerWidth } = window;
    const history = useHistory();
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const [timer, setTimer] = useState(null);
    const [state, setState] = useState({
        open_modal : false,
        save_loading : false,
    })
    const [filter, setFilter] = useState({
        search : null,
    })

    const doneTyping = async (input) => {
        clearTimeout(timer);
        const newTimer = setTimeout(() => {
            setFilter(filter => ({ ...filter, search: input, }));
        }, 300);
        setTimer(newTimer);
    }

    const saveBom = async () => {
        setState(state => ({...state, save_loading : true, }));
        let { name, quantity, } = (form.getFieldsValue());
        quantity = quantity ?? 1;

        const saveBomResponse = await bomService?.saveBom({ name, quantity, });
        if(saveBomResponse){
            const { save_bom } = saveBomResponse;
            setTimeout(() => {
                setState(state => ({...state, save_loading : false}));
                dispatch(refresh_bom_list()); // not required as we redirect into page

                notification.success({
                    message : "Success",
                    description : `Successfully saved BOM ${name}`
                })
                history.push(`/production/bom/${save_bom?.bom_id}`)
            }, 500);
        }
        
    }

    return (
        <>
            <div>
                <div style={{textAlign:'start',}}>
                    <MenuTitleComponent />
                </div>

                <div className='filter-panel' style={{marginBottom:12,}}>
                    <div>
                        <Input
                        onChange={(e) => doneTyping(e.target.value)}
                        // name="search"
                        prefix={<SearchOutlined style={{color :'var(--secondary-text-color)'}} />}
                        placeholder='Search BOMs'
                        style={{minWidth: '100%',}}
                        allowClear
                        />
                    </div>

                    <div style={{display:'flex', alignItems:'center', marginTop:12, gap:8,}}>
                        <div>
                            <div className='filter-panel' style={{textAlign:'start'}}>
                                <Space direction={innerWidth > 768 ? 'horizontal' : 'vertical'}>
                                    
                                </Space>
                            </div>
                        </div>

                        <div style={{marginLeft:'auto'}}>
                            <Button onClick={() => setState(state => ({...state, open_modal : true,}))} type='primary'>New BOM</Button>
                        </div>
                    </div>
                </div>


                <div>
                    <BomList filter={filter} />
                </div>
            </div>


            <Modal
            open={state?.open_modal}
            onCancel={() => setState(state => ({...state, open_modal : false,}))}
            title="New BOM"
            width={600}
            confirmLoading={state?.save_loading}
            onOk={() => form.submit()}
            okText="Next"
            >
                <Form
                form={form}
                layout='vertical'
                onFinish={saveBom}
                >
                    <BomEditBasic form={form}/>
                </Form>
            </Modal>
        </>
    );
}

export default BomLayout;