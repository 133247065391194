import React, { useEffect, useState } from 'react';
import outboundService from '../../../services/warehouse/outbound.service';
import { Button, Dropdown, Form, Input, message, Modal, Popover, Select, Space, Spin, Table, notification } from 'antd';
import SearchMaterial from '../../production/bom/search_material';
import { CheckOutlined, CloseOutlined, DeleteOutlined, EditOutlined, EllipsisOutlined, LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import NewOutboundMaterialForm from './new_outbound_material_form';
import materialService from '../../../services/config/material.service';
import warehouseService from '../../../services/config/warehouse.service';
import locationService from '../../../services/warehouse/location.service';

const OutboundMaterial = ({ outbound_id, outbound_status, warehouse_id }) => {
    const [messageApi, contextHolder] = message.useMessage();
    const [form] = Form.useForm();
    const [new_form] = Form.useForm();
    const [state, setState] = useState({
        loading : false,
        open : false,
        outbound_materials : [],
        materials : [],
        locations : [],
        warehouses : [],
        locations_list : [],

        edit_mode : null,
        save_loading : null,
        edit_mode_loading : null,
        material_modal : false,
        new_modal : false,

        // edit
        edit_boms : [],

        refresh : 0,
    })
    
    const [field, setField] = useState({
        location_id : null,
    })

    const onFieldChange = async (key, value) => {
        debugger;
        setField(field => ({...field, [key] : value}));
    }

    const error = (err) => {
        messageApi.open({
          type: 'error',
          content: err,
        });
    };

    const initializeSelections = async () => {
        // fetch locations
        const locationResponse = await locationService?.getLocationsList();
        const warehouseResponse = await warehouseService?.getWarehouses();
        setState(state => ({...state, locations_list : locationResponse?.dataList, warehouses : warehouseResponse?.warehouses }));
    }

    const fetchOutboundMaterials = async () => {
        setState(state => ({...state, loading : true,}));
        const outboundMaterialsResponse = await outboundService?.getOutboundMaterials({
            outbound_id,
        });

        const { dataList:outbound_materials } = outboundMaterialsResponse;

        setState(state => ({...state, loading : false, outbound_materials : outbound_materials, }));
        // set into form
        form.setFieldsValue({ outbound_materials : outbound_materials,});
    }

    const customFunction = async (index, material_id) => {
        const materialResponse = await materialService?.getMaterials({ material_id });
        const { rows : materials, count } = materialResponse?.materials;
        const material = materials?.length > 0 ? materials[0] : null;

        let outbound_materials_arr = state?.outbound_materials ?? [];
        setState(state => ({...state, select_loading : index }));
        outbound_materials_arr[index].material_id = material_id;
        outbound_materials_arr[index].material = material;
        outbound_materials_arr[index].updated = true;
        setState(state => ({...state, open : false }));

        setTimeout(() => {
            setState(state => ({...state, outbound_materials : outbound_materials_arr, select_loading : null, }));
            //dynamic set form
            form.setFieldsValue({ outbound_materials : outbound_materials_arr, });
        }, 200);
    }

    const onSaveOutboundMaterial = async () => {
        setState(state => ({...state, save_loading : true, }));
        const { material_id, location_id, uom_id, quantity, } = new_form?.getFieldsValue();

        const outboundMaterialsResponse = await outboundService?.checkMaterialAvailableQuantity({
            material_id : material_id,
            location_id : location_id,
            uom_id : uom_id,
            quantity : quantity,
        });

        if(outboundMaterialsResponse.err)
        {
            setTimeout(async () => {
                error(outboundMaterialsResponse.err);
                setState(state => ({...state, save_loading : false, }));
            }, 1500)
            return;
        }
        
        const saveOutboundMaterialResponse = await outboundService?.saveOutboundMaterial({
            outbound_id : outbound_id,
            material_id,
            location_id,
            uom_id,
            quantity,
        });

        setTimeout(async () => {
            setState(state => ({...state, refresh : (state?.refresh + 1), save_loading : false, new_modal : false,}));
            if(saveOutboundMaterialResponse){
                const outboundMaterialsResponse = await outboundService?.getOutboundMaterials(outbound_id, material_id,);
                const { dataList:material } = outboundMaterialsResponse;
                notification?.success({
                    message : 'Successfully added item to outbound',
                    description : (
                        <>
                            <div>
                                <div>
                                    <span>{material?.name}</span>
                                </div>
                                <div>
                                    <span style={{color : 'var(--secondary-text-color)', fontSize:12,}}>{material?.material_code}</span>
                                </div>
                            </div>
                        </>
                    )
                })
            }
            
            new_form?.resetFields();
        }, 500);
    }

    const onUpdateOutboundMaterial = async (outbound_material_id) => {
        setState(state => ({...state, save_loading : outbound_material_id, }));
        const { outbound_materials, } = form?.getFieldsValue();
        const outbound_material = outbound_materials?.find(x => x.outbound_material_id === outbound_material_id);
        const { quantity, location_id, material, } = outbound_material ?? {};
        const { material_id } = material ?? {};
        const { uom_id } = material.uom_id ?? {};

        const outboundMaterialsResponse = await outboundService?.checkMaterialAvailableQuantity({
            material_id : material_id,
            location_id : location_id,
            uom_id : uom_id,
            quantity : quantity,
        });

        if(outboundMaterialsResponse.err)
        {
            setTimeout(async () => {
                error(outboundMaterialsResponse.err);
                setState(state => ({...state, save_loading : false, }));
            }, 1500)
            return;
        }

        const updateOutboundMaterialResponse = await outboundService?.updateOutboundMaterial(outbound_material_id, {
            material_id,
            quantity,
            location_id,
            uom_id,
        })
        
        if(updateOutboundMaterialResponse.err)
        {
            setTimeout(async () => {
                error(updateOutboundMaterialResponse.err);
                setState(state => ({...state, save_loading : false, }));
            }, 1500)
            return;
        }

        setTimeout(() => {
            setState(state => ({...state, save_loading : null, edit_mode : null, refresh : (state?.refresh + 1) }));
            notification?.success({
                message : 'Successfully updated item',
                description : (
                    <>
                        <div>
                            <div>
                                <span>{material?.name}</span>
                            </div>
                            <div>
                                <span style={{color : 'var(--secondary-text-color)', fontSize:12,}}>{material?.material_code}</span>
                            </div>
                        </div>
                    </>
                )
            })
        }, 500);
    }

    const deleteConfirm = async (outbound_material_id) => {
        const outbound_material = state?.outbound_materials?.find(x => x.outbound_material_id === outbound_material_id);
        const { material, } = outbound_material;
        Modal.confirm({
            title : 'Delete material from outbound',
            content : (
                <>
                    <div>
                        <div>
                            <span>{material?.name}</span>
                        </div>
                        <div>
                            <span style={{fontSize:12, color : 'var(--secondary-text-color)'}}>{material?.material_code}</span>
                        </div>
                    </div>
                </>
            ),
            onOk : () => {
                return new Promise(async (resolve, reject) => {
                    await onDeleteOutboundMaterial(outbound_material_id).then((result) => {
                        setTimeout(() => {
                            // notification
                            notification?.success({
                                message : 'Successfully deleted item from outbound',
                                description : (
                                    <>
                                        <div>
                                            <div>
                                                <span>{material?.name}</span>
                                            </div>
                                            <div>
                                                <span style={{fontSize:12, color : 'var(--secondary-text-color)'}}>{material?.material_code}</span>
                                            </div>
                                        </div>
                                    </>
                                )
                            })
                            setState(state => ({...state, refresh : (state?.refresh + 1)}));
                            resolve();
                        }, 500);
                    });
                    
                }).catch(() => console.log('Oops errors!'));
            }
        })
    }

    const onDeleteOutboundMaterial = async (outbound_material_id) => {
        const deleteOutboundMaterialResponse = await outboundService?.deleteOutboundMaterial(outbound_material_id);
        return deleteOutboundMaterialResponse;
    }

    const onRowChange = async (index, key, value) => {
        let outbound_materials = state?.outbound_materials ?? [];
        outbound_materials[index][key] = value;

        setState(state => ({...state, outbound_materials : outbound_materials, }));
        // //dynamic set form
        form.setFieldsValue({ outbound_materials : outbound_materials, });
    }

    const toggleEditMode = async (outbound_material_id) => {
        setState(state => ({...state, edit_mode_loading : outbound_material_id}))
        const locations = state?.locations_list.filter((x) => x.warehouse_id === warehouse_id);
        setState(state => ({...state, edit_mode : outbound_material_id, edit_mode_loading : null, locations : locations}));
    }

    const handleOpenChange = (newOpen) => {
        setState(state => ({...state, open : newOpen}));
    };

    useEffect(() => {
        fetchOutboundMaterials();
    }, [state?.refresh])

    useEffect(() => {
        initializeSelections();
    }, [])

    return (
        <>
            <Spin spinning={state?.loading} indicator={<LoadingOutlined />}>
                <div style={{display : 'flex'}}>
                    {
                        (
                            <>
                                {
                                    outbound_status?.name == 'Delivered' || outbound_status?.is_complete ?
                                    (
                                        <>
                                        </>
                                    )
                                    :
                                    (
                                        <>
                                            <div style={{marginLeft:'auto'}}>
                                                <Button onClick={() => setState(state => ({...state, new_modal : true,}))} icon={<PlusOutlined style={{color:'var(--secondary-text-color)'}} />}>
                                                    Add Item
                                                </Button>
                                            </div>
                                        </>
                                    )
                                }
                            </>
                        )             
                    }
                    
                </div>
                <div style={{marginTop:12,}}>
                    <Form
                    form={form}
                    layout='vertical'
                    >
                        <Form.Item name='outbound_materials'>
                            <Table
                            pagination={false}
                            // scroll={{ x : 400, }}
                            className='hub-table bordered'
                            dataSource={state?.outbound_materials}
                            columns={[{
                                title : 'Item',
                                dataIndex : 'material',
                                key : 'material',
                                width : '30%',
                                render : (material, row, index) => {
                                    const { material_id, name, material_code } = material ?? {};
                                    const { outbound_material_id } = row ;
                                    return (
                                        <>
                                            <div>
                                                {
                                                    state?.edit_mode === outbound_material_id ?
                                                    (
                                                        <>
                                                            <Popover
                                                            content={<SearchMaterial form={form} default_material_id={material_id} customFunction={(material_id) => customFunction(index, material_id,)} />}
                                                            trigger={['click']}
                                                            open={state?.open}
                                                            onOpenChange={handleOpenChange}
                                                            placement='bottomLeft'
                                                            overlayInnerStyle={{padding : 0,}}
                                                            >
                                                                <Button
                                                                // loading={is_loading}
                                                                style={{width:'100%', textAlign:'start',}}
                                                                onClick={() => setState(state => ({...state, material_modal : true, }))}
                                                                >
                                                                    {name}
                                                                </Button>
                                                            </Popover>
                                                        </>
                                                    )
                                                    :
                                                    (
                                                        <>
                                                            <div>
                                                                <span>{name}</span>
                                                            </div>
                                                            <div>
                                                                <span style={{ fontSize:12, color : 'var(--secondary-text-color)' }}>{material_code}</span>
                                                            </div>
                                                        </>
                                                    )
                                                }
                                                
                                            </div>
                                        </>
                                    )
                                }
                            },{
                                title : 'Location',
                                dataIndex : 'location',
                                key : 'location',
                                width : '30%',
                                render : (location, row, index) => {
                                    const { location_id, name, warehouse_id } = location ?? {};
                                    const { outbound_material_id } = row;
                                    return (
                                        <>
                                            <div>
                                                {
                                                    state?.edit_mode === outbound_material_id ?
                                                    (
                                                        <>
                                                <Select
                                                // onChange={(e) => onFieldChange('location_id', e)}
                                                onChange={(e) => onRowChange(index, 'location_id', e)}
                                                defaultValue={location_id}
                                                options={state?.locations?.map((location, index) => {
                                                    const { location_id, name, } = location;
                                                    return (
                                                        {   
                                                            value : location_id, 
                                                            label : (
                                                                <>
                                                                    <Space>
                                                                        <span>{name}</span>
                                                                    </Space>
                                                                    
                                                                </>
                                                            ), 
                                                        })
                                                })}
                                                placeholder={"Select Location"} 
                                                style={{minWidth:200,}}
                                                />
                                            </>
                                                    )
                                                    :
                                                    (
                                                        <>
                                                            <div>
                                                                <span>{name}</span>
                                                            </div>
                                                        </>
                                                    )
                                                }
                                                
                                            </div>
                                        </>
                                    )
                                }
                            },{
                                title : 'Quantity',
                                dataIndex : 'quantity',
                                key : 'quantity',
                                width : '30%',
                                render : (quantity, row, index) => {
                                    const { material, outbound_material_id } = row;
                                    return (
                                        <>
                                            <div>
                                                {
                                                    state?.edit_mode === outbound_material_id ?
                                                    (
                                                        <>
                                                            <Input
                                                            defaultValue={quantity}
                                                            // value={material_quantity}
                                                            type='number'
                                                            onChange={(e) => onRowChange(index, 'quantity', e?.target?.value)}
                                                            suffix={
                                                                <>
                                                                    <span style={{fontSize:12, color:'var(--secondary-text-color)'}}>{(material?.uom?.code)}</span>
                                                                </>
                                                            }
                                                            />
                                                        </>
                                                    )
                                                    :
                                                    (
                                                        <>
                                                            <Space>
                                                                <span>{quantity}</span>
                                                                <span style={{ fontSize:12, color : 'var(--secondary-text-color)' }}>{material?.uom?.name}</span>
                                                            </Space>
                                                        </>
                                                    )
                                                }
                                                
                                            </div>
                                        </>
                                    )
                                }
                            },{
                                title : 'Action',
                                dataIndex : 'outbound_material_id',
                                key : 'outbound_material_id',
                                width : '10%',
                                render : (outbound_material_id, row, index) => {
                                    return (
                                        <>
                                            {
                                                outbound_status?.name == 'Delivered' || outbound_status?.is_complete ?
                                                (
                                                    <>
                                                    </>
                                                ) 
                                                : 
                                                (
                                                    state?.edit_mode === outbound_material_id ?
                                                (
                                                    <>
                                                        <Space>
                                                            <Button
                                                            loading={state?.save_loading === outbound_material_id}
                                                            size='small' 
                                                            icon={<CheckOutlined 
                                                            style={{color :'var(--secondary-text-color)'}} />}
                                                            onClick={() => onUpdateOutboundMaterial(outbound_material_id)}
                                                            />
                                                            <Button
                                                            onClick={() => setState(state => ({...state, edit_mode : null, }))}
                                                            size='small'
                                                            icon={<CloseOutlined style={{color :'var(--secondary-text-color)'}} />}
                                                            />
                                                        </Space>
                                                    </>
                                                )
                                                :
                                                (
                                                    <>
                                                        <Dropdown
                                                        menu={{ 
                                                            items : [{
                                                                key: '1',
                                                                icon : <EditOutlined style={{color : 'var(--secondary-text-color)'}} />,
                                                                label: (
                                                                    <>
                                                                        <span>Edit</span>
                                                                    </>
                                                                ),
                                                                onClick : async () => {
                                                                    await toggleEditMode(outbound_material_id);
                                                                },
                                                            },
                                                            {
                                                                key: '2',
                                                                label: (
                                                                    <>
                                                                        <div>
                                                                            <span>Delete</span>
                                                                        </div>
                                                                    </>
                                                                ),
                                                                icon: <DeleteOutlined style={{color : 'var(--secondary-text-color)'}} />,
                                                                onClick : async () => {
                                                                    await deleteConfirm(outbound_material_id);
                                                                }
                                                            },]
                                                        }}
                                                        >
                                                            <Button loading={(state?.edit_mode_loading === outbound_material_id)} size='small' icon={<EllipsisOutlined style={{color : 'var(--secondary-text-color)'}} />}/>
                                                        </Dropdown>
                                                    </>
                                                )
                                                )
                                            }
                                        </>
                                    )
                                }
                            }]}
                            />
                        </Form.Item>
                    </Form>
                </div>

                <div>
                    
                </div>


            </Spin>
            {contextHolder}
            <Modal
            open={state?.new_modal}
            title="Add Item To Outbound"
            onCancel={() => setState(state => ({...state, new_modal : false,}))}
            onOk={() => new_form?.submit()}
            confirmLoading={state?.save_loading}
            >
                <Form
                form={new_form}
                layout='vertical'
                onFinish={onSaveOutboundMaterial}
                >
                    <NewOutboundMaterialForm refresh={state?.refresh} form={new_form} warehouse_id={warehouse_id}/>
                </Form>
            </Modal>
        </>
    );
}

export default OutboundMaterial;

