import React, { useEffect, useState } from 'react';
// import WorkOrderList from './work_order_list';
import MenuTitleComponent from '../../../helpers/components/menu_title.component';
import { Button, Checkbox, DatePicker, Form, Input, List, Modal, Space, Tabs, Tag, notification } from 'antd';
import FilterBullet from '../../../helpers/components/filter_bullet';
import { CaretDownFilled, SearchOutlined } from '@ant-design/icons';
import WorkOrderList from './work_order_list';
import workOrderService from '../../../services/production/work_order.service';
import userService from '../../../services/admin/user.service';
import NewWorkOrder from './new_work_order';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
const { RangePicker } = DatePicker;

const WorkOrderLayout = () => {
    const history = useHistory();
    const { innerWidth } = window;
    const [timer, setTimer] = useState(null);
    const [form] = Form.useForm();
    const [state, setState] = useState({
        loading : false,
        work_order_statuses : [],
        tab_items : [],
        assignees: [],

        // modal
        new_work_order_modal : false,
        save_loading : false,

        filter_changed: false,
    });

    const [filter, setFilter] = useState({
        search: null,
        work_order_status: null,
        assignee: null,
        startDate: null,
        endDate: null,
    });

    const doneTyping = async (input) => {
        clearTimeout(timer);
        const newTimer = setTimeout(() => {
            setFilter(filter => ({ ...filter, search: input, }));
        }, 300);
        setTimer(newTimer);
    }

    const onCheckChange = (key, checkedValues) => {
        setFilter(filter => ({ ...filter, [key]: checkedValues }));
        setState(state => ({ ...state, filter_changed: true, }));
    }

    const onDateChange = (date, dateString) => {
        setFilter(filter => ({ ...filter, startDate: dateString[0], endDate: dateString[1], }));
        setState(state => ({ ...state, filter_changed: true, }));
    };

    const fetchInitialization = async () => {
        setState(state => ({...state, loading : true, }));
        const workOrderStatusResponse = await workOrderService?.getWorkOrderStatuses();
        const { work_order_statuses } = workOrderStatusResponse;
        const userResponse = await userService?.getUsers();
        const { users } = userResponse;
        
        setState(state => ({ ...state, loading: false, work_order_statuses: work_order_statuses, assignees: users }));
    }

    const openWorkOrderModal = async (open) => {
        setState(state => ({...state, new_work_order_modal : open}));
    }

    const saveWorkOrder = async () => {
        setState(state => ({...state, save_loading : true, }))
        const { productList, assignee_id, production_line_id, remark, title, issue_date, start_date, end_date, } = form?.getFieldValue();

        // call save work order api
        const saveWorkOrderResponse = await workOrderService?.saveWorkOrder({
            productList, assignee_id, production_line_id, remark, title, 
            issue_date : issue_date ?? (moment()?.format("YYYY-MM-DD")), 
            start_date, 
            end_date,
        })
        
        setTimeout(() => {
            // const { save_workorder } = saveWorkOrderResponse;
            // setState(state => ({...state, save_loading : false,}));

            // notification?.success({
            //     message : 'New Work Order Created',
            //     description : save_workorder?.running_number,
            // })

            // // redirect to saved item page
            // history.push(`/production/work_order/${save_workorder?.work_order_id}`);

            setState(state => ({...state, save_loading : false, }));

            if(saveWorkOrderResponse)
                {
                    const { save_workorder } = saveWorkOrderResponse;
                    form.resetFields();
                    notification?.success({
                    message : "New Work Order Created",
                    description : save_workorder?.running_number,
                })
                // redirect to saved item page
                history.push(`/production/work_order/${save_workorder?.work_order_id}`);
            }
        }, 500);
       
    }

    useEffect(() => {
        fetchInitialization();
    }, [])

    return (
        <>
            <div style={{textAlign:'start',}}>
                <MenuTitleComponent />
            </div>
            <div>
                <div className='filter-panel' style={{marginBottom:12,}}>
                    <div>
                        <Input
                        onChange={(e) => doneTyping(e.target.value)}
                        name="search"
                        prefix={<SearchOutlined style={{color :'var(--secondary-text-color)'}} />}
                        placeholder='Search Work Order'
                        style={{minWidth: '100%',}}
                        allowClear
                        />
                    </div>

                    <div style={{display:'flex', alignItems:'center', marginTop:12, gap:8,}}>
                        <div>
                            <div className='filter-panel' style={{textAlign:'start'}}>
                                <Space direction={innerWidth > 768 ? 'horizontal' : 'vertical'}>
                                    <FilterBullet
                                        popover={
                                            <>
                                                <div style={{ maxHeight: 400, overflowY: 'auto' }}>
                                                    <div style={{ padding: 12, }}>
                                                        <Input
                                                            prefix={<SearchOutlined style={{ color: 'var(--secondary-text-color)' }} />}
                                                            placeholder='Search Status'
                                                            allowClear
                                                            onChange={async (e) => {
                                                                const workOrderStatusResponse = await workOrderService?.getWorkOrderStatuses({ search: e.target.value, size:100 });
                                                                setState(state => ({ ...state, work_order_statuses: workOrderStatusResponse?.work_order_statuses }))
                                                            }}
                                                        />
                                                    </div>
                                                    <Checkbox.Group
                                                        value={filter?.work_order_status}
                                                        onChange={(checkedValues) => onCheckChange('work_order_status', checkedValues)}
                                                        style={{ display: 'block', }}
                                                    >
                                                        <div style={{ padding: '0 12px', }}>
                                                            <List
                                                                style={{ width: 280, }}
                                                                dataSource={state?.work_order_statuses}
                                                                renderItem={(item, index) => {
                                                                    const { work_order_status_id, name, } = item ?? {};
                                                                    return (
                                                                        <List.Item style={{ width: '100%' }} key={index}>
                                                                            <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                                                                                <div>
                                                                                    <span>{name}</span>
                                                                                </div>
                                                                                <div style={{ marginLeft: 'auto' }}>
                                                                                    <Checkbox
                                                                                        value={work_order_status_id}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </List.Item>
                                                                    )
                                                                }}
                                                            />
                                                        </div>
                                                    </Checkbox.Group>
                                                </div>
                                            </>
                                        }
                                    >
                                        <div>
                                            <Space>
                                                <div>
                                                    <span>{`Status ${filter?.work_order_status?.length > 0 ? `: ${filter?.work_order_status?.length} items` : ""}`}</span>
                                                </div>

                                                <div>
                                                    <CaretDownFilled style={{ color: '#a0aec0' }} />
                                                </div>
                                            </Space>
                                        </div>
                                    </FilterBullet>

                                    <FilterBullet
                                        popover={
                                            <>
                                                <div style={{ maxHeight: 400, overflowY: 'auto' }}>
                                                    <div style={{ padding: 12, }}>
                                                        <Input
                                                            prefix={<SearchOutlined style={{ color: 'var(--secondary-text-color)' }} />}
                                                            placeholder='Search Assignee'
                                                            allowClear
                                                            onChange={async (e) => {
                                                                const userResponse = await userService?.getUsers({ search: e.target.value, size:100 });
                                                                setState(state => ({ ...state, assignees: userResponse?.users }))
                                                            }}
                                                        />
                                                    </div>
                                                    <Checkbox.Group
                                                        value={filter?.assignee}
                                                        onChange={(checkedValues) => onCheckChange('assignee', checkedValues)}
                                                        style={{ display: 'block', }}
                                                    >
                                                        <div style={{ padding: '0 12px', }}>
                                                            <List
                                                                style={{ width: 280, }}
                                                                dataSource={state?.assignees}
                                                                renderItem={(item, index) => {
                                                                    const { user_id, name, } = item ?? {};
                                                                    return (
                                                                        <List.Item style={{ width: '100%' }} key={index}>
                                                                            <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                                                                                <div>
                                                                                    <span>{name}</span>
                                                                                </div>
                                                                                <div style={{ marginLeft: 'auto' }}>
                                                                                    <Checkbox
                                                                                        value={user_id}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </List.Item>
                                                                    )
                                                                }}
                                                            />
                                                        </div>
                                                    </Checkbox.Group>
                                                </div>
                                            </>
                                        }
                                    >
                                        <div>
                                            <Space>
                                                <div>
                                                    <span>{`Assignee ${filter?.assignee?.length > 0 ? `: ${filter?.assignee?.length} items` : ""}`}</span>
                                                </div>

                                                <div>
                                                    <CaretDownFilled style={{ color: '#a0aec0' }} />
                                                </div>
                                            </Space>
                                        </div>
                                    </FilterBullet>
                                    <RangePicker onChange={onDateChange} />
                                </Space>
                            </div>
                        </div>

                        <div style={{marginLeft:'auto'}}>
                            <Button type='primary' onClick={() => openWorkOrderModal(true)}>New Work Order</Button>
                        </div>
                    </div>
                </div>

                <div>
                    <WorkOrderList filter={filter} filter_changed={state?.filter_changed} />
                </div>
            </div>

            <Modal
            style={{top : 20,}}
            open={state?.new_work_order_modal}
            onCancel={() => openWorkOrderModal(false)}
            width={800}
            title="New Work Order"
            onOk={() => form.submit()}
            confirmLoading={state?.save_loading}
            >
                <Form
                onFinish={saveWorkOrder}
                form={form}
                layout='vertical'
                >
                    <NewWorkOrder form={form} />
                </Form>
            </Modal>
        </>
    );
}

export default WorkOrderLayout;