import { Avatar, Button, Dropdown, Image, Menu, Popover, Space, Skeleton, Spin } from 'antd';
import { LogoutOutlined, UserOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import menuService from '../../services/admin/menu.service';
import Icon from '@ant-design/icons';
import { Link, useLocation, useHistory  } from 'react-router-dom';
import DynamicSvg from '../../helpers/components/dynamic_svg';
import authService from '../../services/admin/auth.service';
import { useSelector } from 'react-redux';
//   const items = [
//     {
//         key: '1',
//         disabled: true,
//         style: { padding: '5px 60px' },
//         label: (
//             <>
//                 <div style={{ margin: 'auto', textAlign: 'center' }}>
//                     <div style={{ paddingBottom: 10 }}>
//                         <Avatar style={{ backgroundColor: '#fde3cf', color: '#f56a00' }}>{initial}</Avatar>
//                     </div>
//                     <div>
//                         <span style={{ color: '#000' }}>{authRedux?.auth?.username}</span>
//                     </div>
//                     <div>
//                         <span style={{ color: '#000', color : 'var(--secondary-text-color)', }}>{authRedux?.auth?.full_name}</span>
//                     </div>
//                 </div>
//             </>
//         ),
//     },
//     {
//         type: 'divider',
//     },
//     {
//         key : '3',
//         icon : <UserOutlined />,
//         label : (
//             <>
//                 <Link to="/account/profile">
//                     Account
//                 </Link>
//             </>
//         )
//     },
//     {
//         key: '2',
//         icon: <LogoutOutlined />,
//         onClick: handleLogout,
//         label: (
//             <>
//                 <span>
//                     Logout
//                 </span>
//             </>
//         ),
//     },
// ];

const MasterSider = () => {
    const authRedux = useSelector(state => state?.auth);
    const initial = authRedux?.auth?.name?.charAt(0)?.toUpperCase();

    const [state, setState] = useState({
        loading : false,
        modules : [],

        selected_module : null,
    })

    const changeMenu = (module_id) => {
        
        setState(state => ({...state, selected_module : `${module_id}`}));
    }

    const fetchModules = async () => {
        setState(state => ({...state, loading : true,}));
        const moduleResponse = await menuService?.getModules();

        setState(state => ({...state, loading : false, modules : moduleResponse?.modules}));
    }

    useEffect(() => {
        fetchModules();
    }, [])

    const handleLogout = async () => {
        setState(state => ({...state, loading : true,}));
        const logoutResponse = await authService?.logout({});
        setTimeout(() => {
            setState(state => ({...state, loading : false, }));
            window.location.reload(false);
        }, 2000);
    }

    // const resetPassword = async () => {
    //     setState(state => ({...state, loading : true,}));
    //     const changePasswordResponse = await authService?.changePassword({
    //         password : 'test111',
    //     });
    //     setTimeout(() => {
    //         setState(state => ({...state, loading : false, }));
    //     }, 2000);
    // }

    return (
        <>
            {
                state?.loading ?
                (
                    <>
                        <div style={{padding:12,}}>
                            <Skeleton active />
                        </div>
                    </>
                )
                :
                (
                    <>
                        {/* user detail */}
                        <div>
                            <div style={{border:'1px solid #f0f0f0', margin:12, borderRadius:4,}}>
                                {/* <Popover content={content} title="BH">
                                    <Avatar style={{ backgroundColor: '#fde3cf', color: '#f56a00', cursor: 'pointer' }}>
                                        BH
                                    </Avatar>
                                </Popover> */}
                                <Dropdown
                                    arrow
                                    menu={{
                                        items: [
                                            {
                                                key: '1',
                                                disabled: true,
                                                style: { padding: '5px 60px' },
                                                label: (
                                                    <>
                                                        <div style={{ margin: 'auto', textAlign: 'center' }}>
                                                            <div style={{ paddingBottom: 10 }}>
                                                                <Avatar style={{ backgroundColor: '#fde3cf', color: '#f56a00' }}>{initial}</Avatar>
                                                            </div>
                                                            <div>
                                                                <span style={{ color: '#000' }}>{authRedux?.auth?.username}</span>
                                                            </div>
                                                            <div>
                                                                <span style={{ color: '#000', color : 'var(--secondary-text-color)', }}>{authRedux?.auth?.full_name}</span>
                                                            </div>
                                                        </div>
                                                    </>
                                                ),
                                            },
                                            {
                                                type: 'divider',
                                            },
                                            {
                                                key : '3',
                                                icon : <UserOutlined />,
                                                label : (
                                                    <>
                                                        <Link to="/reset_password/">
                                                            Reset Password
                                                        </Link>
                                                    </>
                                                )
                                            },
                                            {
                                                key: '2',
                                                icon: <LogoutOutlined />,
                                                onClick: handleLogout,
                                                label: (
                                                    <>
                                                        <span>
                                                            Logout
                                                        </span>
                                                    </>
                                                ),
                                            },
                                        ]
                                    }}
                                    trigger={['click']}>
                                        <Avatar style={{ backgroundColor: '#fde3cf', color: '#f56a00', cursor: 'pointer' }}>{initial}</Avatar>
                                    </Dropdown>
                            </div>
                        </div>
                        <Menu
                        className='main-menu'
                        theme='light'
                        mode='inline'
                        selectedKeys={state?.selected_module}
                        >
                            {/* Dynamic Menu */}
                            {
                                state?.modules?.map((module, index) => {
                                    const { module_id, name, description, link, icon, sequence } = module;
                                    return (
                                        <Menu.Item
                                        className='main-menu-item'
                                        onClick={() => changeMenu(module_id)}
                                        key={`${module_id}`}
                                        icon={<Icon component={() => (<DynamicSvg image={icon} color={state?.selected_module == module_id ? '#fff' : '#6f7287'} size={16} />)} />}
                                        >
                                            <Link to={link}>
                                                {name}
                                            </Link>
                                        </Menu.Item>
                                    )
                                })
                            }
                        </Menu>

                        
                    </>
                )
            }
        </>
    );
}

export default MasterSider;