import axios from 'axios';
import serviceHelper from '../../helpers/functions/service.helper';

async function getUoms(query){
    const queryString = serviceHelper.queryString(query);
    const uomResponse = await axios.get(`config/uom/get?${queryString}`, { withCredentials : true, });
    return uomResponse?.data;
}

async function getUomList(query){
    const queryString = serviceHelper.queryString(query);
    const uomResponse = await axios.get(`config/uom/get/list?${queryString}`, { withCredentials : true, });
    return uomResponse?.data; 
}

async function getUsedUomList(query) {
    const queryString = serviceHelper.queryString(query);
    const uomResponse = await axios.get(`config/uom/getuse/list`, { withCredentials: true, });
    return uomResponse?.data;
}

async function updateUom(uom_id, params){
    const updateUomResponse = await axios.post(`config/uom/${uom_id}/update`, params, { withCredentials : true, });
    return updateUomResponse?.data;
}

async function saveUom(params){
    const saveUomResponse = await axios.post(`config/uom/save`, params, { withCredentials : true, });
    return saveUomResponse?.data;
}

async function deleteUom(uom_id){
    const deleteUomResponse = await axios.delete(`config/uom/${uom_id}/delete`, { withCredentials : true, });
    return deleteUomResponse?.data;
}

export default {
    getUoms,
    getUomList,
    getUsedUomList,
    updateUom,
    saveUom,
    deleteUom,
}