import { Button, Col, Form, Image, Input, Popover, Row, Table } from 'antd';
import React, { useEffect, useState } from 'react';
import bomService from '../../../services/production/bom.service';
import SearchMaterial from './search_material';
import materialService from '../../../services/config/material.service';
import { DeleteOutlined, PlusOutlined, UndoOutlined } from '@ant-design/icons';
import { uid } from 'react-uid';
import moment from 'moment';

const BomEditMaterial = ({ bom, form, refresh }) => {
    const [state, setState] = useState({
        visibleIndex : null,
        loading : false,
        bom_materials : [],

        // custom
        select_loading : null,
    })

    const fetchBomMaterials = async (bom_id) => {
        setState(state => ({...state, loading : true,}));
        const bomMaterialResponse = await bomService?.getBomMaterials(bom_id);

        const { bom_materials } = bomMaterialResponse;
        setState(state => ({...state, loading : false, bom_materials : bom_materials,}));

        //dynamic set form
        form.setFieldsValue({ bom_materials : bom_materials, });
    }

    const customFunction = async (index, material_id,) => {
        const materialResponse = await materialService?.getMaterials({ material_id });
        const { rows : materials, count } = materialResponse?.materials;
        const material = materials?.length > 0 ? materials[0] : null;

        let bom_material_arr = state?.bom_materials ?? [];
        setState(state => ({...state, select_loading : index }));
        bom_material_arr[index].material_id = material_id;
        bom_material_arr[index].material = material;
        bom_material_arr[index].updated = true;
        setState(state => ({...state, visibleIndex : null }));

        setTimeout(() => {
            setState(state => ({...state, bom_materials : bom_material_arr, select_loading : null, }));
            //dynamic set form
            form.setFieldsValue({ bom_materials : bom_material_arr, });
        }, 200);
    }

    const onRowChange = async (index, key, value) => {
        let bom_material_arr = state?.bom_materials ?? [];
        bom_material_arr[index][key] = value;
        bom_material_arr[index].updated = true;

        setState(state => ({...state, bom_materials : bom_material_arr, }));
        //dynamic set form
        form.setFieldsValue({ bom_materials : bom_material_arr, });
    }

    const addRow = async () => {
        const generate_uid = (`${Math.random()}_${moment()?.format("YYYY-MM-DD")?.toString()}`);
        let bom_material_arr = [...state?.bom_materials, { uid : generate_uid, bom_material_id : null, bom_id : bom?.bom_id, }];

        setState(state => ({...state, bom_materials : bom_material_arr, }));
        //dynamic set form
        form.setFieldsValue({ bom_materials : bom_material_arr, });
    }

    const removeRow = async (uid) => {
        let bom_material_arr = state?.bom_materials ?? [];
        // Create a new array without the object at the specified index
        bom_material_arr = bom_material_arr?.filter(x => x?.uid != uid);
        setState(state => ({...state, bom_materials : bom_material_arr, }));
        //dynamic set form
        form.setFieldsValue({ bom_materials : bom_material_arr, });
    }

    const handleOpenChange = (e, index) =>
    {
        if(e === true)
        {
            setState(state => ({...state, visibleIndex : index}));
        } else {
            setState(state => ({...state, visibleIndex : null}));
        }
    };

    useEffect(() => {
        bom?.bom_id && fetchBomMaterials(bom?.bom_id);
    }, [bom?.bom_id, refresh,])

    return (
        <>
            <div>
                <div style={{display:'flex',}}>
                    <div>
                        <span
                        className='header-font' 
                        style={{
                            fontWeight:500, 
                            fontSize:16,
                        }}>
                            {`Bill of Materials`}
                        </span>
                    </div>

                    <div style={{marginLeft:'auto'}}>
                        <Button onClick={addRow}>Add Item</Button>
                    </div>
                </div>

                <div style={{marginTop:12,}}>
                    <Form.Item name="bom_materials">
                        {
                            state?.bom_materials?.length > 0 ?
                            (
                                <>
                                    <Table
                                    rowClassName={(row) => {
                                        const { deleted } = row;
                                        return deleted ? "row-deleted" : "";
                                    }}
                                    dataSource={state?.bom_materials}
                                    scroll={{ x : 400, }}
                                    className='hub-table bordered'
                                    columns={[{
                                        title : 'Item',
                                        dataIndex : 'material',
                                        key : 'material',
                                        width : '35%',
                                        render : (material, row, index) => {
                                            const { bom_material_id, uid } = row;
                                            const { material_id : default_material_id, name, material_code, uom } = material ?? {};
                                            const is_loading = state?.select_loading == index;
                                            return (
                                                <>
                                                    <div>
                                                        <div>
                                                            <Form.Item name="bom_material_material_id">
                                                                <div>
                                                                    <Popover
                                                                    content={<SearchMaterial default_material_id={default_material_id} form={form} customFunction={(material_id) => customFunction(index, material_id,)} />}
                                                                    trigger={['click']}
                                                                    open={state?.visibleIndex === index}
                                                                    onOpenChange={(e) => handleOpenChange(e, index)}
                                                                    placement='bottomLeft'
                                                                    overlayInnerStyle={{padding : 0,}}
                                                                    >
                                                                        <Button
                                                                        loading={is_loading}
                                                                        style={{width:'100%', textAlign:'start',}}
                                                                        onClick={() => setState(state => ({...state, material_modal : true, }))}
                                                                        >
                                                                            {name}
                                                                        </Button>
                                                                    </Popover>
                                                                </div>
                                                            </Form.Item>
                                                        </div>
                                                    </div>
                                                </>
                                            )
                                        }
                                    },{
                                        title : 'Quantity',
                                        dataIndex : 'quantity',
                                        key : 'quantity',
                                        width : '20%',
                                        render : (quantity, row, index) => {
                                            const { material } = row ?? {};
                                            // const { bom_materials } = form.getFieldsValue();
                                            // const form_quantity = (bom_materials[index]?.quantity);
                                            return (
                                                <>
                                                    <Form.Item>
                                                        <Input
                                                        defaultValue={quantity}
                                                        // value={form_quantity}
                                                        type='number'
                                                        onChange={(e) => onRowChange(index, 'quantity', e?.target?.value)}
                                                        suffix={
                                                            <>
                                                                <span style={{fontSize:12, color:'var(--secondary-text-color)'}}>{(material?.uom?.code)}</span>
                                                            </>
                                                        }
                                                        />
                                                    </Form.Item>
                                                </>
                                            )
                                        }
                                    },{
                                        title : 'Action',
                                        dataIndex : 'bom_material_id',
                                        key : 'bom_material_id',
                                        width : '10%',
                                        render : (bom_material_id, row, index) => {
                                            const { deleted, uid } = row;

                                            const icon = deleted ? <UndoOutlined style={{color:'var(--secondary-text-color)'}} /> : <DeleteOutlined style={{color:'var(--secondary-text-color)'}} />;
                                            return (
                                                <>
                                                    <Form.Item>
                                                    {
                                                        bom_material_id ?
                                                        (
                                                            <>
                                                                <Button
                                                                onClick={() => onRowChange(index, 'deleted', !deleted)} 
                                                                icon={icon} 
                                                                />
                                                            </>
                                                        )
                                                        :
                                                        (
                                                            <>
                                                                <Button
                                                                onClick={() => removeRow(uid)}
                                                                icon={<DeleteOutlined style={{color:'var(--secondary-text-color)'}} />}
                                                                />
                                                            </>
                                                        )
                                                    }
                                                    </Form.Item>
                                                </>
                                            )
                                        }
                                    },]}
                                    />
                                </>
                            )
                            :
                            (
                                <>
                                    <div>
                                        <div style={{background:'var(--secondary-button-color)', padding:24, borderRadius: 24, textAlign:'center',}}>
                                            <div>
                                                <div><span style={{fontSize:24, fontWeight:'bold',}}>No Material</span></div>
                                            </div>
                                            <div>
                                                <span style={{color:'var(--secondary-text-color)'}}>You haven't add any material yet.</span>
                                            </div>

                                            <div style={{marginTop:24,}}>
                                                <Button onClick={addRow} icon={<PlusOutlined />} type='primary' size='large'>Add Materials</Button>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )
                        }
                        
                    </Form.Item>
                </div>
            </div>
        </>
    );
}

export default BomEditMaterial;