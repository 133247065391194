import axios from 'axios';
import serviceHelper from '../../helpers/functions/service.helper';

async function getLines(query){
    const queryString = serviceHelper.queryString(query);
    const lineResponse = await axios.get(`production/line/get?${queryString}`, { withCredentials : true, });
    return lineResponse?.data;
}

async function saveLine(params){
    const saveLineResponse = await axios.post(`production/line/save`, params, { withCredentials : true, });
    return saveLineResponse?.data;
}

async function updateLine(production_line_id, params){
    const updateLineResponse = await axios.post(`production/line/${production_line_id}/update`, params, { withCredentials : true, });
    return updateLineResponse?.data;
}

async function deleteLine(production_line_id){
    const deleteLineResponse = await axios.delete(`production/line/${production_line_id}/delete`, { withCredentials : true, });
    return deleteLineResponse?.data;
}

export default {
    getLines,
    saveLine,
    updateLine,
    deleteLine,
}