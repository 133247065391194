import React, { useEffect, useState } from 'react';
import materialTypeService from '../../../services/config/material_type.service';
import { Form, Input, Skeleton } from 'antd';

const MaterialTypeEditDetail = ({ material_type_id, form }) => {
    const [state, setState] = useState({
        loading : false,
        material_type : null,
    })

    const fetchMaterialType = async () => {
        setState(state => ({...state, loading : true,}));
        const materialTypeResponse = await materialTypeService?.getMaterialTypes({
            material_type_id,
        });
        const { rows, } = materialTypeResponse?.material_types;
        const material_type = rows?.length > 0 ? rows[0] : null;
        const { name, } = material_type;

        setTimeout(() => {
            form.setFieldsValue({ name, });
            setState(state => ({...state, loading : false, material_type : material_type,}));
        }, 500);
    }

    useEffect(() => {
        material_type_id && fetchMaterialType();
    }, [material_type_id])

    return (
        <>
            {
                state?.loading ?
                (
                    <>
                        <div>
                            <Skeleton active />
                        </div>
                    </>
                )
                :
                (
                    <>
                        <div>
                            <Form.Item name={'name'} label={'Item Type'}>
                                <Input placeholder='Item Type Name' />
                            </Form.Item>
                        </div>
                    </>
                )
            }
        </>
    );
}

export default MaterialTypeEditDetail;