import React from 'react';
import ConfigUomLayout from './uom/config_uom_layout';
import ContainerComponent from '../../helpers/components/container.component';

const ConfigUomIndex = () => {
    return (
        <>
            <ContainerComponent span={22} top={20}>
                <ConfigUomLayout />
            </ContainerComponent>
        </>
    );
}

export default ConfigUomIndex;